import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  handleGenbillState,
  handleRedirect,
} from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import Popover from "@mui/material/Popover";
import { useSelector } from "react-redux";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CustomDrawer } from "@genrecp/g2clientportal-common";
import ChangeBillOwnerDrawerForm from "./ChangeBillOwnerDrawerForm";

type BillDetailsLinkType = {
  selectedMenu: string;
  brokerBillingExternalViewer: boolean;
};

const BillDetailsLink = ({
  selectedMenu,
  brokerBillingExternalViewer,
}: BillDetailsLinkType) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openDrawer, setDrawerOpen] = React.useState<boolean>(false);
  const historyTab = selectedMenu === "HISTORY";
  const showOwnerDrawerOnEditSetup = !(selectedMenu === "BILLDETAILS");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let linkText: string = "Go To Edit Bill Setup";
  let redirectPath = useSelector(
    (state: RootState) => state.genbill.redirectPath
  ); 

  if (selectedMenu !== "BILLDETAILS") {
    redirectPath = "BILLDETAILS";
    linkText = "Go to Bill Details";
  } else {
    redirectPath = (redirectPath === "BILLDETAILS") ? "USERACCESS": redirectPath;
  }  

  const handleNavigation = () => {
    dispatch(handleRedirect(selectedMenu));
    dispatch(
      handleGenbillState({
        type: "HANDLE_GENBILL_NAV",
        payload: { selectedMenu: redirectPath },
      })
    );
    handleClose();
  };

  const openDrawerHandler = () => {
    setDrawerOpen(true);
    handleClose();
  };

  const closeDrawerHandler = () => {
    setDrawerOpen(false);
  };

  return brokerBillingExternalViewer || historyTab ? null : (
    <Fragment>
      <IconButton onClick={handleClick} size="small">
        <MenuSharpIcon sx={{ color: "#fff" }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ListItemButton onClick={handleNavigation}>
          <ListItemText primary={<Typography>{linkText}</Typography>} />
        </ListItemButton>
        {showOwnerDrawerOnEditSetup && (
          <ListItemButton onClick={openDrawerHandler}>
            <ListItemText
              primary={<Typography>Change Bill Owner</Typography>}
            />
          </ListItemButton>
        )}
      </Popover>
      <CustomDrawer
        drawerWidth={600}
        open={openDrawer}
        setOpen={setDrawerOpen}
        title="Change Bill Owner"
        children={
          <ChangeBillOwnerDrawerForm closeDrawerHandler={closeDrawerHandler} />
        }
      />
    </Fragment>
  );
};

export default BillDetailsLink;
