import SubHome from "./subHome/SubHome";
import Grid from "@mui/material/Grid";
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY!);

function App() {

  return (
    <>
      <Grid container spacing={2} maxWidth="lg"></Grid>

      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} item>
          <SubHome />
        </Grid>
      </Grid>
    </>
  );
}

export default App;
export * from "./genbillUtils";
