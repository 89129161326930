import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import Button from "../button/Button";
import { TransitionProps } from "@mui/material/transitions/transition";
import { ModalErrors } from "../../util/CommonUtils";
import { DialogTitle, Stack } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import DialogContentText from "@mui/material/DialogContentText";
import useRibbonSnackbar from "../useRibbonSnackbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
  request: ModalErrors;
  setRequest: (v: ModalErrors) => void;
}

const CustomSnackbar: React.FC<Props> = ({ request, setRequest }) => {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const { ErrorModelOpen, errors } = request;

  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    let timeout = copied ? setTimeout(() => setCopied(false), 3000) : undefined;

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied]);

  const handleClose = () => {
    setRequest({
      ...request,
      errors: { messages: [], correlationID: undefined },
      ErrorModelOpen: false,
    });
  };

  const copy = async () => {
    if (!errors.correlationID || errors.messages.length === 0) return;
    else
      try {
        const errorObj = {
          correlationID: errors.correlationID,
          messages: errors.messages,
        };
        await navigator.clipboard.writeText(JSON.stringify(errorObj));
        setCopied(true);
      } catch (err) {
        enqueueSnackbar("Failed to copy", {
          variant: "error",
        });
        console.log(err);
        setCopied(false);
      }
  };

  return (
    <Dialog
      open={
        errors?.status && errors?.status === 401
          ? false
          : ErrorModelOpen && !!errors.messages.length
          ? true
          : false
      }
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
    >
      <DialogTitle>Error</DialogTitle>
      <DialogContent dividers>
        {errors.messages.map((item, idx) => (
          <DialogContentText
            display={"flex"}
            alignItems={"center"}
            gap={1}
            mb={1}
            key={idx}
          >
            <ErrorIcon color="error" /> {item}
          </DialogContentText>
        ))}
      </DialogContent>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={1}
        py={1}
        pr={1}
      >
        {errors.correlationID ? (
          <Button onClick={copy} variant="containedTertiary">
            {copied ? <CheckCircleRoundedIcon /> : <ContentCopyIcon />}
          </Button>
        ) : null}
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CustomSnackbar;