import React, { useEffect, useState } from "react";
import { CashTrackerProps, ErrorReasons } from "../../../CashTrackerUtils";
import QALog from "./QALog";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import {
  handleErrorResponse,
  ModalErrors,
  showInvalidSnackbar,
  SummarySkeleton,
  TextInput,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { delegatedAxios } from "../../../../../../services/axios";
import { QAErrorReasonGridItem } from "../../../../admin/delegatedIssuesAdmin/qaErrorReasons/qaErrorFormUtils";

interface Props extends CashTrackerProps {}

function QAWrapper({ request, dispatch }: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [errorReasons, setErrorReasons] = useState<ErrorReasons[]>([]);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();

  useEffect(() => {
    getErrorReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getErrorReasons() {
    setLoading(true);
    delegatedAxios
      .get(`/QAErrorsReason`)
      .then((res: any) => {
        setLoading(false);
        const { status, data } = res || {};
        if (status === 200) {
          setError(false);
          const mappedData = data
            ? data.map((item: QAErrorReasonGridItem) => ({
                qaErrorReasonTypeID: item.qaErrorReasonTypeID,
                qaErrorReasonTypeText: item.qaErrorReasonTypeText,
              }))
            : [];
          setErrorReasons(mappedData || []);
        } else {
          setErrorReasons([]);
          setError(true);
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        setError(true);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;
    dispatch({
      QA: {
        ...request.QA,
        [name]: checked,
        ...(checked && {
          selectedErrorReasons: undefined,
        }),
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={1.5}
        lg={1.5}
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <Typography variant="labelMedium" fontWeight={"bold"}>
          Fail
        </Typography>
        <Switch
          {...label}
          name="pass"
          id="pass"
          test-id="pass"
          checked={request.QA.pass}
          onChange={handleInputChange}
        />
        <Typography variant="labelMedium" fontWeight={"bold"}>
          Pass
        </Typography>
      </Grid>
      {request.QA.pass ? null : (
        <Grid item md={6} lg={4.5} display={"flex"} gap={1}>
          <SummarySkeleton condition={loading}>
            <Autocomplete
              multiple
              limitTags={1}
              size="small"
              fullWidth
              options={errorReasons}
              value={request.QA.selectedErrorReasons}
              inputValue={request.QA.errorReasonsinputValue}
              onInputChange={(event, newInputValue, reason) => {
                dispatch({
                  QA: {
                    ...request.QA,
                    errorReasonsinputValue: newInputValue,
                  },
                });
              }}
              isOptionEqualToValue={(
                option: ErrorReasons,
                value: ErrorReasons
              ) => option.qaErrorReasonTypeText === value.qaErrorReasonTypeText}
              getOptionLabel={(option: ErrorReasons) =>
                option.qaErrorReasonTypeText!
              }
              onChange={(event, value) => {
                dispatch({
                  QA: {
                    ...request.QA,
                    selectedErrorReasons: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={"Error Reason"}
                  required
                  placeholder=""
                />
              )}
            />
          </SummarySkeleton>
        </Grid>
      )}

      <Grid item md={12}>
        {error && (
          <Typography
            variant="bodyLarge"
            sx={{ textAlign: "center", display: "block" }}
          >
            Something Went Wrong!
          </Typography>
        )}
        {errorReasons.length > 0 && (
          <QALog
            request={request}
            dispatch={dispatch}
            errorReasons={errorReasons}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default QAWrapper;
