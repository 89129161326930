export const commonActionTypes= {
    // STATE_CODE
    GET_USER_STATES_START:"GET_USER_STATES_START",
    GET_USER_STATES_SUCCESS:"GET_USER_STATES_SUCCESS",
    GET_USER_STATES_ERROR:"GET_USER_STATES_ERROR",
    // LOCATION
    GET_USER_LOCATIONS_START:"GET_USER_LOCATIONS_START",
    GET_USER_LOCATIONS_SUCCESS:"GET_USER_LOCATIONS_SUCCESS",
    GET_USER_LOCATIONS_ERROR:"GET_USER_LOCATIONS_ERROR",
    // AUTO_COMPLETE
    SET_AUTOCOMPLETE_RESULT:"SET_AUTOCOMPLETE_RESULT",
    // SEARCH_BY
    SET_SEARCH_BY:"SET_SEARCH_BY",
    //  SUMMARY_LISTING GRID 
    SET_SHOW_GRID:"SET_SHOW_GRID",
    SET_SHOW_DOC:"SET_SHOW_DOC",
      
}