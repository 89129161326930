import {
  DelegatedClientLocations,
  RiskStates,
} from "../experienceReport/utils";

export type IncurredLossesRequestType = {
  clientLocations: DelegatedClientLocations[];
  selectedClientLocations: DelegatedClientLocations[];
  clientLocationsLoading: boolean;
  riskStates: RiskStates[];
  selectedRiskStates:RiskStates[],
  selectedbillPoints:string[],
  billPoints:string[],
  riskStatesLoading: boolean;
  lineOfBusiness:string,
  incurredLossesReport: IncurredLossesReport[],
  runReportLoader:boolean,
  billPointsLoading: boolean,
  firstSubmit: boolean
};

interface IncurredLossesReport {
  claimCount: number;
  sumIncurredAmount: number;
  causeLossCode: string;
  causeLossDescription: null;
}
export type IncurredLossesAction = {
  type: "UPDATE_UNIVERSAL_REQUEST";
  request: Partial<IncurredLossesRequestType>;
};

export type IncurredLossesDispatch = React.Dispatch<IncurredLossesAction>;

export const initialIncurredLossesState: IncurredLossesRequestType = {
  clientLocationsLoading: false,
  clientLocations: [],
  selectedClientLocations:[],
  riskStates: [],
  riskStatesLoading: false,
  billPointsLoading: false,
  selectedRiskStates:[],
  selectedbillPoints:[],
  billPoints: [],
  lineOfBusiness:"all",
  incurredLossesReport:[],
  runReportLoader: false,
  firstSubmit:false
};

export const IncurredLossesReducer = (
  state: IncurredLossesRequestType,
  action: IncurredLossesAction
) => {
  switch (action.type) {
    case "UPDATE_UNIVERSAL_REQUEST":
      return {
        ...state,
        ...action.request
      }
    default:
      return state;
  }
};

export interface IncurredLossesProps {
  request: IncurredLossesRequestType;
  dispatch: IncurredLossesDispatch;
}
