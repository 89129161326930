import { ModalErrors } from "@genrecp/g2clientportal-common";

export type InputProps = {
  [key: string]: string | number;
};
type ColumnType = "number" | "string" | "datetime";
export interface AcccountingGroupColumn {
  name: string;
  type: ColumnType;
  length: number;
}

export interface RowDataType {
  openingBrace?: string;
  compareColumnName: string;
  sqlOperator: string;
  compareToString: string;
  closingBrace?: string;
  compoundOperator: string;
  BillID?: string;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  accountingGroupID?: string;
  createdDate?: string | null;
  createdBy?: string | null;
  order?: number;
}

export const dateParserToString = (v: string) => {
  if (!v) return "";
  const dateParts = v.split("/");
  const reOrderedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
  return new Date(reOrderedDate).toISOString();
};

const checkNumberType = (v: string) => {
  if (isNaN(Number(v))) return true;
  return false;
};

const checkDateType = (v: string) => {
  if (
    !/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/]([0]?[1-9]|[1][0-2])[/]([0-9]{4}|[0-9]{2})$/.test(
      v
    )
  )
    return true;
  return false;
};

export const validateRequired = (obj: RowDataType) => {
  const comparisonObj = {
    compareColumnName: obj?.compareColumnName,
    sqlOperator: obj?.sqlOperator,
    compareToString: obj?.compareToString,
    compoundOperator: obj?.compoundOperator,
  };

  const valuesCheck = Object.values(comparisonObj).some(
    (item) => item === "" || item === undefined
  );
  const keysCheck = Object.keys(obj).length === 0;

  if (keysCheck) return false;
  else if (valuesCheck) return false;
  else return true;
};

const validateTypeCheck = (
  allRows: RowDataType[],
  columnTypeData: AcccountingGroupColumn[]
) => {
  const errors: string[] = [];

  allRows.forEach((item: RowDataType) => {
    columnTypeData.forEach((columnData: AcccountingGroupColumn) => {
      if (item.compareColumnName === columnData.name) {
        // matched column
        if (columnData.type === "number") {
          // Number Check
          if (checkNumberType(item.compareToString)) {
            errors.push(`${columnData.name} expects a valid number!`);
            return;
          }
          if (columnData.length === 0) return;
          if (item.compareToString.length > columnData.length) {
            errors.push(
              `${columnData.name} expects the value length to be less than or equal to ${columnData.length} digits!`
            );
            return;
          }
        }
        if (columnData.type === "datetime") {
          // Date Check
          if (checkDateType(item.compareToString)) {
            errors.push(
              `${columnData.name} expects a valid date of DD/MM/YYYY format!`
            );
            return;
          }
        }
        if (columnData.type === "string") {
          // Date Check
          if (item.compareToString.length > columnData.length) {
            errors.push(
              `${columnData.name} expects the value length to be less than or equal to ${columnData.length} characters!`
            );
            return;
          }
        }
      }
    });
  });

  return errors;
};

export const validateRequest = (
  allRows: RowDataType[],
  columnTypeData: AcccountingGroupColumn[],
  modalErrors: ModalErrors,
  setModalErrors: (v: ModalErrors) => void
) => {
  if (!allRows.length) {
    setModalErrors({
      ...modalErrors,
      errors: {
        correlationID:undefined,
        messages:[`Error! Enter some rules first.`]
      },
      ErrorModelOpen: true,
    });
    return false;
  }

  if (!allRows.every((item: RowDataType) => validateRequired(item))) {
    setModalErrors({
      ...modalErrors,
      errors: {
        correlationID:undefined,
        messages:[`Error! Fill in the required rules.`]
      },
      ErrorModelOpen: true,
    });
    return false;
  }

  if (
    allRows
      .slice(0, allRows.length - 1)
      .some((i: RowDataType) => i.compoundOperator === "END")
  ) {
    setModalErrors({
      ...modalErrors,
      errors: {
        correlationID:undefined,
        messages: [`Error! End Operator can only be in the last item.`]
      },
      ErrorModelOpen: true,
    });
    return false;
  }

  if (allRows[allRows.length - 1].compoundOperator !== "END") {
    setModalErrors({
      ...modalErrors,
      errors: {
        correlationID:undefined,
        messages: [`Error! Please select the last Compound Operator as END.`],
      },
      ErrorModelOpen: true,
    });
    return false;
  }

  const errors = validateTypeCheck(allRows, columnTypeData);

  if (errors.length > 0) {
    // throw error
    setModalErrors({
      ...modalErrors,
      errors: {
        correlationID:undefined,
        messages: errors,
      },
      ErrorModelOpen: true,
    });
    return false;
  }

  return true;
};
