import * as React from "react";
import { CircularProgressProps, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

const Spinner: React.FC<CircularProgressProps> = (props) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress {...props} />
    </Box>
  );
};

export default Spinner;