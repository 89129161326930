import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { storeWithLogger } from "./redux/store";
import { Provider } from "react-redux";
import { SnackBar } from "@genrecp/g2clientportal-common";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import {LicenseManager} from "ag-grid-enterprise";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { StyledEngineProvider } from '@mui/material/styles';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY!);

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const customStore =
  process.env.NODE_ENV === "development" ? storeWithLogger : store;
export const persistedStore = persistStore(customStore);

root.render(
  <Provider store={customStore}>
    <PersistGate loading={null} persistor={persistedStore}>
      <StyledEngineProvider injectFirst>
        <SnackBar>
          <App pca={msalInstance} />
        </SnackBar>
        </StyledEngineProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
