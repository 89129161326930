import { SelectList, showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { Grid, IconButton, MenuItem, SelectChangeEvent } from "@mui/material";
import React from "react";
import { DocArray, DocFolderStructure, DocumentType } from "../adminDocUtils";
import { ViewDocuments } from "./ViewDocuments";
import DeleteIcon from '@mui/icons-material/Delete';
import { commonAxios } from "../../../../../services/axios";

interface Props extends DocumentType {
  docFolderStructure : DocFolderStructure
}

function ViewDoc({documentName,setDocumentName,docFolderStructure}:Props) {
  const [checkedStringItems, setCheckedStringItems] = React.useState<string[]>([]);
  const [checkedFolderStringItems, setCheckedFolderStringItems] = React.useState<string[]>([]);
  const [reloadState, setReloadState] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {enqueueSnackbar} = useRibbonSnackbar();

  function deleteItems() {
    setLoading(true);
    commonAxios.post(`/Documentation/DeleteDocument`, docFolderStructure.data.length > 0 ? checkedFolderStringItems : checkedStringItems).then(res => {
      setLoading(false);
      const {status} = res || {};
      if(status === 200) {
        enqueueSnackbar(`Action was successfully performed!`, {
          variant: "success",
          autoHideDuration:3000
        });
        setCheckedFolderStringItems([]);
        setCheckedStringItems([]);
        setReloadState(!reloadState);
      }

    })
    .catch((e: any) => {
      setLoading(false);
      showInvalidSnackbar(e,enqueueSnackbar);
    });
  }

  return (
    <Grid container gap={1}>
      <Grid md={4} item display={"flex"} alignItems={"center"} gap={1}>
        <SelectList
          label="Documents"
          name="documents"
          id="documents"
          test-id="documents"
          allowempty={false}
          value={documentName.folder}
          onChange={(event: SelectChangeEvent<unknown>) => {
            setCheckedStringItems([]);
            setCheckedFolderStringItems([]);
            setDocumentName({
              ...documentName,
              folder:event.target.value as string,
              subFolder:""
            })
          }}
        >
          {DocArray.map((item, idx) => {
            return (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            );
          })}
        </SelectList>
        {
          checkedStringItems.length > 0 || checkedFolderStringItems.length > 0 ?
          <IconButton onClick={() => deleteItems()}>
            <DeleteIcon />
          </IconButton>
          : null
        }
      </Grid>
      <Grid md={12} item>
        <ViewDocuments 
          reloadState={reloadState}
          checkedStringItems={checkedStringItems}
          setCheckedStringItems={setCheckedStringItems}
          documentName={documentName.folder}
          checkedFolderStringItems={checkedFolderStringItems}
          setCheckedFolderStringItems={setCheckedFolderStringItems}
          loading={loading}
          setLoading={setLoading}
        />
      </Grid>
    </Grid>
  );
}

export default ViewDoc;
