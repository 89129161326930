import styled from "styled-components";

export const LandingHeader = styled.section`
  width: 100%;
  height: 100%;
  padding: 0.5em;
  margin: 0;
  border: none;
  margin-bottom: 0.5em;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const GridContainer = styled.div`
  height: 90%;
  width: 100%;
  padding: 0px;
`;

export interface ViewSchema {
    columnData: string;
    createdBy: string;
    createdDate: string;
    filterData: string;
    isDefault: boolean;
    isSystem: boolean;
    modifiedBy: string | null;
    modifiedDate: string | null;
    screenName: string;
    userGridViewID?: string;
    viewName: string;
  }