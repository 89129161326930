import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  CashTrackerFormStateType,
  cashTrackerStateReducer,
  CreateCommentType,
  initialCashTrackerFormStateType,
  initialcashTrackerMasterFields,
  initialCashTrackerState,
  initialQAState,
  initialQCState,
  initialSuspenseState,
  SuspenseStateType,
} from "./CashTrackerUtils";
import { Grid } from "@mui/material";
import InfoSectionSelector from "./components/InfoSectionSelector";
import CashTrackerActionBar from "./components/CashTrackerActionBar";
import {
  CustomDrawer,
  CustomSnackbar,
  handleErrorResponse,
  handleSuccessResponse,
  ModalErrors,
  showInvalidSnackbar,
  SimpleBackdrop,
  useRibbonSnackbar,
  DrawerCommentForm,
} from "@genrecp/g2clientportal-common";
import {
  CreateCashTrackerPayloadType,
  CreateSuspenseFormPayloadType,
  UpdateCashTrackerPayloadType,
  UpdateSuspenseFormPayloadType,
  validateAmountLimit,
  validateCashTrackerRecord,
  validateSuspenseRecord,
} from "./cashTracker/cashTrackerFormUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { delegatedAxios } from "../../../services/axios";

enum SuspenseScreen {
  Comment = 1,
  ResearchLog = 2,
  ResolutionLog = 3,
}

function CashTrackerWrapper() {
  const [request, dispatch] = useReducer(
    cashTrackerStateReducer,
    initialCashTrackerState
  );
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const [open, setOpen] = useState(false);

  const isCashTrackerAdmin = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerAdmin
  );
  const isCashTrackerContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerContributer
  );
  const isCashTrackerQAContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQAContributer
  );
  const isCashTrackerQCContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQCContributer
  );
  const isCashTrackerAdminOrContributer =
    isCashTrackerAdmin || isCashTrackerContributer;

  const [commentSaveAction, setCommentSaveAction] = useState<
    "CORRECTION" | "COMMENT" | "RESOLUTION"
  >("COMMENT");

  useEffect(()=>{
    request.cashTrackerMasterFields.cashTrackerID && getLogGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[request.cashTrackerMasterFields.cashTrackerID]);

  const handleCommentLoading = request.cashTrackerLoader === "SUBMITTING";

  const handleCommentReset = () =>
    dispatch({
      comment: "",
    });

  const handleCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
    dispatch({
      comment: e.target.value,
    });

  const onCommentDrawerClose = () => {
    dispatch({
      comment: "",
    });
  };

  const goBack = () => {
    dispatch({
      cashTrackerScreenSelector: "CASH_TRACKER_GRID",
      cashTrackerForm: initialCashTrackerFormStateType,
      cashTrackerMasterFields: initialcashTrackerMasterFields,
      screenName: "Cash Tracker",
    });
  };

  const goBackFromSuspenseGrid = () => {
    dispatch({
      cashTrackerScreenSelector: "SUSPENSE",
      suspenseForm: initialSuspenseState,
      screenName: "Suspense",
    });
  };

  const navigateToCreateSuspense = () => {
    dispatch({
      cashTrackerDetailTab: "ADD_SUSPENSE",
      screenName: "Add Suspense",
    });
  };

  const goBackToSuspenseGrid = () => {
    dispatch({
      cashTrackerDetailTab: "SUSPENSE_GRID",
      suspenseForm: initialSuspenseState,
      screenName: "Suspense Records",
      suspenseDetailScreen: "SUSPENSE_DETAIL",
    });
  };

  const markAsGRAPOrResolved = (status: "GRAP" | "resolved") => {
    if (!request.suspenseForm.suspenseID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    let payload: any = undefined;

    if (status === "resolved") {
      const suspenseLogTypeID = SuspenseScreen["ResolutionLog"];
      payload = {
        suspenseID: request.suspenseForm.suspenseID,
        suspenseLogTypeID,
        comment: request.comment,
        createdBy: createdBy!.id!,
        createdDate: new Date().toISOString(),
      };
    } else {
      payload = {
        comment: "",
      };
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .post(
        `/Suspenses/${request.suspenseForm.suspenseID}/ChangeSuspenseStatus/${status}`,
        payload
      )
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          dispatch({
            cashTrackerLoader: "",
            comment: "",
          });
          getHeaderData("SUSPENSE");
          setOpen(false);
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const createComment = () => {
    if (!request.comment) {
      showInvalidSnackbar(null, enqueueSnackbar, "Comment is required.");
      return;
    }

    if (!request.suspenseForm.suspenseID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    const suspenseLogTypeID = SuspenseScreen["Comment"];

    const payload: CreateCommentType = {
      suspenseID: request.suspenseForm.suspenseID,
      suspenseLogTypeID,
      comment: request.comment,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
    };

    delegatedAxios
      .post(`/SuspenseLog/Create`, payload)
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
            comment: "",
            reloadComments: !request.reloadComments,
          });

          setOpen(false);
          handleSuccessResponse(res, enqueueSnackbar);
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const logLastResearched = () => {
    if (!request.suspenseForm.suspenseID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    const payload: CreateCommentType = {
      suspenseID: request.suspenseForm.suspenseID,
      suspenseLogTypeID: SuspenseScreen["ResearchLog"],
      comment: "",
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
    };

    delegatedAxios
      .post(`/SuspenseLog/Create`, payload)
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
            comment: "",
          });
          setOpen(false);
          handleSuccessResponse(res, enqueueSnackbar);
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const completeCorrections = () => {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
      reloadCorrections: true,
    });

    const payload = {
      comment: request.comment,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
    };

    delegatedAxios
      .post(
        `/CashTracker/${request.cashTrackerMasterFields.cashTrackerID}/CashTrackerCorrection/Create`,
        payload
      )
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
            comment: "",
            reloadCorrections: false,
          });
          setOpen(false);
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
          reloadCorrections: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const QALog = () => {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    if (!request.QA.pass && !request.QA.selectedErrorReasons.length) {
      showInvalidSnackbar(null, enqueueSnackbar, "Error Reason is required.");
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
      QA: {
        ...request.QA,
        reloadQA: true,
      },
    });

    const payload = {
      cashTrackerID: request.cashTrackerMasterFields.cashTrackerID,
      qualityTypeID: 1,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
      ...(request.QA.pass
        ? {
            pass: request.QA.pass,
          }
        : {
            pass: request.QA.pass,
            qaErrorReasonTypeID: request.QA.selectedErrorReasons!.map(
              (item) => item.qaErrorReasonTypeID
            ),
          }),
    };

    delegatedAxios
      .post(
        `/CashTracker/${request.cashTrackerMasterFields.cashTrackerID}/CashTrackerQuality/Create`,
        payload
      )
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
            QA: initialQAState,
          });
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
        } else {
          dispatch({
            cashTrackerLoader: "",
            QA: initialQAState,
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
          QA: initialQAState,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const QCLog = () => {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    if (!request.QC.pass && !request.QC.selectedErrorReasons.length) {
      showInvalidSnackbar(null, enqueueSnackbar, "Error Reason is required.");
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
      QC: {
        ...request.QC,
        reloadQC: true,
      },
    });

    const payload = {
      cashTrackerID: request.cashTrackerMasterFields.cashTrackerID,
      qualityTypeID: 2,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
      ...(request.QC.pass
        ? {
            pass: request.QC.pass,
          }
        : {
            pass: request.QC.pass,
            qaErrorReasonTypeID: request.QC.selectedErrorReasons!.map(
              (item) => item.qaErrorReasonTypeID
            ),
          }),
    };

    delegatedAxios
      .post(
        `/CashTracker/${request.cashTrackerMasterFields.cashTrackerID}/CashTrackerQuality/Create`,
        payload
      )
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
            QC: initialQCState,
          });
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
        } else {
          dispatch({
            cashTrackerLoader: "",
            QC: initialQCState,
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
          QC: initialQCState,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const suspenseDetailActionMenu = isCashTrackerAdminOrContributer
    ? [
        {
          text: "Save",
          onClick: () => handleSuspenseUpdate(request.suspenseForm),
        },
        {
          text: "Create Comment",
          onClick: () => {
            setCommentSaveAction("COMMENT");
            setOpen(true);
          },
        },
        {
          text: "Log Last Researched",
          onClick: () => logLastResearched(),
        },
        {
          text: "Mark as GRAP",
          onClick: () => markAsGRAPOrResolved("GRAP"),
        },
        {
          text: "Mark as Resolved",
          onClick: () => {
            setCommentSaveAction("RESOLUTION");
            setOpen(true);
          },
        },
        {
          text: "Go back to Suspense Grid",
          onClick: () => goBackToSuspenseGrid(),
        },
      ]
    : [
        {
          text: "Go back to Suspense Grid",
          onClick: () => goBackToSuspenseGrid(),
        },
      ];

  const cashTrackerGridActionItems = isCashTrackerAdminOrContributer
    ? [
        {
          text: "Create Cash Tracker record(s)",
          onClick: () =>
            dispatch({
              cashTrackerScreenSelector: "ADD_CASH_TRACKER",
              cashTrackerMasterFields: initialcashTrackerMasterFields,
              cashTrackerForm: initialCashTrackerFormStateType,
              screenName: "Add Cash Tracker Record",
            }),
        },
      ]
    : [];

  const addCashTrackerActionItems = [
    {
      text: "Save",
      onClick: () => handleCashTrackerSubmit(request.cashTrackerForm),
    },
    {
      text: "Go back",
      onClick: () => goBack(),
    },
  ];

  const suspenseDetailActionItems = [
    ...(suspenseDetailActionMenu.length > 1
      ? suspenseDetailActionMenu.slice(0, 5)
      : []),
    {
      text: "Go Back",
      onClick: () => goBackFromSuspenseGrid(),
    },
  ];

  const suspenseLogActionItems = [
    ...(request.suspenseLogScreen === "COMMENTS"
      ? [
          {
            text: "Create Comment",
            onClick: () => {
              setCommentSaveAction("COMMENT");
              setOpen(true);
            },
          },
        ]
      : []),
    {
      text: "Go Back",
      onClick: () => goBackFromSuspenseGrid(),
    },
  ];

  const suspenseFormGridActionItems = isCashTrackerAdminOrContributer
    ? [
        {
          text: "Create Suspense",
          onClick: () => navigateToCreateSuspense(),
        },
        {
          text: "Go back",
          onClick: () => goBack(),
        },
      ]
    : [
        {
          text: "Go back",
          onClick: () => goBack(),
        },
      ];

  const addSuspenseActionItems = isCashTrackerAdminOrContributer
    ? [
        {
          text: "Save",
          onClick: () => handleSuspenseSubmit(request.suspenseForm),
        },
        {
          text: "Go back to Suspense Grid",
          onClick: () => goBackToSuspenseGrid(),
        },
      ]
    : [
        {
          text: "Go back to Suspense Grid",
          onClick: () => goBackToSuspenseGrid(),
        },
      ];

  const cashTrackerSuspenseLogActionItems = [
    ...(request.suspenseLogScreen === "COMMENTS"
      ? [
          {
            text: "Create Comment",
            onClick: () => {
              setCommentSaveAction("COMMENT");
              setOpen(true);
            },
          },
        ]
      : []),
    {
      text: "Go back to Suspense Grid",
      onClick: () => goBackToSuspenseGrid(),
    },
  ];

  const cashTrackerDetailActionItems = useMemo(
    () => {
      return isCashTrackerAdminOrContributer
        ? [
            ...(request.cashTrackerMasterFields?.cashTrackerStatusID &&
            request.cashTrackerMasterFields?.cashTrackerStatusID === 6
              ? [
                  {
                    text: "Mark as In Progress",
                    onClick: () => markAsInProgress(),
                  },
                ]
              : [
                  {
                    text: "Notify Operations - Unable to locate back up",
                    onClick: () =>
                      notifyOperationsORMarkAsCompleted("NOTIFY_OPERATIONS"),
                  },
                  {
                    text: "Mark as Completed",
                    onClick: () =>
                      notifyOperationsORMarkAsCompleted("MARK_AS_COMPLETE"),
                  },
                  {
                    text: "Save",
                    onClick: () =>
                      handleCashTrackerUpdate(request.cashTrackerForm),
                  },
                ]),

            {
              text: "Go back",
              onClick: () => goBack(),
            },
          ]
        : [
            {
              text: "Go back",
              onClick: () => goBack(),
            },
          ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request]
  );

  const correctionsActionItems =
    isCashTrackerAdmin || isCashTrackerContributer
      ? [
          {
            text: "Complete Corrections",
            onClick: () => {
              setCommentSaveAction("CORRECTION");
              setOpen(true);
            },
          },
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ]
      : [
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ];

  const qaLogActionItems =
    isCashTrackerAdmin ||
    (isCashTrackerContributer && isCashTrackerQAContributer)
      ? [
          {
            text: "QA Log Action",
            onClick: () => QALog(),
          },
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ]
      : [
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ];

  const qcLogActionItems =
    isCashTrackerAdmin ||
    (isCashTrackerContributer && isCashTrackerQCContributer)
      ? [
          {
            text: "QC Log Action",
            onClick: () => QCLog(),
          },
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ]
      : [
          {
            text: "Go back",
            onClick: () => goBack(),
          },
        ];

  const actionMenuItems = {
    CASH_TRACKER_GRID: cashTrackerGridActionItems,
    SUSPENSE: [],
    ADD_CASH_TRACKER: addCashTrackerActionItems,
    SUSPENSE_DETAIL: [
      ...(request.suspenseDetailScreen === "SUSPENSE_DETAIL"
        ? suspenseDetailActionItems
        : request.suspenseDetailScreen === "SUSPENSE_LOG"
        ? suspenseLogActionItems
        : []),
    ],
    CASH_TRACKER_DETAIL: [
      ...(request.cashTrackerDetailTab === "SUSPENSE_GRID"
        ? suspenseFormGridActionItems
        : request.cashTrackerDetailTab === "ADD_SUSPENSE"
        ? addSuspenseActionItems
        : request.cashTrackerDetailTab === "SUSPENSE_DETAIL" &&
          request.suspenseDetailScreen === "SUSPENSE_DETAIL"
        ? suspenseDetailActionMenu
        : request.cashTrackerDetailTab === "SUSPENSE_DETAIL" &&
          request.suspenseDetailScreen === "SUSPENSE_LOG"
        ? cashTrackerSuspenseLogActionItems
        : request.cashTrackerDetailTab === "CASH_TRACKER_DETAIL"
        ? cashTrackerDetailActionItems
        : request.cashTrackerDetailTab === "CORRECTIONS"
        ? correctionsActionItems
        : request.cashTrackerDetailTab === "QA_LOG"
        ? qaLogActionItems
        : request.cashTrackerDetailTab === "QC_LOG"
        ? qcLogActionItems
        : []),
    ],
  };

  function handleCashTrackerSubmit(
    cashTrackerFormState: CashTrackerFormStateType
  ) {
    const { isValid, fieldName } =
      validateCashTrackerRecord(cashTrackerFormState);
    if (!isValid) {
      showInvalidSnackbar(null, enqueueSnackbar, `${fieldName} is required.`);
      return;
    }

    if (
      cashTrackerFormState.checkAmountValidation ||
      cashTrackerFormState.checkNumberValidation
    )
      return;

    if (validateAmountLimit(cashTrackerFormState.checkAmount)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Check Amount must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    const payload: CreateCashTrackerPayloadType = {
      clientInfoID: cashTrackerFormState.clientInfo!.clientInfoID,
      checkDate: cashTrackerFormState.checkDate!.split("T")[0],
      lockBoxDate: cashTrackerFormState.lockBoxDate!.split("T")[0],
      checkNumber: cashTrackerFormState.checkNumber,
      cashIssueStatusTypeID: +cashTrackerFormState.cashTrackerStatus,
      totalCheckAmount: +cashTrackerFormState.checkAmount,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
    };

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .post(`/CashTrackers/Create`, payload)
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          dispatch({
            cashTrackerScreenSelector: "CASH_TRACKER_GRID",
            cashTrackerLoader: "",
            cashTrackerMasterFields: initialcashTrackerMasterFields,
            cashTrackerForm: initialCashTrackerFormStateType,
            screenName: "Cash Tracker",
          });
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const handleCashTrackerUpdate = (
    cashTrackerFormState: CashTrackerFormStateType
  ) => {
    const { isValid, fieldName } =
      validateCashTrackerRecord(cashTrackerFormState);
    if (!isValid) {
      showInvalidSnackbar(null, enqueueSnackbar, `${fieldName} is required.`);
      return;
    }

    if (
      cashTrackerFormState.checkAmountValidation ||
      cashTrackerFormState.checkNumberValidation
    )
      return;

    if (validateAmountLimit(cashTrackerFormState.checkAmount)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Check Amount must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    const payload: UpdateCashTrackerPayloadType = {
      clientInfoID: cashTrackerFormState.clientInfo!.clientInfoID,
      checkDate: cashTrackerFormState.checkDate!.split("T")[0],
      lockBoxDate: cashTrackerFormState.lockBoxDate!.split("T")[0],
      checkNumber: cashTrackerFormState.checkNumber,
      cashIssueStatusTypeID: +cashTrackerFormState.cashTrackerStatus,
      totalCheckAmount: +cashTrackerFormState.checkAmount,
      createdBy: cashTrackerFormState.createdBy!,
      createdDate: cashTrackerFormState.createdDate!,
      CashTrackerID: request.cashTrackerMasterFields.cashTrackerID,
      modifiedBy: createdBy!.id!,
      modifiedDate: new Date().toISOString(),
    };

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .put(
        `/CashTrackers/Update/${request.cashTrackerMasterFields.cashTrackerID}`,
        payload
      )
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          dispatch({
            cashTrackerLoader: "",
          });
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  function handleSuspenseSubmit(suspenseStateFormType: SuspenseStateType) {
    const { isValid, fieldName } = validateSuspenseRecord(
      suspenseStateFormType
    );
    if (!isValid) {
      showInvalidSnackbar(null, enqueueSnackbar, `${fieldName} is required.`);
      return;
    }

    if (validateAmountLimit(request.suspenseForm.suspendedAmount)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Suspended Amount must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    if (validateAmountLimit(request.suspenseForm.grossPremium)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Gross Premium must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    const payload: CreateSuspenseFormPayloadType = {
      additionalInfo: request.suspenseForm.addInfo,
      cashTrackerID: request.cashTrackerMasterFields.cashTrackerID,
      endorsementNumber: +request.suspenseForm.endtNumber,
      grossPremium: Number(
        request.suspenseForm.grossPremium.replace(/[^0-9.-]+/g, "")
      ),
      policyNumber: request.suspenseForm.policyNumber,
      suspendedAmount: Number(
        request.suspenseForm.suspendedAmount.replace(/[^0-9.-]+/g, "")
      ),
      suspenseReasonTypeID: +request.suspenseForm.suspenseReason,
      insuredName: request.suspenseForm.insuredName,
      suspenseTypeID: request.suspenseForm.suspenseTypeID,
      createdBy: createdBy!.id!,
      createdDate: new Date().toISOString(),
      cashIssueStatusTypeID: 5,
    };

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .post(`/Suspenses/Create`, payload)
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          const { suspenseReasons, ...restinitialSuspenseState } =
            initialSuspenseState;
          dispatch({
            cashTrackerLoader: "",
            suspenseForm: {
              ...request.suspenseForm,
              ...restinitialSuspenseState,
            },
            cashTrackerDetailTab: "SUSPENSE_GRID",
            screenName: "Suspense Grid",
          });
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function handleSuspenseUpdate(suspenseStateFormType: SuspenseStateType) {
    const { isValid, fieldName } = validateSuspenseRecord(
      suspenseStateFormType
    );
    if (!isValid) {
      showInvalidSnackbar(null, enqueueSnackbar, `${fieldName} is required.`);
      return;
    }

    if (
      !request.suspenseForm.createdDate ||
      !request.suspenseForm.createdBy ||
      !request.suspenseForm.suspenseID
    ) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    if (validateAmountLimit(request.suspenseForm.suspendedAmount)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Suspended Amount must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    if (validateAmountLimit(request.suspenseForm.grossPremium)) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Gross Premium must be less than 999999999999.99 and greater than -999999999999.99"
      );
      return;
    }

    const payload: UpdateSuspenseFormPayloadType = {
      additionalInfo: request.suspenseForm.addInfo,
      cashTrackerID: request.cashTrackerMasterFields.cashTrackerID,
      endorsementNumber: +request.suspenseForm.endtNumber,
      grossPremium: Number(
        request.suspenseForm.grossPremium.replace(/[^0-9.-]+/g, "")
      ),
      policyNumber: request.suspenseForm.policyNumber,
      suspendedAmount: Number(
        request.suspenseForm.suspendedAmount.replace(/[^0-9.-]+/g, "")
      ),
      suspenseReasonTypeID: +request.suspenseForm.suspenseReason,
      insuredName: request.suspenseForm.insuredName,
      suspenseTypeID: request.suspenseForm.suspenseTypeID,
      suspenseID: request.suspenseForm.suspenseID,
      createdDate: request.suspenseForm.createdDate,
      createdBy: request.suspenseForm.createdBy,
      modifiedBy: createdBy!.id!,
      modifiedDate: new Date().toISOString(),
      cashIssueStatusTypeID: 5,
    };

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .put(`/Suspenses/Update/${request.suspenseForm.suspenseID}`, payload)
      .then((res: any) => {
        const { status } = res || {};

        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          dispatch({
            cashTrackerLoader: "",
          });
          getHeaderData("SUSPENSE");
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const handleCommentSave = () => {
    commentSaveAction === "COMMENT" && createComment();
    commentSaveAction === "CORRECTION" && completeCorrections();
    commentSaveAction === "RESOLUTION" && markAsGRAPOrResolved("resolved");
  };

  function notifyOperationsORMarkAsCompleted(
    params: "MARK_AS_COMPLETE" | "NOTIFY_OPERATIONS"
  ) {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    let url =
      params === "MARK_AS_COMPLETE"
        ? `/CashTrackers/MarkAsCompleted/${request.cashTrackerMasterFields.cashTrackerID}`
        : `/CashTrackers/NotifyOperations/${request.cashTrackerMasterFields.cashTrackerID}`;

    delegatedAxios
      .get(url)
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
          dispatch({
            cashTrackerLoader: "",
            comment: "",
          });
        } else {
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function getHeaderData(screen: "SUSPENSE" | "CASH_TRACKER") {
    if (screen === "SUSPENSE" && !request.suspenseForm.suspenseID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    if (
      screen === "CASH_TRACKER" &&
      !request.cashTrackerMasterFields.cashTrackerID
    ) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    const url =
      screen === "CASH_TRACKER"
        ? `CashTrackers/GetHeaderData/${request.cashTrackerMasterFields.cashTrackerID}`
        : `Suspenses/GetSuspenseStatus/${request.suspenseForm.suspenseID}`;

    delegatedAxios
      .get(url)
      .then((res: any) => {
        const { status, data } = res || {};
        if (status === 200) {
          screen === "CASH_TRACKER"
            ? dispatch({
                cashTrackerLoader: "",
                cashTrackerMasterFields: {
                  ...request.cashTrackerMasterFields,
                  client: data?.clientName || "",
                  checkNumber: data?.checkNumber || "",
                  cashTrackerStatus: data?.status || "",
                  cashTrackerStatusID: data?.statusID,
                },
              })
            : dispatch({
                cashTrackerLoader: "",
                cashTrackerMasterFields: {
                  ...request.cashTrackerMasterFields,
                  suspenseStatus: data?.status || "",
                },
              });
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function markAsInProgress() {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      cashTrackerLoader: "SUBMITTING",
    });

    delegatedAxios
      .get(
        `/CashTrackers/StatusInProgress/${request.cashTrackerMasterFields.cashTrackerID}`
      )
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getHeaderData("CASH_TRACKER");
          getLogGridData();
        } else {
          dispatch({
            cashTrackerLoader: "",
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function getLogGridData() {
    if (!request.cashTrackerMasterFields.cashTrackerID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    dispatch({
      logGridDataLoader: true,
    });

    delegatedAxios
      .get(
        `/CashTrackers/GetCashTrackerActionLogs/${request.cashTrackerMasterFields.cashTrackerID}`
      )
      .then((res: any) => {
        const { status, data } = res || {};
        if (status === 200) {
          dispatch({
            logGridDataLoader: false,
            logGridData: data || []
          });
        } else {
          dispatch({
            logGridDataLoader: false,
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        dispatch({
          logGridDataLoader: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  return (
    <Grid container>
      <CashTrackerActionBar
        request={request}
        dispatch={dispatch}
        actionMenuItems={actionMenuItems}
      />
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SimpleBackdrop open={request.cashTrackerLoader === "SUBMITTING"} />
      <CustomDrawer
        open={open}
        setOpen={setOpen}
        onClose={onCommentDrawerClose}
        drawerWidth={600}
        containerPadding={0}
        title="Add Comment"
        drawerPadding={6}
        children={
          <DrawerCommentForm
            commentValue={request.comment}
            handleReset={handleCommentReset}
            loading={handleCommentLoading}
            handleSubmit={handleCommentSave}
            onChange={handleCommentChange}
          />
        }
      />
      <InfoSectionSelector request={request} dispatch={dispatch} />
    </Grid>
  );
}

export default CashTrackerWrapper;
