import React from "react";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { CellClassParams } from "ag-grid-community";
import {
  comparatorForDate,
  currencyFormatter,
  CustomSnackbar,
  formatDateForGrid,
  handleErrorResponse,
  ModalErrors,
  negativeValueColorFormatter,
  ViewSchema,
} from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { DelegatedIssuesProps } from "./utils";
import { RootState } from "../../../redux/store";
import { delegatedAxios } from "../../../services/axios";
import CommentRenderer from "./CommentRenderer";

interface Props extends DelegatedIssuesProps {}

function SuspenseIssues({ state, setState }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;

    return delegatedAxios
      .post(`/Issues/GetSuspenseIssues`, obj)
      .then((data: any) => {
        return data.data;
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        colId: "checkNumber",
        field: "checkNumber",
        headerName: "Check Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "checkDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.checkDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "totalCheckAmount",
        headerName: "Check Amount",
        field: "totalCheckAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.totalCheckAmount),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.totalCheckAmount, true)}`;
        },
      },
      {
        colId: "suspendedAmount",
        headerName: "Suspended Amount",
        field: "suspendedAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.suspendedAmount),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.suspendedAmount, true)}`;
        },
      },
      {
        colId: "suspenseReasonTypeText",
        field: "suspenseReasonTypeText",
        headerName: "Suspense Reason",
        width: 260,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "policyNumber",
        field: "policyNumber",
        headerName: "Policy Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "insuredName",
        field: "insuredName",
        headerName: "Insured Name",
        width: 260,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "grossPremium",
        headerName: "Gross Premium Amount",
        field: "grossPremium",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.grossPremium),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.grossPremium, true)}`;
        },
      },
      {
        colId: "operationsContactName",
        field: "operationsContactName",
        tooltipField: "operationsContactName",
        headerName: "Operations Contact",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "clientName",
        field: "clientName",
        headerName: "Client Name",
        tooltipField: "clientName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 320,
      },
      {
        colId: "clientCity",
        field: "clientCity",
        headerName: "Client City",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
      {
        colId: "clientState",
        field: "clientState",
        headerName: "Client State",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
      
      {
        colId: "clientComment",
        field: "clientComment",
        tooltipField: "clientComment",
        width: 320,
        headerName: "Client Comments",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "Actions",
        cellRenderer: CommentRenderer,
        sortable:false,
        filter: false,
      }
    ];
  }

  return (
    <Grid md={12} item>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <AggridWrapper
        gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        getColumnDefs={getColumnDefs}
        getGridRowsData={getGridRowsData}
        dashboardName={""}
        enableUserViews={false}
        enableSystemViews={false}
        landingPage={"Suspense"}
        views={false}
        currentUser={{
          userName: createdBy && createdBy.id ? createdBy.id : "",
          email: "",
          fullName: "",
        }}
        gridProps={{
          headerHeight: 40,
          tooltipMouseTrack: true,
          tooltipShowDelay: 0,
        }}
        hideHeader
        paginationPageSize={50}
        rowHeight={29}
        headerHeight={32}
        gridHeight={window.innerHeight - 190}
        showSidebar
      ></AggridWrapper>
    </Grid>
  );
}

export default SuspenseIssues;
