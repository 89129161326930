import React from "react";
import ResolutionLog from "./ResolutionLog";
import ResearchLog from "./ResearchLog";
import SuspenseCommentsGrid from "./SuspenseCommentsGrid";
import { CashTrackerProps } from "../../../CashTrackerUtils";

interface Props extends CashTrackerProps {
}
function SuspenseLogInfoSectionSelector({ request,dispatch }: Props) {
  switch (request.suspenseLogScreen) {
    case "COMMENTS":
      return <SuspenseCommentsGrid request={request} dispatch={dispatch}/>;
    case "RESEARCH_LOG":
      return <ResearchLog request={request} dispatch={dispatch}/>;
    case "RESOLUTION_LOG":
      return <ResolutionLog request={request} dispatch={dispatch}/>;

    default:
      return null;
  }
}

export default SuspenseLogInfoSectionSelector;
