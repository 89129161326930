export type DelegatedIssueScreenName =
  | "CASH_ISSUE_DETAILS"
  | "CLIENT_CASH_DETAILS"
  | "QA_ERROR_REASON"
  | "SUSPENSE_REASONS";

export type DelegatedIssuesAction = Partial<DelegatedIssuesType>;

export type CashTrackerStateDispatch =
  React.Dispatch<DelegatedIssuesAction>;

export interface DelegatedIssuesType {
  screenName: DelegatedIssueScreenName;
}
export const initialDelegatedIssuesState: DelegatedIssuesType = {
  screenName: "CASH_ISSUE_DETAILS",
};

export interface DelegatedIssuesProps {
  request: DelegatedIssuesType;
  dispatch: CashTrackerStateDispatch;
}

export const delegatedIssuesReducer = (
  state: DelegatedIssuesType,
  dispatch: DelegatedIssuesAction
) => {
  return {
    ...state,
    ...dispatch,
  };
};
