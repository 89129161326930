import React, { useReducer } from "react";
import { SubAppRolePermission } from "./SubAppRolePermission";
import {
  PermissionLevelArray,
} from "./appRolePermissionUtils";
import { RequestType, Action } from "./appRolePermissionUtils";
import { authAxios } from "../../../../services/axios";
import { showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import axios from "axios";

function AppRolePermission() {
  
    const initialState = {
    appTypeArray: [],
    roleArray: [],
    permissionLevelArray: PermissionLevelArray,
    appRolePermissionTable:[],
    appTypeID:"",
    roleID:"",
    permissionLevelID:"",
    appRolePermissionID:"",
    refreshToggler:false,
    updateRequest:false,
    createdBy:"",
    modifiedBy:"",
    createdDate: "",
    modifiedDate: "",
    loading:false
  };

  const { enqueueSnackbar } = useRibbonSnackbar();

  const reducer = (state: RequestType, action: Action) => {
    switch (action.type) {
      case "UPDATE_UNIVERSAL_REQUEST":
        return Object.assign({}, state, action.request);
      default:
        return state;
    }
  };
  const [request, dispatch] = useReducer(reducer, initialState);
  
  React.useEffect(()=>{
    
    const getRoles = authAxios.get("/GetRoles?active=true");

    const getAppTypes = authAxios.get("/AppTypeRoles");
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, loading:true },
    });

    axios.all([getRoles, getAppTypes]).then(axios.spread((...responses:any) => {
      const roles = responses[0];
      const appTypes = responses[1];

      if(roles.data && appTypes.data) {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: { ...request, appTypeArray : appTypes.data, roleArray: roles.data, loading:false },
        });
      } else {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: { ...request, loading:false },
        });
      }

    })).catch((e:any) => {
          dispatch({
           type: "UPDATE_UNIVERSAL_REQUEST",
           request: { ...request, loading:false },
          });
          showInvalidSnackbar(e,enqueueSnackbar);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return <SubAppRolePermission request={request} dispatch={dispatch} />;
}

export default AppRolePermission;
