import React from "react";
import { Grid, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import Button from "@mui/lab/LoadingButton";

import { SelectList, currencyFormatter } from "@genrecp/g2clientportal-common";
import {
  DelegatedProps,
  LossGridSelectorState,
  lineOfBusiness,
  propertyIndicator,
} from "./utils";
import ClientLocationControl from "./experienceReportComponents/ClientLocationControl";
import LossGridSelector from "./lossGrids/LossGridSelector";
import EffectiveDateRange from "./experienceReportComponents/EffectiveDateRange";
import SummarySkeletons from "./experienceReportComponents/SummarySkeletons";
import SummaryCards from "./experienceReportComponents/SummaryCards";
import RiskStateControl from "./experienceReportComponents/RiskStateControl";
import BillPointControl from "./experienceReportComponents/BillPointControl";
import ExpReportInfo from "./experienceReportComponents/ExpReportInfo";
import AccidentYearControl from "./experienceReportComponents/AccidentYearControl";

interface OthersProps extends DelegatedProps {
  runReport: () => void;
  reset: () => void;
  resetDates: () => void;
}

function ExperienceReportForm({
  request,
  dispatch,
  runReport,
  reset,
  resetDates
}: OthersProps) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const showMonthInCaseOfOneDateRange =
    request.effectiveYearsRange.length === 1;

  function handleDropDownChange(event: SelectChangeEvent<unknown>) {
    const { name, value } = event.target;
    const val = value as string;
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { [name]: val },
    });
  }

  function handleGridChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const lossGridSelectorState: LossGridSelectorState = checked
      ? "ClassCode"
      : "RiskState";
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        lossGridSelectorState,
      },
    });
  }

  return (
    <Grid
      container
      spacing={1}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      <Grid
        md={12}
        sm={12}
        xs={12}
        item
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography variant="displaySmall">Experience Report</Typography>
        <ExpReportInfo />
      </Grid>

      <Grid md={5} sm={12} xs={12} item>
        <ClientLocationControl request={request} dispatch={dispatch} />
      </Grid>
      <Grid md={2} sm={12} xs={12} item>
        <AccidentYearControl request={request} dispatch={dispatch} />
      </Grid>
      <EffectiveDateRange
        request={request}
        dispatch={dispatch}
        showMonthInCaseOfOneDateRange={showMonthInCaseOfOneDateRange}
      />
      <Grid md={showMonthInCaseOfOneDateRange ? 1:3} sm={1} xs={1} item>
      <Button
          variant="containedTertiary"
          onClick={resetDates}
          disabled={
            request.clientLocationsLoading ||
            request.billPointsLoading ||
            request.runReportLoader
          }
          sx={{px:1}}
        >
          Reset Dates
        </Button>
      </Grid>
      <Grid
        md={1}
        sm={12}
        xs={12}
        display={"flex"}
        justifyContent={"flex-end"}
        flexDirection={"column"}
        item
      >
        <SelectList
          label="Line Of Buisness"
          name="lineOfBusiness"
          id="lineOfBusiness"
          test-id="lineOfBusiness"
          value={request.lineOfBusiness}
          onChange={handleDropDownChange}
          allowempty={false}
          disabled={!request.firstSubmit}
        >
          {lineOfBusiness.map((item, idx) => {
            return (
              <MenuItem value={item.value} key={idx}>
                {item.name}
              </MenuItem>
            );
          })}
        </SelectList>
      </Grid>
      <Grid md={2} sm={12} xs={12} item>
        <BillPointControl request={request} dispatch={dispatch} />
      </Grid>
      <Grid
        md={2}
        sm={12}
        xs={12}
        display={"flex"}
        justifyContent={"flex-end"}
        flexDirection={"column"}
        item
      >
        <SelectList
          label="GL/Property Indicator"
          name="glPropertyIndicator"
          id="glPropertyIndicator"
          test-id="glPropertyIndicator"
          value={request.glPropertyIndicator}
          onChange={handleDropDownChange}
          disabled={!request.firstSubmit}
        >
          {propertyIndicator.map((item, idx) => {
            return (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            );
          })}
        </SelectList>
      </Grid>
      <Grid md={2} sm={12} xs={12} item>
        <RiskStateControl request={request} dispatch={dispatch} />
      </Grid>

      <Grid md={3} sm={12} xs={12} item display={"flex"} gap={1}>
        <Button
          variant="containedTertiary"
          loading={request.runReportLoader}
          onClick={runReport}
          disabled={request.clientLocationsLoading || request.billPointsLoading}
        >
          Run Report
        </Button>
        <Button
          variant="outlinedTertiary"
          onClick={reset}
          disabled={
            request.clientLocationsLoading ||
            request.billPointsLoading ||
            request.runReportLoader
          }
        >
          Reset All
        </Button>
      </Grid>

      <Grid md={12} sm={12} xs={12} item container spacing={1}>
        <Grid
          md={1.5}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          item
        >
          <SummarySkeletons condition={request.runReportLoader} height="60px">
            <SummaryCards
              title="Premium Written"
              mainText={
                currencyFormatter(request.sumPremiumWriteAmount) || "$0"
              }
            />
          </SummarySkeletons>
        </Grid>

        <Grid
          md={1.5}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          item
        >
          <SummarySkeletons condition={request.runReportLoader} height="60px">
            <SummaryCards
              title="Premium Earned"
              mainText={
                currencyFormatter(request.sumPremiumEarnedAmount) || "$0"
              }
              delay={"100ms"}
            />
          </SummarySkeletons>
        </Grid>

        <Grid
          md={1.5}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          flexDirection={"column"}
          item
        >
          <SummarySkeletons condition={request.runReportLoader} height="60px">
            <SummaryCards
              title="Losses Incurred"
              mainText={currencyFormatter(request.sumIncurredAmount) || "$0"}
              delay={"200ms"}
            />
          </SummarySkeletons>
        </Grid>

        <Grid
          md={1.5}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          flexDirection={"column"}
          item
        >
          <SummarySkeletons condition={request.runReportLoader} height="60px">
            <SummaryCards
              title="Loss Ratio"
              mainText={request.lossRatio ? request.lossRatio + "%" : "0%"}
              delay={"300ms"}
            />
          </SummarySkeletons>
        </Grid>
        <Grid
          md={6}
          sm={12}
          xs={12}
          item
          display={"flex"}
          justifyContent={"end"}
          alignItems={"flex-end"}
          gap={1}
        >
          <Typography variant="labelMedium">
            <b>Losses By</b>
          </Typography>
          <Typography variant="labelMedium">Risk State</Typography>
          <Switch
            {...label}
            size="small"
            checked={
              request.lossGridSelectorState === "ClassCode" ? true : false
            }
            onChange={handleGridChange}
          />
          <Typography variant="labelMedium">Class Code</Typography>
        </Grid>
      </Grid>
      <Grid md={12} sm={12} xs={12} item>
        <Grid md={12} sm={12} xs={12} item>
          <LossGridSelector request={request} dispatch={dispatch} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExperienceReportForm;
