import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  AppDispatch,
  RootState,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import {MuiStyledTabs as Tabs, MuiStyledTab as Tab} from "@genre/g2common-theme"

export default function RoutableTabs() {
  const $dispatch = useDispatch<AppDispatch>();
  const selectedMenu = useSelector(
    (state: RootState) => state.genbill.selectedMenu
  );
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );

  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));
  const appRolePermissions = useSelector((state:RootState) => state.auth.currentUser?.appRolePermissions);
  const permissions = appRolePermissions && JSON.parse(appRolePermissions);
  const brokerbillingExtUser = permissions?.find((item:any) => item.roleId === 6);

  const extViewer = isExternalUser && brokerbillingExtUser && brokerbillingExtUser.permissionLevelId === 3;
  const extAdminOrContributer = (isExternalUser && brokerbillingExtUser) && (brokerbillingExtUser.permissionLevelId === 1 || brokerbillingExtUser.permissionLevelId === 2)
  
  const billDetailsArray = [
    { value: "HOME", label: "Home" },
    { value: "BILLDETAILS", label: "Bill Details" },
    { value: "SUMMARY", label: "Summary" },
    // { value: "ADDITIONALITEMS", label: "Additional Items", disabled: true },
    // { value: "PRINT", label: "Print", disabled: true },
    // { value: "EXCELDOWNLOAD", label: "Excel Download", disabled: true }
  ];
  const userAccessArray = [
    { value: "HOME", label: "Home" },
    { value: "USERACCESS", label: "User Access" },
    { value: "SELECTIONCRITERIA", label: "Selection Criteria" },
    { value: "EMAILLIST", label: "Email List" },
    { value: "ACCOUNTGROUP", label: "Accounting Group" },
  ];

  const homeTabs = extAdminOrContributer ? [
    { value: "HOME", label: "Home" },
    { value: "HISTORY", label: "Bill History" },
  ]: [
    { value: "HOME", label: "Home" },
    { value: "HISTORY", label: "Bill History" },
    { value: "NEWBILLSETUP", label: "New Bill Setup" },
  ];

  const accountGroupDetail = [
    { value: "HOME", label: "Home" },
    { value: "ACCOUNTGROUP", label: "Accounting Group" },
    { value: "ACCOUNTGROUPDETAIL", label: "Accounting Group Detail" },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {

    if (newValue === "SUMMARY") {
      $dispatch(
        handleGenbillState({
          type: "SHOW_SUMMARY_DRAWER",
          payload: { showSummaryDrawer: true },
        })
      );
      return;
    }

    $dispatch(
      handleGenbillState({
        type: "HANDLE_GENBILL_NAV",
        payload: { selectedMenu: newValue },
      })
    );
  };

  const externalTabs = [
    { value: "HOME", label: "Home" },
    { value: "BILLDETAILS", label: "Bill Details" }
  ];
  
  const tabs = () => {

    if(extViewer) {
      return selectedMenu === "HOME" ? <Tab value={"HOME"} label={"Home"} /> :
      externalTabs.map((item, idx) => (
        <Tab
          value={item.value}
          label={item.label}
          key={idx}
        />
      ));  
    }

    switch (selectedMenu) {
      case "BILLDETAILS":
        return billDetailsArray.map((item, idx) => (
          <Tab
            value={item.value}
            label={item.label}
            key={idx}
          />
        ));
      case "HISTORY":
        return homeTabs
          .map((item, idx) => (
            <Tab value={item.value} label={item.label} key={idx} />
          ));
      case "USERACCESS":
      case "SELECTIONCRITERIA":
      case "ACCOUNTGROUP":
      case "EMAILLIST":
        return userAccessArray.map((item, idx) => (
          <Tab
            value={item.value}
            label={item.label}
            key={idx}
          />
        ));
      case "NEWBILLSETUP":
        return homeTabs
        .filter((itemx) => itemx.value === "NEWBILLSETUP" || itemx.value === "HOME")
          .map((item, idx) => (
            <Tab value={item.value} label={item.label} key={idx} />
          ));
      case "ACCOUNTGROUPDETAIL":
        return accountGroupDetail.map((item, idx) => (
          <Tab value={item.value} label={item.label} key={idx} />
        ));
      default:
        return homeTabs
          .filter((itemx) => itemx.value !== "HISTORY")
          .map((item, idx) => (
            <Tab value={item.value} label={item.label} key={idx} />
          ));
    }
  };

  return (
    <Grid container spacing={2} maxWidth="lg" mb={1}>
      <Grid xs={12} sm={12} md={12} item>
        <Box display={"flex"} justifyContent={"start"} width={"100%"}>
          <Tabs
            value={selectedMenu}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            {tabs()}
          </Tabs>
        </Box>
      </Grid>
    </Grid>
  );
}
