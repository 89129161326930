import { SnackbarProvider } from "notistack";
import React from "react";
import { useSnackbar } from "notistack";
import CustomErrorSnackBar from "./CustomErrorSnackBar";
import { ModalErrorMessagesType } from "../../util/CommonUtils";

interface Props {
  children: JSX.Element;
}

declare module "notistack" {
  interface VariantOverrides {
    error:{
      errorObject?: ModalErrorMessagesType; // !! make it optional in the end
    }
  }
}

const SnackBarComp: React.FC<Props> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={2}
      autoHideDuration={null}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      Components={{
        error: CustomErrorSnackBar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
export default SnackBarComp;

export const useRibbonSnackbar = useSnackbar;
