import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { authActionTypes } from '../auth/authActionTypes'
import {AccountInfo} from '@azure/msal-browser'
import { ModalErrorMessagesType } from '@genrecp/g2clientportal-common';

export interface CurrentUser {
  id: string;
  given_name: string;
  appRolePermissions: string;
  userRoles: string;
  userLoginId: string;
  jti: string;
  iat: number;
  exp: number;
  nbf: number;
  iss: string;
  aud: string;
  isExternal: string;
}

export interface AuthState {
  token: string,
  error:ModalErrorMessagesType|null,
  isFetching:boolean,
  currentUser: Partial<CurrentUser>,
  msalUser: AccountInfo,
  msalToken: string
}

const initialState: Partial<AuthState> = {
  token: "",
  error:null,
  currentUser:undefined,
  isFetching:false,
  msalUser:undefined,
  msalToken:""
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getToken: (state = initialState, action: PayloadAction<any> ) => {
        
      const {type,payload} = action.payload;
        switch (type) {
          case authActionTypes.AUTH_START:
            return {
              ...state,
              isFetching:true,
              error:null,
            }
            case authActionTypes.AUTH_SUCCESS:
              return {
                ...state,
                token:payload.token,
                currentUser:payload.currentUser,
                msalUser:payload.msalUser,
                msalToken:payload.msalToken,
                isFetching:false,
                error:null
              }
              case authActionTypes.AUTH_ERROR:
                return {
                  ...state,
                  isFetching:false,
                  error:payload
                }
        
          default:
            break;
        }
    },
    setMSALToken: (state = initialState, action: PayloadAction<any> ) => {
      const {payload} = action;
      return {
        ...state,
        msalUser: payload.account,
        msalToken: payload.accessToken,
        error:null,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { getToken, setMSALToken } = authSlice.actions

export default authSlice.reducer