import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const ServerErrorDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

function ServerError() {

  return (
    <ServerErrorDiv>
      <Stack sx={{ minWidth: "506px" }}>
        <img
          src={"/GenStar-R.png"}
          alt="general star logo"
          style={{ maxWidth: "240px" }}
        />
        <Typography
          component="h1"
          variant="h6"
          align="right"
          fontStyle={"italic"}
        >
          Looks Like something went wrong.
        </Typography>
        <Typography
          component="h1"
          variant="h6"
          align="right"
          fontStyle={"italic"}
        >
          Click <LinkSpan onClick={() => window.location.reload()}>here</LinkSpan> to Reload.
        </Typography>
      </Stack>
    </ServerErrorDiv>
  );
}

export default ServerError;
