import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
      authority: process.env.REACT_APP_MSAL_AUTHORITY as string,
      redirectUri: "/",
      postLogoutRedirectUri: "/"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      iframeHashTimeout: 12000,
      loggerOptions: {
        loggerCallback: (level:any, message:any, containsPii:any) => {
            if (containsPii) {	
                return;	
            }	
            switch (level) {	
                case LogLevel.Error:	
                    console.error(message);	
                    return;	
                case LogLevel.Info:	
                    console.info(message);	
                    return;	
                case LogLevel.Verbose:	
                    console.debug(message);	
                    return;	
                case LogLevel.Warning:	
                    console.warn(message);	
                    return;	
            }
        }
      }
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest: RedirectRequest = {
   scopes: [process.env.REACT_APP_MSAL_SCOPE as string]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };