import React, { Fragment } from "react";
import {
  CustomDrawer,
  CustomSnackbar,
  DetailSpan,
  DrawerCommentForm,
  handleErrorResponse,
  handleSuccessResponse,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { delegatedAxios } from "../../../services/axios";
import { SuspenseIssuesType } from "./utils";
import { ICellRendererParams } from "ag-grid-community";

function CommentRenderer({
  node,
  data,
  api
}: ICellRendererParams) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [comment, setComment] = React.useState(data?.clientComment || data?.comments || "");
  const [issueID, setIssueID] = React.useState(data?.issueID || "");
  const [suspenseID, setSuspenseID] = React.useState(data?.suspenseID || "");
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();

  const onClose = () => {
    api?.refreshServerSide({ purge: true });
    setOpen(false);
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setComment(e.target.value);

  const handleReset = () => setComment("");
  
  const handleSubmit = () => {
    if (!comment) {
      showInvalidSnackbar(null, enqueueSnackbar, "Comment is required.");
      return;
    }
    
    console.log("IssueID : ", issueID, " SuspenseID : ", suspenseID, " Comments : ", comment);

    if (!issueID && !suspenseID) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }

    const payload = {
      comment,
    };

    setLoading(true);

    if(suspenseID){
      console.log("Suspense Comment");
      delegatedAxios
      .post(`/Issues/SuspenseId/${suspenseID}/UpdateComment`, payload)
      .then((res: any) => {
        setLoading(false);

        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          onClose();
        } else {
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
    }
    if(issueID){
      console.log("Issue Comment");
      delegatedAxios
      .post(`/Issues/DelegatedPolicyIssue/${issueID}/UpdateClientComment`, payload)
      .then((res: any) => {
        setLoading(false);

        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          onClose();
        } else {
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
    }
    
  };

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <DetailSpan onClick={() => setOpen(true)}>
        {comment ? "Update Comment" : "Add Comment"}
      </DetailSpan>
      <CustomDrawer
        title="Add Comment"
        drawerWidth={400}
        setOpen={setOpen}
        open={open}
        onClose={onClose}
      >
        <DrawerCommentForm
          commentValue={comment}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          loading={loading}
          onChange={handleChange}
        />
      </CustomDrawer>
    </Fragment>
  );
}

export default CommentRenderer;
