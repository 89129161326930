import React from 'react'
import AdminDocumentTabs from './AdminDocumentTabs'
import InfoSectionSelector from './InfosectionSelector'
import { commonAxios } from '../../../../services/axios';
import { showInvalidSnackbar, useRibbonSnackbar } from '@genrecp/g2clientportal-common';
import { DocFolderStructure, Document } from './adminDocUtils';
import { Grid } from '@mui/material';

function AdminDocument() {
  const [selectedMenu, setSelectedMenu] = React.useState<string>("ViEWDOCS");
  const [docFolderStructure, setDocFolderStructure] = React.useState<DocFolderStructure>({
    loading:false,
    data:null,
  });
  const [documentName, setDocumentName] = React.useState<Document>({
    folder:"Supplemental Applications",
    subFolder:""
  });
  const { enqueueSnackbar } = useRibbonSnackbar();

  const getDocumentHierarchy = React.useCallback((v:string) => {
    setDocFolderStructure({
      ...docFolderStructure,
      loading:true
    });
    commonAxios.get(`/Documentation/DocumentHierarchy?document=${v}`).then((res:any) => {
      setDocFolderStructure({
        ...docFolderStructure,
        loading:false,
        data:res.data
      });
    })
    .catch((e: any) => {
      setDocFolderStructure({
        ...docFolderStructure,
        loading:false,
      });
      showInvalidSnackbar(e,enqueueSnackbar);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  React.useEffect(() => {
    getDocumentHierarchy(documentName.folder);
  },[getDocumentHierarchy,documentName.folder]);

  return (
    <>
    <Grid container spacing={2} maxWidth="lg"></Grid>
    <Grid>
      <AdminDocumentTabs selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
      <InfoSectionSelector docFolderStructure={docFolderStructure} documentName={documentName} setDocumentName={setDocumentName} selectedMenu={selectedMenu}  setSelectedMenu={setSelectedMenu} />
    </Grid>
    </>
  )
}

export default AdminDocument