import { ModalErrors } from "@genrecp/g2clientportal-common";
import styled from "styled-components";

export const DetailSpan = styled.span`
  color: blue;
`;

export const StyledSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`;

export const LandingContainer = styled.div`
  height: 100%;
  width: 100%;  
  margin: 0;
  padding: 0;
`;

export const LandingToolbar = styled.div`
  background-color: ${(props) => props.theme.backgroundDark};
  height: 37px;
  width: 100%;
  padding: 0;
  border-bottom: solid 1px ${(props) => props.theme.onBackground};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
`;

export const HeaderSwitchToolbar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
`;

export const Toolbuttons = styled.div`
  height: 36px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
`;

export const LandingHeader = styled.section`
  width: 100%;
  height: 100%;
  padding: 0.5em 0;
  margin: 0;
  border: none;
  margin-bottom: 0.5em;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const GridContainer = styled.div`
  height: 90%;
  width: 100%;
  padding: 0px;
`;

export const statusEnumObj = {
  Applied: 1,
  Disabled: 2,
  Fail: 3,
  Idle: 4,
  Load: 5,
  New: 6,
  Received: 7,
  Review: 8,
  Sent: 9,
  Statement: 11,
} as const;

export const permissionLevelArray = [
  { value: 0, name: "" },
  { value: 1, name: "Admin" },
  { value: 2, name: "Contributor" },
  { value: 3, name: "Viewer" },
];

export interface ViewSchema {
  columnData: string;
  createdBy: string;
  createdDate: string;
  filterData: string;
  isDefault: boolean;
  isSystem: boolean;
  modifiedBy: string | null;
  modifiedDate: string | null;
  screenName: string;
  userGridViewID?: string;
  viewName: string;
}

export interface PermissionLevelObject {
  name: string;
  value: number;
}

export interface AssignedUserAccessList {
  userID: string;
  permissionLevelID: number;
}

export interface AssignedEmailAlertsList {
  userID: string;
}

export interface ModalErrorsType {
  modalErrors:ModalErrors;
  setModalErrors:(v:ModalErrors) => void;
} 

export interface BillObj {
  billAccessID: string;
  userID: string;
  permissionLevelID: number;
  clientGroupID?: any;
  groupName?: any;
  clientLocationID: string;
  businessName: string;
  userLoginID: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  status: string;
}

export interface NewBillSetup {
  clientLocation: ClientLocationArrayType | null;
  clientLocationArray: ClientLocationArrayType[];
  clientStates: ClientStates[];
  clientGroups: ClientLocationGroupArrayType[];
  assignedUserAccessList: BillObj[];
  assignedEmailAlertsList: BillObj[];
  selectBy: SelectBy;
  stateCode: string;
  clientLocationInputValue:string;
  groupID: string;
  billTitle: string;
  loading: boolean;
  autoSendBills: boolean;
}

export interface NewBillState {
  state: NewBillSetup;
  setState: (v:NewBillSetup) => void;
}

export type ClientLocationGroupArrayType = {
  clientGroupID: string;
  clientGroupUniqueName: string;
};

export type ClientLocationArrayType = {
  clientLocationID: string;
  clientLocationUniqueName: string;
};

export type ClientStates = {
  name: string;
  stateCode: string;
};

export type SelectBy = "GROUP" | "CLIENTLOCATION";

export interface Request {
  clientLocation: ClientLocationArrayType | null;
  clientLocationArray: ClientLocationArrayType[];
  clientStates: ClientStates[];
  clientGroups: ClientLocationGroupArrayType[];
  selectBy: SelectBy;
  stateCode: string;
  groupID: string;
  billTitle: string;
  loading: boolean;
}

export interface BillFilters {
  billID: string;
  title: string;
}

export const statusParser = (v: number | string) => {
  if (typeof v === "number") {
    const found = Object?.keys(statusEnumObj).find(
      (key: any) => statusEnumObj[key as keyof typeof statusEnumObj] === v
    );
    return found;
  } else {
    const found = Object?.entries(statusEnumObj).find(
      ([key, value]) => key === v
    );
    if (!found) return;
    return found[1];
  }
}
