import React from "react";
import { DelegatedProps, RiskStates } from "../utils";
import { IncurredLossesProps } from "../../incurredLosses/utils";
import { TextInput } from "@genrecp/g2clientportal-common";
import { Autocomplete } from "@mui/material";
import SummarySkeletons from "./SummarySkeletons";

function RiskStateControl({
  request,
  dispatch,
}: DelegatedProps | IncurredLossesProps) {
  const [riskStateInputValue, setRiskStateInputValue] = React.useState("");

  return (
    <SummarySkeletons condition={request.riskStatesLoading}>
      <Autocomplete
        id="riskStates"
        test-id="riskStates"
        multiple
        limitTags={1}
        size="small"
        options={request.riskStates}
        value={request.selectedRiskStates}
        inputValue={riskStateInputValue}
        onInputChange={(event, newInputValue, reason) => {
          setRiskStateInputValue(newInputValue);
        }}
        disabled={!request.firstSubmit}
        isOptionEqualToValue={(option: RiskStates, value: RiskStates) =>
          option.stateCode === value.stateCode
        }
        getOptionLabel={(option: RiskStates) => option.name!}
        onChange={(event, value) => {
          dispatch({
            type: "UPDATE_UNIVERSAL_REQUEST",
            request: {
              ...request,
              selectedRiskStates: value,
            },
          });
        }}
        renderInput={(params) => (
          <TextInput
            {...params}
            label={
              !request.selectedRiskStates.length
                ? "All Risk States"
                : "Selected Risk States"
            }
            placeholder=""
          />
        )}
      />
    </SummarySkeletons>
  );
}

export default RiskStateControl;
