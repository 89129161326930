import * as React from "react";
import { Grid, Stepper, Step, StepLabel, Button } from "@mui/material";
import InfoSectionSelector from "../InfoSectionSelector";
import {
  BillObj,
  NewBillSetup,
} from "../../genbillUtils";
import {
  CustomSnackbar,
  ModalErrors,
  SimpleBackdrop,
  handleErrorResponse,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import {
  authAxios,
  brokerBillingAxios,
} from "@genrecp/g2clientportal-client-portal/src/services/axios";
import axios from "axios";
import { useDispatch } from "react-redux";
import { handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";

const steps = [
  "Create New Bill",
  "Assign User Access",
  "Assign Email Alerts",
];

export default function NewBillStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const $dispatch = useDispatch<AppDispatch>();

  const [state, setState] = React.useState<NewBillSetup>({
    clientLocation: null,
    clientLocationArray: [],
    clientStates: [],
    selectBy: "CLIENTLOCATION",
    clientGroups: [],
    stateCode: "",
    groupID: "",
    billTitle: "",
    clientLocationInputValue: "",
    loading: false,
    assignedEmailAlertsList: [],
    assignedUserAccessList: [],
    autoSendBills: false
  });

  React.useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const GetUserClientGroups = authAxios.get("/CurrentUserClientGroups");

    const GetStates = authAxios.get("GetUserClientStates");

    axios
      .all([GetUserClientGroups, GetStates])
      .then(
        axios.spread((...responses: any) => {
          const clientGroups = responses[0];
          const states = responses[1];

          if (states.data && clientGroups.data) {
            let permittedValues = clientGroups.data.map((value: any) => ({
              clientGroupID: value.clientGroupID,
              clientGroupUniqueName: value.clientGroupUniqueName,
            }));

            setState({
              ...state,
              clientStates: states.data,
              loading: false,
              clientGroups: permittedValues,
            });
          }
        })
      )
      .catch((e: any) => {
        console.log(e);
        setState({
          ...state,
          loading: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewPermissionStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = async () => {
    let flag = true;
    if (activeStep === 0) {
      flag = await validateFirstStep();
    }
    if (activeStep === 1) {
      flag = validateSecondStep();
    }
    if (activeStep === 2) {
      flag = await validateThirdStep();
      flag && $dispatch(
        handleGenbillState({
          type: "HANDLE_GENBILL_NAV",
          payload: { selectedMenu: "HOME" },
        })
      );
    }
 
    flag && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setState({
        ...state,
        assignedUserAccessList: [],
        assignedEmailAlertsList: [],
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function validateThirdStep() {
    if (state.assignedEmailAlertsList.length === 0) {
      enqueueSnackbar(`Error! Assign Email Alert to at least one user.`, {
        variant: "error",
      });
      return false;
    }
    return await handleSubmit();
  }

  function validateSecondStep() {
    if (state.assignedUserAccessList.length === 0) {
      enqueueSnackbar(`Error! Assign Access to at least one user.`, {
        variant: "error",
      });
      return false;
    }

    const permissionsRequired = state.assignedUserAccessList.every(
      (item: BillObj) => item.permissionLevelID
    );
    if (!permissionsRequired) {
      enqueueSnackbar(`Error! Each user should have a permission assigned.`, {
        variant: "error",
      });
      return false;
    }
    return true;
  }

  async function validateFirstStep() {
    if (
      state.selectBy === "CLIENTLOCATION" &&
      (state.stateCode === "" ||
        state.clientLocation === null ||
        state.billTitle === "")
    ) {
      enqueueSnackbar(`Error! All fields are required.`, {
        variant: "error",
      });
      return false;
    }

    if (
      state.selectBy === "GROUP" &&
      (state.groupID === "" || state.billTitle === "")
    ) {
      enqueueSnackbar(`Error! All fields are required.`, {
        variant: "error",
      });
      return false;
    }
    return await checkIfExists();
  }

  async function checkIfExists() {
    setState({
      ...state,
      loading: true,
    });

    const billType = state.selectBy === "CLIENTLOCATION" ? 1 : 2;
    const searchID = state.groupID  ? state.groupID : state.clientLocation?.clientLocationID;

    return brokerBillingAxios
      .get(`/Bills/ValidateBillCreation/billType/${billType}/id/${searchID}`)
      .then((res: any) => {
        const {status} = res || {};
        if(status !== 200) return false
        setState({
          ...state,
          loading: false,
        });
        return true;
      })
      .catch((e: any) => {
        setState({
          ...state,
          loading: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return false;
      });
  }

  async function handleSubmit() {
    setState({
      ...state,
      loading: true,
    });

    const assignedUserAccessList = state.assignedUserAccessList.map(
      (item: BillObj) => ({
        userID: item.userID,
        permissionLevelID: item.permissionLevelID,
      })
    );

    const assignedEmailAlertsList = state.assignedEmailAlertsList.map(
      (itemx: BillObj) => ({
        userID: itemx.userID,
      })
    );

    const params: any = {
      title: state.billTitle,
      billTypeID: state.selectBy === "CLIENTLOCATION" ? 1 : 2,
      billBucketFlag: false,
      statementStatusTypeID: 6,
      billOwner: createdBy && createdBy.id,
      autoSendFlag: state.autoSendBills,
      clientLocationID: state.clientLocation?.clientLocationID
        ? state.clientLocation?.clientLocationID
        : null,
      clientGroupID: state.groupID ? state.groupID : null,
      stateCode: state.stateCode ? state.stateCode : null,
      createdDate: new Date().toISOString(),
      createdBy: createdBy && createdBy.id,
      assignedEmailAlertsList,
      assignedUserAccessList,
    };

    return brokerBillingAxios
      .post("/Bills/Create", params)
      .then((res: any) => {
        setState({
          ...state,
          loading: false,
        });
        enqueueSnackbar(`Success! Bill created successfully.`, {
          variant: "success",
          autoHideDuration:3000
        });
        return true;
      })
      .catch((e: any) => {
        setState({
          ...state,
          loading: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return false;
      });
  }

  return (
    <Grid container>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SimpleBackdrop open={state.loading} />
      <Grid
        sx={{
          width: "100%",
          height: window.innerHeight - 210,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid mt={2}>
          <InfoSectionSelector
            activeStep={activeStep}
            setState={setState}
            state={state}
            modalErrors={modalErrors}
            setModalErrors={setModalErrors}
            handleNext={handleViewPermissionStep}
          />
        </Grid>
      </Grid>
      {activeStep === steps.length ? null : (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
          width={"100%"}
        >
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button variant="contained" onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
