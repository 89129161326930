import {
  ModalErrorMessagesType,
  handleErrorResponse,
} from "@genrecp/g2clientportal-common";
import { loginAxios } from "../../services/axios";
import { CurrentUser, getToken } from "../slices/authSlice";
import { AppDispatch } from "../store";
import { authActionTypes } from "./authActionTypes";
import { Buffer } from "buffer";
import {
  CashTrackerState,
  setCashTrackerRoles,
} from "../slices/cashTrackerSlice";
import {
  AppRolePermissionType,
  CashTrackerLookup,
  CashTrackerRoles,
} from "../../App/Components/admin/adminUtils";
import { persistedStore } from "../..";

const fetchTokenStart = () => ({
  type: authActionTypes.AUTH_START,
});

const fetchTokenSuccess = (payload: any) => ({
  type: authActionTypes.AUTH_SUCCESS,
  payload: payload,
});

export const fetchTokenError = (payload: ModalErrorMessagesType | null) => ({
  type: authActionTypes.AUTH_ERROR,
  payload: payload,
});

export const GetTokenAction = (
  navigate: any,
  msalUser: any,
  accessToken: any,
  currentRoute: any
) => {
  return (dispatch: AppDispatch) => {
    dispatch(getToken(fetchTokenStart()));
    persistedStore.persist();
    loginAxios
      .get("/Auth", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (res) {
        if (res.data.token) {
          const { token } = res.data;

          // GETTING CURRENT USER
          let base64ToString = Buffer.from(
            token.split(".")[1],
            "base64"
          ).toString();
          let base64ToObject = JSON.parse(base64ToString);

          handleCashTrackerRoles(base64ToObject, dispatch);

          const payload = {
            token,
            currentUser: base64ToObject,
            msalUser: msalUser,
            msalToken: accessToken,
          };
          if (currentRoute === "") {
            navigate("/clientPortal");
          } else {
            navigate(currentRoute);
          }
          dispatch(getToken(fetchTokenSuccess(payload)));
        }
      })
      .catch((e) => {
        const modalErrorMessages = handleErrorResponse(e);
        dispatch(getToken(fetchTokenError(modalErrorMessages)));
        sessionStorage.clear();
      });
  };
};

export const logout = () => {
  const url = process.env.REACT_APP_PUBLIC_URL;
  persistedStore.pause();
  sessionStorage.clear();
  if (url) window.location.assign(url);
};

const cashTrackerRoleComparator = (
  cashTrackerAppTypeRoles: AppRolePermissionType[],
  roleId: number
) =>
  cashTrackerAppTypeRoles.some(
    (appTypeRoles) => roleId === appTypeRoles.roleId
  );

export const isCashTrackerQAOperations = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_QA_OPERATIONS
  );
export const isCashTrackerAdmin = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_ADMIN
  );
export const isCashTrackerQAContributer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_QA_CONTRIBUTER
  );
export const isCashTrackerQAViewer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_QA_VIEWER
  );
export const isCashTrackerQCContributer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_QC_CONTRIBUTER
  );
export const isCashTrackerQCViewer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_QC_VIEWER
  );
export const isCashTrackerContributer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_CONTRIBUTER
  );
export const isCashTrackerViewer = (
  cashTrackerAppTypeRoles: AppRolePermissionType[]
) =>
  cashTrackerRoleComparator(
    cashTrackerAppTypeRoles,
    CashTrackerRoles.CASH_TRACKER_VIEWER
  );

function handleCashTrackerRoles(
  base64ToObject: CurrentUser,
  dispatch: AppDispatch
) {
  if (!base64ToObject) return;
  if (!base64ToObject?.appRolePermissions) return;
  const appRolePermissions = base64ToObject.appRolePermissions;

  const parsedAppRolePermissions = appRolePermissions
    ? JSON.parse(appRolePermissions)
    : [];

  const cashTrackerAppTypeRoles: AppRolePermissionType[] =
    parsedAppRolePermissions.filter(
      (appRole: any) => appRole.appTypeId === CashTrackerLookup.CashTracker
    );

  const cashTrackerRolesStateObject: CashTrackerState = {
    cashTrackerQAOperations: isCashTrackerQAOperations(cashTrackerAppTypeRoles),
    cashTrackerAdmin: isCashTrackerAdmin(cashTrackerAppTypeRoles),
    cashTrackerQAContributer: isCashTrackerQAContributer(
      cashTrackerAppTypeRoles
    ),
    cashTrackerQAViewer: isCashTrackerQAViewer(cashTrackerAppTypeRoles),
    cashTrackerQCContributer: isCashTrackerQCContributer(
      cashTrackerAppTypeRoles
    ),
    cashTrackerQCViewer: isCashTrackerQCViewer(cashTrackerAppTypeRoles),
    cashTrackerContributer: isCashTrackerContributer(cashTrackerAppTypeRoles),
    cashTrackerViewer: isCashTrackerViewer(cashTrackerAppTypeRoles),
  };

  dispatch(setCashTrackerRoles(cashTrackerRolesStateObject));
}
