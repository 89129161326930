import React from "react";
import { DelegatedIssuesProps } from "../delegatedIssuesAdminUtils";
import { Grid, Grow } from "@mui/material";
import DelegatedIssueTitle from "../delegatedIssuesComponents/DelegatedIssueTitle";
import QAErrorGrid from "./QAErrorGrid";
import QAErrorForm from "./QAErrorForm";
import { QAErrorReasonGridItem, QAErrorReasonType } from "./qaErrorFormUtils";
import { delegatedAxios } from "../../../../../services/axios";
import {
  handleSuccessResponse,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

function QAErrorReason({ request, dispatch }: DelegatedIssuesProps) {
  const [state, setState] = React.useState<QAErrorReasonType>({
    qaErrorReasonTypeText: "",
    active: false,
    updateToggler: false,
    qaErrorReasonGridItems: [],
    gridDataLoading: false,
    apiLoading: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  React.useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getGridData() {
    setState((prev) => ({
      ...prev,
      gridDataLoading: true,
    }));
    delegatedAxios
      .get("/QAErrorsReason")
      .then((res) => {
        const { data } = res || {};
        setState((prev) => ({
          ...prev,
          qaErrorReasonGridItems: data || [],
          gridDataLoading: false,
        }));
      })
      .catch((e: any) => {
        showInvalidSnackbar(e, enqueueSnackbar);
        setState((prev) => ({
          ...prev,
          gridDataLoading: false,
        }));
      });
  }

  function qaErrorReasonCheck() {
    if (!state.qaErrorReasonTypeText) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "QA Error Reason is required!"
      );
      return false;
    }
    return true;
  }

  function qaErrorReasonIDCheck() {
    if (!state.qaErrorReasonTypeID) {
      showInvalidSnackbar(null, enqueueSnackbar, "ID not Found!");
      return false;
    }
    return true;
  }

  function handleUpdate() {
    const textCheck = qaErrorReasonCheck();
    if (!textCheck) {
      return;
    }
    const idCheck = qaErrorReasonIDCheck();
    if (!idCheck) {
      return;
    }

    const payload: QAErrorReasonGridItem = {
      active: state.active,
      qaErrorReasonTypeText: state.qaErrorReasonTypeText,
      modifiedBy: createdBy!.id,
      modifiedDate: new Date().toISOString(),
      createdBy: state.createdBy,
      createdDate: state.createdDate,
      qaErrorReasonTypeID: state.qaErrorReasonTypeID,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .put(`/QAErrorsReason/Update/${state.qaErrorReasonTypeID}`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleSubmit() {
    const check = qaErrorReasonCheck();
    if (!check) {
      return;
    }

    const payload: QAErrorReasonGridItem = {
      active: state.active,
      qaErrorReasonTypeText: state.qaErrorReasonTypeText,
      createdBy: createdBy!.id,
      createdDate: new Date().toISOString(),
      modifiedBy: null,
      modifiedDate: null,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .post(`/QAErrorsReason/Create`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleReset() {
    setState((prev) => ({
      ...prev,
      active: false,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      qaErrorReasonTypeID: null,
      qaErrorReasonTypeText: "",
      updateToggler: false,
      apiLoading: false,
    }));
  }

  return (
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={12} md={12} item>
          <DelegatedIssueTitle request={request} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <QAErrorForm
            state={state}
            setState={setState}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <QAErrorGrid state={state} setState={setState} />
        </Grid>
      </Grid>
    </Grow>
  );
}

export default QAErrorReason;
