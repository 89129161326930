import * as React from "react";
import { Divider } from "@mui/material";
import styled from "styled-components";
import {
  AppDispatch,
  RootState,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import { MuiCustomDrawer } from "@genre/g2common-theme";

const ScrollPanel = styled.div`
  height: calc(100% - 38px);
  width: 100%;
  padding: 0 0 0 0;
  margin: 0;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: flex-start;
`;

const Container = styled.div`
  padding: 20px;
`;

interface Props {
  title: string;
  children: JSX.Element;
  drawerWidth: number;
}

const BillStatementDrawer: React.FC<Props> = ({
  title,
  children,
  drawerWidth,
}: Props) => {
  const $dispatch = useDispatch<AppDispatch>();
  const showSummaryDrawer = useSelector(
    (state: RootState) => state.genbill.showSummaryDrawer
  );

  const onClose = () => {
    $dispatch(
      handleGenbillState({
        type: "SHOW_SUMMARY_DRAWER",
        payload: { showSummaryDrawer: false },
      })
    );
  };

  return (
    <MuiCustomDrawer
      anchor="right"
      open={showSummaryDrawer}
      drawerWidth={drawerWidth}
      title={title}
      onClose={onClose}
      justifyContent="flex-start"
    >
      <>
        <ScrollPanel>
          <InputPanel>
            <Container>{children}</Container>
          </InputPanel>
        </ScrollPanel>
        <Divider />
      </>
    </MuiCustomDrawer>
  );
};

export default BillStatementDrawer;
