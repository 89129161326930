import React from "react";
import { DelegatedIssuesProps } from "../utils";
import PolicyEndorsementGrid from "../PolicyEndorsementGrid";
import SuspenseIssues from "../SuspenseIssues";

interface Props extends DelegatedIssuesProps {}

function DelegatedIssuesInfoSectionSelector({ state, setState }: Props) {
  switch (state.screenName) {
    case "POLICY_ENDORSEMENT":
      return <PolicyEndorsementGrid state={state} setState={setState} />;
    case "SUSPENSE":
      return <SuspenseIssues state={state} setState={setState} />;
    default:
      return null;
  }
}

export default DelegatedIssuesInfoSectionSelector;
