import React from "react";
import { CashTrackerProps, CashTrackerStatus } from "../CashTrackerUtils";
import {
  handleErrorResponse,
  ModalErrors,
} from "@genrecp/g2clientportal-common";
import { CashTrackerStatusFilterType } from "./cashTrackerFormUtils";
import { delegatedAxios } from "../../../../services/axios";
import axios from "axios";
import CashTrackerForm from "./CashTrackerForm";
import CashLogGrid from "./CashLogGrid";

interface Props extends CashTrackerProps {
  updateCashTrackerForm: boolean;
}

function CashTrackerFormWrapper({
  request,
  dispatch,
  updateCashTrackerForm = false,
}: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getData() {
    dispatch({
      cashTrackerLoader: "CLIENT_NAME",
    });
    const getClientNames = delegatedAxios.get(`/ClientInfos/GetClientInfo`);
    const getStatuses = delegatedAxios.get("/CashIssuesStatus");
    const promises = [getClientNames, getStatuses];

    if (
      updateCashTrackerForm &&
      request.cashTrackerMasterFields.cashTrackerID
    ) {
      const getCashTrackerDetails = delegatedAxios.get(
        `/CashTrackers/Get/${request.cashTrackerMasterFields.cashTrackerID}`
      );
      promises.push(getCashTrackerDetails);
    }

    axios
      .all(promises)
      .then(
        axios.spread((...res: any) => {
          dispatch({
            cashTrackerLoader: "",
          });
          const { data: clientNames } = res[0] || {};
          const { data: cashTrackerStatuses } = res[1] || {};
          const { data: cashTrackerRecord } = res?.[2] || {};

          const formattedCashTrackerStatuses =
            cashTrackerStatuses && cashTrackerStatuses.length
              ? cashTrackerStatuses
                  .filter((x: CashTrackerStatusFilterType) => x.active)
                  .map((item: CashTrackerStatus) => ({
                    cashIssueStatusTypeID: item.cashIssueStatusTypeID,
                    cashIssueStatusTypeText: item.cashIssueStatusTypeText,
                  }))
              : [];

          dispatch({
            cashTrackerForm: {
              ...request.cashTrackerForm,
              clientNames: clientNames || [],
              cashTrackerStatuses: formattedCashTrackerStatuses,
              ...(cashTrackerRecord && {
                clientInfo: {
                  clientName:
                    cashTrackerRecord.clientDetailInfoDto
                      .clientLocationUniqueName,
                  clientInfoID:
                    cashTrackerRecord.clientDetailInfoDto.clientInfoID,
                },
                checkNumber: cashTrackerRecord.checkNumber,
                checkDate: cashTrackerRecord.checkDate,
                lockBoxDate: cashTrackerRecord.lockBoxDate,
                checkAmount: cashTrackerRecord.totalCheckAmount,
                cashTrackerStatus:
                  cashTrackerRecord.cashIssueStatusType.cashIssueStatusTypeID,
                mgAorBroker:
                  cashTrackerRecord?.clientDetailInfoDto?.mgAorBroker === "M"
                    ? "MGA"
                    : "BROKER",
                billpointCode:
                  cashTrackerRecord?.clientDetailInfoDto?.billpointCode || "",
                branchCode:
                  cashTrackerRecord?.clientDetailInfoDto?.branchCode || "",
                g2CompanyCode:
                  cashTrackerRecord?.clientDetailInfoDto?.g2CompanyCode,
                bsdContact:
                  cashTrackerRecord?.clientDetailInfoDto?.operationsContact ||
                  "",
                grap: cashTrackerRecord?.clientDetailInfoDto?.grapCode || "",
              }),
            },
          });
        })
      )
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  return (
    <>
      <CashTrackerForm
        request={request}
        dispatch={dispatch}
        modalErrors={modalErrors}
        setModalErrors={setModalErrors}
        updateCashTrackerForm={updateCashTrackerForm}
      />
      {updateCashTrackerForm && (
        <CashLogGrid request={request} dispatch={dispatch} />
      )}
    </>
  );
}

export default CashTrackerFormWrapper;
