import styled from "styled-components";

export type CashLogGridSelector = "DETAIL" | "GRID" | "ADD";
export type CompanyValueType = "GSI" | "GIC";
export type CompanyName = "General Star" | "Genesis";

export const BlueDiv = styled.div`
  background-color: #3a5baa;
  padding-left: 0.9rem;
`;

export interface CashLogType extends CashLogItemDetail {
  gridSelector: CashLogGridSelector;
}

export interface CustomFile extends File {
  cashLogDocumentID?: string;
  preview: string;
}

export interface FileObject {
  cashLogDocumentID?: string;
  cashLogID: string;
  cashLog: null;
  documentURL: string;
  createdDate: string;
  createdBy: string;
}

export interface CashLogItemDetail {
  cashLogID: string | null;
  g2CompanyCode: CompanyValueType;
  comment: string;
  tatStartDate: string | null;
  cashLogDate: string | null;
  cashLogDocuments?: null;
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  files: any[];
}

interface CompanyListType {
  name: CompanyName;
  value: CompanyValueType;
}

export const companies: CompanyListType[] = [
  {
    name: "General Star",
    value: "GSI",
  },
  {
    name: "Genesis",
    value: "GIC",
  },
];

export type CashLogAction = {
  type: "UPDATE_UNIVERSAL_REQUEST";
  request: Partial<CashLogType>;
};
export type CashLogDispatch = React.Dispatch<CashLogAction>;

export const initialCashLogState: CashLogType = {
  gridSelector: "GRID",
  g2CompanyCode: "GSI",
  tatStartDate: new Date().toISOString(),
  cashLogDate: new Date().toISOString(),
  cashLogID: null,
  files: [],
  comment: "",
};

export interface CashLogProps {
  request: CashLogType;
  dispatch: CashLogDispatch;
}

export const cashLogReducer = (state: CashLogType, action: CashLogAction) => {
  switch (action.type) {
    case "UPDATE_UNIVERSAL_REQUEST":
      return {
        ...state,
        ...action.request,
      };
    default:
      return state;
  }
};

export interface CashLogGridType {
  cashLogID: string;
  g2CompanyCode: string;
  comment: string;
  cashLogDate: string;
  tatStartDate: string;
  cashLogDocuments: CashLogDocument[];
  createdDate: string;
  createdBy: string;
  username: string;
  modifiedDate: string;
  modifiedBy: string;
}

interface CashLogDocument {
  cashLogDocumentID: string;
  cashLogID: string;
  cashLog: null;
  documentURL: string;
  createdDate: string;
  createdBy: string;
  username: null;
}