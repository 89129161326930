import React, { Fragment } from "react";
import styled from "styled-components";
import {
  UserRequestObject,
  AppTypeRoles,
  Role,
  ApptypeTypeArray,
  UserDispatch,
} from "../userUtils";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TransferList from "./TransferList";
import { TextField } from "@mui/material";

export const Container = styled.div`
  padding: 5px;
`;

export const InnerRoleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  AppTypeRoles: AppTypeRoles[];
  request: UserRequestObject;
  setAppTypeRoles: (v: AppTypeRoles[]) => void;
  appTypeArray: ApptypeTypeArray[];
  dispatch: UserDispatch;
}

export const CustomDrawerForm: React.FC<Props> = ({
  AppTypeRoles,
  request,
  setAppTypeRoles,
  appTypeArray,
  dispatch,
}) => {
  const [appFilter, setAppFilter] = React.useState<string>("");
  const [left, setLeft] = React.useState<ApptypeTypeArray[]>(
    appTypeArray.filter(
      (left) =>
        !request.userAppTypes.find(
          (right: ApptypeTypeArray) => right.appTypeID === left.appTypeID
        )
    )
  );
  const [right, setRight] = React.useState<ApptypeTypeArray[]>(
    request.userAppTypes
  );

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    appTypeID: number
  ) => {
    const { name, checked } = event?.target;

    let newApproles: AppTypeRoles[] = [...AppTypeRoles];

    newApproles.forEach((item: any) => {
      item.roles.forEach((innerItem: any) => {
        if (innerItem.name === name && appTypeID === item.appTypeID) {
          innerItem["isAssignedToApp"] = checked;
        }
      });
    });

    setAppTypeRoles(newApproles);
  };

  const removeApp = (e: any, app: AppTypeRoles) => {
    const moveToLeft = right.filter(
      (rightItem) => rightItem.appTypeID === app.appTypeID
    );

    setLeft([...left, ...moveToLeft]);
    setRight(
      right.filter((rightItem) => rightItem.appTypeID !== app.appTypeID)
    );
    setAppTypeRoles(
      AppTypeRoles.filter(
        (AppTypeRole) => AppTypeRole.appTypeID !== app.appTypeID
      )
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} item>
          <TransferList
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
            dispatch={dispatch}
            request={request}
          />
        </Grid>
      </Grid>
      {!AppTypeRoles.length ? null : (
        <Grid md={2} item my={1}>
          <TextField
            label="Search"
            name={"appFilter"}
            value={appFilter}
            fullWidth
            size="small"
            onChange={(e) => setAppFilter(e.target.value)}
          />
        </Grid>
      )}
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: {
            md: "1fr 1fr 1fr 1fr",
            sm: "1fr 1fr",
          },
          gridTemplateRows: "auto",
          gap: 1,
        }}
        item
      >
        {AppTypeRoles.filter((item: ApptypeTypeArray) =>
          item.appTypeText
            ?.toLocaleLowerCase()
            .includes(appFilter.toLocaleLowerCase())
        ).map((item: AppTypeRoles, idz: any) => {
          return (
            <Card
              sx={{
                minWidth: 275,
                ":hover": { boxShadow: 10 },
                position: "relative",
              }}
              key={idz}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="bodyMedium">
                  <b>{item.appTypeText}</b>
                </Typography>

                <CloseIcon
                  sx={{
                    position: "absolute",
                    right: 4,
                    top: 2,
                    color: "#000",
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={(e) => removeApp(e, item)}
                />
                {item.roles.map((innerItem: Role, idx: any) => {
                  return (
                    <InnerRoleSwitchContainer key={idx}>
                      <Checkbox
                        name={innerItem.name}
                        id={innerItem.roleID.toString()}
                        size="small"
                        sx={{
                          padding: 0,
                        }}
                        checked={
                          innerItem.hasOwnProperty("isAssignedToApp")
                            ? innerItem.isAssignedToApp
                            : false
                        }
                        onChange={(event) =>
                          handleInputChange(event, item.appTypeID)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography variant="bodySmall">
                        {innerItem.name}
                      </Typography>
                    </InnerRoleSwitchContainer>
                  );
                })}
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </Fragment>
  );
};
