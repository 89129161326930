import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DetailSpan } from "@genrecp/g2clientportal-genbill";
import { ICellRendererParams } from "ag-grid-community";
import { authAxios } from "../../../../services/axios";
import {
  ActionButtons,
  CustomSnackbar,
  ModalErrors,
  handleErrorResponse,
  handleSuccessResponse,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { Users } from "./userUtils";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ResetPasswordDialog({
  data,
}: ICellRendererParams<Users>) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetPassword = () => {
    if (!(data?.external && data.userID)) return;
    setLoading(true);
    authAxios.get(`/ResetExternalUserPassword/${data.userID}`).then(res => {
      handleSuccessResponse(res,enqueueSnackbar);
      setLoading(false);
      handleClose();
    }).catch(e => {
        setLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
            ...modalErrors,
            errors: modalErrorMessages,
            ErrorModelOpen:true
        })
    })
  };

  const isGenstarInsExt = (userLoginID:string | undefined) => {
    if(!userLoginID) return false;
    return userLoginID.split("@")[1].toLocaleLowerCase() === "genstarinsext.com"
  }

  return (
    <React.Fragment>
      {data?.external && isGenstarInsExt(data?.userLoginID) ? (
        <DetailSpan onClick={handleClickOpen}>Reset Password</DetailSpan>
      ) : null}
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Dialog open={open} maxWidth="md">
        <DialogTitle textAlign={"center"}>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            Are you sure you want to reset password for {" "}
            {data?.userLoginID}?
          </DialogContentText>
        </DialogContent>
        <ActionButtons>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton loading={loading} onClick={resetPassword}>
            Proceed
          </LoadingButton>
        </ActionButtons>
      </Dialog>
    </React.Fragment>
  );
}
