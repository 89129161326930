import { ModalErrorsType, NewBillState } from "../genbillUtils";
import NewBillSetup from "./newBillSetup/NewBillSetup";
import NewBillUserAccess from "./newBillSetup/NewBillUserAccess";
import NewBillEmailAlerts from "./newBillSetup/NewBillEmailAlerts";

interface Props extends NewBillState, ModalErrorsType {
  activeStep: Number;
  handleNext: () => void;
}

function InfoSectionSelector({
  activeStep,
  setState,
  state,
  modalErrors,
  setModalErrors,
  handleNext,
}: Props) {
  switch (activeStep) {
    case 0:
      return (
        <NewBillSetup
          state={state}
          setState={setState}
          modalErrors={modalErrors}
          setModalErrors={setModalErrors}
        />
      );
    case 1:
      return (
        <NewBillUserAccess
          state={state}
          setState={setState}
          modalErrors={modalErrors}
          setModalErrors={setModalErrors}
          handleNext={handleNext}
        />
      );
    case 2:
      return <NewBillEmailAlerts state={state} setState={setState} />;
    default:
      return null;
  }
}

export default InfoSectionSelector;
