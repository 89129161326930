import React, { createRef } from "react";
import { CashLogProps, companies, CompanyValueType } from "./cashLogUtils";
import { Grid, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import {
  Button,
  CustomSnackbar,
  ModalErrors,
  SelectList,
  SimpleBackdrop,
  TextInput,
} from "@genrecp/g2clientportal-common";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dropzone, { DropzoneRef } from "react-dropzone";
import AddCashLogGrid from "./AddCashLogGrid";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface Props extends CashLogProps {
  modalErrors: ModalErrors;
  setModalErrors: (v: ModalErrors) => void;
  loader: boolean;
  setLoader: (v: boolean) => void;
  getData: (id: string | undefined) => void;
  handleSubmit: () => void;
}

function AddCashLogForm({
  request,
  dispatch,
  modalErrors,
  getData,
  handleSubmit,
  loader,
  setModalErrors,
  setLoader,
}: Props) {
  const dropzoneRef = createRef<DropzoneRef>();

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  const acceptedFiles = {
    "application/pdf": [".pdf", ".doc", ".docx", ".xls", ".xlsx",".txt"],
    'image/jpeg': ['.jpeg', '.png', '.jpg']
  };
  const onDrop = (acceptedFiles: File[]) => {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        files: [
          ...request.files,
          ...(acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              customName: file.name,
            })
          ) as any),
        ],
      },
    });
  };
  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as CompanyValueType;
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        g2CompanyCode: value,
      },
    });
  };
  console.log(request);
  const handleDateChange = (
    value: any,
    keyboardInputValue?: string,
    name?: "cashLogDate" | "tatStartDate"
  ) => {
    if (!name) return;
    if(value != null && value.$d != "Invalid Date") {
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          ...request,
          [name]: value ? new Date(value).toISOString() : null,
        },
      });
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container display={"flex"} alignItems={"center"} spacing={1}>
        <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
        <SimpleBackdrop open={loader} />
        <Grid md={4} item>
          <SelectList
            name="g2CompanyCode"
            allowempty={false}
            value={request.g2CompanyCode}
            label="Company"
            onChange={handleDropDownChange}
          >
            {companies.map((item, idx) => (
              <MenuItem value={item.value} key={idx}>
                {item.name}
              </MenuItem>
            ))}
          </SelectList>
        </Grid>
        <Grid md={4} item>
          <DatePicker
            value={request.cashLogDate}
            label="Cash Log Date"
            onChange={(value) =>
              handleDateChange(value, undefined, "cashLogDate")
            }
            renderInput={(props) => (
              <TextField size="small" required fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid md={4} item>
          <DatePicker
            value={request.tatStartDate}
            label="Tat Start Date"
            onChange={(value) =>
              handleDateChange(value, undefined, "tatStartDate")
            }
            renderInput={(props) => (
              <TextField required size="small" fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid md={8} display={"flex"} item>
          <TextInput
            multiline
            rows={4}
            label="Comment"
            value={request.comment}
            inputProps={{
              maxLength: 2000,
            }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch({
                type: "UPDATE_UNIVERSAL_REQUEST",
                request: {
                  comment: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid md={6} item display={"flex"} gap={1} alignItems={"center"}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Dropzone
            onDrop={onDrop}
            accept={acceptedFiles}
            ref={dropzoneRef}
            noClick
            noKeyboard
          >
            {({ getRootProps, getInputProps, acceptedFiles }) => {
              return (
                <>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={openDialog}
                  >
                    Upload Files
                  </Button>
                </>
              );
            }}
          </Dropzone>
          {request.files.some((item) => !item?.cashLogDocumentID) ? (
            <Typography variant="body1" color={"red"}>
              * Submit to save uploaded file
            </Typography>
          ) : null}
        </Grid>
        <Grid md={12} item>
          <AddCashLogGrid
            request={request}
            dispatch={dispatch}
            setLoader={setLoader}
            getData={getData}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default AddCashLogForm;
