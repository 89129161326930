import React from "react";
import { CashTrackerProps } from "../../CashTrackerUtils";
import { Grid } from "@mui/material";
import SuspenseSideMenu from "./SuspenseSideMenu";
import SuspenseDetailInfoSectionSelector from "./SuspenseDetailInfoSectionSelector";

interface Props extends CashTrackerProps {}

function SuspenseDetailWrapper({ request, dispatch }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid md={10} lg={10} item>
        <SuspenseDetailInfoSectionSelector
          request={request}
          dispatch={dispatch}
        />
      </Grid>
      <Grid md={2} item>
        <SuspenseSideMenu request={request} dispatch={dispatch} />
      </Grid>
    </Grid>
  );
}

export default SuspenseDetailWrapper;
