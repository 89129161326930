import React from "react";
import { Grid, Typography,  Stack } from "@mui/material";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { DelegatedIssuesProps } from "../utils";

interface Props extends DelegatedIssuesProps {}

function GridToggler({ state, setState }: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  function handleGridChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setState({
      ...state,
      screenName: checked ? "SUSPENSE" : "POLICY_ENDORSEMENT",
    });
  }
  return (
    <Grid
      md={12}
      gap={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      item
    >
      <Stack display={"flex"} direction={"row"} gap={1}>
        <Typography variant="labelMedium">
          Policy / Endorsement Issues
        </Typography>
        <Switch
          {...label}
          size="small"
          value={state.screenName}
          checked={state.screenName === "POLICY_ENDORSEMENT" ? false : true}
          onChange={handleGridChange}
        />
        <Typography variant="labelMedium">Suspense Issues</Typography>
      </Stack>
    </Grid>
  );
}

export default GridToggler;
