import { showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { authAxios } from "../../../../services/axios";
import { RolesAcessLookUp } from "../adminUtils";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { formatDateForGrid } from "@genrecp/g2clientportal-common";

export interface UserTextFieldValidation {
  fName: boolean;
  lName: boolean;
}

export interface AppTypeRoles {
  appTypeID: number;
  appTypeText: string;
  active: boolean;
  roles: Role[];
}

export interface Role {
  roleID: number;
  name: string;
  active: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate?: any;
  modifiedBy?: any;
  isAssignedToApp?: boolean;
}

export interface UserRequestObject {
  userID?: string;
  userLoginID?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  managerID?: string;
  businessPhone?: string;
  external?: boolean;
  active?: boolean;
  userClientLocations?: any;
  userAppTypes?: any;
  createdBy?: string;
  modifiedBy?: string;
  createdDate?: string;
  modifiedDate?: string;
  openCustomDrawer?: boolean;
  appTypeRoles: AppTypeRoles[];
  isManagedByG2: boolean;
  sendEmailNotification: boolean;
}

export interface ClientLocationArray {
  clientLocationID?: string;
  clientLocationUniqueName?: string;
}
export interface RoleTypeArray {
  roleID?: number;
  name?: string;
}

export interface ApptypeTypeArray {
  appTypeID?: number;
  appTypeText?: string;
}
export type UserAction = Partial<UserRequestObject>;
export type UserDispatch = React.Dispatch<UserAction>;

export const userReducer = (state: UserRequestObject, action: UserAction) => {
  return {
    ...state,
    ...action,
  };
};

export const initialUserState = {
  userLoginID: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  active: true,
  external: true,
  businessPhone: "",
  createdBy: "",
  createdDate: "",
  userAppTypes: [],
  userClientLocations: [],
  userID: "",
  managerID: "",
  openCustomDrawer: false,
  appTypeRoles: [],
  isManagedByG2: true,
  sendEmailNotification: true,
}

// FUNCTION TO CHECK FOR EXTERNAL USER APP TYPES BASED ON CLIENT LOCATION ID SELECTION
export const getAppTypesBasedOnCLientLocationIDs = (
  userClientLocations: any,
  setappTypeLoadingSpecificCase: (v: boolean) => void,
  setAppTypeArray: (v: ApptypeTypeArray[]) => void,
  enqueueSnackbar: any,
  setTempAppRoleData: (v: AppTypeRoles[]) => void,
  request: UserRequestObject,
  dispatch: UserDispatch
) => {
  setappTypeLoadingSpecificCase(true);

  const clientLocationParams = userClientLocations.map(
    (item: any) => item.clientLocationID
  );
  const params: any = {
    clientLocationIds: clientLocationParams,
  };
  const selectedUserAppTypeRole = sessionStorage.getItem("appTypeRoleSelection")
    ? JSON.parse(sessionStorage.getItem("appTypeRoleSelection") || "[]")
    : [];

  authAxios
    .post(`/AppTypesForClientLocation`, params)
    .then((AppTypes: any) => {
      const apptypes = [...AppTypes.data];
      const externalCantBeAssignedSystemAdminArray = apptypes
        .map((item: AppTypeRoles) => {
          return {
            ...item,
            roles: item.roles.filter(
              (role: Role) =>
                role.roleID !== RolesAcessLookUp.SystemAdministrator
            ),
          };
        })
        .filter(
          (finalAppType: AppTypeRoles) => finalAppType.roles.length !== 0
        );

      const permittedValues: ApptypeTypeArray[] =
        externalCantBeAssignedSystemAdminArray.map((value: AppTypeRoles) => ({
          appTypeID: value.appTypeID,
          appTypeText: value.appTypeText,
        }));

      setAppTypeArray(permittedValues);
      if (selectedUserAppTypeRole.length > 0) {
        const updatedData = externalCantBeAssignedSystemAdminArray.map(
          (source: AppTypeRoles) => {
            const foundMatchingApp = selectedUserAppTypeRole.find(
              (selected: AppTypeRoles) =>
                selected.appTypeID === source.appTypeID
            );
            if (foundMatchingApp) {
              const matchingRole = source.roles.map((role: Role) => {
                const foundRole = foundMatchingApp.roles.find(
                  (matchingRole: Role) => matchingRole.roleID === role.roleID
                );
                if (foundRole) return foundRole;
                return role;
              });
              return {
                ...source,
                roles: matchingRole,
              };
            }
            return source;
          }
        );

        setTempAppRoleData(updatedData);

        const permittedAppTypeValues: ApptypeTypeArray[] = updatedData
          .filter((apps: AppTypeRoles) =>
            apps.roles.some((role: Role) => role.isAssignedToApp)
          )
          .map((value: AppTypeRoles) => ({
            appTypeID: value.appTypeID,
            appTypeText: value.appTypeText,
          }));
          dispatch({
            userAppTypes: permittedAppTypeValues,
            ...(userClientLocations && {
              userClientLocations: userClientLocations,
            }),
          })
        // setRequest({
        //   ...request,
        //   userAppTypes: permittedAppTypeValues,
        //   ...(userClientLocations && {
        //     userClientLocations: userClientLocations,
        //   }),
        // });
      } else {
        setTempAppRoleData(externalCantBeAssignedSystemAdminArray);
        userClientLocations && 
        dispatch({
          userClientLocations: userClientLocations,
        })
        // userClientLocations && 
        //   setRequest({
        //     ...request,
        //     userClientLocations: userClientLocations,
        //   });
      }

      setappTypeLoadingSpecificCase(false);
    })
    .catch((e: any) => {
      setappTypeLoadingSpecificCase(false);
      console.log(e.message);
      showInvalidSnackbar(e, enqueueSnackbar);
    });
};

export interface Users {
  userID: string;
  userLoginID: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  managerID?: any;
  businessPhone: string;
  external: boolean;
  active: boolean;
  isManagedByG2: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate?: string;
  modifiedBy?: string;
  userAppTypeRoles: any[];
  userClientLocations: any[];
  appTypeRoles?: any;
}

export const exportInactiveExternalUsersToCSV = (apiData: any, enqueueSnackbar: any) => {
  try {
    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet("Sheet 1");

    workSheet.columns = [
      { header: "Client Location ID", key: "clientLocationIDs", width: 30 },
      { header: "User Login ID", key: "userLoginID", width: 50 },
      { header: "Last Login Date", key: "lastLoginDate", width: 30 },
    ];

    apiData.forEach((item: any) => {
      workSheet.addRow({
        clientLocationIDs: item.clientLocationIDs,
        userLoginID: item.userLoginID,
        lastLoginDate: formatDateForGrid(item.lastLoginDate),
      });
    });

    workBook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats.officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, "lastLoginUsersReport.xlsx");
    });
  } catch (e) {
    console.log(e);
    showInvalidSnackbar(e,enqueueSnackbar);
  }
};

export function loginIDValidatorSpecificCase(
  userRequest: UserRequestObject
) {
  const params = {
    emailAddress: userRequest.userLoginID,
    clientLocationIds: userRequest?.userClientLocations?.map(
      (item: any) => item.clientLocationID
    ),
  };

  return authAxios
    .post("/ValidateEmailForClientLocationGroupDomain", params)
    .then((res: any) => {
      return res?.data ? true : false;
    })
    .catch((e: any) => {
      return false;
    });
}
