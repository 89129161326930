import React from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import "./TextInput.css";
import { otherProps } from "../selectList/SelectList";

type MainTextProps = otherProps & TextFieldProps;

const TextInput: React.FC<MainTextProps> = ({
  error,
  helperText,
  label,
  name,
  ...props
}) => {
  const newlabel = label ? label : name ? camelToPascalWithSpaces(name) : "";
  return (
    <div className="TextInput">
      <TextField
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        name={name}
        label={newlabel}
        size="small"
        fullWidth={true}
        error={error}
        {...props}
      />
      {helperText ? (
        <Typography mt={0.2} variant="labelSmall" color="error">
          {helperText}
        </Typography>
      ) : null}
    </div>
  );
};

export default TextInput;

function camelToPascalWithSpaces(camelCaseStr:string) {
  // Convert camelCase to PascalCase
  let pascalCaseStr = camelCaseStr.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => 
      index === 0 ? word.toUpperCase() : ' ' + word.toUpperCase()
  ).replace(/\s+/g, ' ');

  return pascalCaseStr;
}