import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  UserRequestObject,
  ApptypeTypeArray,
  UserDispatch,
} from "../userUtils";
import { TextField } from "@mui/material";

interface Props {
  request: UserRequestObject;
  left: ApptypeTypeArray[];
  setLeft: (v: ApptypeTypeArray[]) => void;
  right: ApptypeTypeArray[];
  setRight: (v: ApptypeTypeArray[]) => void;
  dispatch: UserDispatch;
}

function not(a: ApptypeTypeArray[], b: ApptypeTypeArray[]) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a: ApptypeTypeArray[], b: ApptypeTypeArray[]) {
  return a.filter((value) => b.includes(value));
}

export default function TransferList({
  request,
  dispatch,
  left,
  setLeft,
  right,
  setRight,
}: Props) {
  const [checked, setChecked] = React.useState<ApptypeTypeArray[]>([]);
  const [leftFilter, setLeftFilter] = React.useState<string>("");
  const [rightFilter, setRightFilter] = React.useState<string>("");

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: ApptypeTypeArray) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    dispatch({
      ...request,
      userAppTypes: right.concat(left),
    });
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    dispatch({
      ...request,
      userAppTypes: right.concat(leftChecked),
    });
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    dispatch({
      ...request,
      userAppTypes: not(right, rightChecked),
    });
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    dispatch({
      ...request,
      userAppTypes: [],
    });
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (
    items: readonly ApptypeTypeArray[],
    filterName: string,
    filterValue: string,
    setFilter: (v: string) => void
  ) => (
    <Paper
      sx={{
        width: 400,
        height: 200,
        overflow: "auto",
        marginBottom: "2px",
        paddingTop: "6px",
        paddingRight: "12px",
        paddingLeft: "12px",
      }}
    >
      <TextField
        label="Filter App List"
        name={filterName}
        value={filterValue}
        fullWidth
        size="small"
        onChange={(e) => setFilter(e.target.value)}
      />
      <List dense component="div" role="list">
        {items
          .filter((item: ApptypeTypeArray) =>
            item.appTypeText
              ?.toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          )
          .map((value: ApptypeTypeArray, idx: number) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItemButton
                key={value.appTypeID}
                role="listitem"
                onClick={handleToggle(value)}
                sx={{ padding: 0 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.includes(value)}
                    tabIndex={-1}
                    disableRipple
                    sx={{ padding: 0 }}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.appTypeText} />
              </ListItemButton>
            );
          })}
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Grid item>
        {customList(left, "leftFilter", leftFilter, setLeftFilter)}
      </Grid>
      <Grid item>
        <Grid container direction="column" sx={{ alignItems: "center" }}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList(right, "rightFilter", rightFilter, setRightFilter)}
      </Grid>
    </Grid>
  );
}
