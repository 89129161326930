import SelectList from "@genrecp/g2clientportal-common/src/components/selectList/SelectList";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { RootState } from "../../../../redux/store";
import { showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { authAxios, commonAxios } from "../../../../services/axios";
import { SelectChangeEvent } from "@mui/material";
import {
  ApptypeType,
  RoleType,
} from "../appRolePermission/appRolePermissionUtils";
import { Button } from "@genrecp/g2clientportal-common";
import RoleAppTypeGrid from "./RoleAppTypeGrid";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
export interface RequestObject {
  roleAppTypeID?: string;
  roleID: string;
  appTypeID: string;
  refreshToggler: boolean;
  appTypeArray: ApptypeType[];
  roleArray: RoleType[];
  createdDate?: string;
  createdBy?: string;
  updatetoggler: boolean;
  loading: boolean;
}

function RoleAppType() {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [request, setrequest] = React.useState<RequestObject>({
    roleID: "",
    appTypeID: "",
    refreshToggler: false,
    appTypeArray: [],
    roleArray: [],
    updatetoggler: false,
    loading: false,
  });

  React.useEffect(() => {
    const getRoles = authAxios.get("/GetRoles?active=true");

    const getAppTypes = commonAxios.get("/AppTypes");

    setrequest({ ...request, loading: true });
    axios
      .all([getRoles, getAppTypes])
      .then(
        axios.spread((...responses: any) => {
          const Roles = responses[0];
          const AppTypes = responses[1];

          if (Roles.data && AppTypes.data) {
            setrequest({
              ...request,
              appTypeArray: AppTypes.data,
              roleArray: Roles.data,
              loading: false,
            });
          } else {
            setrequest({ ...request, loading: false });
          }
        })
      )
      .catch((e: any) => {
        setrequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;
    setrequest({ ...request, [name]: val });
  };

  const handleUpdate = () => {
    if (!request.appTypeID || !request.roleID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      roleAppTypeID: request.roleAppTypeID,
      appTypeID: request.appTypeID,
      roleID: request.roleID,
      createdBy: request.createdBy,
      createdDate: request.createdDate,
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    };

    setrequest({ ...request, loading: true });

    authAxios
      .put(`/UpdateRoleAppTypes/${request.roleAppTypeID}`, params)
      .then((res: any) => {
        setrequest({
          ...request,
          roleID: "",
          appTypeID: "",
          refreshToggler: !request.refreshToggler,
          updatetoggler: false,
          loading: false,
        });
      })
      .catch((e: any) => {
        setrequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleSubmit = () => {
    if (!request.appTypeID || !request.roleID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      appTypeID: request.appTypeID,
      roleID: request.roleID,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
    };
    setrequest({ ...request, loading: true });
    authAxios
      .post("/RoleAppTypes", params)
      .then((res: any) => {
        setrequest({
          ...request,
          roleID: "",
          appTypeID: "",
          refreshToggler: !request.refreshToggler,
          loading: false,
        });
      })
      .catch((e: any) => {
        setrequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  return (
    <>
      <Grid container spacing={1} maxWidth="lg">
        <Grid xs={12} sm={12} md={12} item>
          <Typography variant="displaySmall">Role App Type</Typography>
        </Grid>

        <SimpleBackdrop open={request.loading} />

        <Grid xs={12} sm={6} md={5} item>
          <SelectList
            label="App Type"
            name="appTypeID"
            id="appTypeID"
            test-id="appTypeID"
            required
            value={request.appTypeID}
            onChange={handleDropDownChange}
          >
            {request.appTypeArray.map((item, idx) => {
              return (
                <MenuItem value={item.appTypeID} key={idx}>
                  {item.appTypeText}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>
        <Grid xs={12} sm={6} md={5} item>
          <SelectList
            label="Role"
            name="roleID"
            id="roleID"
            test-id="roleID"
            required
            value={request.roleID}
            onChange={handleDropDownChange}
          >
            {request.roleArray.map((item, idx) => {
              return (
                <MenuItem value={item.roleID} key={idx}>
                  {item.name}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>

        <Grid
          xs={12}
          sm={6}
          md={10}
          gap={1}
          item
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="outlined"
            onClick={() =>
              setrequest({
                ...request,
                roleID: "",
                appTypeID: "",
                roleAppTypeID: "",
                updatetoggler: false,
              })
            }
            id="cancel"
          >
            Cancel
          </Button>
          {!request.updatetoggler ? (
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              id="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              id="update"
            >
              Update
            </Button>
          )}
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          {request.roleArray &&
          request.roleArray.length > 0 &&
          request.appTypeArray &&
          request.appTypeArray.length > 0 ? (
            <RoleAppTypeGrid request={request} setrequest={setrequest} />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default RoleAppType;
