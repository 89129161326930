import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useRibbonSnackbar, EditChildRenderer } from "@genrecp/g2clientportal-common";
import { authAxios } from "../../../../../services/axios";
import { ClientLocationDomainType,clientGroupArrayType } from "../utils";
import { filterColumnsObjCreator } from "@genrecp/g2clientportal-common";
import {AgGridClasses} from "@genre/g2common-theme"

let gridApi: any;
let columnApi: any;

interface Props {
  request : ClientLocationDomainType,
  setRequest:(params:ClientLocationDomainType)=>void,
  clientGroupArray:clientGroupArrayType[]
}

export const ClientLocationDomainGrid:React.FC<Props> = (props) => {

    const {request,setRequest,clientGroupArray} = props;
    const token = useSelector((state: RootState) => state?.auth?.token);
    const { enqueueSnackbar } = useRibbonSnackbar();

    const onRowEditSelected = (params:any) => {
      const {clientGroupID,domain,active,clientGroupDomainID, createdDate , createdBy } = params.data || {};
      const newclientGroupIDObj = clientGroupArray.find((item:clientGroupArrayType) => item.clientGroupID ===  clientGroupID);

      if(newclientGroupIDObj){
          setRequest({
            ...request,
            clientGroupID:newclientGroupIDObj,
            domain,
            active,
            clientLocationDomainID:clientGroupDomainID,
            updatetoggler:true,
            createdBy,
            createdDate,
          })
      } else {
        enqueueSnackbar(`Error! Invalid Request`, {
          variant: "error",
        });
      }
    } 
  
      const defaultColDef:ColDef = {
        cellClass: "cell-wrap-text",
        cellStyle: { "whiteSpace": "normal" },
        sortable: true,
        resizable: true,
        floatingFilter:true
      };
    
    
      const [colDefs] = React.useState<ColDef[]>([
        {
          field: "clientGroupDomainID",
          sort:"desc",
          hide: true,
        },
        {
          field: "clientGroupID",
          colId: "clientGroupID",
          headerName: "Client Group ID",
          filter: "agTextColumnFilter",
          filterParams:{
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
          },
          flex:.5
        },
        {
          field: "clientGroupName",
          colId: "clientGroupName",
          headerName: "Client Group Name",
          filter: "agTextColumnFilter",
          width:200,
          filterParams:{
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
          },
          flex:1
        },
        {
          field: "domain",
          colId: "domain",
          headerName: "Domain",
          filter: "agTextColumnFilter",
          filterParams:{
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
          },
          flex:.4
        },
        {
          field: "active",
          colId: "active",
          headerName: "Active",
          filter: "agSetColumnFilter",  
          filterParams: {
            values: [true,false],
            suppressAndOrCondition: true
          },
          flex:.3
        },
        {
          headerName: '',
          onCellClicked: onRowEditSelected,
          cellRenderer: 'EditChildRenderer',
          flex:.2
        },
      ]);

      const frameworkComponents = {
        EditChildRenderer:EditChildRenderer
      };

      const ensureNonEmptyString = (str: any) => (typeof str === 'string' && str.trim() !== '');

      function getSortedColumns(columnApi: any) {
          return columnApi?.getColumnState().filter((cs: any) => ensureNonEmptyString(cs.sort));;
      }

      const ServerSideDatasource = () => {
        return {
          getRows: async function (params: any) {
            let filterModel = gridApi.getFilterModel();
            let filterCount = Object.keys(gridApi.getFilterModel()).length;
            let sortCount = getSortedColumns (columnApi).length
            let searchDataObj:any = {
                "pageNumber": params.request.endRow / 10,
                "pageSize": 10,
            };
            if (filterCount > 0) {
                let gridOpeartionProps = [];
                // clientGroupID
                if (filterModel.clientGroupID !== undefined) {
                  let clientGroupID = filterColumnsObjCreator("clientGroupID",filterModel.clientGroupID.filter,"string");
                  gridOpeartionProps.push(clientGroupID);
                }
                // clientGroupName
                if (filterModel.clientGroupName !== undefined) {
                  let clientGroupNameObj= filterColumnsObjCreator("clientGroupName",filterModel.clientGroupName.filter,"string");
                  gridOpeartionProps.push(clientGroupNameObj);
                }

                // domain
                if (filterModel.domain !== undefined) {
                  let domainObj = filterColumnsObjCreator("domain",filterModel.domain.filter,"string");
                  gridOpeartionProps.push(domainObj);
                }
                           
                // active
                if (filterModel.active !== undefined && filterModel.active.values.length > 0) {
                  let activeObj = filterColumnsObjCreator("active",filterModel.active.values,"bool");
                  gridOpeartionProps.push(activeObj);

                }                  
                if(gridOpeartionProps.length) searchDataObj["filterColumns"] = gridOpeartionProps;
            }
            if (sortCount > 0) {
                const sortCol = getSortedColumns(columnApi)[0];
                searchDataObj.orderBy = sortCol.colId + " " + sortCol.sort; 
            }  
            
            if (token) {            
              authAxios
                .post("/GetClientGroupDomains",
                {
                  gridOperationsProps: searchDataObj,
                })
                .then((res: any) => {
                  var totalRows = -1;
                  if (res.data !== null && res.data !== undefined) {
                    if (res.data.length < 10) {
                      totalRows = params.request.startRow + res.data.length;
                    }
                    params.successCallback(res.data, totalRows);
                  } else {
                    params.successCallback([], totalRows);
                  }
                })
                .catch((e: any) => {
                  params.fail();
                  console.log(e.message);
                });
            }
          },
        };
      };

      const onGridReady = (params: GridReadyEvent) => {
        gridApi = params.api;
        columnApi = params.columnApi;
        var datasource = ServerSideDatasource();
        gridApi.setServerSideDatasource(datasource);
      };

      const refreshGrid = (gridApi:any) => {
        if(gridApi){
            var datasource = ServerSideDatasource();
            gridApi.setServerSideDatasource(datasource);
        }
    }
      React.useEffect(() => {
        refreshGrid(gridApi);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [request.refreshToggler]);
      
      React.useEffect(() => {
        return () => {
          gridApi && gridApi.destroy();
          gridApi = null
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
              <div style={{ width: "100%", height: 505 }} className={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
                <AgGridReact
                  rowHeight={29}
                  headerHeight={32}
                  defaultColDef={defaultColDef}
                  columnDefs={colDefs}
                  onGridReady={onGridReady}
                  pagination={true}
                  cacheBlockSize={10}
                  paginationPageSize={10}
                  rowModelType={"serverSide"}
                  suppressServerSideInfiniteScroll={false}
                  animateRows={true}
                  components={frameworkComponents}
                ></AgGridReact>
              </div>
      );
}
