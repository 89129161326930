import React, { Fragment, useEffect } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import {
  CustomSnackbar,
  handleErrorResponse,
  ModalErrors,
  SelectList,
  showInvalidSnackbar,
  TextInput,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import {
  ClientCashDetailsProps,
  Companies,
  grapCodeGenerator,
  MgAorBroker,
} from "./clientCashDetailsUtils";
import Button from "@mui/lab/LoadingButton";
import SummarySkeletons from "../../../delegatedApps/experienceReport/experienceReportComponents/SummarySkeletons";
import { delegatedAxios } from "../../../../../services/axios";

interface Props extends ClientCashDetailsProps {
  handleReset: (v: any) => void;
  handleSubmit: () => void;
  handleUpdate: () => void;
}

type CompanyListType = {
  name: string;
  value: Companies;
};

type MgAorBrokerListType = {
  name: string;
  value: MgAorBroker;
};

export const companies: CompanyListType[] = [
  {
    name: "General Star Indemnity",
    value: "GSI",
  },
  {
    name: "Genesis Insurance Company",
    value: "GIC",
  },
  {
    name: "General Star National",
    value: "GSN",
  },
];

const MgAorBrokerList: MgAorBrokerListType[] = [
  {
    name: "MGA",
    value: "M",
  },
  {
    name: "BROKER",
    value: "B",
  },
];

function ClientCashDetailsForm({
  state,
  setState,
  handleSubmit,
  handleReset,
  handleUpdate,
}: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    setState({
      ...state,
      ...(name === "qaErrorReasonTypeText"
        ? { [name]: value }
        : {
            [name]: checked,
          }),
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const grapCodeValueGenerator = React.useMemo(() => {
    return grapCodeGenerator(state, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.billpointCode, state.branchCode, state.g2CompanyCode]);
 
  const getData = (billpointCode:string) => {
    if (!billpointCode) {
      setState({
        ...state,
        selectedClientLocation: "",
        clientLocationDisplayText:""
      });
      return;
    }
    setState({
      ...state,
      clientLocationsLoading: true,
      selectedClientLocation: "",
    });
    delegatedAxios
      .get(
        `ClientInfos/GetClientLocationLocationByBillPoint/${billpointCode}`
      )
      .then((res) => {
        const { status, data } = res || {};

        if (status === 200 && data) {
          setState({
            ...state,
            clientLocationsLoading: false,
            selectedClientLocation: data?.clientLocationID,
            clientLocationDisplayText:`${data?.clientLocationID} - ${data?.clientLocationName}`
          });
        } else {
          setState({
            ...state,
            selectedClientLocation: "",
            clientLocationDisplayText:""
          });
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        setState({
          ...state,
          clientLocationsLoading: false,
          selectedClientLocation: "",
          clientLocationDisplayText:""
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const onBillPointBlur = (e:any) => {
    const {value} = e.target;
    getData(value);
  }

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid
        item
        md={2.5}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <TextInput
          size="small"
          name="billpointCode"
          value={state.billpointCode}
          label={"Bill Points"}
          required
          onChange={handleTextChange}
          onBlur={onBillPointBlur}
        />
      </Grid>
      <Grid item md={5}>
      <SummarySkeletons condition={state.clientLocationsLoading}>
        <TextInput
          size="small"
          name="clientLocation"
          value={state.clientLocationDisplayText}
          label={"Client Location"}
          required
          disabled
        />
        </SummarySkeletons>
      </Grid>
      <Grid md={3} item>
        <SummarySkeletons condition={state.operationContactLoading}>
          <SelectList
            allowempty={false}
            value={
              state.operationsContact === undefined
                ? ""
                : state.operationsContact
            }
            label="Operation Contact"
            onChange={(e) => {
              setState({
                ...state,
                operationsContact: e.target.value as string,
              });
            }}
          >
            {state.operationContactList.map((item, idx) => (
              <MenuItem value={item.userID} key={idx}>
                {item.userLoginID}
              </MenuItem>
            ))}
          </SelectList>
        </SummarySkeletons>
      </Grid>
      <Grid md={1.5} item>
        <SummarySkeletons condition={state.branchesLoading}>
          <SelectList
            allowempty={false}
            value={state.branchCode}
            label="Branch"
            name="branchCode"
            required
            onChange={(e) => {
              setState({
                ...state,
                branchCode: e.target.value as string,
              });
            }}
          >
            {state.branches.map((item, idx) => (
              <MenuItem value={item.branchCode} key={idx}>
                {item.branchCode}
              </MenuItem>
            ))}
          </SelectList>
        </SummarySkeletons>
      </Grid>
      <Grid md={3} item>
        <SelectList
          allowempty={false}
          value={state.g2CompanyCode}
          label="Company"
          onChange={(e) => {
            setState({
              ...state,
              g2CompanyCode: e.target.value as Companies,
            });
          }}
        >
          {companies.map((item, idx) => (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          ))}
        </SelectList>
      </Grid>
      <Grid md={2} item>
        <SelectList
          allowempty={false}
          value={state.mgAorBroker}
          label="MGA Or Broker"
          onChange={(e) => {
            setState({
              ...state,
              mgAorBroker: e.target.value as MgAorBroker,
            });
          }}
        >
          {MgAorBrokerList.map((item, idx) => (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          ))}
        </SelectList>
      </Grid>
      <Grid md={3} item>
        <TextInput
          name="grapSearchCode"
          label="GRAP"
          disabled
          value={grapCodeValueGenerator}
        />
      </Grid>
      <Grid md={2} item display={"flex"} alignItems={"center"} gap={1}>
        <Typography variant="body1">
          <b>Active</b>
        </Typography>
        <Switch
          {...label}
          name="active"
          id="active"
          test-id="active"
          checked={state.active}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid
        item
        md={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={1}
      >
        {state.updateToggler ? (
          <Button
            variant="containedTertiary"
            onClick={handleUpdate}
            loading={state.apiLoading}
            disabled={state.apiLoading}
          >
            UPDATE
          </Button>
        ) : (
          <Button
            variant="containedTertiary"
            onClick={handleSubmit}
            loading={state.apiLoading}
            disabled={state.apiLoading}
          >
            Submit
          </Button>
        )}
        <Button
          disabled={state.apiLoading}
          variant="outlinedTertiary"
          onClick={handleReset.bind(null, setState)}
        >
          Reset
        </Button>
      </Grid>
    </Fragment>
  );
}

export default ClientCashDetailsForm;
