import { Fragment, useState } from "react";
import { LoadingButton as Button } from "@mui/lab";
import { MuiCustomDrawer } from "@genre/g2common-theme";
import { Grid, Typography } from "@mui/material";
import {
  handleSuccessResponse,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { authAxios } from "../services/axios";

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
}
function OAuthDrawer({ open, setOpen }: Props) {
  const closeDrawer = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useRibbonSnackbar();

  const submit = () => {
    setLoading(true);
    authAxios
      .get(`/OAuthInquiry/Create`)
      .then((res: any) => {
        handleSuccessResponse(res, enqueueSnackbar);
        res?.status === 200 && setOpen(false);
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  };

  return (
    <MuiCustomDrawer
      open={open}
      title=""
      anchor="right"
      drawerWidth={800}
      onClose={closeDrawer}
      justifyContent="flex-start"
    >
      <Fragment>
        <Grid container position={"relative"}>
          <Typography variant="bodyMedium">
            The GenStar Client Portal allows clients to login to the system
            leveraging their own corporate username and password. This feature
            makes it much easier to login to the system for users who are
            already logged into their browser with their own corporate account.
            It also removes the requirement for using the @genstarinsext.com
            account that we provided for you.
            <br />
            <br />
          </Typography>
          <Typography variant="bodyMedium">
            There are two items that are required to enable this functionality:
            <br />
            <br />
          </Typography>
          <Typography variant="bodyMedium">
            1. Your organization must use Microsoft work accounts.
            <br />
            2. Someone in your IT Department, who has sufficient permissions
            (typically a Domain Administrator), must click on two links that
            will be provided to you by us. This creates the "link" between the
            GenStar Client Portal and your organization, which allows our portal
            to authenticate you. It's essentially granting us permissions for
            you to login with your own account.
            <br />
            <br />
          </Typography>
          <Typography variant="bodyMedium">
            If you are interested in enabling this feature, click "Submit"
            below, and we'll send you the details on what your IT Department
            will need to do. Also, feel free to contact us at
            ask_genstar@generalstar.com if you have any questions.
            <br />
            <br />
          </Typography>
          <Grid md={6} lg={6} item display={"flex"} gap={2}>
            <Button
              variant="contained"
              loading={loading}
              disabled={loading}
              onClick={submit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    </MuiCustomDrawer>
  );
}

export default OAuthDrawer;
