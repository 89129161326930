import React from "react";
// import { useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


/**
* Renders a drop down button with child buttons for logging in with a popup or redirect
*/

export const SignInButton = () => {
// const { instance } = useMsal();

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

const handleLogin = (loginType:any) => {
// if (loginType === "popup") {
// instance.loginPopup(loginRequest).catch((e:any) => {
// console.log(e);
// });
// } else if (loginType === "redirect") {
// instance.loginRedirect(loginRequest).catch((e:any) => {
// console.log(e);
// });
// }
}
return (
<div>
    <Button  onClick={handleClick}>
        Account
    </Button>
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
        <MenuItem onClick={()=> handleLogin("popup")}>Login</MenuItem>
    </Menu>
</div>
)
}