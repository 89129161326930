import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { commonAxios } from "../../../../services/axios";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { ActionType } from "./actionTypeUtils";
import { useRibbonSnackbar, EditChildRenderer, showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { appTypeLookup } from "../../admin/appRolePermission/appRolePermissionUtils";
import {AgGridClasses} from "@genre/g2common-theme"

let gridApi: any;

interface Props {
  request: ActionType;
  setRequest: (v: ActionType) => void;
}

export const ActionTypeReportingGrid: React.FC<Props> = (props) => {
  const { setRequest, request } = props;
  const token = useSelector((state: RootState) => state?.auth?.token);
  const { enqueueSnackbar } = useRibbonSnackbar();

  const gridStyle = useMemo(() => ({ height: 470, width: "100%" }), []);

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
  };

  const onRowEditSelected = (params:any) => {

    const {actionTypeID,actionTypeText,appTypeID,active,createdDate,createdBy} = params.data || {};
    setRequest({
      ...request,
      actionTypeID,
      actionTypeText,
      appTypeID,
      active,
      createdDate
      ,createdBy,
      updatetoggler:true
    })
  }

  const [colDefs] = React.useState<ColDef[]>([
    {
      field: "actionTypeID",
      colId: "actionTypeID",
      headerName: "ActionTypeID",
      sort: "desc",
      hide: true,
    },
    {
      field: "appTypeID",
      colId: "appTypeID",
      headerName: "AppTypeID",
      valueGetter: function (params: any) {
        return appTypeLookup(request.appTypeArray,params.data.appTypeID);
      },
      flex:1
    },
    {
      field: "actionTypeText",
      colId: "actionTypeText",
      headerName: "ActionTypeText",
      flex:1
    },
    {
      field: "active",
      colId: "active",
      headerName: "Active",
      flex:.5
    },
    {
      headerName: '',
      onCellClicked: onRowEditSelected,
      cellRenderer: 'EditChildRenderer',
      flex:.3
    }
  ]);

  const frameworkComponents = {
    EditChildRenderer:EditChildRenderer,
  };

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
        if (token) {
        commonAxios
            .get("/LogActionType/GetActionTypes")
            .then((res: any) => {
              if (res.data) {
                params.successCallback(res.data, res.data.length);
              }
            })
            .catch((e: any) => {
              console.log(e.message);
              showInvalidSnackbar(e,enqueueSnackbar);
            });
        }
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };


  React.useEffect(() => {
    gridApi?.refreshServerSide({ purge: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.refreshToggler]);

  return (
    <div style={gridStyle} className={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowHeight={29}
        headerHeight={32}
        animateRows={true}
        components={frameworkComponents}
        rowModelType={"serverSide"}
      ></AgGridReact>
    </div>
  );
};

