import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CircleIcon from "@mui/icons-material/Circle";
import { Stack } from "@mui/material";

function ExpReportInfo() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const helpText = [
    `Please select all the required fields and click on 'Run Report' button.`,
    `Risk States will be available for selection after the 'Run Report' action is performed.After the selection, you have to click on the "Run Report" button again to generate results. `,
  ];
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleClick}
        edge="start"
      >
        <HelpIcon />
      </IconButton>
      <Popover
        id={id}
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Box boxShadow={4} sx={{ maxWidth: 560 }}>
          <Paper sx={{ padding: 1 }}>
            {helpText.map((item: string, idx: number) => (
              <Stack
                display={"flex"}
                alignItems={"flex-start"}
                direction={"row"}
                width={"100%"}
                p={1}
                gap={1}
                key={idx}
              >
                <CircleIcon sx={{ fontSize: "12px", marginTop: "6px" }} />
                <Typography>{item}</Typography>
              </Stack>
            ))}
          </Paper>
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default ExpReportInfo;
