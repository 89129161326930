import HomeGrid from "./HomeGrid";
import React from "react";
import Grid from "@mui/material/Grid";
import {MuiStyledSwitch as Switch} from "@genre/g2common-theme";
import { handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import { AppDispatch, RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch, useSelector } from 'react-redux';
import Typography from "@mui/material/Typography";

function Home() {

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const $dispatch = useDispatch<AppDispatch>();
  const isClientView = useSelector((state:RootState) => state.genbill.isClientView);
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    $dispatch(
      handleGenbillState({
        type: "SET_CLIENT_VIEW",
        payload: { isClientView: event.target.checked },
      })
    );
  };

  React.useEffect(() => {
    (!isExternalUser && isClientView) && $dispatch(
      handleGenbillState({
        type: "SET_CLIENT_VIEW",
        payload: { isClientView: false },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={12} md={12} item>
        {
          !isExternalUser && 
          <Grid
            xs={12}
            sm={12}
            md={2}
            gap={1}
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="displaySmall">
              Client View
            </Typography>
            <Switch
              {...label}
              checked={isClientView}
              name="isClientView"
              onChange={handleInputChange}
            />
          </Grid>
        }
        <HomeGrid />
      </Grid>
    </Grid>
  );
}

export default Home;
