import { configureStore } from '@reduxjs/toolkit'
import { persistedReducer } from './RootReducer';
import logger from "redux-logger";
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: persistedReducer
})
export const storeWithLogger = configureStore({
  reducer: persistedReducer,
  middleware:(gDM) => gDM({
    serializableCheck:false
  }).concat(logger as any,thunk)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
