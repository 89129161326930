import React from 'react'
import {ListItem, ListItemButton, ListItemIcon,ListItemText,Divider, Tooltip} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
    initialValueMenuToggle:boolean | undefined;
    togglerFunc:() => void;
    tabToggler:boolean | undefined;
    tabIcon:JSX.Element;
    tabName:string;
}

const CommonListButton:React.FC<Props> = (props) => {

  const {initialValueMenuToggle,togglerFunc,tabToggler,tabIcon,tabName} = props;

  return (
    <Tooltip title={tabName} placement="right">
    <ListItem disablePadding sx={{ display: "block" }}>
    <>
      <Divider />
      <ListItemButton
        sx={{
          justifyContent: initialValueMenuToggle
            ? "initial"
            : "center",
          px: 2.5,
        }}
        selected={false}
        onClick={() => togglerFunc()}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: initialValueMenuToggle ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          {tabIcon}
        </ListItemIcon>
        <ListItemText
          primary={tabName}
          sx={{ opacity: initialValueMenuToggle ? 1 : 0 }}
        />
        {initialValueMenuToggle && tabToggler ? 
        <ListItemIcon
          sx={{
            minWidth: 0,
            ml: initialValueMenuToggle ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <KeyboardArrowDownIcon />
        </ListItemIcon> 
        : initialValueMenuToggle && !tabToggler ?
        <ListItemIcon
          sx={{
            minWidth: 0,
            ml: initialValueMenuToggle ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <KeyboardArrowRightIcon />
        </ListItemIcon> : null
        }                 
      </ListItemButton>
    </>
    </ListItem>
  </Tooltip>
  )
}

export default CommonListButton