import React, { useEffect, useReducer } from "react";
import { IncurredLossesReducer, initialIncurredLossesState } from "./utils";
import {
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { delegatedAxios } from "../../../../services/axios";
import IncurredLossesForm from "./IncurredLossesForm";
import { getBillPoints } from "../utils";

function IncurredLossesWrapper() {
  const [request, dispatch] = useReducer(
    IncurredLossesReducer,
    initialIncurredLossesState
  );
  const { enqueueSnackbar } = useRibbonSnackbar();

  function handleLoader(loading: boolean = false) {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        clientLocationsLoading: loading,
      },
    });
  }

  function getData() {
    handleLoader(true);
    delegatedAxios
      .get("/Delegated/GetClientLocations")
      .then((res: any) => {
        const { data } = res || {};
        const { userClientLocationList: clientLocations } = data || {};

        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            clientLocations: clientLocations || [],
            clientLocationsLoading: false,
          },
        });
      })
      .catch((e: any) => {
        handleLoader();
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getBillPoints(request, dispatch, enqueueSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    request.selectedClientLocations,
    request.lineOfBusiness,
    request.clientLocations,
  ]);

  const runReport = () => {
    if (!request.selectedClientLocations.length) {
      showInvalidSnackbar(
        undefined,
        enqueueSnackbar,
        "Please select at least one location."
      );
      return;
    }

    if (!request.billPoints.length) {
      const reason = request.firstSubmit ? "Line of Business" : "Locations";
      showInvalidSnackbar(
        undefined,
        enqueueSnackbar,
        `No Bill Points found for the selected ${reason}.`
      );
      return;
    }

    const payload = {
      riskStates: request.selectedRiskStates.length
        ? request.selectedRiskStates.map((item) => item.stateCode)
        : request.riskStates.map((item) => item.stateCode),
      userBillPoints: request.selectedbillPoints.length
        ? request.selectedbillPoints
        : request.billPoints,
    };

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        runReportLoader: true,
        incurredLossesReport: [],
      },
    });

    delegatedAxios
      .post("/Delegated/IncurredLossReport", payload)
      .then((res: any) => {
        const { incurredLossReportRecords, riskStates } = res?.data || {};
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            runReportLoader: false,
            incurredLossesReport: incurredLossReportRecords || [],
            firstSubmit: true,
            ...(request.riskStates.length === 0 && {
              riskStates: riskStates || [],
            }),
          },
        });
      })
      .catch((e: any) => {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            runReportLoader: false,
          },
        });
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  };

  function reset() {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        selectedClientLocations: [],
        selectedRiskStates: [],
        selectedbillPoints: [],
        incurredLossesReport: [],
        firstSubmit: false,
        riskStates: [],
        billPoints:[],
        lineOfBusiness:"all"
      },
    });
  }

  return (
    <IncurredLossesForm
      request={request}
      dispatch={dispatch}
      runReport={runReport}
      reset={reset}
    />
  );
}

export default IncurredLossesWrapper;
