import React, { Fragment } from "react";
import {
  CashTrackerProps,
  SuspenseFormGridType,
  SuspenseTypeIDType,
  suspenseTypeList,
} from "../../CashTrackerUtils";
import {
  currencyFormatter,
  CustomSnackbar,
  handleErrorResponse,
  ModalErrors,
  negativeValueColorFormatter,
} from "@genrecp/g2clientportal-common";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef, CellClickedEvent } from "ag-grid-community";
import {
  EditChildRenderer,
} from "@genrecp/g2clientportal-common";
import { Grow } from "@mui/material";
import { ValueGetterParams } from "ag-grid-community";
import { delegatedAxios } from "../../../../../services/axios";
import { GridReadyEvent } from "ag-grid-community";
import { CellClassParams } from "ag-grid-community";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CashTrackerStatusFilterType } from "../../cashTracker/cashTrackerFormUtils";
import { SetFilterValuesFuncParams } from "ag-grid-community";

interface Props extends CashTrackerProps {}

function SuspenseFormGrid({ request, dispatch }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const isCashTrackerAdmin = useSelector((state: RootState) => state?.cashTracker?.cashTrackerAdmin);
  const isCashTrackerContributer = useSelector((state: RootState) => state?.cashTracker?.cashTrackerContributer);
  const isCashTrackerViewer = useSelector((state: RootState) => state?.cashTracker?.cashTrackerViewer);
  const isCashTrackerAdminOrContributerOrViewer = isCashTrackerAdmin || isCashTrackerContributer || isCashTrackerViewer; 

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const onRowEditSelected = (
    evt: CellClickedEvent<SuspenseFormGridType, any>
  ) => {
    const {
      additionalInfo,
      endorsementNumber,
      grossPremium,
      insuredName,
      policyNumber,
      suspendedAmount,
      suspenseID,
      suspenseReasonTypeID,
      suspenseTypeID,
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate,
      cashTrackerStatus
    } = evt.data as SuspenseFormGridType;
    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        addInfo: additionalInfo,
        endtNumber: endorsementNumber.toString(),
        grossPremium: grossPremium.toString(),
        insuredName,
        policyNumber,
        suspendedAmount: suspendedAmount.toString(),
        suspenseReason: suspenseReasonTypeID.toString(),
        suspenseTypeID: suspenseTypeID as SuspenseTypeIDType,
        suspenseID,
        createdBy,
        createdDate,
        modifiedBy,
        modifiedDate
      },
      cashTrackerDetailTab: "SUSPENSE_DETAIL",
      screenName: "Suspense Detail",
      cashTrackerMasterFields:{
        ...request.cashTrackerMasterFields,
              suspenseStatus: cashTrackerStatus || ""
      }
    });
  };

  const colDefs: ColDef[] = [
    {
      field: "policyNumber",
      colId: "policyNumber",
      headerName: "Policy Number",
      flex:1.2,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "grossPremium",
      colId: "grossPremium",
      flex:1.1,
      headerName: "Gross Premium",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      cellStyle: (params: CellClassParams) =>
        negativeValueColorFormatter(params.data?.grossPremium),
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.grossPremium, true)}`;
      },
    },
    {
      field: "suspendedAmount",
      colId: "suspendedAmount",
      headerName: "Suspended Amt",
      filter: "agNumberColumnFilter",
      flex:1.25,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      cellStyle: (params: CellClassParams) =>
        negativeValueColorFormatter(params.data?.suspendedAmount),
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.suspendedAmount, true)}`;
      },
    },
    {
      field: "endorsementNumber",
      colId: "endorsementNumber",
      headerName: "Endorsement Number",
      flex:1.5,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "insuredName",
      colId: "insuredName",
      headerName: "Insured Name",
      filter: "agTextColumnFilter",
      flex:1.1,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "suspenseReasonType",
      field: "suspenseReasonType",
      flex:1.25,
      headerName: "Suspense Reason",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "suspenseTypeID",
      field: "suspenseTypeID",
      headerName: "Suspense Type",
      filter: "agSetColumnFilter",
      flex:1.1,
      filterParams: {
        values: suspenseTypeList.map(item => item.name),
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: ValueGetterParams<SuspenseFormGridType>) {
        const { suspenseTypeID } = params?.data || {};
        return suspenseTypeList.find((i) => i.value === suspenseTypeID)?.name || "";
      },
    },
    {
      colId: "cashTrackerStatus",
      field: "cashTrackerStatus",
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        suppressSelectAll: true,
        defaultToNothingSelected: true,
        filterOptions: ["equals"],
        values: async (params: SetFilterValuesFuncParams) => {
          let values = await getStatusFilters();
          let cashIssueStatusTypeTextList: string[] = values.map(
            (i: CashTrackerStatusFilterType) => i.cashIssueStatusTypeText
          );
          params.success(cashIssueStatusTypeTextList);
        },
        suppressAndOrCondition: true,
      },
      headerName: "Status",
      width: 320,
    },
    {
      headerName: "",
      filter: false,
      sortable: false,
      onCellClicked: onRowEditSelected,
      cellRenderer: "EditChildRenderer",
      flex: 0.4,
      hide:!isCashTrackerAdminOrContributerOrViewer
    },
  ];

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
  };

  const onGridReady = (params: GridReadyEvent) => {
    delegatedAxios
      .get(`/CashTrackers/Get/${request.cashTrackerMasterFields.cashTrackerID}`)
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          const { suspenses: suspenseReasonsGrid } = res?.data || {};
          params.api.setRowData(suspenseReasonsGrid || []);
        } else params.api.setRowData([]);
      })
      .catch((e: any) => {
        params.api.setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  async function getStatusFilters() {
    return delegatedAxios
      .get("/CashIssuesStatus")
      .then((res: any) => {
        const { data } = res || {};
        let finalMappedData: CashTrackerStatusFilterType[] = [];

        if (data && data?.length) {
          finalMappedData = data.filter(
            (x: CashTrackerStatusFilterType) => x.active
          );
          return finalMappedData;
        } else {
          return [];
        }
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  }

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grow in={true} style={{ transitionDelay: "100ms" }}>
        <div
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
          style={{ width: "100%", height: window.innerHeight - 210 }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            columnDefs={colDefs}
            pagination={true}
            cacheBlockSize={10}
            paginationPageSize={10}
            animateRows={true}
            rowHeight={29}
            headerHeight={32}
            components={frameworkComponents}
          ></AgGridReact>
        </div>
      </Grow>
    </Fragment>
  );
}

export default SuspenseFormGrid;
