import * as React from "react";
import { Divider } from "@mui/material";
import styled from "styled-components";
import { UserRequestObject, AppTypeRoles, Role, ApptypeTypeArray, UserDispatch } from "../userUtils";
import { CustomDrawerForm } from "./CustomDrawerForm";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { MuiCustomDrawer } from "@genre/g2common-theme";

const ScrollPanel = styled.div`
  height: calc(100% - 38px);
  width: 100%;
  padding: 0 0 220px 0;
  margin: 0;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: flex-start;
`;

interface Props {
  request: UserRequestObject;
  tempAppRoleData: AppTypeRoles[];
  updatetoggler: boolean;
  appTypeArray: ApptypeTypeArray[];
  handleUpdate: (v:AppTypeRoles[]|undefined) => void;
  dispatch: UserDispatch
}

export const CustomDrawer: React.FC<Props> = ({
  request,
  tempAppRoleData,
  updatetoggler,
  appTypeArray,
  handleUpdate,
  dispatch
}) => {
  const [AppTypeRoles, setAppTypeRoles] = React.useState<AppTypeRoles[]>([]);
  const { enqueueSnackbar } = useRibbonSnackbar();

  React.useEffect(() => {

    if (request.userAppTypes && request.userAppTypes.length > 0) {
      const filteredUserAppTypes:AppTypeRoles[] = tempAppRoleData.filter((sourceData:ApptypeTypeArray) => {
        return request.userAppTypes.find((userAppType:ApptypeTypeArray) => userAppType.appTypeID === sourceData.appTypeID )
      })

      setAppTypeRoles(filteredUserAppTypes);
    } else setAppTypeRoles([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.userAppTypes]);

  const closeDrawer = () => {
    const flag = AppTypeRoles.every((item: AppTypeRoles) =>
      item.roles.some((item: Role) => item.isAssignedToApp === true)
    );

    if (flag) {
      dispatch({
        openCustomDrawer: false,
        appTypeRoles: AppTypeRoles,
      })
      sessionStorage.setItem("appTypeRoleSelection",JSON.stringify(AppTypeRoles))
    } else {
      enqueueSnackbar(
        `Error! Please select at least one Role for selected Apps`,
        {
          variant: "error",
        }
      );
      return
    }

    updatetoggler && handleUpdate(AppTypeRoles);
  };
  return (
    <MuiCustomDrawer
      open={request.openCustomDrawer ? request.openCustomDrawer : false}
      title="App Roles"
      anchor="right"
      drawerWidth={window.innerWidth - 200}
      onClose={closeDrawer}
      justifyContent="flex-start"
    >
      <>
        <ScrollPanel>
          <InputPanel>
            <CustomDrawerForm
              AppTypeRoles={AppTypeRoles}
              setAppTypeRoles={setAppTypeRoles}
              request={request}
              appTypeArray={appTypeArray}
              dispatch={dispatch}
            />
          </InputPanel>
        </ScrollPanel>
        <Divider />
      </>
    </MuiCustomDrawer>
  );
};
