import React, { useState } from "react";
import { commonAxios } from "../../../../services/axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { ActionType } from "./actionTypeUtils";
import SubActionTypeReporting from "./SubActionTypeReporting";

function ActionTypeReporting() {
   
  const [request, setRequest] = useState<ActionType>({
    appTypeID:"",
    actionTypeText:"",
    active:true,
    appTypeArray:[],
    refreshToggler:false,
    createdBy:"",
    createdDate:"",
    actionTypeID:"",
    updatetoggler:false,
    loading:false,
    apiLoading:false,
  });
  
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  React.useEffect(() => {
    setRequest({...request, loading:true});
    commonAxios
      .get("/AppTypes")
      .then((res: any) => {
        setRequest({ ...request, appTypeArray: res.data, loading:false });
      })      
      .catch((e: any) => {
        setRequest({...request, loading:false});
        showInvalidSnackbar(e,enqueueSnackbar);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if(!request.appTypeID || !request.actionTypeText){
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params:any = {
      appTypeID:request.appTypeID,
      actionTypeText: request.actionTypeText,
      active: request.active,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
    }
    setRequest({...request, apiLoading:true});
    commonAxios
      .post("/LogActionType/CreateActionType", params)
      .then((res: any) => {
        setRequest({
          ...request,
          appTypeID:"",
          actionTypeText:"",
          active:true,
          refreshToggler: !request.refreshToggler,
          createdBy:"",
          createdDate:"",
          actionTypeID:"",
          updatetoggler:false,
          apiLoading:false
        });
      })      
      .catch((e: any) => {
        setRequest({...request, apiLoading:false});
        showInvalidSnackbar(e,enqueueSnackbar);
      });
    
  }

  const handleUpdate = () => {
    if(!request.appTypeID || !request.actionTypeText){
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params:any = {
      actionTypeID:request.actionTypeID,
      appTypeID:request.appTypeID,
      actionTypeText: request.actionTypeText,
      active: request.active,
      createdBy:request.createdBy,
      createdDate:request.createdDate,
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    }
    setRequest({...request, apiLoading:true});
    commonAxios
      .put(`/LogActionType/UpdateActionType?id=${request.actionTypeID}`, params)
      .then((res: any) => {
        setRequest({
          ...request,
          appTypeID:"",
          actionTypeText:"",
          active:true,
          refreshToggler: !request.refreshToggler,
          createdBy:"",
          createdDate:"",
          actionTypeID:"",
          updatetoggler:false,
          apiLoading:false
        });
      })      
      .catch((e: any) => {
        setRequest({...request, apiLoading:false});
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  }

  return (
    <SubActionTypeReporting request={request} setRequest={setRequest} handleSubmit={handleSubmit} handleUpdate={handleUpdate} />
  )
}

export default ActionTypeReporting