
function padString(value: string, length: number, apply: (s: string) => string): string {
    const zed = `${value}`;
    const delta = length - zed.length;

    if (delta <= 0) {
        return zed;
    }

    let ret = zed;
    for (let i = 1; i <= delta; i++) {
        ret = apply(ret);
    }

    return ret;
}

export function padLeft(value: (string | number), length: number, character: string): string {
    return padString(`${value}`, length, (s) => `${character}${s}`);
}

export function padRight(value: (string | number), length: number, character: string): string {
    return padString(`${value}`, length, (s) => `${s}${character}`);
}