import AddNewDoc from "./addNewDoc/AddNewDoc";
import { DocFolderStructure, DocumentType } from "./adminDocUtils";
import ViewDoc from "./viewDoc/ViewDoc";

interface Props extends DocumentType {
    selectedMenu:string;
    setSelectedMenu:(v:string) => void;
    docFolderStructure:DocFolderStructure;
}

function InfoSectionSelector({selectedMenu,documentName,setDocumentName,setSelectedMenu, docFolderStructure}:Props) {

    switch(selectedMenu) {
        case "ViEWDOCS":
            return <ViewDoc docFolderStructure={docFolderStructure} documentName={documentName} setDocumentName={setDocumentName} />
        case "NEWDOC":
            return <AddNewDoc docFolderStructure={docFolderStructure} documentName={documentName} setDocumentName={setDocumentName} setSelectedMenu={setSelectedMenu} />        
        default:
            return null;
        }
}

export default InfoSectionSelector