import Grid from "@mui/material/Grid";
import { GridContainer, LandingHeader, ViewSchema, statusEnumObj, statusParser } from "../genbillUtils";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import {AgGridClasses} from "@genre/g2common-theme"
import React from "react";
import { CustomSnackbar, handleErrorResponse } from "@genrecp/g2clientportal-common";
import { ModalErrors, comparatorForDate, formatDateForGrid } from "@genrecp/g2clientportal-common";

function BillHistory() {
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "monthEndDate",
        flex: 1,
        colId: "monthEndDate",
        headerName: "Bill Month End Dt.",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.monthEndDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
      {
        field: "createdDate",
        colId: "createdDate",
        headerName: "Bill Status Dt",
        flex: 1,
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
      {
        field: "statementStatusTypeID",
        colId: "statementStatusTypeID",
        sortable:false,
        headerName: "Status",
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: ["equalss"],
          values: Object?.keys(statusEnumObj).map((key: any) => key),
          suppressAndOrCondition: true,
        },
        flex: 1,
        valueGetter: function (params: any) {
          return statusParser(params.data.statementStatusTypeID);
        },
      },
    ];
  }

  const getGridRowsData = async (
    pagination: any,
    filterModel: any,
    sortModel: Array<any>,
    selectedView: ViewSchema | undefined
  ) => {

    const newFilterModel = Object.keys(filterModel).reduce(
      (acc: any, key: any) => {
        const value = filterModel[key];
        if (value !== null && value !== "") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const newObj: any = { ...newFilterModel };

    if (newObj.statementStatusTypeID) {
      const tempStatus = newObj.statementStatusTypeID
        .split(",")
        .map((item: string) => statusParser(item.trim()));
      newObj.statementStatusTypeID = tempStatus.join();
    }

    const orderByCheck = sortModel
    .map((model: any) => model.colId + " " + model.sort)
    .join(", ");

    const obj: any = {
      ...pagination,
      ...newObj,
        ...(billID ? {billID : billID}:{}),
        ...(orderByCheck ? {orderBy : orderByCheck}:{})
    };

    return brokerBillingAxios
      .post(`/Bills/BillHistory`, obj)
      .then((data: any) => {
        return data.data;
      }).catch(e => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };


  return (
    <Grid container spacing={2}>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid xs={12} sm={12} md={12} item>
        <LandingHeader>
          <GridContainer>
            <AggridWrapper
              gridClassName={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}
              getColumnDefs={getColumnDefs}
              getGridRowsData={getGridRowsData}
              dashboardName={""}
              enableUserViews={false}
              enableSystemViews={false}
              landingPage={"Bill History"}
              views={true}
              currentUser={{
                userName: createdBy && createdBy.id ? createdBy.id : "",
                email: "",
                fullName: "",
              }}
              gridProps={{
                headerHeight:40,
              }}
              gridHeight={window.innerHeight - 210}
              rowHeight={29}
              headerHeight={32}
              hideHeader
            ></AggridWrapper>
          </GridContainer>
        </LandingHeader>
      </Grid>
    </Grid>
  );
}

export default BillHistory;
