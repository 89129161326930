import React from "react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { commonAxios } from "../../../../services/axios";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { appTypeLookup } from "../../admin/appRolePermission/appRolePermissionUtils";
import { ApptypeType } from "../../admin/clientLocation/utils";
import { formatDateForGrid } from "@genrecp/g2clientportal-common";
import {ActionTypesType,actionTypeIDLookup} from './UserActionLogReportUtils';
import { LandingHeader,GridContainer,ViewSchema } from "../../../../clientPortalUtils";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import {AgGridClasses} from "@genre/g2common-theme"

interface Props {
  appTypes:ApptypeType[],
  actionTypes:ActionTypesType[]
}

const UserAccessLogReportGrid:React.FC<Props> = (props) => {

  const {appTypes,actionTypes} = props;
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  function AppTypeParser(v: string, appTypeArray: ApptypeType[]) {
     const found = appTypeArray.filter(x=>x.appTypeText === v)
     return found[0]?.appTypeID || "";
  }

  function ActionTypeParser(v: string, actionTypeArray: ActionTypesType[]) {
    const found = actionTypeArray.filter(x=>x.actionTypeText === v)
    return found[0]?.actionTypeID || "";
 }

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    if (newObj.appTypeID) {
      const tempStatus = newObj.appTypeID
        .split(",")
        .map((item: string) => AppTypeParser(item.trim(),appTypes));
      newObj.appTypeID = tempStatus.join();
    }

    if (newObj.actionTypeID) {
      const tempStatus = newObj.actionTypeID
        .split(",")
        .map((item: string) => ActionTypeParser(item.trim(),actionTypes));
      newObj.actionTypeID = tempStatus.join();
    }

    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;

    if(obj.orderBy && obj.orderBy.split(" ")[0] === "actionTypeID") {
      obj.orderBy = `actionTypeName ${obj.orderBy.split(" ")[1]}`;
    }

    if(obj.orderBy && obj.orderBy.split(" ")[0] === "appTypeID") {
      obj.orderBy = `appTypeName ${obj.orderBy.split(" ")[1]}`;
    }

    return commonAxios
      .post(
        "/ActionLogDashboard/GetActionLogs", obj
      )
      .then((data: any) => {
        return data.data;
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "actionTypeID",
        colId: "actionTypeID",
        filter: "agSetColumnFilter",
        headerName: "Action Type",
        filterParams: {
          values: actionTypes.map((i: any) => i.actionTypeText),
          suppressAndOrCondition: true,
        },
        valueGetter: function (params: any) {
          return actionTypeIDLookup(actionTypes, params.data.actionTypeID);
        },
        flex: 1,
      },
      {
        field: "appTypeID",
        colId: "appTypeID",
        filter: "agSetColumnFilter",
        headerName: "App Type",
        filterParams: {
          values: appTypes.map((i: any) => i.appTypeText),
          suppressAndOrCondition: true,
        },
        valueGetter: function (params: any) {
          return appTypeLookup(appTypes, params.data.appTypeID);
        },
        flex: 1,
      },
      {
        field: "message",
        colId: "message",
        filter: "agTextColumnFilter",
        headerName: "Message",
        tooltipField: "message",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        flex: 1,
      },
      {
        field: "userLoginID",
        colId: "userLoginID",
        filter: "agTextColumnFilter",
        headerName: "User Login ID",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        flex: 1,
      },
      {
        field: "createdDate",
        colId: "createdDate",
        filter: "agDateColumnFilter",
        flex: 1,
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
      },
      
    ];
  }

  return (  
    <LandingHeader>
      <GridContainer>
        <AggridWrapper
          gridClassName={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}
          getColumnDefs={getColumnDefs}
          getGridRowsData={getGridRowsData}
          dashboardName={""}
          enableUserViews={false}
          enableSystemViews={false}
          landingPage={"ActionLogScreen"}
          views={false}
          currentUser={{
            userName: createdBy && createdBy.id ? createdBy.id : "",
            email: "",
            fullName: "",
          }}
          gridProps={{
            tooltipMouseTrack: true,
            tooltipShowDelay: 0,
          }}
          gridHeight={window.innerHeight - 170}
          hideHeader
          rowHeight={29}
          headerHeight={32}
        ></AggridWrapper>
      </GridContainer>
    </LandingHeader>
  )
}

export default UserAccessLogReportGrid