import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useLocation } from "react-router-dom";
import { commonAxios } from "../../../services/axios";
import Typography from "@mui/material/Typography";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";

interface DocumentTree {
  folderName: string;
  fileList: string[];
}

export const Documents = () => {
  const { pathname } = useLocation();
  const [loading, SetLoading] = React.useState<boolean>(false);
  const [pdfShow, SetPdfShow] = React.useState<boolean>(false);
  const [pdfLoading, SetPdfLoading] = React.useState<boolean>(false);
  const [pdfDocument, setPdfDocument] = React.useState<string>("");
  const [pdfTitle, setPdfTitle] = React.useState<string>("");
  const [documentTreeList, setDocumentTreeList] = React.useState<
    DocumentTree[]
  >([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const documentDirectory = pathname.replace("/","").replaceAll("-", " ");
  function docTreeView() {
    SetLoading(true);
    commonAxios.get('Documentation?document=' + encodeURIComponent(documentDirectory))
      .then(function (response: any) {
        setDocumentTreeList(response.data);
        SetLoading(false);
  })
      .catch(function (error: any) {
        console.log(error);
        SetLoading(false);
      });
  }

  function pdfViewer(directory: string, pdfTitle: string)
  {
    SetPdfLoading(true);
    const fullDirectory = documentDirectory + directory;
    commonAxios.get("Documentation/Pdfdocument?directory="
    + encodeURIComponent(fullDirectory) + "&filename=" + encodeURIComponent(pdfTitle))
    .then(function (response) {
      setPdfDocument(b64toBlob(response.data));
      setPdfTitle(pdfTitle);
      SetPdfShow(true);
      SetPdfLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      SetLoading(false);
    });
  }
  
  const b64toBlob = (b64Data: any, sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: 'application/pdf'});
    return URL.createObjectURL(blob);
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    const togglingNode = nodeIds;
    if(togglingNode.length > 1) togglingNode.pop();
    setExpanded(togglingNode);
  };

  React.useEffect(() => {
    docTreeView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
      <Grid container>
        <Grid item md={4} pr={2}>
        <Typography variant="displaySmall">
            {documentDirectory}
          </Typography>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{
              height: "auto",
              flexGrow: 1,
              maxWidth: "auto",
              overflowY: "auto",
            }}
            onNodeToggle={handleToggle}
            expanded={expanded}
          >
            {documentTreeList.length > 1
              ? documentTreeList.map((item, index) => {
                  return (
                      <TreeItem key={index} nodeId={`${index+1}`} label={item.folderName}>
                        {item.fileList.map((fileName, key) => {
                          return <TreeItem onClick={()=>pdfViewer("/"+item.folderName,fileName)} key={key} nodeId={`${key+10}`} label={fileName} />;
                        })}
                      </TreeItem>
                  );
                })
              : documentTreeList.map((item) => {
                  return item.fileList.map((fileName, key) => {
                        return <TreeItem onClick={()=>pdfViewer("",fileName)} key={key} nodeId={`${key+1}`} label={fileName} />;
                      })
                })}
          </TreeView>
        </Grid>
        <SimpleBackdrop open={pdfLoading || loading} />
        <Grid item md={8}>
        {pdfShow && !pdfLoading ?
          (
            <embed 
            src={`${pdfDocument}`}
            type="application/pdf"
            title={`${pdfTitle}`}
            style={{ width: "100%", height: "88vh" }}
            />
          )
           : null
        }
          
        </Grid>
      </Grid>)

};
