import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridReadyEvent,
  CellClickedEvent,
  GridApi,
  ColumnApi,
} from "ag-grid-community";
import { authAxios } from "../../../../services/axios";
import {
  EditChildRenderer,
  DeleteChildRenderer,
  ModalErrors,
  handleErrorResponse,
  CustomSnackbar,
  filterColumnsObjCreator,
} from "@genrecp/g2clientportal-common";
import { RequestObject } from "./RoleAppType";
import { AgGridClasses } from "@genre/g2common-theme";

let gridApi: GridApi;
let columnApi: ColumnApi;

interface Props {
  request: RequestObject;
  setrequest: (v: RequestObject) => void;
}

export const RoleAppTypeGrid: React.FC<Props> = (props) => {
  const { setrequest, request } = props;
  const gridStyle = useMemo(() => ({ height: 505, width: "100%" }), []);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    resizable: true,
  };

  const onRowDelete = (event: CellClickedEvent) => {
    const { roleAppTypeID } = event.data || {};

    if (!roleAppTypeID) return;

    setrequest({ ...request, loading: true });

    authAxios
      .delete(`/RoleAppTypes/${roleAppTypeID}`)
      .then((res: any) => {
        setrequest({ ...request, loading: false });

        gridApi?.refreshServerSide({ purge: true });
      })
      .catch((e: any) => {
        setrequest({ ...request, loading: false });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const onRowEditSelected = (params: any) => {
    const { roleAppTypeID, appTypeID, roleID, createdDate, createdBy } =
      params.data || {};
    setrequest({
      ...request,
      roleAppTypeID,
      appTypeID,
      roleID,
      createdDate,
      createdBy,
      // updatetoggler:true
    });
  };

  const [colDefs] = React.useState<ColDef[]>([
    {
      field: "roleAppTypeID",
      colId: "roleAppTypeID",
      headerName: "RoleAppTypeID",
      hide: true,
    },
    {
      field: "appTypeText",
      colId: "appTypeText",
      headerName: "App Type",
      sort: "desc",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      floatingFilter: true,
      flex: 2,
      sortable: true,
    },
    {
      field: "roleName",
      colId: "roleName",
      headerName: "Role",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      filter: "agTextColumnFilter",
      floatingFilter: true,
      flex: 2,
      sortable: true,
    },
    {
      headerName: "",
      onCellClicked: onRowEditSelected,
      cellRenderer: "EditChildRenderer",
      flex: 0.3,
      sortable: false,
    },
    {
      headerName: "",
      onCellClicked: onRowDelete,
      cellRenderer: "DeleteChildRenderer",
      flex: 0.3,
      sortable: false,
    },
  ]);

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
    DeleteChildRenderer: DeleteChildRenderer,
  };

  const ensureNonEmptyString = (str: any) =>
    typeof str === "string" && str.trim() !== "";

  function getSortedColumns(columnApi: any) {
    return columnApi
      ?.getColumnState()
      .filter((cs: any) => ensureNonEmptyString(cs.sort));
  }

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
        let filterModel = gridApi.getFilterModel();
        let filterCount = Object.keys(gridApi.getFilterModel()).length;
        let sortCount = getSortedColumns(columnApi).length;
        let searchDataObj: any = {
          pageNumber: params.request.endRow / 10,
          pageSize: 10,
        };

        if (filterCount > 0) {
          let gridOperationProps = [];
          if (filterModel.appTypeText !== undefined) {
            let appTypeTextObj = filterColumnsObjCreator(
              "appTypeText",
              filterModel.appTypeText.filter,
              "string"
            );
            gridOperationProps.push(appTypeTextObj);
          }

          if (filterModel.roleName !== undefined) {
            let roleNameObj = filterColumnsObjCreator(
              "roleName",
              filterModel.roleName.filter,
              "string"
            );
            gridOperationProps.push(roleNameObj);
          }

          if (gridOperationProps.length)
            searchDataObj["filterColumns"] = gridOperationProps;
        }
        if (sortCount > 0) {
          const sortCol = getSortedColumns(columnApi)[0];
          searchDataObj.orderBy = sortCol.colId + " " + sortCol.sort;
        }

        authAxios
          .post("/GetRoleAppTypes", {
            gridOperationsProps: searchDataObj,
          })
          .then((res: any) => {
            var totalRows = -1;
            if (res.data !== null && res.data !== undefined) {
              if (res.data.length < 10) {
                totalRows = params.request.startRow + res.data.length;
              }
              params.successCallback(res.data, totalRows);
            } else {
              params.successCallback([], totalRows);
            }
          })
          .catch((e: any) => {
            console.log(e.message);
          });
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };

  React.useEffect(() => {
    gridApi?.refreshServerSide({ purge: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.refreshToggler]);

  return (
    <div
      style={gridStyle}
      className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
    >
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        pagination={true}
        rowHeight={29}
        headerHeight={32}
        cacheBlockSize={10}
        paginationPageSize={10}
        rowModelType={"serverSide"}
        suppressServerSideInfiniteScroll={false}
        animateRows={true}
        components={frameworkComponents}
      ></AgGridReact>
    </div>
  );
};

export default RoleAppTypeGrid;
