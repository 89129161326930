export interface BillItemsType {
  pageNumber?: any;
  pageSize?: any;
  orderBy?: any;
  billItemID: string;
  billID: string;
  accountingGroupID?: any;
  clientLocationID: string;
  clientGroupID?: string;
  recordSequence: number;
  policyNumber: string;
  insuredName: string;
  corporateCode: string;
  uwBranchCode: string;
  billPointCode: string;
  billingDate: string;
  billingDateOperator?: any;
  billingDateTo?: any;
  policyTransactionTypeCode: string;
  otherChargeName: string;
  transactionEffectiveDate: string;
  transactionEffectiveDateOperator?: any;
  transactionEffectiveDateTo?: any;
  transactionRegistrationDate: string;
  transactionRegistrationDateOperator?: any;
  transactionRegistrationDateTo?: any;
  endNumber: string;
  sequenceNumber: number;
  grossDueAmount: number;
  commissionRatePercent: number;
  commissionAmount: number;
  netPremiumAmount: number;
  netPremiumDueAmount: number;
  dueDate?: string;
  dueDateOperator?: any;
  dueDateTo?: any;
  daysOverdue: number;
  grapSearchCode: string;
  otherChargeAmount: number;
  gsmUWID: string;
  policyEffectiveDate: string;
  policyEffectiveDateOperator?: any;
  policyEffectiveDateTo?: any;
  policyExpirationDate: string;
  policyExpirationDateOperator?: any;
  policyExpirationDateTo?: any;
  monthEndDate: string;
  monthEndDateOperator?: any;
  monthEndDateTo?: any;
  transactionGroupID?: any;
  isClientDisplay: boolean;
  hasComment: boolean;
  isGSMRelease: boolean;
  gsmReleaseID?: string;
  gsmReleaseDate?: string;
  gsmReleaseDateOperator?: any;
  gsmReleaseDateTo?: any;
  clientApproverUserID?: string;
  clientApproved: boolean;
  clientApprovedDate?: string;
  clientApprovedDateOperator?: any;
  clientApprovedDateTo?: any;
  clientBillSubmitted: boolean;
  clientBillSubmittedUserID?: any;
  clientBillSubmittedDate?: any;
  clientBillSubmittedDateOperator?: any;
  clientBillSubmittedDateTo?: any;
  amountDueOverride: number;
  amountPaid: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate?: string;
  modifiedBy?: string;
  total: number;
  createdDateOperator?: any;
  createdDateTo?: any;
}

export const findClientBillItemDisabledStatus = (
  currentMonthEndDate: string | undefined,
  monthEndDate: string,
  clientBillSubmitted: boolean
) => {
  if (!currentMonthEndDate) {
    console.error("currentMonthEndDate not found");
    return false;
  }
  const disabledCondition1 =
    currentMonthEndDate === monthEndDate && clientBillSubmitted;
  const disabledCondition2 =
    currentMonthEndDate !== monthEndDate && !clientBillSubmitted;
  const disabledCondition3 =
    currentMonthEndDate !== monthEndDate && clientBillSubmitted;
  const disabledFlag =
    disabledCondition1 || disabledCondition2 || disabledCondition3;

  return disabledFlag;
};

export const findClientBillItemCheckedStatus = (
  currentMonthEndDate: string | undefined,
  monthEndDate: string,
  clientApproved: boolean,
  clientBillSubmitted: boolean
) => {
  if (!currentMonthEndDate) {
    console.error("currentMonthEndDate not found");
    return false;
  }

  const checkedCondition1 =
    currentMonthEndDate === monthEndDate &&
    clientApproved &&
    !clientBillSubmitted;
  const checkedCondition2 =
    currentMonthEndDate === monthEndDate && clientBillSubmitted;
  const checkedCondition3 =
    currentMonthEndDate !== monthEndDate && clientBillSubmitted;
  const checkedFlag =
    checkedCondition1 || checkedCondition2 || checkedCondition3;

  return checkedFlag;
};

export const findBillItemCheckedStatus = (
  currentMonthEndDate: string | undefined,
  monthEndDate: string,
  isClientDisplay: boolean,
  isGSMRelease: boolean
) => {
  if (!currentMonthEndDate) {
    console.error("currentMonthEndDate not found");
    return false;
  }
  const checkedCondition1 =
    currentMonthEndDate === monthEndDate && isClientDisplay && !isGSMRelease;
  const checkedCondition2 =
    currentMonthEndDate === monthEndDate && isClientDisplay && isGSMRelease;
  const checkedCondition3 =
    currentMonthEndDate !== monthEndDate && isClientDisplay;
  const checkedFlag =
    checkedCondition1 || checkedCondition2 || checkedCondition3;

  return checkedFlag;
};

export const findBillItemDisabledStatus = (
  currentMonthEndDate: string | undefined,
  monthEndDate: string,
  isClientDisplay: boolean,
  isGSMRelease: boolean
) => {
  if (!currentMonthEndDate) {
    console.error("currentMonthEndDate not found");
    return false;
  }
  const disabledCondition1 =
    currentMonthEndDate === monthEndDate && isClientDisplay && isGSMRelease;
  const disabledCondition2 =
    currentMonthEndDate !== monthEndDate && isClientDisplay;
  const disabledCondition3 =
    currentMonthEndDate !== monthEndDate && !isClientDisplay;
  const disabledFlag =
    disabledCondition1 || disabledCondition2 || disabledCondition3;

  return disabledFlag;
};

export const sendSelectedDataformatter = (data: BillItemsType[]) => {
  return data.map((item) => ({
    billItemID: item.billItemID,
    billID: item.billID,
    isClientDisplay: item.isClientDisplay,
    isPayAmount: false,
    amountDueOverride: item.amountDueOverride,
    amountPaid: item.amountPaid,
  }));
};

const detectClientDisplayFlagChanges = (
  sourceArray: BillItemsType[],
  newObj: BillItemsType
) => {
  const newFlag = newObj.isClientDisplay;

  const sourceItem = sourceArray.find(
    (item) => item.billItemID === newObj.billItemID
  );

  if (sourceItem) {
    if (sourceItem.isClientDisplay !== newFlag) {
      // if flag changed
      return true;
    }
  }
  return false;
};

export const handleSaveButtonLogic = (
  sourceData: BillItemsType[],
  data: BillItemsType,
  detectflagsChangedArray: BillItemsType[],
  setDetectflagsChangedArray: (v: BillItemsType[]) => void
) => {

  if (detectClientDisplayFlagChanges(sourceData, data)) {
    const changedFlagExists = detectflagsChangedArray.some(
      (x) => x.billItemID === data.billItemID
    );
    if (changedFlagExists)
      setDetectflagsChangedArray(
        detectflagsChangedArray.map((y) => {
          if (y.billItemID === data.billItemID) {
            return data;
          }
          return y;
        })
      );
    else setDetectflagsChangedArray([...detectflagsChangedArray, data]);
  } else {
    setDetectflagsChangedArray(
      detectflagsChangedArray.filter((y) => y.billItemID !== data.billItemID)
    );
  }
};