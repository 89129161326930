import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

/**
* Renders a sign-out button
*/
export const SignOutButton = () => {
const { instance, accounts } = useMsal();

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
        setAnchorEl(null);
        };
        const handleLogout = () => {
            sessionStorage.clear();
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
        }

        return (
        <>
            <Button size="small" onClick={handleClick}>
            {accounts[0].name}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
                <MenuItem onClick={()=> handleLogout()}>Sign out</MenuItem>
            </Menu>
        </>
        )
        }