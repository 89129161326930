import React from "react";
import {
  CellClassParams,
  ValueSetterParams,
  EditableCallbackParams,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  Button,
  SimpleBackdrop,
  useRibbonSnackbar,
  handleSuccessResponse,
  CustomSnackbar,
  handleErrorResponse,
  showInvalidSnackbar,
  ModalErrors,
} from "@genrecp/g2clientportal-common";
import {Select,Grid,MenuItem} from "@mui/material";
import GeneralCommentRenderer from "../GeneralCommentRenderer";
import { SelectChangeEvent,Skeleton } from "@mui/material";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import { AgGridClasses } from "@genre/g2common-theme";
import {
  RootState,
  AppDispatch,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector, useDispatch } from "react-redux";
import BillStatementSummary from "../BillStatementSummary";
import { handleBillItemsFilters, handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import { GridContainer, LandingHeader, ViewSchema } from "../../genbillUtils";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import {
  AccountingGroup,
  BillItemGeneralState,
  SendDataObject,
  exportToCSV,
  monthYearFormatter,
  handlClientSaveButtonLogic,
  sendClientSelectedDataformatter
} from "../clientView/utils";
import {
  comparatorForDate,
  formatDateForGrid,
  currencyFormatter,
  percentageFormatter,
  negativeValueColorFormatter,
} from "@genrecp/g2clientportal-common";
import SubmitConfirmation from "./SubmitConfirmation";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { PermissionLevelLookup, RolesAcessLookUp } from "@genrecp/g2clientportal-client-portal/src/App/Components/admin/adminUtils";
import CustomCheckboxRenderer from "../CustomCheckboxRenderer";
import { ICellRendererParams } from "ag-grid-community";
import { BillItemsType, findClientBillItemCheckedStatus, findClientBillItemDisabledStatus } from "../billDetailUtils";

function ClientViewBillDetails() {
  const [state, setState] = React.useState<BillItemGeneralState>({
    accountingGroupData: [{  
      accountingGroupID: "",
      billID: "",
      title: "All",
      stopBucketEdit: false
    }],
    accountingGroupID: "",
    monthEndDate: "",
    monthEndDateData: [],
    transactionDate: "",
  });
  const [transactionDateData, setTransactionDateData] = React.useState<string[]>([""]);
  const [sendSelectedData, setSendSelectedData] = React.useState<SendDataObject[]>([]);
  const [sourceData, setSourceData] = React.useState<BillItemsType[]>([]);
  const [detectflagsChangedArray, setDetectflagsChangedArray] = React.useState<BillItemsType[]>([]);
  
  const [confirmationRowData, setConfirmationRowData] = React.useState<BillItemsType[]>(
    []
  );
  const [currentMonthEndDate, setCurrentMonthEndDate] = React.useState<string>("")

  const [loading, setLoading] = React.useState<boolean>(false);
  const [showGrid, setShowGrid] = React.useState<boolean>(false);
  const [excelFileloading, setExcelFileloading] = React.useState<boolean>(false);
  const [transactionFilterLoading, setTransactionFilterLoading] = React.useState<boolean>(false);
  const [confirmSubmit, setConfirmSubmit] = React.useState<boolean>(false);
  const [billAssignedPermission, setBillAssignedPermission] = React.useState<Number | null>(null);

  const medRef = React.useRef<string>();
  const accRef = React.useRef<string>();
  const trdRef = React.useRef<string>();
  const cmedRef = React.useRef<string>();

  medRef.current = state.monthEndDate;
  accRef.current = state.accountingGroupID;
  trdRef.current = state.transactionDate;
  cmedRef.current = currentMonthEndDate;

  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });

  const $dispatch = useDispatch<AppDispatch>();
  const isClientView = useSelector(
    (state: RootState) => state.genbill.isClientView
  );
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const gridRef: any = React.useRef(null);

  const appRolePermissions = useSelector(
    (state: RootState) => state.auth.currentUser?.appRolePermissions
  );
  const permissions = appRolePermissions && JSON.parse(appRolePermissions);
  const subTotal = confirmationRowData.reduce(
    (acc, current) => acc + +current.amountPaid,
    0
  );

  const brokerBillingExternalViewer = permissions?.find(
    (item: any) => item.roleId === RolesAcessLookUp.BrokerBillingExternalViewer
  );
  const brokerBillingExternalContributor = permissions?.find(
    (item: any) => item.roleId === RolesAcessLookUp.BrokerBillingExternalContributor
  );
  const billAssignedToViewerRole = billAssignedPermission === PermissionLevelLookup.Viewer;
  const hideSaveSubmit = (brokerBillingExternalViewer && billAssignedToViewerRole) ||
                    (brokerBillingExternalContributor && billAssignedToViewerRole);

  function onCheckboxChange(checked:boolean,params:ICellRendererParams){
    
    const data:BillItemsType = {...params.data,clientApproved: checked};
 
    let finalSelectedData:SendDataObject[] = [...sendSelectedData];
    let confirmationdata:BillItemsType[] = [...confirmationRowData];

    const newData = {
      billItemID: data.billItemID,
      billID: data.billID,
      isClientDisplay: false,
      isPayAmount: data.clientApproved,
      amountDueOverride: data.amountDueOverride,
      amountPaid: data.amountPaid,
    }
    
    const exists = finalSelectedData.some(x => x.billItemID === newData.billItemID);
    if(checked){
    
      if(exists) {
        finalSelectedData = finalSelectedData.map(y => {
          if(y.billItemID === newData.billItemID) {
            return newData
          }
          return y;
        });
        confirmationdata = confirmationdata.map(y => {
          if(y.billItemID === data.billItemID) {
            return data
          }
          return y;
        });
      } else {
        finalSelectedData.push(newData)
        confirmationdata.push(data)
      } 
    } else {
      if(exists) {
        finalSelectedData = finalSelectedData.map(y => {
          if(y.billItemID === newData.billItemID) {
            return newData
          }
          return y;
        });
        confirmationdata = confirmationdata.map(y => {
          if(y.billItemID === data.billItemID) {
            return data
          }
          return y;
        });
      }
    }

    handlClientSaveButtonLogic(
      sourceData,
      data,
      detectflagsChangedArray,
      setDetectflagsChangedArray
    );
 
    setSendSelectedData(finalSelectedData);
    setConfirmationRowData(confirmationdata);
    params.node.setData(data);
  }

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "payNow",
        filter: false,
        width: 140,
        sortable: false,
        cellRenderer: (params:ICellRendererParams) => {
          const {monthEndDate, clientBillSubmitted, clientApproved} = params.data || {};

          const disabledFlag = findClientBillItemDisabledStatus(cmedRef.current, monthEndDate, clientBillSubmitted);
          const checkedFlag = findClientBillItemCheckedStatus(
            cmedRef.current,
            monthEndDate,
            clientApproved,
            clientBillSubmitted
            );

          return (
            <CustomCheckboxRenderer 
              isDisabled={disabledFlag}
              onCheckboxChange={(checked) => onCheckboxChange(checked,params)}
              value={checkedFlag}
            />
          )
        }
      },
      {
        field: "hasComment",
        headerName: "Comments",
        cellRenderer: GeneralCommentRenderer,
        cellRendererParams: { text: "View" },
        wrapText: true,
        width: 200,
        filter: "agSetColumnFilter",
        filterParams: {
          values: [true, false],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "amountPaid",
        headerName: "Pay Amount",
        filter: "agNumberColumnFilter",
        editable: (params: EditableCallbackParams | CellClassParams) => {
          const {clientBillSubmitted} = params.data;
          return !clientBillSubmitted;
        },
        valueSetter: (params: ValueSetterParams) => {
          const newVal = params.newValue;
          if (isNaN(+newVal)) {
            alert("Not a valid value!");
            return false;
          }
          if(newVal > 9999999) {
            alert("Value can not be more than 7 digits");
            return false;
          }
          // if(newVal < 0) {
          //   alert("Value can not be less than 0");
          //   return false;
          // }
          params.data.amountPaid = (+newVal).toFixed(2);
          const amtPaid = (+newVal).toFixed(2);
          const newSelectedData = sendSelectedData.map(y => {
            if(y.billItemID === params.data.billItemID) {
              return {
                ...y,
                amountPaid:+amtPaid
              }
            }
            return y;
          });
          setSendSelectedData(newSelectedData);
          params.node?.setData({...params.data, amountPaid:amtPaid});
          return (+newVal).toFixed(2);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        cellRenderer: function (params: any) {
          return `${currencyFormatter(params?.data?.amountPaid,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.amountPaid)
      },
      {
        field: "amountDueOverride",
        filter: "agNumberColumnFilter",
        width: 240,
        hide:true,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.amountDueOverride,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.amountDueOverride)
      },
      {
        field: "policyNumber",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "insuredName",
        filter: "agTextColumnFilter",
        width: 340,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        headerName: "Gross Prem Amt",
        field: "grossDueAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.grossDueAmount,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.grossDueAmount)
      },
      {
        field: "commissionRatePercent",
        filter: "agNumberColumnFilter",
        width: 240,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueFormatter: function (params:ValueFormatterParams) {return percentageFormatter(params.value)},
      },
      {
        field: "commissionAmount",
        width: 240,
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.commissionAmount,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.commissionAmount)
      },
      {
        field: "netPremiumAmount",
        width: 240,
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.netPremiumAmount,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.netPremiumAmount)
      },
      {
        headerName: "Current Amt Due",
        field: "netPremiumDueAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.netPremiumDueAmount,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.netPremiumDueAmount)
      },
      {
        field: "dueDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.dueDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
      {
        field: "daysOverdue",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "policyTransactionTypeCode",
        headerName: "Policy Transaction Type",
        filter: "agTextColumnFilter",
        width: 260,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "endNumber",
        headerName: "Endorsement Number",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "transactionEffectiveDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.transactionEffectiveDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
        width: 240,
      },
      {
        field: "policyEffectiveDate",
        filter: "agDateColumnFilter",
        width: 240,
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.policyEffectiveDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
      {
        field: "policyExpirationDate",
        width: 240,
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.policyExpirationDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
      {
        field: "gsmUWID",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "otherChargeName",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "otherChargeAmount",
        filter: "agNumberColumnFilter",
        width: 240,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        valueGetter: function (params: any) {
          return `${currencyFormatter(params?.data?.otherChargeAmount,true)}`;
        },
        cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.otherChargeAmount)
      },
      {
        field: "clientLocationID",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "grapSearchCode",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        field: "transactionRegistrationDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.transactionRegistrationDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
        width: 260,
      },
      {
        field: "billingDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.billingDate);
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
        },
      },
    ];
  }

  React.useEffect(() => {
    gridRef?.current?.api?.refreshServerSide({ purge: true });
    $dispatch(handleBillItemsFilters({
      accountingGroupID: state.accountingGroupID ? state.accountingGroupID : "",
      clientBillSubmittedDate: state.transactionDate ? state.transactionDate : "",
      monthEndDate: state.monthEndDate ? state.monthEndDate : "",
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.monthEndDate, state.accountingGroupID, state.transactionDate]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    state.monthEndDate && getTransactionDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.monthEndDate]);

  React.useEffect(() => {
    !confirmSubmit && setConfirmationRowData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmSubmit]);

  function showDrawer() {
    isClientView &&
      $dispatch(
        handleGenbillState({
          type: "SHOW_SUMMARY_DRAWER",
          payload: { showSummaryDrawer: true },
        })
      );
  }

  function getTransactionDate(){
    setTransactionFilterLoading(true);
    brokerBillingAxios.get(
      `/Bills/BillsTransactionDates/${billID}/MonthEndDate/${state.monthEndDate}`
    ).then((res:any) => {
      setTransactionFilterLoading(false);
      const {data} = res || {};
      if(data && data?.length > 0) setTransactionDateData((prev) => [...prev,...data]);
      else setTransactionDateData([""]);
    })
    .catch((e: any) => {
      setTransactionFilterLoading(false);
      console.log(e);
      showInvalidSnackbar(e,enqueueSnackbar);
    });
  }

  function getData() {
    setLoading(true);
    brokerBillingAxios.get(
      `/Bills/InitialLoad/${billID}`
    ).then((res:any) => {
      setLoading(false);
      const {accountingGroupObj ,billAccess,monthEndDates} = res.data || {};
      
      if(monthEndDates && monthEndDates.length){
        setCurrentMonthEndDate(monthEndDates[monthEndDates.length - 1]);
        cmedRef.current = monthEndDates[monthEndDates.length - 1];
      } else {
        setCurrentMonthEndDate("");
        cmedRef.current = "";
      }

      if(billAccess){
        setBillAssignedPermission(billAccess?.permissionLevelID);
      }      
      setState({
        ...state,
        ...(accountingGroupObj.isUniqueSplit && {accountingGroupData: [...state.accountingGroupData,...accountingGroupObj.accountingGroupList]}),
        monthEndDateData: monthEndDates ? monthEndDates : [],
        monthEndDate: monthEndDates.length > 0 ? monthEndDates[monthEndDates.length - 1] : "",
      });
      showDrawer();
      setShowGrid(true);
    })
    .catch((e: any) => {
      showDrawer();
      setLoading(false);
      setShowGrid(true);
      console.log(e);
      showInvalidSnackbar(e,enqueueSnackbar);
    });
  }

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;

    if (name === "monthEndDate") {
      medRef.current = val;
      trdRef.current = "";
      setState((prev) => {
        return {
          ...prev,
          [name]: val,
          transactionDate:""
        };
      });
      return;
    }
    if (name === "transactionDate") {
      trdRef.current = val;
    }
    if (name === "accountingGroupID") {
      accRef.current = val;
    }
    setState((prev) => {
      return {
        ...prev,
        [name]: val,
      };
    });
  };

  const getGridRowsData = async (
    pagination: any,
    filterModel: any,
    sortModel: Array<any>,
    selectedView: ViewSchema | undefined
  ) => {
    const newFilterModel = Object.keys(filterModel).reduce(
      (acc: any, key: any) => {
        const value = filterModel[key];
        if (value !== null && value !== "") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const orderByCheck = sortModel
      .map((model: any) => model.colId + " " + model.sort)
      .join(", ");

    const obj: any = {
      ...pagination,
      ...newFilterModel,
      ...(medRef.current
        ? { monthEndDate: medRef.current, monthEndDateOperator: "equals" }
        : {}),
      ...(accRef.current ? { accountingGroupID: accRef.current } : {}),
      ...(trdRef.current ? { clientBillSubmittedDate: trdRef.current, clientBillSubmittedDateOperator: "equals" } : {}),
      ...(billID ? { billID: billID } : {}),
      ...(orderByCheck ? { orderBy: orderByCheck } : {}),
    };

    if (obj.hasComment) {
      obj.hasComment = JSON.parse(obj.hasComment);
    }

    return brokerBillingAxios
      .post(`/BillItems/GetBillItems`, obj)
      .then((res: any) => {
        const {data} = res || {};
        const filtered = data.filter((item:any) => item.isClientDisplay && item.isGSMRelease)
        const length = filtered.length;
        let mapped:any = [];
        if(length > 0) {
          mapped = filtered.map((item:any)=> ({...item,total:filtered.length}));
          // Pre Select
          let findChecked: BillItemsType[] = [];
          findChecked = mapped.filter(
            ({
              monthEndDate,
              clientApproved,
              clientBillSubmitted,
            }: BillItemsType) => {
              return findClientBillItemCheckedStatus(
                cmedRef.current,
                monthEndDate,
                clientApproved,
                clientBillSubmitted
              );
            }
          );
          findChecked = findChecked.filter(
            ({ clientBillSubmitted }: BillItemsType) => !clientBillSubmitted
          );

          if(findChecked.length > 0){
            setSendSelectedData(sendClientSelectedDataformatter(findChecked));
            setConfirmationRowData(findChecked);
          } else {
            setSendSelectedData([]);
            setConfirmationRowData([]);
          }
        }
        setDetectflagsChangedArray([]);
        setSourceData(mapped);
        return mapped;
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };


  const handleSubmit = (payload: string) => {
    if (!sendSelectedData.length) {
      enqueueSnackbar(`Error! Please select at least one record.`, {
        variant: "error",
      });
      return;
    }

    const url: string =
      payload === "SAVE"
        ? `/BillItems/SaveBill?billID=${billID}&isClientView=${isClientView}`
        : `/BillItems/SubmitBill?billID=${billID}&isClientView=${isClientView}`;

    setLoading(true);

    brokerBillingAxios
      .post(url, sendSelectedData)
      .then((res: any) => {
        setLoading(false);

        handleSuccessResponse(res, enqueueSnackbar);
        setSendSelectedData([]);
        setConfirmSubmit(false);

        gridRef?.current?.api?.deselectAll();
        gridRef?.current?.api?.refreshServerSide({ purge: true });
      })
      .catch((e: any) => {
        setLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const setConfirmFlagCallback = () => {
    setConfirmationRowData(confirmationRowData.filter(item => item.clientApproved))
    setConfirmSubmit(true);
  };
  const handleSaveCallback = () => handleSubmit("SAVE");

  const validatePayAmounts = (callback:()=>void) => {
    
    const flag = sendSelectedData.some((item:SendDataObject) => item.amountPaid === null || item.amountPaid === undefined );
    if(flag) {
      enqueueSnackbar(`Error! Pay Amount cannot be null.`, {
        variant: "error",
      });
      return;
    }
    callback();
  }

  function getExcelReport(){

    if(!state.monthEndDate) {
      enqueueSnackbar(`Error! Month End Date cannot be empty.`, {
        variant: "error",
      });
      return;
    }

    const params = {
      billID,
      monthEndDate: state.monthEndDate,
      monthEndDateOperator: "equals",
      ...(state.accountingGroupID && {accountingGroupID:state.accountingGroupID})
    }
    
    setExcelFileloading(true);

    brokerBillingAxios
    .post("/BillItems/GetBillItemsExport", params).then((res:any) =>{
      setExcelFileloading(false);
      const {data} = res || {};
      data && exportToCSV(data,enqueueSnackbar);
    })
    .catch((e: any) => {
      setExcelFileloading(false);
      showInvalidSnackbar(e,enqueueSnackbar);
    });
  }
  function cancel(){
    setConfirmSubmit(false)
    setSendSelectedData([]);
    setConfirmationRowData([])
  }
  
  const accountingGroup:string = React.useMemo(() => {
    return state.accountingGroupData.find((item:AccountingGroup) => item.accountingGroupID === state.accountingGroupID)?.title || ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.accountingGroupID]);
  
  return (
    <>
      <Grid container spacing={2} mb={2}>
        <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
        <BillStatementSummary 
          title="Bill Statement Summary" 
          billID={billID} 
          accountingGroup={accountingGroup}
        />
        <SimpleBackdrop open={loading} />
        {confirmSubmit ? (
          <Grid
            xs={12}
            sm={4}
            md={6}
            item
            display={"flex"}
            alignItems={"center"}
          >
            <Typography variant="body1">
              Outstanding Amounts Approved on <b>{new Date().toString()}</b>
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid xs={12} sm={4} md={2} item>
              <Typography variant="labelMedium"><b>Accounting Group</b></Typography>
              <Select
                name="accountingGroupID"
                id="accountingGroupID"
                test-id="accountingGroupID"
                value={state.accountingGroupID}
                onChange={handleDropDownChange}
                size="small"
                fullWidth
                displayEmpty
              >
                {state.accountingGroupData.map(
                  (item: AccountingGroup, idx: number) => {
                    return (
                      <MenuItem value={item.accountingGroupID} key={idx}>
                        {item.title}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </Grid>
            <Grid xs={12} sm={4} md={2} item>
            <Typography variant="labelMedium"><b>Month End Date</b></Typography>
              <Select
                name="monthEndDate"
                id="monthEndDate"
                test-id="monthEndDate"
                value={state.monthEndDate}
                onChange={handleDropDownChange}
                size="small"
                fullWidth
              >
                {state.monthEndDateData.length > 0
                  ? state.monthEndDateData.map((item: string, idx: number) => {
                      return (
                        <MenuItem value={item} key={idx}>
                          {monthYearFormatter(item,false)}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </Grid>
            <Grid xs={12} sm={4} md={2} item display={"flex"} direction={"column"}>
                <Typography variant="labelMedium"><b>Transaction Date</b></Typography>
                {
                  transactionFilterLoading ? (
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                  ):
                    <Select
                    name="transactionDate"
                    id="transactionDate"
                    test-id="transactionDate"
                    value={state.transactionDate}
                    onChange={handleDropDownChange}
                    size="small"
                    fullWidth
                    displayEmpty
                    >
                  {transactionDateData.length > 0
                    ? transactionDateData.map((item: string, idx: number) => {
                      return (
                        <MenuItem value={item} key={idx}>
                            {item === "" ? "All" : monthYearFormatter(item)}
                          </MenuItem>
                        );
                      })
                      : null}
                </Select>
                    }
            </Grid>
          </>
          )}
        <Grid xs={12} sm={4} md={6} item display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"} gap={1}>
        {hideSaveSubmit ? null : (
          <>
            {confirmSubmit ? (
              <>
                <Button
                  onClick={() => handleSubmit("SUBMIT")}
                  variant="containedTertiary"
                >
                  Confirm
                </Button>
                <Button
                  onClick={cancel}
                  variant="outlinedTertiary"
                  sx={{
                    ":focus":{
                    "&.MuiButton-outlinedTertiary":{
                      border:"none"
                     },
                    }
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => validatePayAmounts(handleSaveCallback)}
                  variant="contained"
                  disabled={detectflagsChangedArray.length === 0}
                >
                  Save
                </Button>
                <Button
                  onClick={() => validatePayAmounts(setConfirmFlagCallback)}
                  variant="contained"
                  disabled={sendSelectedData.every(item => !item.isPayAmount)}
                >
                  Submit
                </Button>
              </>
            )}
          </>
        )}
          <LoadingButton
            onClick={getExcelReport}
            loading={excelFileloading}
            variant="containedTertiary"
          >
            Export All as Excel
          </LoadingButton>        
        </Grid>
      </Grid>
      {confirmSubmit ? (
        <Grid xs={12} sm={12} md={12} item>
          <Typography variant="body1">
            SubTotal for Outstanding Items: <b>${subTotal?.toLocaleString()}</b>
          </Typography>
        </Grid>
      ) : null}
      {confirmSubmit ? (
        <SubmitConfirmation rowData={confirmationRowData} />
      ) : (
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12} item>
            <LandingHeader>
              <GridContainer>
                {showGrid ? <AggridWrapper
                  getGridRowsData={getGridRowsData}
                  gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
                  getColumnDefs={getColumnDefs}
                  dashboardName={""}
                  enableUserViews={false}
                  enableSystemViews={false}
                  landingPage={"BillDetails"}
                  views={false}
                  currentUser={{
                    userName: createdBy && createdBy.id ? createdBy.id : "",
                    email: "",
                    fullName: "",
                  }}
                  ref={gridRef}
                  gridHeight={window.innerHeight - 280}
                  gridProps={{
                    singleClickEdit: true,
                    suppressRowClickSelection: true,
                    rowSelection: "multiple",
                  }}
                  rowHeight={29}
                  headerHeight={32}
                  hideHeader
                  showSidebar
                ></AggridWrapper>:null}
              </GridContainer>
            </LandingHeader>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ClientViewBillDetails;
