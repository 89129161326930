import React from 'react'
import { DelegatedIssuesProps } from '../delegatedIssuesAdminUtils'
import CashIssueDetails from '../cashIssueDetails/CashIssueDetails';
import SuspenseReason from '../suspenseReasons/SuspenseReason';
import ClientCashDetails from '../clientCashDetails/ClientCashDetails';
import QAErrorReason from '../qaErrorReasons/QAErrorReason';

function InfoSectionSelector({request,dispatch}:DelegatedIssuesProps) {
  switch (request.screenName) {
    case "CASH_ISSUE_DETAILS":
        return <CashIssueDetails request={request} dispatch={dispatch}/>
        case "QA_ERROR_REASON":
            return <QAErrorReason request={request} dispatch={dispatch}/>
            case "CLIENT_CASH_DETAILS":
                return <ClientCashDetails request={request} dispatch={dispatch}/>
                case "SUSPENSE_REASONS":
                    return <SuspenseReason request={request} dispatch={dispatch}/>
    default:
        return null;
  }
}

export default InfoSectionSelector