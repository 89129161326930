import React, { Fragment } from "react";
import { DelegatedProps, yearConverter } from "../utils";
import { Grid, Stack, Typography } from "@mui/material";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker, { DateObject } from "react-multi-date-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Asterisk from "./Asterisk";

type Props = {
  showMonthInCaseOfOneDateRange: boolean;
};
function EffectiveDateRange({
  request,
  dispatch,
  showMonthInCaseOfOneDateRange,
}: DelegatedProps & Props) {
  const currentYear = new Date().getFullYear().toString();
  const currentYearMinus12Year = (new Date().getFullYear() - 12).toString();

  const onChange = (
    date: DateObject[],
    options: {
      validatedValue: string | string[];
      input: HTMLElement;
      isTyping: boolean;
    },
    name: "YEAR_RANGE" | "MONTH_RANGE"
  ) => {
    const { validatedValue: range } = options;
    if (typeof range === "string") return;

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        ...(name === "YEAR_RANGE"
          ? { effectiveYearsRange: range, yearValues: date }
          : name === "MONTH_RANGE"
          ? { effectiveYearsMonthRange: range, monthValues: date }
          : {}),
      },
    });
  };

  return (
    <Fragment>
      <Grid
        md={1.7}
        sm={12}
        xs={12}
        item
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <DatePicker
          render={(value, openCalendar) => {
            return (
              <Stack display={"flex"} direction={"row"} gap={1} mt={1}>
                <Typography
                  variant="bodyMedium"
                  sx={{
                    ...((request.firstSubmit && request.disableDates) && { color: "#0000005e" }),
                  }}
                >
                  Effective Date Range<Asterisk />
                </Typography>
                <CalendarTodayIcon
                  onClick={openCalendar}
                  sx={{
                    ...((request.firstSubmit && request.disableDates) && { color: "#0000005e" }),
                  }}
                />
              </Stack>
            );
          }}
          onlyYearPicker
          range
          highlightToday={false}
          value={request.yearValues}
          onChange={(ranges, options) => {
            onChange(ranges, options, "YEAR_RANGE");
          }}
          style={{
            width: "100%",
            boxSizing: "border-box",
          }}
          containerStyle={{
            width: "100%",
          }}
          maxDate={yearConverter(currentYear, true)}
          minDate={yearConverter(currentYearMinus12Year, true)}
          plugins={[<DatePanel header="Selected Range" />]}
          disabled={(request.firstSubmit && request.disableDates)}
        />
      </Grid>
      {showMonthInCaseOfOneDateRange && (
        <Grid
          md={1.7}
          sm={12}
          xs={12}
          item
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <DatePicker
            onlyMonthPicker
            range
            hideYear
            highlightToday={false}
            format="MMMM"
            value={request.monthValues}
            onChange={(ranges, options) => {
              onChange(ranges, options, "MONTH_RANGE");
            }}
            style={{
              width: "100%",
              boxSizing: "border-box",
            }}
            containerStyle={{
              width: "100%",
            }}
            plugins={[<DatePanel header="Selected Range" />]}
            disabled={(request.firstSubmit && request.disableDates)}
            render={(value, openCalendar) => {
              return (
                <Stack display={"flex"} direction={"row"} gap={1} mt={1}>
                  <Typography
                    variant="bodyMedium"
                    sx={{
                      ...((request.firstSubmit && request.disableDates) && { color: "#0000005e" }),
                    }}
                  >
                    Select Month Range<Asterisk />
                  </Typography>
                  <CalendarTodayIcon
                    onClick={openCalendar}
                    sx={{
                      ...((request.firstSubmit && request.disableDates) && { color: "#0000005e" }),
                    }}
                  />
                </Stack>
              );
            }}
          />
        </Grid>
      )}
    </Fragment>
  );
}

export default EffectiveDateRange;
