import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { formatDateForGrid, showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { BillItemsType } from "../billDetailUtils";

export interface AccountingGroup {
  accountingGroupID: string;
  billID: string;
  title: string;
  stopBucketEdit: boolean;
  accountingGroupRules?: any;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: any;
  modifiedBy?: any;
}

export interface BillItemGeneralState {
  accountingGroupData: AccountingGroup[];
  accountingGroupID: string;
  transactionDate: string;
  monthEndDate: string;
  monthEndDateData: string[];
}

export const monthYearFormatter = (params: any,showDate = true) => {
  if (!params) return;
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date(params);
  let name = month[d.getMonth()];
  let year = d.getFullYear();
  let date = d.getDate();
  return `${showDate ? date : ""} ${name}, ${year}`;
};

export interface SendDataObject {
  billItemID: string;
  billID: string;
  isClientDisplay: boolean;
  isPayAmount: boolean;
  amountDueOverride: number;
  amountPaid: number;
}

export const exportToCSV = (apiData: any, enqueueSnackbar: any) => {
  try {
    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet("Sheet 1");

    workSheet.columns = [
      // { header: "Bill Item ID", key: "billItemID", width: 40 },
      // { header: "Bill ID", key: "billID", width: 40 },
      // { header: "Client Location ID", key: "clientLocationID", width: 30 },
      // { header: "Record Sequence", key: "recordSequence", width: 30 },
      { header: "Policy Number", key: "policyNumber", width: 30 },
      { header: "Insured Name", key: "insuredName", width: 50 },
      { header: "Corporate Code", key: "corporateCode", width: 30 },
      { header: "Uw Branch Code", key: "uwBranchCode", width: 30 },
      { header: "Bill Point Code", key: "billPointCode", width: 30 },
      { header: "Billing Date", key: "billingDate", width: 30 },
      {
        header: "Policy Transaction Type Code",
        key: "policyTransactionTypeCode",
        width: 30,
      },
      { header: "Other Charge Name", key: "otherChargeName", width: 30 },
      {
        header: "Transaction Effective Date",
        key: "transactionEffectiveDate",
        width: 30,
      },
      {
        header: "Transaction Registration Date",
        key: "transactionRegistrationDate",
        width: 30,
      },
      { header: "End Number", key: "endNumber", width: 30 },
      { header: "Sequence Number", key: "sequenceNumber", width: 30 },
      { header: "Gross Due Amount", key: "grossDueAmount", width: 30 },
      {
        header: "Commission Rate Percent",
        key: "commissionRatePercent",
        width: 30,
      },
      { header: "Commission Amount", key: "commissionAmount", width: 30 },
      { header: "Net Premium Amount", key: "netPremiumAmount", width: 30 },
      {
        header: "Net Premium Due Amount",
        key: "netPremiumDueAmount",
        width: 30,
      },
      { header: "Due Date", key: "dueDate", width: 30 },
      { header: "Days Overdue", key: "daysOverdue", width: 30 },
      { header: "GrapSearchCode", key: "grapSearchCode", width: 30 },
      { header: "OtherChargeAmount", key: "otherChargeAmount", width: 30 },
      { header: "Gsm UW ID", key: "gsmUWID", width: 30 },
      {
        header: "Policy Effective Date",
        key: "policyEffectiveDate",
        width: 30,
      },
      {
        header: "Policy Expiration Date",
        key: "policyExpirationDate",
        width: 30,
      },
      { header: "Month End Date", key: "monthEndDate", width: 30 },
      // { header: "Transaction Group ID", key: "transactionGroupID", width: 30 },
      // { header: "Is Client Display", key: "isClientDisplay", width: 30 },
      // { header: "Has Comment", key: "hasComment", width: 30 },
      { header: "Comments", key: "comments", width: 30 },
      // { header: "Is GSM Release", key: "isGSMRelease", width: 30 },
      // { header: "Gsm Release ID", key: "gsmReleaseID", width: 30 },
      // { header: "Gsm Release Date", key: "gsmReleaseDate", width: 30 },
      // {
      //   header: "Client Approver User ID",
      //   key: "clientApproverUserID",
      //   width: 40,
      // },
      // { header: "Client Approved", key: "clientApproved", width: 30 },
      // { header: "Client Approved Date", key: "clientApprovedDate", width: 30 },
      {
        header: "Client Bill Submitted",
        key: "clientBillSubmitted",
        width: 30,
      },
      // {
      //   header: "Client Bill Submitted User ID",
      //   key: "clientBillSubmittedUserID",
      //   width: 40,
      // },
      {
        header: "Client Bill Submitted Date",
        key: "clientBillSubmittedDate",
        width: 30,
      },
      { header: "Amount Due Override", key: "amountDueOverride", width: 30 },
      { header: "Amount Paid", key: "amountPaid", width: 30 },
      // { header: "Is Deleted", key: "isDeleted", width: 30 },
      // { header: "Created Date", key: "createdDate", width: 30 },
      // { header: "Created By", key: "createdBy", width: 40 },
      // { header: "Modified Date", key: "modifiedDate", width: 30 },
      // { header: "Modified By", key: "modifiedBy", width: 40 },
    ];

    apiData.forEach((item: any) => {
      workSheet.addRow({
        billItemID: item.billItemID,
        billID: item.billID,
        clientLocationID: item.clientLocationID,
        recordSequence: item.recordSequence,
        policyNumber: item.policyNumber,
        insuredName: item.insuredName,
        corporateCode: item.corporateCode,
        uwBranchCode: item.uwBranchCode,
        billPointCode: item.billPointCode,
        billingDate: formatDateForGrid(item.billingDate),
        policyTransactionTypeCode: item.policyTransactionTypeCode,
        otherChargeName: item.otherChargeName,
        transactionEffectiveDate: formatDateForGrid(item.transactionEffectiveDate),
        transactionRegistrationDate: formatDateForGrid(item.transactionRegistrationDate),
        endNumber: item.endNumber,
        sequenceNumber: item.sequenceNumber,
        grossDueAmount: item.grossDueAmount,
        commissionRatePercent: item.commissionRatePercent,
        commissionAmount: item.commissionAmount,
        netPremiumAmount: item.netPremiumAmount,
        netPremiumDueAmount: item.netPremiumDueAmount,
        dueDate: formatDateForGrid(item.dueDate),
        daysOverdue: item.daysOverdue,
        grapSearchCode: item.grapSearchCode,
        otherChargeAmount: item.otherChargeAmount,
        gsmUWID: item.gsmUWID,
        policyEffectiveDate: formatDateForGrid(item.policyEffectiveDate),
        policyExpirationDate: formatDateForGrid(item.policyExpirationDate),
        monthEndDate: formatDateForGrid(item.monthEndDate),
        transactionGroupID: item.transactionGroupID,
        isClientDisplay: item.isClientDisplay,
        hasComment: item.hasComment,
        comments: item.comments,
        isGSMRelease: item.isGSMRelease,
        gsmReleaseID: item.gsmReleaseID,
        gsmReleaseDate: formatDateForGrid(item.gsmReleaseDate),
        clientApproverUserID: item.clientApproverUserID,
        clientApproved: item.clientApproved,
        clientApprovedDate: formatDateForGrid(item.clientApprovedDate),
        clientBillSubmitted: item.clientBillSubmitted,
        clientBillSubmittedUserID: item.clientBillSubmittedUserID,
        clientBillSubmittedDate: formatDateForGrid(item.clientBillSubmittedDate),
        amountDueOverride: item.amountDueOverride,
        amountPaid: item.amountPaid,
        isDeleted: item.isDeleted,
        createdDate: formatDateForGrid(item.createdDate),
        createdBy: item.createdBy,
        modifiedDate: formatDateForGrid(item.modifiedDate),
        modifiedBy: item.modifiedBy,
      });
    });

    workBook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats.officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, "GeneralStarBilling.xlsx");
    });
  } catch (e) {
    console.log(e);
    showInvalidSnackbar(e,enqueueSnackbar);
  }
};


export const sendClientSelectedDataformatter = (data: BillItemsType[]) => {
  return data.map((item) => ({
    billItemID: item.billItemID,
    billID: item.billID,
    isClientDisplay: false,
    isPayAmount: item.clientApproved,
    amountDueOverride: item.amountDueOverride,
    amountPaid: item.amountPaid,
  }));
};

const detectClientApprovedFlagChanges = (
  sourceArray: BillItemsType[],
  newObj: BillItemsType
) => {
  const newFlag = newObj.clientApproved;

  const sourceItem = sourceArray.find(
    (item) => item.billItemID === newObj.billItemID
  );

  if (sourceItem) {
    if (sourceItem.clientApproved !== newFlag) {
      // if flag changed
      return true;
    }
  }
  return false;
};

export const handlClientSaveButtonLogic = (
  sourceData: BillItemsType[],
  data: BillItemsType,
  detectflagsChangedArray: BillItemsType[],
  setDetectflagsChangedArray: (v: BillItemsType[]) => void
) => {

  if (detectClientApprovedFlagChanges(sourceData, data)) {
    const changedFlagExists = detectflagsChangedArray.some(
      (x) => x.billItemID === data.billItemID
    );
    if (changedFlagExists)
      setDetectflagsChangedArray(
        detectflagsChangedArray.map((y) => {
          if (y.billItemID === data.billItemID) {
            return data;
          }
          return y;
        })
      );
    else setDetectflagsChangedArray([...detectflagsChangedArray, data]);
  } else {
    setDetectflagsChangedArray(
      detectflagsChangedArray.filter((y) => y.billItemID !== data.billItemID)
    );
  }
};
