import React, { Fragment, useEffect, useState } from "react";
import {
  comparatorForDate,
  CustomSnackbar,
  formatDateForGrid,
  GridLoadingSpinner,
  handleErrorResponse,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef } from "ag-grid-community";
import { EditChildRenderer } from "@genrecp/g2clientportal-common";
import { Grow } from "@mui/material";
import { delegatedAxios } from "../../../../../../services/axios";
import { CashTrackerProps } from "../../../CashTrackerUtils";

interface Props extends CashTrackerProps {}

function ResearchLog({request,dispatch}:Props) {

  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useRibbonSnackbar();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // useEffect(() => {
  //   !open && getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  function getData() {

    if(!request.suspenseForm.suspenseID){
      showInvalidSnackbar(null,enqueueSnackbar)
      return;
    }

    setLoading(true);

    delegatedAxios
      .get(`/SuspenseLog/GetLogs/${request.suspenseForm.suspenseID}/by/research`)
      .then((res: any) => {
        setLoading(false);

        const { status, data } = res || {};
        if (status === 200) {
          setRowData(data || []);
        } else setRowData([]);
      })
      .catch((e: any) => {
        setLoading(false);
        setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: false,
    floatingFilter: false,
    flex: 1,
  };

  const colDefs: ColDef[] = [
    {
      field: "createdDate",
      colId: "createdDate",
      flex: 1,
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.createdDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdBy",
      colId: "createdBy",
      headerName: "Created By",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    }
  ];

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
  };

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <GridLoadingSpinner loading={loading}>
        <Grow in={true} style={{ transitionDelay: "100ms" }}>
          <div
            className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
            style={{ width: "100%", height: window.innerHeight - 260 }}
          >
            <AgGridReact
              rowHeight={29}
              headerHeight={32}
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={colDefs}
              pagination={true}
              cacheBlockSize={10}
              paginationPageSize={10}
              animateRows={true}
              components={frameworkComponents}
            ></AgGridReact>
          </div>
        </Grow>
      </GridLoadingSpinner>
    </Fragment>
  );
}

export default ResearchLog;
