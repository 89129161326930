import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ArticleIcon from "@mui/icons-material/Article";
import {
  DelegatedIssueScreenName,
  DelegatedIssuesProps,
} from "../delegatedIssuesAdminUtils";

export default function DelegatedIssuesSideBar({
  request,
  dispatch,
}: DelegatedIssuesProps) {

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: DelegatedIssueScreenName
  ) => {
    dispatch({
      screenName: value,
    });
  };

  type SideNavArrayType = {
    name: string;
    value: DelegatedIssueScreenName;
  };
  const sideNavArray: SideNavArrayType[] = [
    { name: "Cash Issue Details", value: "CASH_ISSUE_DETAILS" },
    { name: "Client Cash Details", value: "CLIENT_CASH_DETAILS" },
    { name: "QA Error Reasons", value: "QA_ERROR_REASON" },
    { name: "Suspense Reasons", value: "SUSPENSE_REASONS" },
  ];

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper", position:"absolute", top:90 }}>
      <List
        component="nav"
        subheader={
          <ListItemButton
            sx={{
              background: "#3A5BAA",
              "&:hover": {
                background: "#002B73",
              },
              minHeight: 30,
            }}
          ></ListItemButton>
        }
      >
        {sideNavArray.map((item, idx) => (
          <ListItemButton
            key={idx}
            selected={request.screenName === item.value}
            onClick={(event) => handleListItemClick(event, item.value)}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </Box>
  );
}
