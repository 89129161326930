import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextInput, SelectList } from "@genrecp/g2clientportal-common";
import MenuItem from "@mui/material/MenuItem";
import { FormControlLabel, FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { SelectChangeEvent } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ClientLocationArrayType,
  SelectBy,
  NewBillState,
} from "../../genbillUtils";

interface Props extends NewBillState {
  handleDropDownChange: (e: SelectChangeEvent<unknown>) => void;
  handleAutoCompleteChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: ClientLocationArrayType | null
  ) => void;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function NewBillSetupForm(props: Props) {
  const {
    state,
    setState,
    handleAutoCompleteChange,
    handleDropDownChange,
    handleTextChange,
  } = props;
  const [inputValue, setInputValue] = React.useState("");

  return (
    <Grid container spacing={1}>
      <Grid
        md={12}
        item
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography align="left" fontWeight={"bold"}>
          Setup the Bill By
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={state.selectBy}
            onChange={(e) => {
              setState({
                ...state,
                selectBy: e.target.value as SelectBy,
                ...((e.target.value as SelectBy) === "GROUP" && {
                  billTitle: "",
                  clientLocation: null,
                  stateCode: "",
                }),
                ...((e.target.value as SelectBy) === "CLIENTLOCATION" && {
                  groupID: "",
                  billTitle: "",
                }),
              });
            }}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="CLIENTLOCATION"
              control={<Radio />}
              label="Client Location"
            />
            <FormControlLabel value="GROUP" control={<Radio />} label="Group" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {state.selectBy === "CLIENTLOCATION" ? (
        <Grid md={3} item>
          <SelectList
            value={state.stateCode}
            label="State"
            onChange={handleDropDownChange}
            name="stateCode"
            test-id="stateCode"
            required
          >
            {state.clientStates.length > 0
              ? state.clientStates?.map((e, idx) => (
                  <MenuItem value={e.stateCode} key={idx}>
                    {e.name}
                  </MenuItem>
                ))
              : null}
          </SelectList>
        </Grid>
      ) : null}
      <Grid md={5} item>
        {state.selectBy === "GROUP" ? (
          <SelectList
            label="Choose a Group"
            name="groupID"
            id="groupID"
            test-id="groupID"
            required
            value={state.groupID}
            onChange={handleDropDownChange}
          >
            {state.clientGroups.length > 0
              ? state.clientGroups?.map((e: any, idx: any) => (
                  <MenuItem value={e.clientGroupID} key={idx}>
                    {e.clientGroupUniqueName}
                  </MenuItem>
                ))
              : null}
          </SelectList>
        ) : (
          <Autocomplete
            disablePortal
            id="userClientLocations"
            test-id="userClientLocations"
            size="small"
            disabled={state.stateCode === ""}
            options={state.clientLocationArray}
            value={state.clientLocation}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) =>
              setInputValue(newInputValue)
            }
            isOptionEqualToValue={(option, value) =>
              option.clientLocationUniqueName === value.clientLocationUniqueName
            }
            getOptionLabel={(option: ClientLocationArrayType) =>
              option.clientLocationUniqueName!
            }
            onChange={handleAutoCompleteChange}
            renderInput={(params) => (
              <TextInput
                {...params}
                label="Client Locations"
                required
                placeholder=""
              />
            )}
          />
        )}
      </Grid>
      <Grid md={3} item>
        <TextInput
          value={state.billTitle}
          onChange={handleTextChange}
          name="billTitle"
          label="Bill Title"
          required
        />
      </Grid>
    </Grid>
  );
}

export default NewBillSetupForm;
