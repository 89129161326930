import React, { useState } from "react";
import { Grid, Skeleton, Autocomplete } from "@mui/material";
import {
  CustomSnackbar,
  handleErrorResponse,
  handleSuccessResponse,
  ModalErrors,
  SelectList,
  showInvalidSnackbar,
  SummarySkeleton,
  TextInput,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import Button from "@mui/lab/LoadingButton";

import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import {} from "../../";
import { useSelector } from "react-redux";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import axios from "axios";

interface User {
  userID: string;
  userLoginID: string;
  firstName: string;
  lastName: string;
}

interface UpdateUser {
  billId: string;
  billOwnerId: string;
}

interface Props {
  closeDrawerHandler: () => void;
}

function ChangeBillOwnerDrawerForm({ closeDrawerHandler }: Props) {
  const [modalErrors, setModalErrors] = useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState("");
  const { enqueueSnackbar } = useRibbonSnackbar();
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);

  function getUsers() {
    setLoading(true);
    const getAllUsers = brokerBillingAxios.get(
      "/Bills/GetBillOwnerInternalUsers"
    );
    const getCurrentBillOwner = brokerBillingAxios.get(
      `/Bills/GetBillOwner/BillId/${billID}`
    );

    axios
      .all([getAllUsers, getCurrentBillOwner])
      .then(
        axios.spread((...res) => {
          setLoading(false);
          const { data: incomingUsers } = res[0] || {};
          const { data: incomingBillOwner } = res[1] || {};

          if (incomingUsers && incomingUsers.length) {
            setUsers(incomingUsers);
            if (incomingBillOwner && incomingBillOwner.length) {
              const foundUser = incomingUsers.find(
                (user: User) => user.userID === incomingBillOwner[0].billOwnerId
              );
              setSelectedUser(foundUser || null);
            } else setSelectedUser(null);
          } else {
            setUsers([]);
            setSelectedUser(null);
          }
        })
      )
      .catch((e) => {
        setLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function handleUpdate() {
    if (!selectedUser) {
      showInvalidSnackbar(null, enqueueSnackbar, "Please select a user!");
      return;
    }
    setUpdateLoading(true);

    const UpdateUser: UpdateUser = {
      billId: billID!,
      billOwnerId: selectedUser.userID,
    };

    brokerBillingAxios
      .post(`/Bills/UpdateBillOwner`, UpdateUser)
      .then((res) => {
        setUpdateLoading(false);
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          closeDrawerHandler();
        } else {
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e) => {
        setUpdateLoading(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: User | null
  ) => {
    setSelectedUser(value);
  };

  React.useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid md={12} item>
        <SummarySkeleton condition={loading}>
          <Autocomplete
            id="asynchronous-demo"
            test-id="autocomplete"
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.userID === value.userID
            }
            value={selectedUser}
            getOptionLabel={(option: User) => {
              const { firstName, lastName, userLoginID } = option || {};
              return `${firstName} ${lastName} - ${userLoginID}`;
            }}
            options={users}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={handleAutoCompleteChange}
            renderInput={(params) => (
              <TextInput {...params} size="small" label="Users" required />
            )}
          />
        </SummarySkeleton>
      </Grid>
      <Grid md={12} display={"flex"} justifyContent={"flex-end"} item>
        <Button
          variant="contained"
          disabled={loading}
          loading={updateLoading}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChangeBillOwnerDrawerForm;
