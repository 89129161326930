import React from "react";
import { DelegatedProps } from "../utils";
import LossesByClassCodeGrid from "./LossesByClassCode";
import LossesByRiskStateGrid from "./LossesByRiskGrid";

function LossGridSelector({ request, dispatch }: DelegatedProps) {
  return request.lossGridSelectorState === "RiskState" ? (
    <LossesByRiskStateGrid request={request} dispatch={dispatch} />
  ) : (
    <LossesByClassCodeGrid request={request} dispatch={dispatch} />
  );
}

export default LossGridSelector;
