import React from "react";
import { Grid, Stack } from "@mui/material";
import { TextInput } from "@genrecp/g2clientportal-common";
import Button from "@mui/lab/LoadingButton";

interface Props {
  handleSubmit: (data?: any) => void;
  loading: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleReset: () => void;
  commentValue: string;
}

function SuspenseComments({
  handleSubmit,
  loading,
  onChange,
  handleReset,
  commentValue,
}: Props) {
  return (
    <Grid container gap={2} mt={1}>
      <Grid md={12} item display={"flex"} direction={"column"} gap={1}>
        <TextInput
          label="Add Comment"
          value={commentValue}
          onChange={onChange}
          multiline
          rows={8}
        />
        <Stack
          display={"flex"}
          gap={1}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          pr={.5}
        >
          <Button
            variant="containedTertiary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
          <Button
            disabled={loading}
            variant="outlinedTertiary"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default SuspenseComments;
