import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { authAxios } from "../../../../services/axios";
import { useRibbonSnackbar, EditChildRenderer, showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { filterColumnsObjCreator } from "@genrecp/g2clientportal-common";
import {
  UserRequestObject,
  ClientLocationArray,
  ApptypeTypeArray,
  AppTypeRoles,
  UserDispatch,
} from "./userUtils";
import axios from "axios";
import {AgGridClasses} from "@genre/g2common-theme"
import { RolesAcessLookUp } from "../adminUtils";
import ResetPasswordDialog from "./PasswordResetModal";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

let gridApi: any;
let columnApi: any;

interface Props {
  request: UserRequestObject;
  refreshToggler: boolean;
  setUpdatetoggler: (v: boolean) => void;
  setHideExternalForSystenAdmin: (v: boolean) => void;
  setIsEmailValid: (v: boolean) => void;
  setEmailDomainValidation: (v: boolean) => void;
  appTypeArray: ApptypeTypeArray[];
  setclientLocationArray: (v: ClientLocationArray[]) => void;

  setAppTypeArray: (v: ApptypeTypeArray[]) => void;
  setappTypeLoadingSpecificCase: (v: boolean) => void;
  setSubmitUpdateCancelHandler: (v: boolean) => void;
  submitUpdateCancelHandler: boolean;
  setTempAppRoleData: (v: AppTypeRoles[]) => void;
  setApiLoading: (v: boolean) => void;
  dispatch: UserDispatch
}

export const UsersGrid: React.FC<Props> = (props) => {
  const {
    setUpdatetoggler,
    setTempAppRoleData,
    setclientLocationArray,
    setAppTypeArray,
    setappTypeLoadingSpecificCase,
    setIsEmailValid,
    setEmailDomainValidation,
    refreshToggler,
    setApiLoading,
    setHideExternalForSystenAdmin,
    dispatch
  } = props;
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));
  const { enqueueSnackbar } = useRibbonSnackbar();
  const allLocations = [
    {
      clientLocationID: "0000",
      clientLocationUniqueName: "All",
    },
  ];
  const gridStyle = useMemo(() => ({ height: "350px", width: "100%" }), []);
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    floatingFilter: true,
  };
  const getexternalClientLocationsAndAppTypes = async (
    clientLocationIdArray: ClientLocationArray[],
    apiData: any,
  ) => {
    try {
      const {
        userID,
        userLoginID,
        firstName,
        lastName,
        emailAddress,
        businessPhone,
        managerID,
        external,
        active,
        createdDate,
        userClientLocations,
        createdBy,
        appTypeRoles,
        isManagedByG2,
      } = apiData || {};
      setappTypeLoadingSpecificCase(true);
      /**************SETTING PARAMS FOR APPTYPES********** */
      const clientLocationParams = userClientLocations.map(
        (item: any) => item.clientLocationID
      );
      const params = {
        userID,
        clientLocationIds: clientLocationParams,
      };
      /****************************************************** */

      /*********************API CALL SETUP********************************* */
      const getCurrentUserClientLocations = authAxios.get("/CurrentUserClientLocations");
      const getUserApps = authAxios.post(`/AppTypesForClientLocation`, params);
      const responses = await axios.all([
        getCurrentUserClientLocations,
        getUserApps,
      ]);
      /****************************************************** */

      const res: any = responses[0];
      const AppTypes: any = responses[1];

      if (res.data && AppTypes.data) {
        /*************GETTING USER CLIENT LOCATIONS SETUP********* */
        setclientLocationArray(res.data);
        // LOOKING UP CLIENT LOCATIONS
        let obj:any = {};
        let userClientLocationsLookup: ClientLocationArray[] = [];
        clientLocationIdArray?.forEach((x: any) => {
          obj = res.data.find((y: any) => {
            return x.clientLocationID === y.clientLocationID;
          });
          if (obj) {
            userClientLocationsLookup.push(obj);
          }
        });

        let temp = userClientLocationsLookup.map((item: any) => ({
          clientLocationID: item.clientLocationID,
          clientLocationUniqueName: item.clientLocationUniqueName,
        }));
        /****************************************************** */

        /*************GETTING APPTYPES SETUP********* */
        setappTypeLoadingSpecificCase(false);

        AppTypes.data.forEach((itemx: any) => {
          itemx.roles.forEach((itemy: any, id: string) => {
            if (itemy.roleID === RolesAcessLookUp.SystemAdministrator) {
              itemx.roles.splice(id, 1);
            }
          });
        });
        let externalCantBeAssignedSystemAdmin = AppTypes.data.filter(
          (item: any) => item.roles.length !== 0
        );
        let permittedValues: ApptypeTypeArray[] =
          externalCantBeAssignedSystemAdmin.map((value: any) => ({
            appTypeID: value.appTypeID,
            appTypeText: value.appTypeText,
          }));
        setAppTypeArray(permittedValues);

        /*************GETTING APPROLETYPE SETUP********* */
        appTypeRoles.forEach((itemx: any) => {
          // x = edit, y= data
          AppTypes.data.forEach((itemy: any) => {
            if (itemx.appTypeID === itemy.appTypeID) {
              itemx.roles.forEach((resx: any) => {
                itemy.roles.forEach((resy: any) => {
                  if (resx.roleID === resy.roleID) {
                    resy["isAssignedToApp"] = true;
                  } //ENDIF
                });
              });
            } // ENDIF
          });
        });
        /****************************************************** */
        setTempAppRoleData(AppTypes.data);
        let selectedApps = AppTypes.data
          .filter((item: any) => item.roles.find((i: any) => i.isAssignedToApp))
          .map((value: any) => ({
            appTypeID: value.appTypeID,
            appTypeText: value.appTypeText,
          }));
        dispatch({
          userID,
          userLoginID,
          firstName,
          lastName,
          emailAddress,
          businessPhone,
          userClientLocations: temp,
          managerID,
          external,
          active,
          createdDate,
          createdBy,
          userAppTypes: selectedApps,
          isManagedByG2,
          appTypeRoles:AppTypes.data
        })

        /****************************************************** */
      }
    } catch (e: any) {
      setappTypeLoadingSpecificCase(false);

      console.log("e", e);
      showInvalidSnackbar(e,enqueueSnackbar);
    }
  };

  const getAllAppsApiCall = async (
    apiData: any,
  ) => {
    try {
      setappTypeLoadingSpecificCase(true);
      const {
        userID,
        userLoginID,
        firstName,
        lastName,
        emailAddress,
        businessPhone,
        managerID,
        external,
        active,
        createdDate,
        createdBy,
        appTypeRoles,
        isManagedByG2,
      } = apiData || {};

      const AppRes = await authAxios.get("/AppTypesWithRoles");

      if (AppRes.data) {
        setappTypeLoadingSpecificCase(false);
        let permittedValues = AppRes.data.map((value: any) => ({
          appTypeID: value.appTypeID,
          appTypeText: value.appTypeText,
        }));
        setAppTypeArray(permittedValues);

        /*************GETTING APPROLETYPE SETUP********* */
        appTypeRoles.forEach((itemx: any) => {
          // x = edit, y= data
          AppRes.data.forEach((itemy: any) => {
            if (itemx.appTypeID === itemy.appTypeID) {
              itemx.roles.forEach((resx: any) => {
                itemy.roles.forEach((resy: any) => {
                  if (resx.roleID === resy.roleID) {
                    resy["isAssignedToApp"] = true;
                  } //ENDIF
                });
              });
            } // ENDIF
          });
        });
        /****************************************************** */

        setTempAppRoleData(AppRes.data);
        let selectedApps = AppRes.data
          .filter((item: any) => item.roles.find((i: any) => i.isAssignedToApp))
          .map((value: any) => ({
            appTypeID: value.appTypeID,
            appTypeText: value.appTypeText,
          }));
          dispatch({
            userID,
            userLoginID,
            firstName,
            lastName,
            emailAddress,
            businessPhone,
            userClientLocations: allLocations,
            managerID,
            external,
            active,
            createdDate,
            createdBy,
            userAppTypes: selectedApps,
            isManagedByG2,
            appTypeRoles:AppRes.data 
          })
      }
    } catch (e: any) {
      setappTypeLoadingSpecificCase(false);

      console.log("e", e);
      showInvalidSnackbar(e,enqueueSnackbar);
    }
  };

  const onRowEditSelected = (params: any) => {
    setIsEmailValid(false);
    setEmailDomainValidation(false);
    setApiLoading(true);
    authAxios
      .get(`/Users/${params.data.userID}`)
      .then((res: any) => {
        const { external, userClientLocations } = res.data;

        if (!external) {
          setHideExternalForSystenAdmin(false);
          getAllAppsApiCall(
            res.data, 
            );
        } else {
          setHideExternalForSystenAdmin(false);
          getexternalClientLocationsAndAppTypes(
            userClientLocations,
            res.data,
          );
        }
        setUpdatetoggler(true);
        setApiLoading(false);
      })
      .catch((e: any) => {
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const [colDefs] = React.useState<ColDef[]>([
    {
      field: "userLoginID",
      colId: "userLoginID",
      headerName: "User Login ID",
      filter: "agTextColumnFilter",
      flex:2,
      tooltipField: "userLoginID",
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "firstName",
      colId: "firstName",
      tooltipField: "firstName",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      flex:1,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "lastName",
      colId: "lastName",
      tooltipField: "lastName",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      flex:1,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "emailAddress",
      colId: "emailAddress",
      tooltipField: "emailAddress",
      headerName: "Email Address",
      filter: "agTextColumnFilter",
      flex:2,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "businessPhone",
      colId: "businessPhone",
      tooltipField: "businessPhone",
      headerName: "Business Phone",
      filter: "agTextColumnFilter",
      flex:1.2,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "external",
      colId: "external",
      headerName: "External User",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
      flex:1.2,
    },
    {
      field: "active",
      colId: "active",
      headerName: "Active",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
      flex:1,
    },
    {
      headerName: "Reset Password",
      cellRenderer:"ResetPasswordDialog",
      flex:1.5,
      hide:isExternalUser
    },
    {
      headerName: "",
      onCellClicked: onRowEditSelected,
      cellRenderer: "EditChildRenderer",
      flex:.5,
    },
    {
      field: "createdDate",
      colId: "createdDate",
      headerName: "createdDate",
      hide: true,
      sort: "desc",
    },
  ]); 
  const ensureNonEmptyString = (str: any) =>
    typeof str === "string" && str.trim() !== "";

  function getSortedColumns(columnApi: any) {
    return columnApi
      ?.getColumnState()
      .filter((cs: any) => ensureNonEmptyString(cs.sort));
  }
  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
        let filterModel = gridApi.getFilterModel();
        let filterCount = Object.keys(gridApi.getFilterModel()).length;
        let sortCount = getSortedColumns(columnApi).length;
        let searchDataObj: any = {
          pageNumber: params.request.endRow / 10,
          pageSize: 10,
        };
        if (filterCount > 0) {
          let gridOpeartionProps = [];

          //userLoginID
          if (filterModel.userLoginID !== undefined) {
            let userLoginIDObj = filterColumnsObjCreator(
              "userLoginID",
              filterModel.userLoginID.filter,
              "string"
            );
            gridOpeartionProps.push(userLoginIDObj);
          }

          //firstName
          if (filterModel.firstName !== undefined) {
            let firstNameObj = filterColumnsObjCreator(
              "firstName",
              filterModel.firstName.filter,
              "string"
            );
            gridOpeartionProps.push(firstNameObj);
          }

          //lastName
          if (filterModel.lastName !== undefined) {
            let lastNameObj = filterColumnsObjCreator(
              "lastName",
              filterModel.lastName.filter,
              "string"
            );
            gridOpeartionProps.push(lastNameObj);
          }

          //emailAddress
          if (filterModel.emailAddress !== undefined) {
            let emailAddressObj = filterColumnsObjCreator(
              "emailAddress",
              filterModel.emailAddress.filter,
              "string"
            );
            gridOpeartionProps.push(emailAddressObj);
          }

          //businessPhone
          if (filterModel.businessPhone !== undefined) {
            let businessPhoneObj = filterColumnsObjCreator(
              "businessPhone",
              filterModel.businessPhone.filter,
              "string"
            );
            gridOpeartionProps.push(businessPhoneObj);
          }

          //external
          if (
            filterModel.external !== undefined &&
            filterModel.external.values.length > 0
          ) {
            let externalObj = filterColumnsObjCreator(
              "external",
              filterModel.external.values,
              "bool"
            );
            gridOpeartionProps.push(externalObj);
          }

          // active
          if (
            filterModel.active !== undefined &&
            filterModel.active.values.length > 0
          ) {
            let activeObj = filterColumnsObjCreator(
              "active",
              filterModel.active.values,
              "bool"
            );
            gridOpeartionProps.push(activeObj);
          }

          if (gridOpeartionProps.length)
            searchDataObj["filterColumns"] = gridOpeartionProps;
        }
        if (sortCount > 0) {
          const sortCol = getSortedColumns(columnApi)[0];
          searchDataObj.orderBy = sortCol.colId + " " + sortCol.sort;
        }

          authAxios
            .post(
              "/GetUsersByAssignedLocations",
              {
                gridOperationsProps: searchDataObj,
              }
            )
            .then((res: any) => {
              var totalRows = -1;
              if (res?.data !== null && res?.data !== undefined) {
                if (res.data.length < 10) {
                  totalRows = params.request.startRow + res.data.length;
                }
                params.successCallback(res.data, totalRows);
              } else {
                params.successCallback([], totalRows);
              }
            })
            .catch((e: any) => {
              params.fail()
              console.log(e.message);
            });
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // gridApi.sizeColumnsToFit();
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };

  const refreshGrid = (gridApi: any) => {
    if (gridApi) {
      var datasource = ServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);
    }
  };

  React.useEffect(() => {
    refreshGrid(gridApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToggler]);

  React.useEffect(() => {
    return () => {
      gridApi && gridApi.destroy();
      gridApi = null
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
    ResetPasswordDialog: ResetPasswordDialog
  };

  return (
    <div style={gridStyle} className={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        pagination={true}
        rowHeight={29}
        headerHeight={32}
        cacheBlockSize={10}
        paginationPageSize={10}
        animateRows={true}
        tooltipMouseTrack={true}
        tooltipShowDelay={0}
        components={frameworkComponents}
        rowModelType={"serverSide"}
        suppressServerSideInfiniteScroll={false}
        domLayout="autoHeight"
      ></AgGridReact>
    </div>
  );
};
