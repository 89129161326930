import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { commonAxios } from "../../../../../services/axios";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
interface DocumentTree {
  folderName: string;
  fileList: string[];
}

interface Props {
  documentName: string;
  reloadState: boolean;
  loading: boolean;
  setLoading: (v: boolean) => void;
  checkedStringItems: string[];
  setCheckedStringItems: (v: string[]) => void;
  checkedFolderStringItems: string[];
  setCheckedFolderStringItems: (v: string[]) => void;
}
export const ViewDocuments = ({
  documentName,
  checkedStringItems,
  setCheckedStringItems,
  reloadState,
  checkedFolderStringItems,
  setCheckedFolderStringItems,
  loading,
  setLoading,
}: Props) => {
  const [pdfShow, SetPdfShow] = React.useState<boolean>(false);
  const [pdfLoading, SetPdfLoading] = React.useState<boolean>(false);
  const [pdfDocument, setPdfDocument] = React.useState<string>("");
  const [pdfTitle, setPdfTitle] = React.useState<string>("");
  const [documentTreeList, setDocumentTreeList] = React.useState<
    DocumentTree[]
  >([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);

  function docTreeView() {
    SetPdfShow(false);
    setLoading(true);
    commonAxios
      .get("Documentation?document=" + encodeURIComponent(documentName))
      .then(function (response: any) {
        const { data } = response || {};
        if (data && data.length > 0) {
          setDocumentTreeList(data);
        } else {
          setDocumentTreeList([]);
        }
        setCheckedFolderStringItems([]);
        setCheckedStringItems([]);
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log(error);
        setLoading(false);
      });
  }

  function pdfViewer(directory: string, pdfTitle: string) {
    SetPdfLoading(true);
    const fullDirectory = documentName + directory;
    commonAxios
      .get(
        "Documentation/Pdfdocument?directory=" +
          encodeURIComponent(fullDirectory) +
          "&filename=" +
          encodeURIComponent(pdfTitle)
      )
      .then(function (response) {
        setPdfDocument(response.data);
        setPdfTitle(pdfTitle);
        SetPdfShow(true);
        SetPdfLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    const togglingNode = nodeIds;
    if (togglingNode.length > 1) togglingNode.pop();
    setExpanded(togglingNode);
  };

  React.useEffect(() => {
    docTreeView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentName, reloadState]);

  return (
    <div>
      <Grid container>
        <Grid item md={4} pr={2}>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{
              height: "auto",
              flexGrow: 1,
              maxWidth: "auto",
              overflowY: "auto",
            }}
            onNodeToggle={handleToggle}
            expanded={expanded}
          >
            {documentTreeList.length > 1
              ? documentTreeList.map((item, index) => {
                  return (
                    <TreeItem
                      key={index}
                      nodeId={`${index + 1}`}
                      label={item.folderName}
                    >
                      {item.fileList.map((fileName, key) => {
                        const folderWithFileName =
                        documentName + "/" + item.folderName + "/" + fileName;
                        return (
                          <Stack
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            key={key}
                          >
                            <Checkbox
                              {...label}
                              sx={{ padding: "0" }}
                              size="small"
                              checked={
                                checkedFolderStringItems.find(
                                  (item) => item === folderWithFileName
                                )
                                  ? true
                                  : false
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean
                              ) => {
                                checked
                                  ? setCheckedFolderStringItems([
                                      ...checkedFolderStringItems,
                                      folderWithFileName,
                                    ])
                                  : setCheckedFolderStringItems(
                                      checkedFolderStringItems.filter(
                                        (prev) => prev !== folderWithFileName
                                      )
                                    );
                              }}
                            />
                            <TreeItem
                              key={key}
                              nodeId={`${key + 10}`}
                              label={fileName}
                              onClick={() =>
                                pdfViewer("/" + item.folderName, fileName)
                              }
                            />
                          </Stack>
                        );
                      })}
                    </TreeItem>
                  );
                })
              : documentTreeList.map((item) => {
                  return item.fileList.map((fileName, key) => {
                    const stringFileName = documentName + "/" + fileName
                    return (
                      <Stack
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        key={key}
                      >
                        <Checkbox
                          {...label}
                          sx={{ padding: "0" }}
                          size="small"
                          checked={
                            checkedStringItems.find((item) => item === stringFileName)
                              ? true
                              : false
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            checked
                              ? setCheckedStringItems([
                                  ...checkedStringItems,
                                  stringFileName,
                                ])
                              : setCheckedStringItems(
                                  checkedStringItems.filter(
                                    (item) => item !== stringFileName
                                  )
                                );
                          }}
                        />
                        <TreeItem
                          key={key}
                          nodeId={`${key + 1}`}
                          label={fileName}
                          sx={{ marginRight: "8px" }}
                          onClick={() => pdfViewer("", fileName)}
                        />
                      </Stack>
                    );
                  });
                })}
          </TreeView>
        </Grid>
        <SimpleBackdrop open={pdfLoading || loading} />
        <Grid item md={8}>
          {pdfShow && !pdfLoading ? (
            <embed
              src={`data:application/pdf;base64,${pdfDocument}`}
              type="application/pdf"
              title={`${pdfTitle}`}
              style={{ width: "100%", height: "85vh" }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
