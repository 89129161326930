import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import BillStatementDrawer from "./BillStatementDrawer";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { Spinner, showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { dateFormatter,currencyFormatter } from "@genrecp/g2clientportal-common";

const SpinnerDiv = styled.div`
  width: -webkit-fill-available;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
`;
const StyledInput = styled.input`
  font-weight: bold;
  text-align: right;
`;

interface Props {
  title: string;
  billID: string;
  accountingGroup: string;
}

interface GSMTotals {
  gsmCurrentTotal: number;
  gsmTotalBilling: number;
  gsmDue31_60: number;
  gsmDue61_90: number;
  gsmDue90: number;
}

interface ClientTotals {
  clientCurrentTotal: number;
  clientTotalBilling: number;
  clientDue31_60: number;
  clientDue61_90: number;
  clientDue90: number;
}

export default function BillStatementSummary({ title, billID,accountingGroup }: Props) {
  const [gsmTotals, setGSMTotals] = React.useState<GSMTotals | null>(null);
  const [clientTotals, setClientTotals] = React.useState<ClientTotals | null>(null);
  const isClientView = useSelector(
    (state: RootState) => state.genbill.isClientView
  );
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const accountingGroupID = useSelector(
    (state: RootState) => state?.genbill.billItemFilters.accountingGroupID
  );
  const clientBillSubmittedDate = useSelector(
    (state: RootState) => state?.genbill.billItemFilters.clientBillSubmittedDate
  );
  const monthEndDate = useSelector(
    (state: RootState) => state?.genbill.billItemFilters.monthEndDate
  );

  const isExternalUser = isExternal && JSON.parse(isExternal.toLowerCase());
  const showSummaryDrawer = useSelector(
    (state: RootState) => state.genbill.showSummaryDrawer
  );
  const [loader, setLoader] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();

  const getSummaryData = () => {
    setLoader(true);
    let endpoint = isExternalUser ? "GCR" : !isClientView ? "GCR" : "CLIENT";
    const params:any = {
      ...(clientBillSubmittedDate ? { clientBillSubmittedDate, clientBillSubmittedDateOperator: "equals" } : {}),
      ...(monthEndDate ? {monthEndDate, monthEndDateOperator: "equals"}:{}),
      ...(accountingGroupID ? {accountingGroupID}:{}),
      ...(billID ? {billID : billID}:{}),
    }

    brokerBillingAxios
      .post(`/BillItems/Summary/${endpoint}`,params)
      .then((response) => {
        setLoader(false);
        setGSMTotals(response.data.gsmTotals);
        setClientTotals(response.data.clientTotals);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  React.useEffect(() => {
    (showSummaryDrawer && monthEndDate)&& getSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSummaryDrawer,monthEndDate]);

  return (
    <BillStatementDrawer title={title} drawerWidth={700}>
      {loader ? (
        <SpinnerDiv>
          <Spinner />
        </SpinnerDiv>
      ) : (
        <Grid container spacing={2} maxWidth="lg">
          <Grid xs={12} sm={12} md={12} item mt={1} mb={2}>
            <Typography variant="h5" component="h1" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"}>
              Statement As Of Date
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={8} item>
            <Typography variant="body1" align="left">
             {dateFormatter(new Date())}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"}>
              Accounting Group
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={8} item>
            <Typography variant="body1" align="left">
              {accountingGroup}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"}>
              Statement Items
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={8} item>
            <Typography variant="body1" align="left">
              {clientBillSubmittedDate ? 
                dateFormatter(new Date(clientBillSubmittedDate))
                : "All"
              }
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            {/* <Typography variant="body1" fontWeight={"bold"} align="right">From GSM</Typography> */}
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"} align="center">
              {isExternalUser || isClientView
                ? "Current Outstanding"
                : "From GSM"}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"} align="center">
              {isExternalUser || isClientView
                ? "Remittance"
                : "Client Statement"}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" align="left">
              Current Total
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item display="flex" justifyContent="end">
            <StyledInput
              value={currencyFormatter(gsmTotals?.gsmCurrentTotal)}
              readOnly={true}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <StyledInput
              value={currencyFormatter(clientTotals?.clientCurrentTotal)}
              readOnly={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" align="left">
              Overdue &gt; 90 days
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item display="flex" justifyContent="end">
            <StyledInput
              value={currencyFormatter(gsmTotals?.gsmDue90)}
              readOnly={true}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <StyledInput
              value={currencyFormatter(clientTotals?.clientDue90)}
              readOnly={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" align="left">
              Overdue &gt; 61-90 days
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item display="flex" justifyContent="end">
            <StyledInput
              value={currencyFormatter(gsmTotals?.gsmDue61_90)}
              readOnly={true}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <StyledInput
              value={currencyFormatter(clientTotals?.clientDue61_90)}
              readOnly={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" align="left">
              Overdue &gt; 31-90 days
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item display="flex" justifyContent="end">
            <StyledInput
              value={currencyFormatter(gsmTotals?.gsmDue31_60)}
              readOnly={true}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <StyledInput
              value={currencyFormatter(clientTotals?.clientDue31_60)}
              readOnly={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} item>
            <Typography variant="body1" fontWeight={"bold"} align="left">
              Total Billing
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={4} item display="flex" justifyContent="end">
            <StyledInput
              value={currencyFormatter(gsmTotals?.gsmTotalBilling)}
              readOnly={true}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} item>
            <StyledInput
              value={currencyFormatter(clientTotals?.clientTotalBilling)}
              readOnly={true}
            />
          </Grid>
        </Grid>
      )}
    </BillStatementDrawer>
  );
}
