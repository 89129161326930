export type ClientLocations = {
  clientLocationID: string;
  statusCode: string;
  businessName: string;
  clientLocationUniqueName: string;
  mailCity: null;
  mailState: string;
  stateName: string;
  clientGroupID: string;
};

export type branchTypes = {
  branchCode: string;
  name: string;
  active: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
};

export type ClientCashDetailsItem = {
  clientInfoID?: string;
  clientLocationID?: string;
  branchCode: string;
  grapCode: string;
  billpointCode: string | null;
  operationsContact?: string;
  g2CompanyCode: string;
  mgAorBroker: string;
  active: boolean;
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  clientName?: string | null;
};

export type Companies = "GSI" | "GIC" | "GSN";
export type MgAorBroker = "M" | "B";

export interface OperationsContact {
  userID: string;
  userLoginID: string;
}

export interface ClientCashDetailsType extends ClientCashDetailsItem {
  updateToggler: boolean;
  clientCashDetailGridItems: ClientCashDetailsItem[];
  gridDataLoading: boolean;
  apiLoading: boolean;
  clientLocationsLoading: boolean;
  billPointsLoading: boolean;
  operationContactLoading: boolean;
  operationContactList: OperationsContact[];
  clientLocations: ClientLocations[];
  selectedClientLocation: string;
  clientLocationDisplayText: string;
  billPoints: string[];
  g2CompanyCode: Companies;
  mgAorBroker: MgAorBroker;
  branches: branchTypes[];
  branchesLoading: boolean;
}

export type ClientCashDetailsProps = {
  state: ClientCashDetailsType;
  setState: (v: ClientCashDetailsType) => void;
};

export function handleReset(setState: any) {
  setState((prev: any) => ({
    ...prev,
    gridDataLoading: false,
    apiLoading: false,
    clientLocationsLoading: false,
    selectedClientLocation: "",
    selectedBillPoints: null,
    billpointCode: "",
    g2CompanyCode: "GSI",
    mgAorBroker: "M",
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    operationsContact: "",
    active: false,
    updateToggler: false,
    billPointsLoading: false,
    operationContactLoading: false,
    branchesLoading: false,
    branchCode: "",
    grapCode: "",
    clientLocationDisplayText: "",
  }));
}

export const grapCodeGenerator = (state: any, setState: any) => {
  let grapCode = "";

  if (state.branchCode) {
    grapCode = grapCode + state.branchCode + "-";
  }
  const companyCode =
    (state.g2CompanyCode as Companies) === "GIC"
      ? "8"
      : (state.g2CompanyCode as Companies) === "GSI"
      ? "4"
      : (state.g2CompanyCode as Companies) === "GSN"
      ? "3"
      : "";
  grapCode = grapCode + companyCode;

  if (state.billpointCode) {
    grapCode =
      grapCode +
      "-" +
      state.billpointCode.slice(0, 4) +
      "-" +
      state.billpointCode.slice(0, 2) +
      "-" +
      state.billpointCode.slice(2, 5);
  }
  setState({
    ...state,
    grapCode,
  });
  return grapCode;
};
