import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { DelegatedClientLocations, DelegatedProps } from "../utils";
import { TextInput } from "@genrecp/g2clientportal-common";
import { IncurredLossesProps } from "../../incurredLosses/utils";
import SummarySkeletons from "./SummarySkeletons";

export default function ClientLocationControl({
  request,
  dispatch,
}: DelegatedProps | IncurredLossesProps) {
  const [clientLocationInputValue, setClientLocationInputValue] =
    React.useState("");

  return (
    <SummarySkeletons
      condition={request.clientLocationsLoading}
    >
      <Autocomplete
        id="userClientLocations"
        test-id="userClientLocations"
        multiple
        limitTags={1}
        size="small"
        options={request.clientLocations}
        value={request.selectedClientLocations}
        inputValue={clientLocationInputValue}
        onInputChange={(event, newInputValue, reason) => {
          setClientLocationInputValue(newInputValue);
        }}
        isOptionEqualToValue={(
          option: DelegatedClientLocations,
          value: DelegatedClientLocations
        ) => option.clientLocationUniqueName === value.clientLocationUniqueName}
        getOptionLabel={(option: DelegatedClientLocations) =>
          option.clientLocationUniqueName!
        }
        disabled={request.firstSubmit}
        onChange={(event, value) => {
          dispatch({
            type: "UPDATE_UNIVERSAL_REQUEST",
            request: {
              ...request,
              selectedClientLocations: value,
            },
          });
        }}
        renderInput={(params) => (
          <TextInput
            {...params}
            label={
              !request.selectedClientLocations.length ? 
              "All Client Locations" : 
              "Selected Client Locations"
            }
            required
            placeholder=""
          />
        )}
      />
    </SummarySkeletons>
  );
}
