import React from 'react'
import { DelegatedProps } from '../utils'
import { Autocomplete } from "@mui/material";
import { TextInput } from '@genrecp/g2clientportal-common';

function AccidentYearControl({request,dispatch}:DelegatedProps) {
    const [accidentYearInputValue, setAccidentYearInputValue] = React.useState<string>("");

    return (
        <Autocomplete
          id="accidentYear"
          test-id="accidentYear"
          multiple
          limitTags={1}
          size="small"
          options={request.accidentYears}
          value={request.accidentYear}
          inputValue={accidentYearInputValue}
          onInputChange={(event, newInputValue, reason) => {
            setAccidentYearInputValue(newInputValue);
          }}
          disabled={(request.firstSubmit && request.disableDates)}
          isOptionEqualToValue={(option: string, value: string) =>
            option === value
          }
          getOptionLabel={(option: string) => option!}
          onChange={(event, value) => {
            dispatch({
              type: "UPDATE_UNIVERSAL_REQUEST",
              request: {
                ...request,
                accidentYear: value,
              },
            });
          }}
          renderInput={(params) => (
            <TextInput
              {...params}
              label={
                !request.accidentYear.length
                  ? "All Accident Years"
                  : "Selected Accident Years"
              }
              placeholder=""
            />
          )}
        />
    );
}

export default AccidentYearControl