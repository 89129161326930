import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {
  CashTrackerDetailTabSelector,
  CashTrackerProps,
} from "../../CashTrackerUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export default function CashTrackerDetailSideMenu({
  request,
  dispatch,
}: CashTrackerProps) {
  const isCashTrackerAdmin = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerAdmin
  );
  const isCashTrackerContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerContributer
  );
  const isCashTrackerViewer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerViewer
  );

  const isCashTrackerQAContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQAContributer
  );
  const isCashTrackerQAViewer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQAViewer
  );

  const isCashTrackerQCContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQCContributer
  );
  const isCashTrackerQCViewer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerQCViewer
  );

  const isCashTrackerAdminOrContributerOrViewer =
    isCashTrackerAdmin || isCashTrackerContributer || isCashTrackerViewer;
  const isCashTrackerQAContributerOrViewer =
    isCashTrackerQAContributer || isCashTrackerQAViewer;
  const isCashTrackerQCContributerOrViewer =
    isCashTrackerQCContributer || isCashTrackerQCViewer;

  const DashboardNameMap: { [key: string]: string } = {
    CASH_TRACKER_DETAIL: "Cash Tracker Detail",
    QA_LOG: "QA Log",
    QC_LOG: "QC Log",
    CORRECTIONS: "Corrections",
    SUSPENSE_GRID: "Suspense Records",
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: CashTrackerDetailTabSelector
  ) => {
    dispatch({
      cashTrackerDetailTab: value,
      screenName: DashboardNameMap[value] || "",
    });
  };

  type SideNavArrayType = {
    name: string;
    value: CashTrackerDetailTabSelector;
  };

  const sideNavArray: SideNavArrayType[] =
    isCashTrackerAdminOrContributerOrViewer
      ? [
          { name: "Cash Tracker Details", value: "CASH_TRACKER_DETAIL" },
          { name: "Suspense", value: "SUSPENSE_GRID" },
          ...(isCashTrackerAdminOrContributerOrViewer
            ? [
                {
                  name: "Corrections",
                  value: "CORRECTIONS",
                } as SideNavArrayType,
              ]
            : []),
          ...((isCashTrackerContributer && isCashTrackerQCContributerOrViewer) || isCashTrackerAdmin
            ? [{ name: "QC Log", value: "QC_LOG" } as SideNavArrayType]
            : []),
          ...((isCashTrackerContributer && isCashTrackerQAContributerOrViewer) || isCashTrackerAdmin
            ? [{ name: "QA Log", value: "QA_LOG" } as SideNavArrayType]
            : []),
        ]
      : [];
  
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List
        component="nav"
        subheader={
          <ListItemButton
            sx={{
              background: "#3A5BAA",
              "&:hover": {
                background: "#002B73",
              },
              minHeight: 30,
            }}
          ></ListItemButton>
        }
      >
        {sideNavArray.map((item, idx) => (
          <ListItemButton
            key={idx}
            selected={request.cashTrackerDetailTab === item.value}
            onClick={(event) => handleListItemClick(event, item.value)}
          >
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </Box>
  );
}
