import React from 'react'
import { CashTrackerProps } from '../../CashTrackerUtils'
import SuspenseFormWrapper from './SuspenseFormWrapper';
import SuspenseLog from './suspenseLog/SuspenseLog';

interface Props extends CashTrackerProps {}

function SuspenseDetailInfoSectionSelector({ request, dispatch }: Props) {
 switch (request.suspenseDetailScreen) {
    case "SUSPENSE_DETAIL":
        return <SuspenseFormWrapper request={request} dispatch={dispatch} />
        case "SUSPENSE_LOG":
            return <SuspenseLog request={request} dispatch={dispatch} />
    default:
        return null;
 }
}

export default SuspenseDetailInfoSectionSelector