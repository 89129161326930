import React from "react";
import { IncurredLossesProps } from "./utils";
import { Grid, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import Button from "@mui/lab/LoadingButton";
import ClientLocationControl from "../experienceReport/experienceReportComponents/ClientLocationControl";
import { SelectList } from "@genrecp/g2clientportal-common";
import IncurredLossesReportGrid from "./IncurredLossesReportGrid";
import RiskStateControl from "../experienceReport/experienceReportComponents/RiskStateControl";
import { lineOfBusiness } from "../experienceReport/utils";
import BillPointControl from "../experienceReport/experienceReportComponents/BillPointControl";
import ExpReportInfo from "../experienceReport/experienceReportComponents/ExpReportInfo";

interface OthersProps extends IncurredLossesProps {
  runReport: () => void;
  reset: () => void;
}

function IncurredLossesForm({
  request,
  dispatch,
  runReport,
  reset,
}: OthersProps) {
  function handleDropDownChange(event: SelectChangeEvent<unknown>) {
    const { name, value } = event.target;
    const val = value as string;
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, [name]: val },
    });
  }
  return (
    <Grid
      container
      spacing={1}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      <Grid
        md={12}
        sm={12}
        xs={12}
        item
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography variant="displaySmall">
          Incurred Losses Report - Current Calendar Year
        </Typography>
        <ExpReportInfo />
      </Grid>
      <Grid md={12} sm={12} xs={12} item display={"flex"}>
        <Grid md={5} sm={12} xs={12} item>
          <ClientLocationControl request={request} dispatch={dispatch} />
        </Grid>
        <Grid
          md={2}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          flexDirection={"column"}
          item
          pl={1}
        >
          <SelectList
            label="Line Of Buisness"
            name="lineOfBusiness"
            id="lineOfBusiness"
            test-id="lineOfBusiness"
            value={request.lineOfBusiness}
            onChange={handleDropDownChange}
            allowempty={false}
          >
            {lineOfBusiness.map((item, idx) => {
              return (
                <MenuItem value={item.value} key={idx}>
                  {item.name}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>
        <Grid md={2} sm={12} xs={12} item pl={1}>
          <BillPointControl
            request={request}
            dispatch={dispatch}
            screen="INCURRED"
          />
        </Grid>
      </Grid>
        <Grid md={3} sm={12} xs={12} item pl={1}>
          <RiskStateControl request={request} dispatch={dispatch} />
        </Grid>
        <Grid
          md={3}
          sm={12}
          xs={12}
          item
          display={"flex"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <Button
            variant="containedTertiary"
            loading={request.runReportLoader}
            onClick={runReport}
            disabled={
              request.clientLocationsLoading || request.billPointsLoading
            }
          >
            Run Report
          </Button>
          <Button
            variant="outlinedTertiary"
            onClick={reset}
            disabled={
              request.clientLocationsLoading ||
              request.billPointsLoading ||
              request.runReportLoader
            }
          >
            Reset
          </Button>
      </Grid>

      <Grid md={12} sm={12} xs={12} item>
        <IncurredLossesReportGrid request={request} dispatch={dispatch} />
      </Grid>
    </Grid>
  );
}

export default IncurredLossesForm;
