import React from "react";
import { CashTrackerProps, SuspenseTypeIDType } from "../CashTrackerUtils";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { delegatedAxios } from "../../../../services/axios";
import { CellClassParams } from "ag-grid-community";
import {
  comparatorForDate,
  currencyFormatter,
  CustomSnackbar,
  DetailSpan,
  formatDateForGrid,
  handleErrorResponse,
  ModalErrors,
  negativeValueColorFormatter,
  showInvalidSnackbar,
  useRibbonSnackbar,
  ViewSchema,
} from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import { SuspenseGridType } from "./suspenseGridUtils";
import { ICellRendererParams } from "ag-grid-community";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CashTrackerStatusFilterType } from "../cashTracker/cashTrackerFormUtils";
import { SetFilterValuesFuncParams } from "ag-grid-community";

interface Props extends CashTrackerProps {}

function SuspenseGrid({ request, dispatch }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const [cashTrackerStatusList, setCashTrackerStatusList] = React.useState<
    CashTrackerStatusFilterType[]
  >([]);
  const cashTrackerStatusListRef =
    React.useRef<CashTrackerStatusFilterType[]>();

  cashTrackerStatusListRef.current = cashTrackerStatusList;

  const { enqueueSnackbar } = useRibbonSnackbar();

  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const isCashTrackerAdmin = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerAdmin
  );
  const isCashTrackerContributer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerContributer
  );
  const isCashTrackerViewer = useSelector(
    (state: RootState) => state?.cashTracker?.cashTrackerViewer
  );

  const isCashTrackerAdminOrContributerOrViewer =
    isCashTrackerAdmin || isCashTrackerContributer || isCashTrackerViewer;

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;

    if (newObj.cashTrackerStatus) {
      const tempStatus = obj.cashTrackerStatus
        .split(",")
        .map((item: string) => cashTrackerStatusParser(item.trim()))
        .filter((item: number | undefined) => item !== undefined);
      obj.cashTrackerStatus = tempStatus.join();
    }

    return delegatedAxios
      .post(`/CashTrackers/GetCashTrackerSuspense`, obj)
      .then((data: any) => {
        return data.data;
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        colId: "checkNumber",
        field: "checkNumber",
        headerName: "Check Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        cellRenderer: function (params: ICellRendererParams<SuspenseGridType>) {
          const {
            additionalInfo,
            endorsementNumber,
            grossPremium,
            insuredName,
            policyNumber,
            suspendedAmount,
            suspenseID,
            suspenseReasonTypeID,
            suspenseTypeID,
            createdBy,
            createdDate,
            modifiedBy,
            modifiedDate,
            checkNumber,
            cashTrackerID,
            checkDate,
            lockBoxDate,
            billpointCode,
            branchName,
            g2CompanyCode,
            client,
            cashTrackerStatus
          } = params.data || {};
          const company =
            g2CompanyCode === "GSI"
              ? "General Star"
              : g2CompanyCode === "GIC"
              ? "Genesis"
              : "";
          return isCashTrackerAdminOrContributerOrViewer ? (
            <DetailSpan
              onClick={() => {
                dispatch({
                  cashTrackerMasterFields: {
                    ...request.cashTrackerMasterFields,
                    billpoint: billpointCode || "",
                    branchName: branchName || "",
                    cashTrackerID: cashTrackerID || "",
                    checkDate: checkDate || "",
                    checkNumber: checkNumber || "",
                    companyName: company || "",
                    lockBoxDate: lockBoxDate || "",
                    suspenseID: suspenseID || "",
                    client:client || "",
                    suspenseStatus: cashTrackerStatus || ""
                  },
                  suspenseForm: {
                    ...request.suspenseForm,
                    addInfo: additionalInfo || "",
                    endtNumber: endorsementNumber
                      ? endorsementNumber.toString()
                      : "",
                    grossPremium: grossPremium ? grossPremium.toString() : "",
                    insuredName: insuredName || "",
                    policyNumber: policyNumber || "",
                    suspendedAmount: suspendedAmount
                      ? suspendedAmount.toString()
                      : "",
                    suspenseReason: suspenseReasonTypeID
                      ? suspenseReasonTypeID.toString()
                      : "",
                    suspenseTypeID: suspenseTypeID as SuspenseTypeIDType,
                    suspenseID,
                    createdBy,
                    createdDate,
                    modifiedBy,
                    modifiedDate,
                  },
                  cashTrackerScreenSelector: "SUSPENSE_DETAIL",
                  screenName: "Suspense Detail",
                  suspenseLogScreen:"COMMENTS"
                });
              }}
            >
              {checkNumber}
            </DetailSpan>
          ) : (
            checkNumber
          );
        },
      },
      {
        colId: "billpointCode",
        field: "billpointCode",
        headerName: "Bill point",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "cashTrackerStatus",
        field: "cashTrackerStatus",
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: true,
          defaultToNothingSelected: true,
          filterOptions: ["equals"],
          values: async (params: SetFilterValuesFuncParams) => {
            let values = await getStatusFilters();
            let cashIssueStatusTypeTextList: string[] = values.map(
              (i: CashTrackerStatusFilterType) => i.cashIssueStatusTypeText
            );
            params.success(cashIssueStatusTypeTextList);
          },
          suppressAndOrCondition: true,
        },
        headerName: "Status",
        width: 320,
      },
      {
        colId: "policyNumber",
        field: "policyNumber",
        headerName: "Policy Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "client",
        field: "client",
        headerName: "Client Name",
        width: 260,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "clientComment",
        field: "clientComment",
        tooltipField: "clientComment",
        width: 320,
        headerName: "Client Comments",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "insuredName",
        field: "insuredName",
        headerName: "Insured Name",
        width: 260,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "operationsContact",
        field: "operationsContact",
        headerName: "Operations Contact",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "totalCheckAmount",
        headerName: "Check Amount",
        field: "totalCheckAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.totalCheckAmount),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.totalCheckAmount, true)}`;
        },
      },
      {
        colId: "suspendedAmount",
        headerName: "Suspended Amount",
        field: "suspendedAmount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.suspendedAmount),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.suspendedAmount, true)}`;
        },
      },
      {
        colId: "grossPremium",
        headerName: "Gross Premium Amount",
        field: "grossPremium",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params: CellClassParams) =>
          negativeValueColorFormatter(params.data?.grossPremium),
        valueFormatter: function (params: any) {
          return `${currencyFormatter(params?.data?.grossPremium, true)}`;
        },
      },
      {
        field: "checkDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.checkDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        field: "lockBoxDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.lockBoxDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "createdName",
        field: "createdName",
        headerName: "Created By",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "createdDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "modifiedName",
        field: "modifiedName",
        headerName: "Last Updated By",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "modifiedDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.modifiedDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
    ];
  }

  async function getStatusFilters() {
    return delegatedAxios
      .get("/CashIssuesStatus")
      .then((res: any) => {
        const { data } = res || {};
        let finalMappedData: CashTrackerStatusFilterType[] = [];

        if (data && data?.length) {
          finalMappedData = data.filter(
            (x: CashTrackerStatusFilterType) => x.active
          );
          setCashTrackerStatusList(() => finalMappedData);

          return finalMappedData;
        } else {
          setCashTrackerStatusList([]);

          return [];
        }
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  }

  const cashTrackerStatusParser = (payload: string) => {
    if (
      !cashTrackerStatusListRef.current ||
      !cashTrackerStatusListRef.current.length
    ) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    const finalCashTrackerStatus: number | undefined =
      cashTrackerStatusListRef.current.find(
        (status) => status.cashIssueStatusTypeText.trim() === payload
      )?.cashIssueStatusTypeID || undefined;

    return finalCashTrackerStatus;
  };

  return (
    <Grid md={12} item>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <AggridWrapper
        gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        getColumnDefs={getColumnDefs}
        getGridRowsData={getGridRowsData}
        dashboardName={""}
        enableUserViews={false}
        enableSystemViews={false}
        landingPage={"Suspense"}
        views={false}
        currentUser={{
          userName: createdBy && createdBy.id ? createdBy.id : "",
          email: "",
          fullName: "",
        }}
        gridProps={{
          tooltipMouseTrack: true,
          tooltipShowDelay: 0,
        }}
        hideHeader
        paginationPageSize={50}
        rowHeight={29}
        headerHeight={32}
        gridHeight={window.innerHeight - 190}
        showSidebar
      ></AggridWrapper>
    </Grid>
  );
}

export default SuspenseGrid;
