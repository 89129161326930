import { CashTrackerProps } from "../../CashTrackerUtils";
import CashTracker from "../../cashTracker/CashTrackerFormWrapper";
import Corrections from "../corrections/Corrections";
import QAWrapper from "../qaqcLogs/qa/QAWrapper";
import QCWrapper from "../qaqcLogs/qc/QCWrapper";
import SuspenseDetailWrapper from "../suspense/SuspenseDetailWrapper";
import SuspenseFormGrid from "../suspense/SuspenseFormGrid";
import SuspenseFormWrapper from "../suspense/SuspenseFormWrapper";

interface Props extends CashTrackerProps {}

function CashTrackerDetailsInfoSelector({ request, dispatch }: Props) {
  switch (request.cashTrackerDetailTab) {
    case "CASH_TRACKER_DETAIL":
      return (
        <CashTracker
          request={request}
          dispatch={dispatch}
          updateCashTrackerForm={true}
        />
      );
    case "SUSPENSE_GRID":
      return <SuspenseFormGrid request={request} dispatch={dispatch} />;
    case "ADD_SUSPENSE":
      return (
        <SuspenseFormWrapper
          request={request}
          dispatch={dispatch}
        />
      );
    case "SUSPENSE_DETAIL":
      return (
        <SuspenseDetailWrapper
          request={request}
          dispatch={dispatch}
        />
      );
      case "CORRECTIONS":
        return (
          <Corrections
            request={request}
            dispatch={dispatch}
          />
        );
        case "QA_LOG":
          return (
            <QAWrapper
              request={request}
              dispatch={dispatch}
            />
          );
          case "QC_LOG":
          return (
            <QCWrapper
              request={request}
              dispatch={dispatch}
            />
          );
    default:
      return null;
  }
}

export default CashTrackerDetailsInfoSelector;
