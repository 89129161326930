import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridReadyEvent,
  CellEditingStoppedEvent,
  CellClickedEvent,
  ICellRendererParams,
} from "ag-grid-community";
import {
  CustomSnackbar,
  DeleteChildRenderer,
  formatDateForGrid,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import Grid from "@mui/material/Grid";
import { AgGridClasses } from "@genre/g2common-theme";
import { CashLogProps, FileObject } from "./cashLogUtils";
import { delegatedAxios } from "../../../services/axios";
import { DetailSpan } from "@genrecp/g2clientportal-genbill";

interface Props extends CashLogProps {
  setLoader: any;
  getData: (id: string | undefined) => void;
}
type FileType = { name: string; base64: string };

function AddCashLogGrid({ request, dispatch, setLoader, getData }: Props) {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [file, setFile] = useState<FileType>({
    name: "",
    base64: "",
  });
  const linkRef = useRef<any>();

  useEffect(() => {
    if (file.name === "") return;
    linkRef.current.click();
  }, [file]);

  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "name",
      headerName: "File Name",
      colId: "name",
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        const { name, documentURL, cashLogID } = params.data || {};
        return documentURL ? (
          <DetailSpan onClick={downloadFile.bind(null, cashLogID, documentURL)}>
            {documentURL}
          </DetailSpan>
        ) : (
          name
        );
      },
    },
    {
      field: "createdDate",
      colId: "createdDate",
      flex: 1,
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.createdDate);
      },
    },
    {
      headerName: "",
      onCellClicked: onDelete,
      cellRenderer: "DeleteChildRenderer",
      flex: 0.1,
    },
  ]);
  const frameworkComponents = {
    DeleteChildRenderer: DeleteChildRenderer,
  };

  function downloadFile(cashLogID: string, documentURL: string) {
    setFile(() => ({
      base64: "",
      name: "",
    }));
    setLoader(() => true);
    delegatedAxios
      .get(
        `CashLogs/GetDocument?directory=${cashLogID}&filename=${documentURL}`
      )
      .then((res: any) => {
        const { status, data } = res || {};
        setLoader(() => false);
        if (status === 200) {
          setFile(() => ({
            base64: data,
            name: documentURL,
          }));
        }
      })
      .catch((e: any) => {
        setLoader(() => false);
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function onDelete(evt: CellClickedEvent<FileObject, any>) {
    const { data } = evt;
    if (!data) return;
    if (!data.cashLogDocumentID) {
      const updatedFiles = [...request.files];

      const filtered = updatedFiles.filter((file: any) => file !== data);
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          files: filtered as FileObject[],
        },
      });
      return;
    }
    setLoader(() => true);
    delegatedAxios
      .delete(`/CashLogs/DeleteDocument/${data.cashLogDocumentID}`)
      .then((res: any) => {
        const { status } = res || {};
        setLoader(() => false);

        if (status === 200) {
          getData(undefined);
        }
      })
      .catch((e: any) => {
        setLoader(() => false);
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const updatedFiles = [...request.files];
    updatedFiles[event.node.rowIndex as number] = event.value;

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        files: updatedFiles as any,
      },
    });
  };

  const defaultColDef: ColDef = {
    cellStyle: { whiteSpace: "normal" },
    sortable: false,
    resizable: false,
    floatingFilter: false,
    cellClass: "cell-wrap-text",
    flex: 1,
  };

  return (
    <Grid container spacing={2}>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <a
        download={file.name}
        href={`data:application/zip;base64,${file.base64}`}
        style={{ display: "none" }}
        ref={linkRef}
      >
        Download
      </a>
      <Grid md={12} item>
        <div
          style={{ height: window.innerHeight - 350 }}
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onCellEditingStopped={onCellEditingStopped}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            rowData={request.files}
            suppressRowClickSelection
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowHeight={29}
            headerHeight={32}
            components={frameworkComponents}
          ></AgGridReact>
        </div>
      </Grid>
    </Grid>
  );
}

export default AddCashLogGrid;
