import React from "react";
import { DelegatedIssuesProps } from "../delegatedIssuesAdminUtils";
import { Grid, Grow } from "@mui/material";
import DelegatedIssueTitle from "../delegatedIssuesComponents/DelegatedIssueTitle";
import SuspenseReasonGrid from "./SuspenseReasonGrid";
import SuspenseReasonForm from "./SuspenseReasonForm";
import {
  SuspenseReasonGridItem,
  SuspenseReasonType,
} from "./suspenseReasonUtils";
import { delegatedAxios } from "../../../../../services/axios";
import {
  handleSuccessResponse,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

function SuspenseReason({ request, dispatch }: DelegatedIssuesProps) {
  const [state, setState] = React.useState<SuspenseReasonType>({
    suspenseReasonTypeText: "",
    active: false,
    updateToggler: false,
    suspenseReasonGridItems: [],
    gridDataLoading: false,
    apiLoading: false,
    showToClient: false
  });
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  React.useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getGridData() {
    setState((prev) => ({
      ...prev,
      gridDataLoading: true,
    }));
    delegatedAxios
      .get("/SuspenseReason")
      .then((res) => {
        const { data } = res || {};
        setState((prev) => ({
          ...prev,
          suspenseReasonGridItems: data || [],
          gridDataLoading: false,
        }));
      })
      .catch((e: any) => {
        showInvalidSnackbar(e, enqueueSnackbar);
        setState((prev) => ({
          ...prev,
          gridDataLoading: false,
        }));
      });
  }

  function suspenseReasonCheck() {
    if (!state.suspenseReasonTypeText) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Suspense Reason is required!"
      );
      return false;
    }
    return true;
  }

  function suspenseReasonIDCheck() {
    if (!state.suspenseReasonTypeID) {
      showInvalidSnackbar(null, enqueueSnackbar, "ID not Found!");
      return false;
    }
    return true;
  }

  function handleUpdate() {
    const textCheck = suspenseReasonCheck();
    if (!textCheck) {
      return;
    }
    const idCheck = suspenseReasonIDCheck();
    if (!idCheck) {
      return;
    }

    const payload: SuspenseReasonGridItem = {
      active: state.active,
      suspenseReasonTypeText: state.suspenseReasonTypeText,
      modifiedBy: createdBy!.id,
      modifiedDate: new Date().toISOString(),
      createdBy: state.createdBy,
      createdDate: state.createdDate,
      suspenseReasonTypeID: state.suspenseReasonTypeID,
      showToClient: state.showToClient
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .put(`/SuspenseReason/Update/${state.suspenseReasonTypeID}`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleSubmit() {
    const check = suspenseReasonCheck();
    if (!check) {
      return;
    }

    const payload: SuspenseReasonGridItem = {
      active: state.active,
      suspenseReasonTypeText: state.suspenseReasonTypeText,
      createdBy: createdBy!.id,
      createdDate: new Date().toISOString(),
      modifiedBy: null,
      modifiedDate: null,
      showToClient: state.showToClient
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .post(`/SuspenseReason/Create`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleReset() {
    setState((prev) => ({
      ...prev,
      active: false,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      suspenseReasonTypeID: null,
      suspenseReasonTypeText: "",
      updateToggler: false,
      apiLoading: false,
      showToClient: false
    }));
  }

  return (
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={12} md={12} item>
          <DelegatedIssueTitle request={request} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <SuspenseReasonForm
            state={state}
            setState={setState}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <SuspenseReasonGrid state={state} setState={setState} />
        </Grid>
      </Grid>
    </Grow>
  );
}

export default SuspenseReason;
