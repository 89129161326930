import InfoSectionSelector from "../home/InfoSectionSelector";
import RoutableTabs from "../home/RoutableTabs";
import HeaderFooter from "./HeaderFooter";

function SubHome() {
 
  return (
    <>
      <RoutableTabs />
      <HeaderFooter>
          <InfoSectionSelector />
      </HeaderFooter>
    </>
  );
}

export default SubHome;
