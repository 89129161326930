export type CashTrackerScreenSelector =
  | "CASH_TRACKER_GRID"
  | "SUSPENSE"
  | "CASH_TRACKER_DETAIL"
  | "SUSPENSE_DETAIL"
  | "ADD_CASH_TRACKER";
export type CashTrackerDetailTabSelector =
  | "CASH_TRACKER_DETAIL"
  | "QC_LOG"
  | "QA_LOG"
  | "ADD_SUSPENSE"
  | "SUSPENSE_DETAIL"
  | "SUSPENSE_GRID"
  | "CORRECTIONS"
  | "";

type CashTrackerMasterFields = {
  mgAorBroker: string;
  cashTrackerID: string;
  suspenseID: string;
  checkNumber: string;
  checkDate: string;
  lockBoxDate: string;
  branchName: string;
  billpoint: string;
  companyName: string;
  client:string;
  cashTrackerStatus: string;
  suspenseStatus?: string;
  cashTrackerStatusID?: number
};
export type CashTrackerLoaderType =
  | "CLIENT_NAME"
  | "CLIENT_INFO"
  | "SUBMITTING"
  | "";

interface ClientNames {
  clientInfoID: string;
  clientName: string;
}

export interface CashTrackerStatus {
  cashIssueStatusTypeID: number;
  cashIssueStatusTypeText: string;
}

export interface Client {
  clientInfoID: string;
  clientName: string;
}

export interface CashTrackerFormStateType {
  billpointCode: string;
  g2CompanyCode: string;
  mgAorBroker: string;
  clientNames: ClientNames[];
  clientInfo: Client | null;
  checkNumber: string;
  checkAmount: string;
  branchCode: string;
  bsdContact: string;
  grap: string;
  cashTrackerStatuses: CashTrackerStatus[];
  cashTrackerStatus: string;
  checkDate: string | null;
  lockBoxDate: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  modifiedBy?: string | null;
  modifiedDate?: string | null;
  checkNumberValidation: boolean;
  checkAmountValidation: boolean;
}
export interface SuspenseReasonsType {
  suspenseReasonTypeID: number;
  suspenseReasonTypeText: string;
  showToClient: boolean;
  active: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: null;
  modifiedBy: null;
}
export interface SuspenseFormGridType {
  suspenseID: string;
  cashTrackerID: string;
  cashTrackerStatus: string;
  policyNumber: string;
  insuredName: string;
  grossPremium: number;
  suspendedAmount: number;
  suspenseReasonTypeID: number;
  suspenseTypeID: number;
  endorsementNumber: number;
  additionalInfo: string;
  createdDate: string;
  createdBy: string;
  modifiedDate: null;
  modifiedBy: null;
}

interface SuspenseTypeListType {
  name: "Policy" | "Endorsement";
  value: 2 | 1;
}

export const suspenseTypeList: SuspenseTypeListType[] = [
  {
    name: "Policy",
    value: 2,
  },
  {
    name: "Endorsement",
    value: 1,
  },
];

export type SuspenseTypeIDType = 1 | 2;
export interface SuspenseStateType {
  policyNumber: string;
  insuredName: string;
  grossPremium: string;
  suspendedAmount: string;
  suspenseReasons: SuspenseReasonsType[];
  suspenseReasonsLoading: boolean;
  suspenseReason: string;
  endtNumber: string;
  addInfo: string;
  suspenseTypeID: SuspenseTypeIDType;
  suspenseID?: string;
  createdBy?: string | null;
  createdDate?: string | null;
  modifiedBy?: string | null;
  modifiedDate?: string | null;
}
export interface ErrorReasons {
  qaErrorReasonTypeID: number;
  qaErrorReasonTypeText: string;
}
export type SuspenseDetailScreen = "SUSPENSE_DETAIL" | "SUSPENSE_LOG";
export interface QAType {
  reloadQA: boolean;
  pass:boolean;
  selectedErrorReasons:ErrorReasons[],
  errorReasonsinputValue: string
}
export interface QCType {
  reloadQC: boolean;
  pass:boolean;
  selectedErrorReasons:ErrorReasons[],
  errorReasonsinputValue: string
}
export interface LogGridData {
  actionlog: string;
  createdByName: string;
  createdDate: string;
}
export type SuspenseNavState = "COMMENTS" | "RESEARCH_LOG" | "RESOLUTION_LOG"
export type CashTrackerStateType = {
  cashTrackerScreenSelector: CashTrackerScreenSelector;
  cashTrackerDetailTab: CashTrackerDetailTabSelector;
  cashTrackerLoader: CashTrackerLoaderType;
  cashTrackerMasterFields: CashTrackerMasterFields;
  cashTrackerForm: CashTrackerFormStateType;
  suspenseForm: SuspenseStateType;
  suspenseDetailScreen: SuspenseDetailScreen;
  suspenseLogScreen: SuspenseNavState;
  logGridData: LogGridData[];
  logGridDataLoader: boolean;
  screenName: string;
  comment: string;
  reloadComments: boolean;
  reloadCorrections: boolean;
  QA: QAType;
  QC: QCType;
};

export type CashTrackerStateAction = Partial<CashTrackerStateType>;

export type CashTrackerStateDispatch = React.Dispatch<CashTrackerStateAction>;

export const initialCashTrackerFormStateType = {
  g2CompanyCode: "",
  mgAorBroker: "",
  billpointCode: "",
  clientNames: [],
  clientInfo: null,
  checkNumber: "",
  branchCode: "",
  bsdContact: "",
  grap: "",
  cashTrackerStatuses: [],
  cashTrackerStatus: "1",
  checkAmount: "",
  checkDate: null,
  lockBoxDate: null,
  checkAmountValidation: false,
  checkNumberValidation: false,
};

export const initialcashTrackerMasterFields:CashTrackerMasterFields = {
  mgAorBroker: "",
  cashTrackerID: "",
  suspenseID: "",
  checkNumber: "",
  checkDate: "",
  lockBoxDate: "",
  branchName: "",
  billpoint: "",
  companyName: "",
  client:"",
  cashTrackerStatus:"1",
  cashTrackerStatusID: 0
};

export const initialSuspenseState: SuspenseStateType = {
  policyNumber: "",
  insuredName: "",
  grossPremium: "",
  suspendedAmount: "",
  suspenseReasons: [],
  suspenseReason: "",
  suspenseReasonsLoading: false,
  endtNumber: "",
  addInfo: "",
  suspenseTypeID: 2,
  suspenseID: undefined,
  createdBy: undefined,
  createdDate: undefined,
  modifiedBy: undefined,
  modifiedDate: undefined,
};

export const initialQAState: QAType = {
  reloadQA: false,
  pass: false,
  selectedErrorReasons: [],
  errorReasonsinputValue:""
};

export const initialQCState: QCType = {
  reloadQC: false,
  pass: false,
  selectedErrorReasons: [],
  errorReasonsinputValue:""
};

export const initialCashTrackerState: CashTrackerStateType = {
  cashTrackerScreenSelector: "CASH_TRACKER_GRID",
  cashTrackerDetailTab: "CASH_TRACKER_DETAIL",
  cashTrackerMasterFields: initialcashTrackerMasterFields,
  cashTrackerForm: initialCashTrackerFormStateType,
  suspenseForm: initialSuspenseState,
  cashTrackerLoader: "",
  screenName: "Cash Tracker",
  suspenseDetailScreen: "SUSPENSE_DETAIL",
  suspenseLogScreen:"COMMENTS",
  logGridData:[],
  logGridDataLoader: false,
  comment: "",
  reloadComments: false,
  reloadCorrections: false,
  QA: initialQAState,
  QC: initialQCState,
};

export interface CashTrackerProps {
  request: CashTrackerStateType;
  dispatch: CashTrackerStateDispatch;
}

export const cashTrackerStateReducer = (
  state: CashTrackerStateType,
  action: CashTrackerStateAction
) => {
  return {
    ...state,
    ...action,
  };
};

export interface CreateCommentType {
  suspenseLogID?: string;
  suspenseID: string;
  suspenseLogTypeID: number;
  comment: string;
  createdDate: string;
  createdBy: string;
}

type ActionMenuItemType = {
  disabled?: boolean;
  text: string;
  onClick: () => void;
};

export type ActionMenuItemTypeMap = {
  [key in CashTrackerScreenSelector]: ActionMenuItemType[]
}

type MgAorBroker = "M" | "B" | "";
type MgAorBrokerListType = {
  name: string;
  value: MgAorBroker;
};
export const MgAorBrokerList: MgAorBrokerListType[] = [
  {
    name: "MGA",
    value: "M",
  },
  {
    name: "BROKER",
    value: "B",
  },
];
