import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  TextInput,
  SelectList,
  SimpleBackdrop,
  Button,
  CustomSnackbar,
  handleSuccessResponse,
  useRibbonSnackbar,
  handleErrorResponse,
  ModalErrors
} from "@genrecp/g2clientportal-common";
import MenuItem from "@mui/material/MenuItem";
import { FormControlLabel, FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Request,
  ClientLocationArrayType,
  SelectBy,
} from "../../genbillUtils";
import { AppDispatch, RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";

interface Props {
  state: Request;
  setState: (v: Request) => void;
  modalErrors: ModalErrors;
  setModalErrors: (v: ModalErrors) => void;
}

function SelectionCriteriaForm(props: Props) {
  const {
    state,
    setState,
    modalErrors,
    setModalErrors,
  } = props;
  const [inputValue, setInputValue] = React.useState("");
  const selectedMenu = useSelector((state:RootState) => state.genbill.selectedMenu);
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const $dispatch = useDispatch<AppDispatch>();

  function stopBilling(){
    if(!billID) return;

    setState({
      ...state,
      loading: true,
    });

    brokerBillingAxios.get(`/Bills/Stop/${billID}`).then(res => {
      setState({
        ...state,
        loading: false,
      });
      handleSuccessResponse(res, enqueueSnackbar);
      $dispatch(
        handleGenbillState({
          type: "HANDLE_GENBILL_NAV",
          payload: { selectedMenu: "HOME" },
        })
      );
    })
    .catch(e => {
      console.log(e);
      setState({
        ...state,
        loading: false,
      });
      const modalErrorMessages = handleErrorResponse(e);
      setModalErrors({
        ...modalErrors,
        errors: modalErrorMessages,
        ErrorModelOpen: true,
      });
    });
  }

  return (
    <>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SimpleBackdrop open={state.loading} />
      <Grid
        md={selectedMenu === "SELECTIONCRITERIA" ? 10 : 12}
        item
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography align="left" fontWeight={"bold"}>
          Setup the Bill By
        </Typography>
        <FormControl disabled={true}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={state.selectBy}
            onChange={(e) =>
              setState({
                ...state,
                selectBy: e.target.value as SelectBy,
              })
            }
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="CLIENTLOCATION"
              control={<Radio />}
              label="Client Location"
            />
            <FormControlLabel value="GROUP" control={<Radio />} label="Group" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {
        selectedMenu === "SELECTIONCRITERIA" &&
        <Grid
        md={2}
        item
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Button variant="containedTertiary" onClick={stopBilling}>Stop Billing</Button>
      </Grid>
      }
      {state.selectBy === "CLIENTLOCATION" ? (
        <Grid md={3} item>
          <SelectList
            value={state.stateCode}
            label="State"
            name="stateCode"
            test-id="stateCode"
            required
            disabled={true}
          >
            {state.clientStates.length > 0
              ? state.clientStates?.map((e, idx) => (
                  <MenuItem value={e.stateCode} key={idx}>
                    {e.name}
                  </MenuItem>
                ))
              : null}
          </SelectList>
        </Grid>
      ) : null}
      <Grid md={5} item>
        {state.selectBy === "GROUP" ? (
          <SelectList
            label="Choose a Group"
            name="groupID"
            id="groupID"
            test-id="groupID"
            required
            value={state.groupID}
            disabled={true}
          >
            {state.clientGroups.length > 0
              ? state.clientGroups?.map((e:any, idx:any) => (
                  <MenuItem value={e.clientGroupID} key={idx}>
                    {e.clientGroupUniqueName}
                  </MenuItem>
                ))
              : null}
          </SelectList>
        ) : (
          <Autocomplete
            id="asynchronous-demo"
            test-id="autocomplete"
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.clientLocationUniqueName === value.clientLocationUniqueName
            }
            value={state.clientLocation}
            getOptionLabel={(option: ClientLocationArrayType) =>
              option.clientLocationUniqueName
            }
            options={state.clientLocationArray}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disabled={true}
            renderInput={(params) => (
              <TextInput
                {...params}
                size="small"
                label="Choose a Client Location"
                required
              />
            )}
          />
        )}
      </Grid>
      <Grid md={3} item>
        <TextInput
          value={state.billTitle}
          name="billTitle"
          label="Bill Title"
          required
          disabled={true}
        />
      </Grid>
      {/* <Grid md={1} display="flex" alignItems={"center"}  item>
        <Button
          type="submit"
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid> */}
    </>
  );
}

export default SelectionCriteriaForm;
