import * as React from "react";
import { Divider } from "@mui/material";
import styled from "styled-components";
import { MuiCustomDrawer } from "@genre/g2common-theme";

const ScrollPanel = styled.div`
  height: calc(100% - 38px);
  width: 100%;
  padding: 0 0 50px 0;
  margin: 0;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: flex-start;
`;
interface ContainerProps {
  containerPadding?: number;
}
const Container = styled.div`
  padding: ${(props: ContainerProps) =>
    props.containerPadding !== undefined && !isNaN(props?.containerPadding)
      ? props.containerPadding
      : 20}px;
`;

interface Props {
  title: string;
  children: JSX.Element;
  drawerWidth: number;
  setOpen: (v: boolean) => void;
  open: boolean;
  purge?: () => void;
  onClose?: () => void;
  containerPadding?: number;
  drawerPadding?:number
}

const CustomDrawer: React.FC<Props> = ({
  title,
  children,
  drawerWidth,
  setOpen,
  open,
  purge,
  onClose,
  containerPadding = 20,
  drawerPadding = 20, 
}: Props) => {
  const closeDrawer = () => {
    onClose && onClose();
    setOpen(false);
    purge && typeof purge === "function" && purge();
  };

  return (
    <MuiCustomDrawer
      open={open}
      anchor={"right"}
      drawerWidth={drawerWidth}
      title={title}
      onClose={closeDrawer}
      justifyContent="flex-start"
      drawerPadding={!isNaN(drawerPadding) ? `${drawerPadding}px` : undefined}
    >
      <>
        <ScrollPanel>
          <InputPanel>
            <Container containerPadding={containerPadding}>
              {children}
            </Container>
          </InputPanel>
        </ScrollPanel>
        <Divider />
      </>
    </MuiCustomDrawer>
  );
};

export default CustomDrawer;
