import React from "react";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomSnackbar, ModalErrors, TextInput, handleErrorResponse } from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import SubAccountGroup from "./SubAccountGroup";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import LoadingButton from "@mui/lab/LoadingButton";

export interface Request {
  title: string;
  refreshToggler: boolean;
  showGrid: boolean;
  apiLoading: boolean;
}
function AccountGroup() {
  const [splitBy, setSplitBy] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [request, setRequest] = React.useState<Request>({
    title: "",
    refreshToggler: false,
    showGrid: false,
    apiLoading: false,
  });
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const handleLoader = (v: boolean) =>
    setRequest({ ...request, apiLoading: v });

  const handleSubmit = () => {
    if (!request.title) {
      enqueueSnackbar(`Error! Title field is required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;
    setIsLoading(true);
    brokerBillingAxios
      .post("/AccountingGroup/Create", {
        billID: billID,
        title: request.title,
        stopBucketEdit: true,
        createdBy: createdBy.id,
        createdDate: new Date().toISOString(),
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setRequest({
            ...request,
            title: "",
            refreshToggler: !request.refreshToggler,
          });
        }
      })
      .catch((e: any) => {
        setIsLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleSplitApi(JSON.parse(value));
  };

  function handleSplitApi(v: boolean) {
    handleLoader(true);

    brokerBillingAxios
      .post(
        `/AccountingGroup/SetIsUniqueSplitFlag?id=${billID}&accountingGroupRuleFlag=${v}`
      )
      .then((res: any) => {
        handleLoader(false);
        if (res.status === 200) setSplitBy(v);
      })
      .catch((e: any) => {
        handleLoader(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const getData = () => {
    handleLoader(true);

    brokerBillingAxios
      .get(`/AccountingGroup/GetIsUniqueSplitFlag?id=${billID}`)
      .then((res: any) => {
        handleLoader(false);
        setSplitBy(res.data);
      })
      .catch((e: any) => {
        handleLoader(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SimpleBackdrop open={request.apiLoading} />
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid container spacing={1} maxWidth={"lg"}>
        <Grid
          md={12}
          item
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={2}
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="split"
            onChange={handleInputChange}
            value={splitBy}
          >
            <FormControlLabel value={false} control={<Radio />} label="None" />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Unique Split"
            />
          </RadioGroup>
        </Grid>

        {!splitBy ? null : (
          <>
            <Grid md={4} item>
              <TextInput
                inputProps={{ maxLength: 50 }}
                name={"title"}
                value={request.title}
                onChange={handleTextChange}
                label="Title"
                required
                id="title"
              />
            </Grid>
            <Grid md={4} item>
              <LoadingButton
                onClick={() => handleSubmit()}
                loading={isLoading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid md={4} item></Grid>
            <Grid md={12} item>
              <SubAccountGroup
                request={request}
                setRequest={setRequest}
                setModalErrors={setModalErrors}
                modalErrors={modalErrors}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default AccountGroup;
