import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { UserRequestObject, UserDispatch, UserTextFieldValidation, AppTypeRoles } from "./userUtils";
import {
  Button,
  ConfirmationDialog,
  useConfirm,
} from "@genrecp/g2clientportal-common";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { RolesAcessLookUp, UserAppsLookup } from "../adminUtils";
import { Box } from "@mui/material";

interface Props {
  hideExternalForSystenAdmin: boolean;
  updatetoggler: boolean;

  userRequest: UserRequestObject;

  internalCase: boolean;
  setInternalCase: (v: boolean) => void;

  submitUpdateCancelHandler: boolean;
  setSubmitUpdateCancelHandler: (v: boolean) => void;

  setUpdatetoggler: (v: boolean) => void;
  setinternalUserLoginIDValidation: (v: boolean) => void;
  setexternalUserLoginIDValidation: (v: boolean) => void;
  setUserLoginIDFormatValidation: (v: boolean) => void;

  handleSubmit: () => void;
  handleUpdate: (v:AppTypeRoles[]|undefined) => void;

  setTextFieldValidations: (v: UserTextFieldValidation) => void;
  dispatch: UserDispatch;
  setAllowedOAuthDomainsCheck: (v: boolean) => void;
}

type JustifyContent =
  | "center"
  | "start"
  | "end"
  | "flex-start"
  | "flex-end"
  | "left"
  | "right"
  | "space-between"
  | "space-around"
  | "space-evenly";
type CustomBoxPropType = {
  justifyContent?: JustifyContent;
  children: React.ReactNode;
};
const CustomBox = ({ justifyContent, children }: CustomBoxPropType) => (
  <Box
    display={"flex"}
    justifyContent={justifyContent || "flex-start"}
    alignItems={"center"}
    gap={1}
  >
    {children}
  </Box>
);

const UserToggleComp: React.FC<Props> = (props) => {
  const {
    updatetoggler,
    hideExternalForSystenAdmin,
    userRequest,
    internalCase,
    setInternalCase,
    submitUpdateCancelHandler,
    setSubmitUpdateCancelHandler,
    setUpdatetoggler,
    setinternalUserLoginIDValidation,
    setexternalUserLoginIDValidation,
    setUserLoginIDFormatValidation,
    handleSubmit,
    handleUpdate,
    setTextFieldValidations,
    dispatch,
    setAllowedOAuthDomainsCheck
  } = props;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [
    conditionForTenantOrClientLocationAdmin,
    setConditionForTenantOrClientLocationAdmin,
  ] = React.useState(false);
  const [
    conditionForLimitedInternalAdmin,
    setConditionForLimitedInternalAdmin,
  ] = React.useState(false);
  const { isConfirmed } = useConfirm();

  const userRole = useSelector(
    (state: RootState) => state?.auth?.currentUser?.appRolePermissions
  );
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );

  const isExternalUser = isExternal && JSON.parse(isExternal.toLowerCase());

  const showExternalUserToggle =
    !conditionForTenantOrClientLocationAdmin &&
    !hideExternalForSystenAdmin &&
    !updatetoggler;
  const showAddAccToggle = userRequest.external;

  React.useEffect(() => {
    if (!userRole) return;

    const userAdministrationRole = JSON.parse(userRole).filter(
      (item: any) => item.appTypeId === UserAppsLookup.UserAdministration
    );

    if (userAdministrationRole.length === 0) return;
    const tenantAdmin = userAdministrationRole.find(
      (itemX: any) => itemX.roleId === RolesAcessLookUp.TenantAdministrator
    );
    const clientLocationAdmin = userAdministrationRole.find(
      (itemY: any) =>
        itemY.roleId === RolesAcessLookUp.ClientLocationAdministrator
    );
    const limitedInternalUserAdmin = userAdministrationRole.find(
      (itemZ: any) => itemZ.roleId === RolesAcessLookUp.LimitedInternalUserAdmin
    );

    /**
       * CASE 1:
       * EXTERNAL USER
       * ROLE: Tenant or Client location Admin
       * Hide external flag if it is true 
  
       * CASE 2:
       * INTERNAL USER
       * ROLE: limited internal user admin role
       * SET External Flag to true and disabled it 
       **/
    if (isExternalUser) {
      if (tenantAdmin || clientLocationAdmin) {
        setConditionForTenantOrClientLocationAdmin(true);
      }
    } else {
      if (limitedInternalUserAdmin) {
        dispatch({
          external: true,
        });
        setConditionForLimitedInternalAdmin(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, isExternalUser]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event?.target;
    if (name === "external") {
      const confirmation = await isConfirmed(
        "Please confirm if you want to proceed."
      );
      if (confirmation) {
        if (checked) {
          dispatch({
            [name]: checked,
            sendEmailNotification: true,
          });
        } else {
          dispatch({
            [name]: checked,
            sendEmailNotification: false,
          });
        }
        setSubmitUpdateCancelHandler(!submitUpdateCancelHandler); // ON CHANGING THE TOGGLE RELOAD EFFECT API
        setInternalCase(!internalCase);
      }
    } else {
      dispatch({[name]: checked})
    }
  };

  return (
    <Grid
      container
      mt={2}
      maxWidth="lg"
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <ConfirmationDialog keepMounted />
      <Grid item md={10} display={"flex"} gap={4}>
        {/**
         * HIDE EXTERNAL FLAG IN CASE OF ( !conditionForTenantOrClientLocationAdmin && !hideExternalForSystenAdmin ) && !updatetoggler
         * conditionForTenantOrClientLocationAdmin: Condtion to check if user is external and tenant OR client location admin
         * hideExternalForSystenAdmin: Condtion to check if user is internal and system admin
         * updatetoggler:To check whether the scenario is update or submit
         */}

        {showExternalUserToggle ? (
          <CustomBox>
            <Typography variant={"labelMedium"}>External User</Typography>
            <Switch
              {...label}
              checked={userRequest.external}
              name="external"
              onChange={handleInputChange}
              disabled={conditionForLimitedInternalAdmin}
            />
          </CustomBox>
        ) : null}
        <CustomBox>
          <Typography variant={"labelMedium"}>Active</Typography>
          <Switch
            {...label}
            checked={userRequest.active}
            name="active"
            onChange={handleInputChange}
          />
        </CustomBox>
        {showAddAccToggle ? (
          <CustomBox>
            {/**
             * HIDE ADD ACCOUNT MANAGED BY G2 IN CASE OF EXTERNAL USER
             */}
            <>
              <Typography variant={"labelMedium"}>
                AAD Account Managed by G2
              </Typography>
              <Switch
                {...label}
                checked={userRequest.isManagedByG2}
                name="isManagedByG2"
                onChange={handleInputChange}
              />
            </>
          </CustomBox>
        ) : null}

        {/* {showSendEmailToggle ? (
          <CustomBox>
            <>
              <Typography variant={"labelMedium"}>
                Send Email Notification
              </Typography>
              <Switch
                {...label}
                checked={userRequest.sendEmailNotification}
                name="sendEmailNotification"
                onChange={handleInputChange}
              />
            </>
          </CustomBox>
        ) : null} */}
      </Grid>
      <Grid item md={2}>
        <CustomBox justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              dispatch({
                userLoginID: "",
                firstName: "",
                lastName: "",
                emailAddress: "",
                businessPhone: "",
                userID: "",
                createdBy: "",
                createdDate: "",
                modifiedBy: "",
                modifiedDate: "",
                userClientLocations: [],
                userAppTypes: [],
                isManagedByG2: true,
              })
              setAllowedOAuthDomainsCheck(false);
              setUpdatetoggler(false);
              setexternalUserLoginIDValidation(false);
              setinternalUserLoginIDValidation(false);
              setUserLoginIDFormatValidation(false);
              setSubmitUpdateCancelHandler(!submitUpdateCancelHandler); // ON CANCEL RELOAD INITIAL STATE API
              setTextFieldValidations({
                fName: false,
                lName: false,
              });
            }}
          >
            Cancel
          </Button>
          {!updatetoggler ? (
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              id="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUpdate(undefined)}
              id="update"
            >
              Update
            </Button>
          )}
        </CustomBox>
      </Grid>
    </Grid>
  );
};

export default UserToggleComp;
