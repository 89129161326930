import React, { useReducer } from "react";
import {
  delegatedReducer,
  initialDelegatedState,
  effectiveDateValidation,
  accidentYearsInitialValue,
} from "./utils";
import ExperienceReportForm from "./ExperienceReportForm";
import { delegatedAxios } from "../../../../services/axios";
import {
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { getBillPoints } from "../utils";

function ExperienceReportWrapper() {
  const [request, dispatch] = useReducer(
    delegatedReducer,
    initialDelegatedState
  );
  const { enqueueSnackbar } = useRibbonSnackbar();

  React.useEffect(() => {
    getClientLocationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getBillPoints(request, dispatch, enqueueSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    request.selectedClientLocations,
    request.lineOfBusiness,
    request.clientLocations,
  ]);

  function handleLoader(loading: boolean = false) {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        clientLocationsLoading: loading,
      },
    });
  }

  function getClientLocationsData() {
    handleLoader(true);
    delegatedAxios
      .get("/Delegated/GetClientLocations")
      .then((res: any) => {
        const { data } = res || {};
        const { userClientLocationList } = data || {};
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            clientLocations: userClientLocationList || [],
            clientLocationsLoading: false,
          },
        });
      })
      .catch((e: any) => {
        handleLoader();
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  const runReport = () => {
    if (!request.selectedClientLocations.length) {
      showInvalidSnackbar(
        undefined,
        enqueueSnackbar,
        "Please select at least one location."
      );
      return;
    }
    
    if (!request.accidentYear.length) {
      showInvalidSnackbar(
        undefined,
        enqueueSnackbar,
        `Please select at least one Accident year.`
      );
      return;
    }

    if (!request.billPoints.length) {
      const reason = request.firstSubmit ? "Line of Business" : "Locations";
      showInvalidSnackbar(
        undefined,
        enqueueSnackbar,
        `No Bill Points found for the selected ${reason}.`
      );
      return;
    }

    const effectiveYearObj = effectiveDateValidation(request, enqueueSnackbar);
    if (!effectiveYearObj) return;
    const payload = {
      ...(effectiveYearObj && { ...effectiveYearObj }),
      ...(request.accidentYear && {
        experienceDate: request.accidentYear.toString(),
      }),
      ...(request.glPropertyIndicator && {
        sublineCode: request.glPropertyIndicator === "GL" ? "100" : "200",
      }),
      riskStates: request.selectedRiskStates.length
        ? request.selectedRiskStates.map((item) => item.stateCode).toString()
        : request.riskStates.map((item) => item.stateCode).toString(),
      billPoints: request.selectedbillPoints.length
        ? request.selectedbillPoints.toString()
        : request.billPoints.toString(),
    };

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        runReportLoader: true,
        premiumLossesByState: [],
        premiumLossesByClassCode: [],
        sumIncurredAmount: null,
        sumPremiumEarnedAmount: null,
        sumPremiumWriteAmount: null,
        lossRatio: undefined,
      },
    });

    delegatedAxios
      .post("/Delegated/ExperienceReport", payload)
      .then((res: any) => {
        const {
          premiumLossesByClassCode,
          premiumLossesByState,
          sumIncurredAmount,
          sumPremiumEarnedAmount,
          sumPremiumWriteAmount,
          lossRatio,
          riskStates,
        } = res.data || {};
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            runReportLoader: false,
            premiumLossesByClassCode: premiumLossesByClassCode || [],
            premiumLossesByState: premiumLossesByState || [],
            sumIncurredAmount,
            sumPremiumEarnedAmount,
            sumPremiumWriteAmount,
            lossRatio,
            firstSubmit: true,
            disableDates: true,
            ...(request.riskStates.length === 0 && {
              riskStates: riskStates || [],
            }),
          },
        });
      })
      .catch((e: any) => {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            runReportLoader: false,
          },
        });
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  };

  function reset() {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        selectedClientLocations: [],
        selectedRiskStates: [],
        riskStates: [],
        selectedbillPoints: [],
        effectiveYearsMonthRange: [],
        effectiveYearsRange: [],
        monthValues: [],
        yearValues: [],
        firstSubmit: false,
        disableDates: false,
        premiumLossesByClassCode: [],
        premiumLossesByState: [],
        lineOfBusiness:"all",
        accidentYear:[accidentYearsInitialValue],
      },
    });
  }

  function resetDates(){
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        effectiveYearsMonthRange: [],
        effectiveYearsRange: [],
        monthValues: [],
        yearValues: [],
        accidentYear:[accidentYearsInitialValue],
        disableDates: false
      },
    });
  }

  return (
    <ExperienceReportForm
      request={request}
      dispatch={dispatch}
      runReport={runReport}
      reset={reset}
      resetDates={resetDates}
    />
  );
}

export default ExperienceReportWrapper;
