import { createSlice } from '@reduxjs/toolkit'
import { commonActionTypes } from "../common/commonActionTypes"
import {DepartObject} from "../slices/lossReportingSlice";
import type { PayloadAction } from '@reduxjs/toolkit'
import { ModalErrorMessagesType } from '@genrecp/g2clientportal-common';
type ClientStates = {
    name: string;
    stateCode: string;
}
interface PolicyObj {
    departmentName?: string;
    lossesCount?: number;
    totalNetIncurred?: number;
    asOfDate?: string;
}
export interface ClientLocation {
    clientLocationID: string;
    statusCode: string;
    businessName: string;
    mailCity: string;
    mailState: string;
    clientLocationUniqueName:string
}
export interface CommonState {
    clientStates: ClientStates[];
    stateArrayError: ModalErrorMessagesType | null;
    isStateArrayFetching: boolean;
    clientLocation: ClientLocation[];
    clientLocationError: ModalErrorMessagesType | null;
    isClientLocationFetching: boolean;
    searchBy:string;
    showGrid:boolean;
    showDoc:boolean;
    multiPolicy:boolean;
    showCommonPolicyGrid:boolean;
    selectedDepartmentObj:DepartObject | undefined;
    selectedPolicyDepartmentObj:PolicyObj | undefined;
    requestedPolicyId:string | string[];
    requestedClaimId:string;
    requestedRoute:string;
    topMenuToggler:boolean;
    adminPortalTabsToggler:boolean;
    documentationTabsToggler:boolean;
    reportingTabsToggler:boolean;
    delegatedTabsToggler:boolean;
    cashTrackerTabsToggler:boolean;
}

const initialState: Partial<CommonState> = {
    clientStates: [],
    stateArrayError: null,
    isStateArrayFetching: false,
    clientLocation: [],
    clientLocationError: null,
    isClientLocationFetching: false,
    searchBy:"POLICY",
    showGrid:false,
    showDoc:false,
    multiPolicy:false,
    showCommonPolicyGrid:false,
    selectedDepartmentObj:undefined,
    requestedPolicyId:"",
    requestedClaimId:"",
    requestedRoute:"",
    topMenuToggler:false,
    adminPortalTabsToggler:false,
    documentationTabsToggler:false,
    reportingTabsToggler:false,
    delegatedTabsToggler:false,
    cashTrackerTabsToggler:false
}

interface SetDocmosisAndShowDoc {
    showDoc:boolean;
    requestedPolicyId : any[]
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        getCommonState: (state = initialState, action: PayloadAction<any>) => {

            const { type, payload } = action.payload;

            switch (type) {
                case commonActionTypes.GET_USER_STATES_START:
                    return {
                        ...state,
                        isStateArrayFetching: true,
                        stateArrayError: null
                    }
                case commonActionTypes.GET_USER_STATES_SUCCESS:
                    return {
                        ...state,
                        clientStates: payload,
                        isStateArrayFetching: false,
                        stateArrayError: null
                    }
                case commonActionTypes.GET_USER_STATES_ERROR:
                    return {
                        ...state,
                        isStateArrayFetching: false,
                        stateArrayError: payload
                    }

                default:
                    break;
            }
        },
        getCommonLocation: (state = initialState, action: PayloadAction<any>) => {

            const { type, payload } = action.payload;

            switch (type) {
                case commonActionTypes.GET_USER_LOCATIONS_START:
                    return {
                        ...state,
                        isClientLocationFetching: true,
                        clientLocationError: null
                    }
                case commonActionTypes.GET_USER_LOCATIONS_SUCCESS:
                    return {
                        ...state,
                        clientLocation: payload,
                        isClientLocationFetching: false,
                        clientLocationError: null
                    }
                case commonActionTypes.GET_USER_LOCATIONS_ERROR:
                    return {
                        ...state,
                        isClientLocationFetching: false,
                        clientLocationError: payload
                    }
                case commonActionTypes.SET_AUTOCOMPLETE_RESULT:
                    return {
                        ...state,
                        AutoCompleteResult:payload
                    }

                default:
                    break;
            }
        },
        handleSearchBy: (state = initialState, action: PayloadAction<any>)=>{
            
            const { type, payload } = action.payload;

            switch (type) {
                case commonActionTypes.SET_SEARCH_BY:
                    return {
                        ...state,
                        searchBy: payload
                    }

                default:
                    break;
            }
        },
        handleShowGrid: (state = initialState, action: PayloadAction<any>)=>{
            
            const { type, payload } = action.payload;

            switch (type) {
                case commonActionTypes.SET_SHOW_GRID:
                    return {
                        ...state,
                        showGrid: payload.showGrid,
                        selectedDepartmentObj:payload.selectedDept,
                    }

                default:
                    break;
            }
        },
        handleShowDoc: (state = initialState, action: PayloadAction<any>)=>{
            
            const { type, payload } = action.payload;

            switch (type) {
                case commonActionTypes.SET_SHOW_DOC:
                    return {
                        ...state,
                        showDoc: payload.params,
                        requestedPolicyId: payload.policyId,
                        requestedClaimId:payload.claimId,
                        requestedRoute:payload.requestedRoute,
                        multiPolicy:false
                    }

                default:
                    break;
            }
        },
        handleTopMenuandSideBarToggler: (state = initialState,action: PayloadAction<boolean>)=>{
            
            return {
                ...state,
                topMenuToggler: action.payload
            }
        },
        handleadminPortalTabsToggler: (state = initialState,action: PayloadAction<boolean>)=>{
           
            return {
                ...state,
                adminPortalTabsToggler:action.payload
            }
        },
        handleDocumetationTabsToggler: (state = initialState,action: PayloadAction<boolean>)=>{
           
            return {
                ...state,
                documentationTabsToggler:action.payload
            }
        },
        handleReportingTabsToggler: (state = initialState,action: PayloadAction<boolean>)=>{
           
            return {
                ...state,
                reportingTabsToggler:action.payload
            }
        },
        handleDelegatedTabsToggler: (state = initialState,action: PayloadAction<boolean>)=>{
           
            return {
                ...state,
                delegatedTabsToggler:action.payload
            }
        },
        handlecashTrackerTabsToggler: (state = initialState,action: PayloadAction<boolean>)=>{
           
            return {
                ...state,
                cashTrackerTabsToggler:action.payload
            }
        },
        showSummaryListingGrid: (state = initialState, action: PayloadAction<any>)=>{
            
            return {
                ...state,
                showGrid:action.payload
            }
        },
        handleSelectedDepart: (state = initialState, action: PayloadAction<any>)=>{
            
            const { payload } = action.payload;
            return {
                ...state,
                selectedDepartmentObj: {
                    department: payload.departmentName,
                    claimsCount: payload.lossesCount,
                    netIncurredITDTotal: payload.totalNetIncurred,
                    asOfDate: payload.asOfDate,

                },
            }
        },
        handleCommonPolicyGrid: (state = initialState, action: PayloadAction<boolean>)=>{
            
            return {
                ...state,
                showCommonPolicyGrid:action.payload
            }
        },
        setPolicyDepartmentObj: (state = initialState, action: PayloadAction<any>)=>{
            return {
                ...state,
                selectedPolicyDepartmentObj:action.payload
            }
        },
        setDocmosisAndShowDoc: (state = initialState, action: PayloadAction<SetDocmosisAndShowDoc>)=>{
            return {
                ...state,
                requestedPolicyId:action.payload.requestedPolicyId,
                showDoc:action.payload.showDoc,
                multiPolicy:true
            }
        }

    },

})

// Action creators are generated for each case reducer function
export const { 
    getCommonState,
    getCommonLocation,
    handleSearchBy,
    handleShowGrid,
    handleSelectedDepart,
    handleShowDoc,
    handleTopMenuandSideBarToggler,
    handleadminPortalTabsToggler,
    handleDocumetationTabsToggler,
    handleReportingTabsToggler,
    showSummaryListingGrid,
    handleCommonPolicyGrid,
    setPolicyDepartmentObj,
    setDocmosisAndShowDoc,
    handleDelegatedTabsToggler,
    handlecashTrackerTabsToggler
 } = commonSlice.actions

export default commonSlice.reducer