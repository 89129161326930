import { Grid } from "@mui/material";
import React from "react";
import InfoSectionSelector from "./delegatedIssuesComponents/InfoSectionSelector";
import {
  delegatedIssuesReducer,
  initialDelegatedIssuesState,
} from "./delegatedIssuesAdminUtils";
import DelegatedIssuesSideBar from "./delegatedIssuesComponents/DelegatedIssuesSideBar";

function DelegatedIssuesAdminWrapper() {
  const [request, dispatch] = React.useReducer(
    delegatedIssuesReducer,
    initialDelegatedIssuesState
  );

  return (
    <Grid container>
      <Grid md={9} sm={12} item pr={1}>
          <InfoSectionSelector request={request} dispatch={dispatch} />
      </Grid>
      <Grid md={3} sm={12} position={"relative"}>
        <DelegatedIssuesSideBar request={request} dispatch={dispatch} />
      </Grid>
    </Grid>
  );
}

export default DelegatedIssuesAdminWrapper;
