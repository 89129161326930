import React,{ useState } from 'react';
import { commonAxios } from '../../../../services/axios';
import UserAccessLogReportGrid from './UserActionLogReportGrid';
import { showInvalidSnackbar, useRibbonSnackbar } from '@genrecp/g2clientportal-common';
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import { ApptypeType } from '../../admin/clientLocation/utils';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import {ActionTypesType} from './UserActionLogReportUtils';

function UserActionLogReport() {

  const [appTypes,SetAppType] = useState<ApptypeType[]>([]);
  const [actionTypes,SetActionTypes] = useState<ActionTypesType[]>([]);
  const [ApiLoading,SetApiLoading] = useState(false);
  const { enqueueSnackbar } = useRibbonSnackbar();

  React.useEffect(() => {
    SetApiLoading(true);

    const getGetActionTypes = commonAxios.get("/LogActionType/GetActionTypes");

    const getUserAppsTypes = commonAxios.get("/AppTypes");

    axios
      .all([getGetActionTypes, getUserAppsTypes])
      .then(
        axios.spread((...responses: any) => {
          const actionTypes = responses[0];
          const appTypes = responses[1];

          SetAppType(appTypes.data);
          SetActionTypes(actionTypes.data);
          SetApiLoading(false);
        })
      )
      .catch((e: any) => {
        SetApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });

    return () => {
      SetApiLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid xs={12} sm={12} md={12} item>
        <Typography variant='displaySmall'>
            User Action Log
        </Typography>
      </Grid>
      <Grid xs={12} sm={8} md={12} item>
        <SimpleBackdrop open={ApiLoading} />
        {!!appTypes?.length && !!actionTypes.length ? <UserAccessLogReportGrid appTypes={appTypes} actionTypes={actionTypes} /> : null}
      </Grid>    
    </Grid>
  )
}

export default UserActionLogReport