import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {Button, showInvalidSnackbar} from "@genrecp/g2clientportal-common";
import SelectList from "@genrecp/g2clientportal-common/src/components/selectList/SelectList";
import MenuItem from "@mui/material/MenuItem";
import { authAxios } from "../../../../services/axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Action, RequestType, RoleType } from './appRolePermissionUtils';
import { SelectChangeEvent } from "@mui/material";
import AppRolePermissionGrid from "./appRolePermissionGrid/AppRolePermissionGrid";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import Typography from "@mui/material/Typography";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";

type Dispatch = React.Dispatch<Action>;

export interface Combine {
  request: RequestType;
  dispatch: Dispatch;
}

export const SubAppRolePermission: React.FC<Combine> = (props) => {
  
  const { request, dispatch } = props;
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [roleArray, setRoleArray] = React.useState<RoleType[]>([]);

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;

    if(name === "appTypeID")
    {
      request.appTypeArray.forEach((item)=>{
        if(item.appTypeID === value)
        {
          return setRoleArray(item.roles);
        }
      })
    }

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, [name]: val },
    });
  };

  const handleSubmit = () => {
    if (!request.appTypeID || !request.roleID || !request.permissionLevelID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      appTypeID: request.appTypeID,
      roleID: request.roleID,
      permissionLevelID: request.permissionLevelID,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
    };
    
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, loading:true },
    });

    authAxios
      .post("/AppRolePermissions", params)
      .then((res: any) => {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            ...request,
            refreshToggler: !request.refreshToggler,
            permissionLevelID: "",
            roleID: "",
            appTypeID: "",
            appRolePermissionID: "",
            createdDate: "",
            createdBy: "",
            loading:false
          },
        });
      })
      .catch((e: any) => {

        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: { ...request, loading:false },
        });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleUpdate = () => {
    if (!request.appTypeID || !request.roleID || !request.permissionLevelID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      appRolePermissionID: request.appRolePermissionID,
      appTypeID: request.appTypeID,
      roleID: request.roleID,
      permissionLevelID: request.permissionLevelID,
      createdBy: request.createdBy,
      createdDate: request.createdDate,
      modifiedDate: new Date().toISOString(),
      modifiedBy: createdBy.id,
    };

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, loading:true },
    });

    authAxios
      .put(`/AppRolePermissions?id=${request.appRolePermissionID}`, params)
      .then((res: any) => {
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            ...request,
            refreshToggler: !request.refreshToggler,
            updateRequest: false,
            permissionLevelID: "",
            roleID: "",
            appTypeID: "",
            appRolePermissionID: "",
            createdDate: "",
            createdBy: "",
            loading:false
          },
        });
      })
      .catch((e: any) => {

        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: { ...request, loading:false },
        });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleCancel = () => {
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        ...request,
        updateRequest: false,
        permissionLevelID: "",
        roleID: "",
        appTypeID: "",
        appRolePermissionID: "",
        createdDate: "",
        createdBy: "",
      },
    });
  };

  return  (
      <Grid container spacing={1} maxWidth="lg">
        <Grid xs={12} sm={12} md={12} item>
          <Typography variant="displaySmall">
            App Role Permissions
          </Typography>
        </Grid>
        <SimpleBackdrop open={request.loading} />
        <Grid xs={12} sm={6} md={4} item>
          <SelectList
            label="App Type"
            name="appTypeID"
            id="appTypeID"
            test-id="appTypeID"
            required
            value={request.appTypeID}
            onChange={handleDropDownChange}
          >
            {request.appTypeArray.map((item, idx) => {
              return (
                <MenuItem value={item.appTypeID} key={idx}>
                  {item.appTypeText}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <SelectList
            label="Role"
            name="roleID"
            id="roleID"
            test-id="roleID"
            required
            value={request.roleID}
            onChange={handleDropDownChange}
          >
            {roleArray?.map((item, idx) => {
              return (
                <MenuItem value={item.roleID} key={idx}>
                  {item.name}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <SelectList
            label="Permission Level"
            name="permissionLevelID"
            id="permissionLevelID"
            test-id="permissionLevelID"
            required
            value={request.permissionLevelID}
            onChange={handleDropDownChange}
          >
            {request.permissionLevelArray.map((item, idx) => {
              return (
                <MenuItem value={item.value} key={idx}>
                  {item.name}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>
        <Grid xs={12} sm={6} md={12} item>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button variant="outlined" onClick={() => handleCancel()}>
              Cancel
            </Button>
            {!request.updateRequest ? (
              <Button
                variant="contained"
                onClick={() => handleSubmit()}
                id="submit"
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleUpdate()}
                id="update"
              >
                Update
              </Button>
            )}
          </Stack>
        </Grid>

        <Grid xs={12} sm={12} md={12} item>
          {request.appTypeArray.length > 0 ? (
            <AppRolePermissionGrid request={request} setRoleArray={setRoleArray} dispatch={dispatch} />
          ) : null}
        </Grid>
      </Grid>
  );
};
