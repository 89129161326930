import React from "react";
import { CashIssueDetailsItem, CashIssueDetailsProps } from "./cashIssueDetailsUtils";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef, CellClickedEvent } from "ag-grid-community";
import { EditChildRenderer } from "@genrecp/g2clientportal-common";
import { Grow } from "@mui/material";
import {GridLoadingSpinner} from "@genrecp/g2clientportal-common";

interface Props extends CashIssueDetailsProps {}

function CashIssueDetailsGrid({ state, setState }: Props) {
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  const onRowEditSelected = (
    evt: CellClickedEvent<CashIssueDetailsItem, any>
  ) => {
    const {
      active,
      createdBy,
      createdDate,
      cashIssueStatusTypeID,
      cashIssueStatusTypeText,
      modifiedBy,
      modifiedDate,
    } = evt.data as CashIssueDetailsItem;
    setState({
      ...state,
      active,
      cashIssueStatusTypeID,
      cashIssueStatusTypeText,
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate,
      updateToggler: true,
    });
  };

  const colDefs: ColDef[] = [
    {
      colId: "cashIssueStatusTypeText",
      field: "cashIssueStatusTypeText",
      headerName: "Cash Issue Status",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex:1
    },
    {
      field: "active",
      colId: "active",
      headerName: "Active",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
      width: 120
    },
    {
      headerName: "",
      filter: false,
      sortable: false,
      onCellClicked: onRowEditSelected,
      cellRenderer: "EditChildRenderer",
      width: 80
    },
  ];

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
  };

  return (
    <GridLoadingSpinner loading={state.gridDataLoading}>
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <div
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        style={{ width: "100%", height: window.innerHeight - 190 }}
      >
        <AgGridReact
          rowHeight={29}
          headerHeight={32}
          components={frameworkComponents}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={state.cashIssueDetailGridItems}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
        ></AgGridReact>
      </div>
    </Grow>
    </GridLoadingSpinner>

  );
}

export default CashIssueDetailsGrid;
