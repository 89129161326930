import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import { ActionButtons } from "../../util/CommonUtils";
import { DialogContentText } from "@mui/material";

interface CustomProps extends Omit<DialogProps, "open"> {}

export default function ConfirmationDialog(props: CustomProps) {
  const { prompt, isOpen, proceed, cancel } = useSelector(
    (state: RootState) => state.confirmation
  );

  return (
    <Dialog maxWidth="md" open={isOpen} {...props}>
      <DialogContent>
        <DialogContentText textAlign={"center"}>{prompt}</DialogContentText>
      </DialogContent>
      <ActionButtons>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={proceed}>Ok</Button>
      </ActionButtons>
    </Dialog>
  );
}
