import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CashTrackerState {
  cashTrackerQAOperations: boolean;
  cashTrackerAdmin: boolean;
  cashTrackerQAContributer: boolean;
  cashTrackerQAViewer: boolean;
  cashTrackerQCContributer: boolean;
  cashTrackerQCViewer: boolean;
  cashTrackerContributer: boolean;
  cashTrackerViewer: boolean;
}

const initialState: Partial<CashTrackerState> = {
  cashTrackerQAOperations: false,
  cashTrackerAdmin: false,
  cashTrackerQAContributer: false,
  cashTrackerQAViewer: false,
  cashTrackerQCContributer: false,
  cashTrackerQCViewer: false,
  cashTrackerContributer: false,
  cashTrackerViewer: false,
};

export const cashTrackerSlice = createSlice({
  name: "cashTracker",
  initialState,
  reducers: {
    setCashTrackerRoles: (
      state = initialState,
      action: PayloadAction<CashTrackerState>
    ) => {
      const { payload } = action;
      return {
        ...state,
        cashTrackerQAOperations: payload.cashTrackerQAOperations,
        cashTrackerAdmin: payload.cashTrackerAdmin,
        cashTrackerQAContributer: payload.cashTrackerQAContributer,
        cashTrackerQAViewer: payload.cashTrackerQAViewer,
        cashTrackerQCContributer: payload.cashTrackerQCContributer,
        cashTrackerQCViewer: payload.cashTrackerQCViewer,
        cashTrackerContributer: payload.cashTrackerContributer,
        cashTrackerViewer: payload.cashTrackerViewer,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCashTrackerRoles } = cashTrackerSlice.actions;

export default cashTrackerSlice.reducer;
