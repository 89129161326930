import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

const NotFoundDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

function NotFound() {
  const navigate = useNavigate();
  return (
    <NotFoundDiv>
      <Stack sx={{ minWidth: "506px" }}>
        <img
          src={"/GenStar-R.png"}
          alt="general star logo"
          style={{ maxWidth: "240px" }}
        />
        <Typography
          component="h1"
          variant="h6"
          align="right"
          fontStyle={"italic"}
        >
          The page you are looking for cannot be found.
        </Typography>
        <Typography
          component="h1"
          variant="h6"
          align="right"
          fontStyle={"italic"}
        >
          Click <LinkSpan onClick={() => navigate(-1)}>here</LinkSpan> to go back to the homepage.
        </Typography>
      </Stack>
    </NotFoundDiv>
  );
}

export default NotFound;
