import React from "react";
import { ModalErrors, showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import {
  brokerBillingAxios,
} from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { useSelector } from "react-redux";
import {
  RootState,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import {
  Request,
} from "../../genbillUtils";
import SelectionCriteriaForm from "./SelectionCriteriaForm";
import Grid from "@mui/material/Grid";

function SelectionCriteria() {
  const [state, setState] = React.useState<Request>({
    clientLocation: null,
    clientLocationArray: [],
    clientStates: [],
    selectBy: "CLIENTLOCATION",
    clientGroups: [],
    stateCode: "",
    groupID: "",
    billTitle: "",
    loading: false,
  });
  
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();

  const billID = useSelector((state: RootState) => state.genbill.bill.billID);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getData() {
    setState({
      ...state,
      loading: true,
    });

    brokerBillingAxios.get(`/Bills/SelectionCriteria/${billID}`)
    .then((res:any) => {
      const {data:createdBillData} = res || {};

      if(createdBillData.clientLocationID) {
        setState({...state,
          selectBy: "CLIENTLOCATION",
          billTitle:createdBillData.title,
          clientLocationArray: [
            {
              clientLocationID: createdBillData.clientLocationID,
              clientLocationUniqueName:
                createdBillData.clientLocationUniqueName,
            },
          ],
          clientLocation: {
            clientLocationID: createdBillData.clientLocationID,
            clientLocationUniqueName: createdBillData.clientLocationUniqueName,
          },
          stateCode:createdBillData.state,
          clientStates:[{name:createdBillData.stateName, stateCode:createdBillData.state}]
        })
      } 

      if(createdBillData.clientGroupID) {
        setState({...state,
          selectBy: "GROUP",
          billTitle:createdBillData.title,
          clientGroups:[
            {
              clientGroupID:createdBillData.clientGroupID,
              clientGroupUniqueName:createdBillData.clientGroupUniqueName
            }
          ],
          groupID: createdBillData.clientGroupID,
        })
      }
    })
    .catch((e: any) => {
      console.log(e);
      setState({
        ...state,
        loading: false,
      });
      showInvalidSnackbar(e,enqueueSnackbar);
    });

  }
  return (
    <Grid container spacing={1}>
      <SelectionCriteriaForm
        state={state}
        setState={setState}
        modalErrors={modalErrors}
        setModalErrors={setModalErrors}
      />
    </Grid>
  );
}

export default SelectionCriteria;
