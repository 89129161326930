import styled from "@emotion/styled";
import { showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { SnackbarKey, SnackbarMessage, OptionsObject } from "notistack";
import { DateObject } from "react-multi-date-picker";

export const BlueDiv = styled.div`
  background-color: #3a5baa;
  padding-left: 0.9rem;
`;

export const propertyIndicator = ["GL", "Property"] as const;
export const lineOfBusiness = [
  {
    name:"All",
    value:"all"
  },
  {
    name:"Mainframe",
    value:"mainframe"
  },
  {
    name:"GAP",
    value:"gap"
  },
  {
    name:"Liquor",
    value:"liquor"
  }
] as const;

export const effectiveYearsList: string[] = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2000",
  "1999",
  "1998",
];


export type DelegatedClientLocations = {
  clientLocationID: string;
  statusCode: string;
  businessName: string;
  clientLocationUniqueName: string;
  mailCity: null;
  mailState: string;
  stateName: string;
  clientGroupID: string;
};

export type LossGridSelectorState = "RiskState" | "ClassCode";

type GlPropertyIndicator = "GL" | "Property" | "";

export type DelegatedRequestType = {
  accidentYears: string[];
  accidentYear: string[];
  effectiveYearsRange: string[];
  effectiveYearsMonthRange: string[];
  glPropertyIndicator: GlPropertyIndicator;
  lineOfBusiness: string;
  billPoints: string[];
  selectedbillPoints: string[];
  billPointsLoading: boolean;
  runReportLoader:boolean;
  lossGridSelectorState: LossGridSelectorState;
  clientLocations: DelegatedClientLocations[];
  selectedClientLocations: DelegatedClientLocations[];
  clientLocationsLoading: boolean;
  riskStates: RiskStates[];
  riskStatesLoading:boolean;
  selectedRiskStates:RiskStates[];
  premiumLossesByState: PremiumLossesByState[];
  premiumLossesByClassCode: PremiumLossesByClassCode[];
  sumIncurredAmount:number | null;
  sumPremiumEarnedAmount:number | null;
  sumPremiumWriteAmount:number | null;
  lossRatio:string;
  yearValues: DateObject[];
  monthValues: DateObject[];
  firstSubmit: boolean;
  disableDates: boolean;
};

export interface RiskStates {
  stateCode: string;
  name: string;
}

interface LossGridType {
  premiumWriteAmount: number;
  premiumEarnedAmount: number;
  incurredAmount: number;
  lossRatio: string;
}

interface PremiumLossesByState extends LossGridType {
  state: string;
}

interface PremiumLossesByClassCode extends LossGridType {
  classDescription: string;
}

export type DelegatedAction = {
  type: "UPDATE_UNIVERSAL_REQUEST";
  request: Partial<DelegatedRequestType>;
};
export type DelegatedDispatch = React.Dispatch<DelegatedAction>;

export const accidentYearsInitialValue = new Date().getFullYear().toString();

export const initialDelegatedState: DelegatedRequestType = {
  accidentYears: getAccidentYears(),
  accidentYear: [accidentYearsInitialValue],
  glPropertyIndicator: "",
  lineOfBusiness: "all",
  lossGridSelectorState: "RiskState",
  clientLocationsLoading: false,
  clientLocations: [],
  selectedClientLocations:[],
  billPoints: [],
  selectedbillPoints:[],
  billPointsLoading: false,
  riskStates:[],
  riskStatesLoading:false,
  selectedRiskStates:[],  
  effectiveYearsRange:[],
  effectiveYearsMonthRange:[],
  premiumLossesByClassCode:[],
  premiumLossesByState:[],
  runReportLoader:false,
  sumIncurredAmount:null,
  sumPremiumEarnedAmount:null,
  sumPremiumWriteAmount:null,
  lossRatio:"",
  yearValues:[],
  monthValues:[],
  firstSubmit:false,
  disableDates:false
};

export interface DelegatedProps {
  request: DelegatedRequestType;
  dispatch: DelegatedDispatch;
}

export const delegatedReducer = (
  state: DelegatedRequestType,
  action: DelegatedAction
) => {
  switch (action.type) {
    case "UPDATE_UNIVERSAL_REQUEST":
      return {
        ...state,
        ...action.request
      }
    default:
      return state;
  }
};

export function getAccidentYears() {
  const currentYear = new Date().getFullYear();
  const previousOneYear = currentYear - 1;
  const previoustwoYear = currentYear - 2;
  const output = [
    previoustwoYear.toString(),
    previousOneYear.toString(),
    currentYear.toString(),
  ];
  return output || [];
};

export const yearConverter = (year: string, getLastMonthDate:boolean = false) => {
  let date:Date | string = getLastMonthDate ? new Date(Number(year), 12, 0) : new Date(year);
  date = date.toISOString().split("T")[0];
  const [toYear, toMonth, toDay] = date.split("-");

  date = toYear + "-" + toMonth + "-" + toDay;
  return date;
}

/**
 * one month selection => get first and last date
 * range selection => apply range
 * */

export const monthLookUp = {
  January : 0,
  February : 1,
  March : 2,
  April : 3,
  May : 4,
  June : 5,
  July : 6,
  September : 8,
  October : 9,
  November : 10,
  December : 11,
}

export const monthConverter = (
  year: string,
  month: keyof typeof monthLookUp,
  getLastMonthDate: boolean = false
) => {
  let date: Date | undefined;

  const tempMonth = monthLookUp[month];
  const tempMonthDate = getLastMonthDate
    ? new Date(Number(year), tempMonth + 1, 0).getDate()
    : new Date(Number(year), tempMonth, 1).getDate();

  date = new Date(Number(year), monthLookUp[month], tempMonthDate);

  let newDate: string = date!.toISOString().split("T")[0];
  const [toYear, toMonth, toDay] = newDate.split("-");

  newDate = toYear + "-" + toMonth + "-" + toDay;
  return newDate;
};

export const effectiveDateValidation = (
  request: DelegatedRequestType,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
) => {
  const effectiveYearObj = {
    fromEffectiveDate: "",
    toEffectiveDate: "",
  };

  if (!request.effectiveYearsRange.length) {
    showInvalidSnackbar(
      null,
      enqueueSnackbar,
      `Please select Effective year or range!`
    );
    return false;
  }

  if (request.effectiveYearsRange.length > 1) {
    const [fromEffectiveDate, toEffectiveDate] =
      request.effectiveYearsRange;
    effectiveYearObj.fromEffectiveDate = yearConverter(fromEffectiveDate);
    effectiveYearObj.toEffectiveDate = yearConverter(toEffectiveDate, true);
  } else {

    if (!request.effectiveYearsMonthRange.length) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        `Please select Effective month or range!`
      );
      return false;
    }

    const singleYear = request.effectiveYearsRange[0];

    if (request.effectiveYearsMonthRange.length > 1) {
      const [fromEffectiveDate, toEffectiveDate] =
        request.effectiveYearsMonthRange;
      effectiveYearObj.fromEffectiveDate = monthConverter(
        singleYear,
        fromEffectiveDate as keyof typeof monthLookUp
      );
      effectiveYearObj.toEffectiveDate = monthConverter(
        singleYear,
        toEffectiveDate as keyof typeof monthLookUp,
        true
      );
    } else {
      const singleMonth = request.effectiveYearsMonthRange[0];
      effectiveYearObj.fromEffectiveDate = monthConverter(
        singleYear,
        singleMonth as keyof typeof monthLookUp
      );
      effectiveYearObj.toEffectiveDate = monthConverter(
        singleYear,
        singleMonth as keyof typeof monthLookUp,
        true
      );
    }
  }
  return effectiveYearObj
};