import React, { useReducer } from "react";
import { cashLogReducer, initialCashLogState } from "./cashLogUtils";
import { ActionBar } from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import InfoSectionSelector from "./InfoSectionSelector";

function CashLogWrapper() {
  const [request, dispatch] = useReducer(cashLogReducer, initialCashLogState);

  const menuItems = [
    {
      text:
        request.gridSelector === "ADD" || request.gridSelector === "DETAIL"
          ? "Go back"
          : "Create New Cash Log",
      onClick: () => navigateTo(),
    },
  ];
  const dashboardName = "Cash Log";

  function navigateTo() {
    const deleteID =
      request.gridSelector === "ADD" || request.gridSelector === "GRID";
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: {
        gridSelector:
          request.gridSelector === "ADD" || request.gridSelector === "DETAIL"
            ? "GRID"
            : "ADD",
        cashLogID: deleteID ? null : request.cashLogID,
        cashLogDate: null,
        files: [],
        cashLogDocuments: null,
        createdBy: null,
        g2CompanyCode: "GSI",
        comment: "",
        createdDate: null,
        modifiedBy: null,
        modifiedDate: null,
        tatStartDate: null,
      },
    });
  }

  return (
    <Grid container>
      <ActionBar
        menuItems={menuItems}
        dashboardName={dashboardName}
        children={<div></div>}
      />
      <InfoSectionSelector request={request} dispatch={dispatch} />
    </Grid>
  );
}

export default CashLogWrapper;
