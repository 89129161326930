import React from "react";
import {
  DesktopDatePicker,
} from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Typography } from "@mui/material";
import { makeEvent } from "../../util/makeEvent";
import {
  guardNonEmptyString,
  guardNonNullObject,
} from "../../util/type-guards";
import { DatePickerProps } from "./DatePicker.types";
import "./DatePicker.css";
import { DateOnly } from "../../util/DateOnly";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface customProps extends DatePickerProps {
  required?: boolean;
  disableFuture?: boolean | undefined;
}

// const addLeadingZero = (num: number): string => {
//   return `${num}`.padStart(2, "0");
// };

const DatePicker: React.FC<customProps> = ({
  onChange,
  error,
  id,
  value,
  label,
  minDate,
  maxDate,
  helperText,
  readonly,
  required,
  disableFuture,
  ...rest
}) => {
  const [displayValue, setDisplayValue] = React.useState(new DateOnly(value));
  const min = guardNonEmptyString(minDate) ? new Date(`${minDate}`) : undefined;

  React.useEffect(() => {
    setDisplayValue(new DateOnly(value));
  }, [value]);

  const handleChanged = (date: Date | null, keyboardInput?: string) => {
    if (readonly === true) {
      return;
    }

    if (guardNonNullObject(date)) {
      const today = new Date();
      const future = today;
      future.setFullYear(today.getFullYear() + 100);
      // const min = guardNonEmptyString(minDate)
      //   ? new Date(`${minDate}`)
      //   : new Date("1921-01-01");
      // const max = guardNonEmptyString(maxDate)
      //   ? new Date(`${maxDate}`)
      //   : future;

      if (typeof onChange === "function") {
        onChange(makeEvent(`${id}`, new DateOnly(date)));
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="date-picker">
        <DesktopDatePicker
          name={id}
          label={label}
          value={displayValue.value}
          onChange={handleChanged}
          closeOnSelect={true}
          inputFormat="MM/dd/yyyy"
          {...rest}
          readOnly={readonly === true}
          orientation="landscape"
          minDate={min ? min : undefined}
          disableFuture={disableFuture ? disableFuture : undefined}
          PopperProps={{
            sx: {
              "& .MuiCalendarPicker-root": {
                maxHeight: "290px",
              },
            },
          }}
          renderInput={(props: any) => (
            <TextField
              fullWidth={true}
              size="small"
              sx={{ width: "100%" }}
              {...props}
              error={error}
              required={required}
            />
          )}
        />
        {helperText ? (
          <Typography variant="subtitle1" color="error">
            {helperText}
          </Typography>
        ) : null}
      </div>
    </LocalizationProvider>
  );
};

export default DatePicker;
