export interface ActionTypesType {
    actionTypeID: number;
    actionTypeText: string;
    appTypeID: number;
    active: boolean;
    createdDate: string;
    createdBy: string;
    modifiedDate: string;
    modifiedBy: string;
  }

  export const actionTypeIDLookup = (actionTypeArray:ActionTypesType[],actionTypeID:string) => {

    if(!actionTypeArray || actionTypeArray.length === 0) return;
    const found = actionTypeArray.filter((elem:any) => elem.actionTypeID === actionTypeID);
    if(found.length === 1) return found[0].actionTypeText;
    else return;
  }
