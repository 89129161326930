export interface ApptypeType {
    appTypeID: number;
    appTypeText: string;
    active: boolean;
    roles: RoleType[];
}

export interface RoleType {
    roleID: number;
    name: string;
    active: boolean;
    createdDate: string;
    createdBy: string;
    modifiedDate?: any;
    modifiedBy?: any;
}

export const PermissionLevelArray = [
    { value: 1, name: "Admin" },
    { value: 2, name: "Contributor" },
    { value: 3, name: "Viewer" },
]

export interface RequestType {
    appTypeArray: ApptypeType[];
    roleArray: RoleType[];
    permissionLevelArray: (typeof PermissionLevelArray);
    appTypeID: string;
    roleID: string;
    permissionLevelID: string;
    appRolePermissionID: string;
    refreshToggler:boolean;
    updateRequest:boolean;
    createdBy:string;
    modifiedBy:string;
    createdDate: string;
    modifiedDate: string;
    loading:boolean;
}

export type Action = { type: "UPDATE_UNIVERSAL_REQUEST"; request: RequestType };

export const appRoleTableLookup = (itemArray: any, payload: number | string) => {

    if(typeof payload === "number"){
        const Item = itemArray.filter((e: any, i: any) => e.roleID === payload);
        if (Item && Item.length > 0) return Item[0].name;
        else return;
    } 
    else if (typeof payload === "string") {

        const Item = itemArray.filter((e: any, i: any) => e.name === payload);
        if (Item && Item.length > 0) return (Item[0].roleID).toString();
        else return;

    } else return;

}


export const clientLocationLookup = (itemArray: any, payload: number) => {

    const Item = itemArray.filter((e: any, i: any) => e.clientLocationID === payload);
    if (Item && Item.length > 0) return Item[0].clientLocationUniqueName;
    else return;

}

export const appTypeLookup = (itemArray: any, payload: number | string) => {

    if(typeof payload === "number"){
        const Item = itemArray.filter((e: any, i: any) => e.appTypeID === payload);
        if (Item && Item.length > 0) return Item[0].appTypeText;
        else return;
    } else if (typeof payload === "string") {

        const Item = itemArray.filter((e: any, i: any) => e.appTypeText === payload);
        if (Item && Item.length > 0) return (Item[0].appTypeID).toString();
        else return;

    } else return;

}

export const permissionLevelLookup = (itemArray: any, payload: number | string) => {

    if(typeof payload === "number"){
        const Item = itemArray.filter((e: any, i: any) => e.value === payload);
        if (Item && Item.length > 0) return Item[0].name;
        else return;
    } else if (typeof payload === "string") {

        const Item = itemArray.filter((e: any, i: any) => e.name === payload);
        if (Item && Item.length > 0) return (Item[0].value).toString();
        else return;

    } else return;

}
