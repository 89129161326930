import { Divider, Grid, Stack, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { DetailSpan } from "@genrecp/g2clientportal-genbill";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Fragment } from "react/jsx-runtime";
import { FileType, dataArray } from "./utils";
import { commonAxios } from "../../../services/axios";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  CustomSnackbar,
  ModalErrors,
  SimpleBackdrop,
  handleErrorResponse,
} from "@genrecp/g2clientportal-common";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  "&:hover": {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
}));

const FapFilesInstructions = () => (
  <Fragment>
    <Typography variant="bodySmall">
      Is your system missing a particular FAP file? Is a current FAP file
      corrupted or not working as intended? Click the links provided below to
      upload any FAP files from 2019 through 2022.
    </Typography>
    <Typography variant="bodySmall">
      If a FAP file cannot be found, please send a request to:{" "}
      <b>Sergio.Ramos@generalstar.com</b>
    </Typography>
  </Fragment>
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  gap: 4,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  height: "4rem",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  display: "flex",
  flexDirection: "column",
  height: "13rem",
  gap: 3,
  overflowY: "auto",
}));

function OracleDocucorp() {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<FileType>({
    name: "",
    base64: "",
  });
  const [modalErrors, setModalErrors] = useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const { pathname } = useLocation();
  const documentDirectory = pathname.replace("/", "").replaceAll("-", " ");
  const linkRef = useRef<any>();

  useEffect(() => {
    if (file.name === "") return;
    linkRef.current.click();
  }, [file]);

  function generateBase64(fileName: string) {
    setLoading(true);

    const fullDirectory = documentDirectory;
    commonAxios
      .get(
        "Documentation/Pdfdocument?directory=" +
          encodeURIComponent(fullDirectory) +
          "&filename=" +
          encodeURIComponent(fileName)
      )
      .then(function (response) {
        setFile({
          base64: response.data,
          name: fileName,
        });
        setLoading(false);
      })
      .catch(function (e) {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        setLoading(false);
      });
  }

  return (
    <Fragment>
      <SimpleBackdrop open={loading} />
      <Typography variant="displaySmall">
        Oracle and Docucorp Libraries
      </Typography>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: {
            md: "1fr 1fr",
            sm: "1fr",
          },
          gap: 1,
          mt: 2,
        }}
      >
        {dataArray.map((data) => (
          <Grid item>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Stack display={"flex"} flexDirection={"column"} width={"100%"}>
                  <Typography variant="titleMedium">{data.title}</Typography>
                  {data?.instructions ? null : (
                    <Typography variant="bodySmall">
                      Instructions and list of the latest change to the GENSTAR
                      Docucorp/Oracle Library
                    </Typography>
                  )}
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Typography variant="titleMedium">Instructions</Typography>
                <Divider />
                {data?.instructions ? (
                  <FapFilesInstructions />
                ) : (
                  <Typography variant="bodySmall">
                    Click the links provided below to download the files needed
                    to update your library.
                  </Typography>
                )}
                {data.links.map((link) => {
                  console.log(link)
                  return (
                    <span>
                      <DetailSpan
                       style={{ cursor: "pointer",marginRight:2 }}
                       onClick={() => generateBase64(link.filename)}
                      >
                       {link.linkText}
                      </DetailSpan>
                      {link?.extraText ? "(this zipfile contains the form.dat files)" : null}
                    </span>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
        <a
          download={file.name}
          href={`data:application/zip;base64,${file.base64}`}
          style={{ display: "none" }}
          ref={linkRef}
        >
          Download
        </a>
      </Grid>
    </Fragment>
  );
}

export default OracleDocucorp;
