import React from "react";
import {
  AppTypeRoles,
  ApptypeTypeArray,
  ClientLocationArray,
  getAppTypesBasedOnCLientLocationIDs,
  UserRequestObject,
  UserDispatch,
} from "./userUtils";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { ConfirmationDialog, TextInput, useConfirm } from "@genrecp/g2clientportal-common";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import LoadingButton from "@mui/lab/LoadingButton";
import { Chip } from "@mui/material";
import { clientLocationArrayType } from "../clientLocation/utils";

interface Props {
  caseWhenOnlyOneClientLocation: boolean;
  appTypeLoadingSpecificCase: boolean;
  userRequest: UserRequestObject;
  clientLocationInputValue: string;
  setClientLocationInputValue: (v: string) => void;
  clientLocationArray: ClientLocationArray[];
  setappTypeLoadingSpecificCase: (v: boolean) => void;
  setAppTypeArray: (v: ApptypeTypeArray[]) => void;
  setTempAppRoleData: (v: AppTypeRoles[]) => void;
  dispatch: UserDispatch
}

const UserAutoCompleteComp: React.FC<Props> = (props) => {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const {
    caseWhenOnlyOneClientLocation,
    userRequest,
    clientLocationInputValue,
    setClientLocationInputValue,
    clientLocationArray,
    setappTypeLoadingSpecificCase,
    setAppTypeArray,
    setTempAppRoleData,
    appTypeLoadingSpecificCase,
    dispatch
  } = props;
  const {isConfirmed} = useConfirm();

  const allLocationsAssigned =
    userRequest?.userClientLocations?.length === 1 &&
    userRequest?.userClientLocations[0]?.clientLocationID === "0000";

  const onDelete = async (evt:any,value:ClientLocationArray) => {
    const flag = await isConfirmed("Removing a Location Id will remove the associated App Type and Roles selected. Do you want to proceed?")
    if(!flag) return;
    const filteredLocations = userRequest.userClientLocations?.filter((cL:clientLocationArrayType) => cL.clientLocationID !== value.clientLocationID);
    getAppTypesBasedOnCLientLocationIDs(
      filteredLocations,
      setappTypeLoadingSpecificCase,
      setAppTypeArray,
      enqueueSnackbar,
      setTempAppRoleData,
      userRequest,
      dispatch
    );
  }
  return (
    <Grid container mt={1} maxWidth="lg">
      <ConfirmationDialog />
      <Grid xs={12} sm={12} md={8} item pr={2}>
        <Autocomplete
          id="userClientLocations"
          test-id="userClientLocations"
          multiple
          limitTags={2}
          size="small"
          disabled={
            caseWhenOnlyOneClientLocation ||
            allLocationsAssigned
          }
          options={clientLocationArray}
          value={userRequest.userClientLocations}
          inputValue={clientLocationInputValue}
          onInputChange={(event, newInputValue, reason) => {
            setClientLocationInputValue(newInputValue);
          }}
          isOptionEqualToValue={(option, value) =>
            option.clientLocationUniqueName === value.clientLocationUniqueName
          }
          getOptionLabel={(option: ClientLocationArray) =>
            option.clientLocationUniqueName!
          }
          onChange={(event, value) => {
            if (value.length !== 0) {
              getAppTypesBasedOnCLientLocationIDs(
                value,
                setappTypeLoadingSpecificCase,
                setAppTypeArray,
                enqueueSnackbar,
                setTempAppRoleData,
                userRequest,
                dispatch
              );
            } else {
              dispatch({userClientLocations: []})
            }
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                label={option.clientLocationUniqueName}
                {...getTagProps({ index })}
                onDelete={(e) => onDelete(e,option)}
              />
            ))}
          }
          renderInput={(params) => (
            <TextInput
              {...params}
              label="Client Locations"
              required
              placeholder=""
            />
          )}
        />
      </Grid>
      <Grid xs={12} sm={12} md={4} item>
        <LoadingButton
          variant="contained"
          loading={appTypeLoadingSpecificCase}
          fullWidth
          onClick={() => {
            dispatch({ openCustomDrawer: true })
            
          }
          }
          disabled={
            userRequest.userClientLocations &&
            userRequest.userClientLocations.length === 0
          }
        >
          User App Type Roles
        </LoadingButton>
      </Grid>
     </Grid>
  );
};

export default UserAutoCompleteComp;
