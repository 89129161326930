import React from "react";
import styled from "styled-components";
import { Menu as MenuIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

interface HeaderProps {
  dashboardName: string;
  menuItems: Array<{ disabled?: boolean; text: string; onClick: () => void }>;
  navigationTabs?: Array<{ name: string; link: string }>;
  children?: JSX.Element | null;
}

const ActionBarWrapper = styled.div`
  align-content: center;
  background-color: #eef0ff;
  border-bottom: solid 1px #000000;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 12px 0 10px;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const HeaderSwitchToolbar = styled.div`
  align-content: center;
  display: flex;
  height: 100%;
`;

const ChildrenDiv = styled.div`
  align-content: center;
  display: flex;
  height: 100%;
`;

const NavigationTab = styled.a`
  align-items: center;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 100%;
  justify-content: center;
  padding: 0px 16px;
  text-decoration: none;
  &:hover {
    background-color: #a1efff;
  }
`;

const Title = styled.div`
  color: #000000;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 39px;
  padding: 0px 12px;
`;

const ActionBar: React.FC<HeaderProps> = ({
  dashboardName,
  menuItems,
  navigationTabs,
  children,
}: HeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const drawerOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ActionBarWrapper>
      <HeaderSwitchToolbar>
        {menuItems && menuItems.length > 0 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            size="small"
            onClick={handleClick}
          >
            <MenuIcon style={{ color: "#000AEB" }} />
          </IconButton>
        )}
        <Menu anchorEl={anchorEl} open={drawerOpen} onClose={handleClose}>
          {menuItems?.map((menuItem, index) => {
            return (
              <MenuItem
                key={index}
                disabled={menuItem.disabled || false}
                onClick={() => {
                  handleClose();
                  menuItem.onClick();
                }}
              >
                {menuItem.text}
              </MenuItem>
            );
          })}
        </Menu>
        {navigationTabs?.map((tab, index) => {
          return (
            <NavigationTab href={tab.link} key={index}>
              {tab.name}
            </NavigationTab>
          );
        })}
      </HeaderSwitchToolbar>
      <Title>{dashboardName}</Title>
      {children && <ChildrenDiv>{children}</ChildrenDiv>}
    </ActionBarWrapper>
  );
};

export default ActionBar;
