import React from "react";
import {
  branchTypes,
  ClientCashDetailsItem,
  ClientCashDetailsProps,
  Companies,
  grapCodeGenerator,
  MgAorBroker,
  OperationsContact,
} from "./clientCashDetailsUtils";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef, CellClickedEvent } from "ag-grid-community";
import { EditChildRenderer, handleErrorResponse, ModalErrors } from "@genrecp/g2clientportal-common";
import { Grow } from "@mui/material";
import {GridLoadingSpinner} from "@genrecp/g2clientportal-common";
import { ValueGetterParams } from "ag-grid-community";
import { SetFilterValuesFuncParams } from "ag-grid-community";
import { delegatedAxios } from "../../../../../services/axios";
import { companies } from "./ClientCashDetailsForm";
interface Props extends ClientCashDetailsProps {}

function ClientCashDetailsGrid({ state, setState }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
  };

  const onRowEditSelected = (
    evt: CellClickedEvent<ClientCashDetailsItem, any>
  ) => {
    const {
      active,
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate,
      clientLocationID,
      g2CompanyCode,
      mgAorBroker,
      operationsContact,
      billpointCode,
      clientInfoID,
      branchCode,
      clientName
    } = evt.data as ClientCashDetailsItem;
    setState({
      ...state,
      selectedClientLocation:clientLocationID || "",
      clientLocationDisplayText:clientName || "",
      g2CompanyCode: g2CompanyCode as Companies,
      mgAorBroker: mgAorBroker as MgAorBroker,
      operationsContact,
      billpointCode,
      clientInfoID,
      active,
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate,
      branchCode,
      updateToggler: true,
      grapCode:grapCodeGenerator(state, setState)
    });
  };

  const colDefs: ColDef[] = [
    {
      field: "createdDate",
      colId: "createdDate",
      headerName: "createdDate",
      hide: true,
      sort: "desc",
    },
    {
      colId: "clientName",
      field: "clientName",
      headerName: "Client",
      filter: "agTextColumnFilter",
      width:330,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "billpointCode",
      field: "billpointCode",
      headerName: "Bill point",
      width:120,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: any) {
        const { billpointCode } = params?.data || {};
        return billpointCode;
      },
    },
    {
      colId: "operationsContact",
      field: "operationsContact",
      headerName: "Operations Contact",
      filter: "agSetColumnFilter",
      width:250,
      filterParams: {
        values: state.operationContactList.length
          ? state.operationContactList.map(
              (i: OperationsContact) => i.userLoginID
            )
          : [],
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: ValueGetterParams<ClientCashDetailsItem>) {
        const { operationsContact } = params?.data || {};
        return (
          state.operationContactList.find(
            (i: OperationsContact) => i.userID === operationsContact
          )?.userLoginID || ""
        );
      },
    },
    {
      colId: "grap",
      field: "grap",
      headerName: "GRAP",
      width:200,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: ValueGetterParams<ClientCashDetailsItem>) {
        const { grapCode } = params?.data || {};
        return grapCode;
      },
    },
    {
      field: "g2CompanyCode",
      colId: "g2CompanyCode",
      headerName: "Company",
      width:190,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["General Star", "Genesis"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: any) {
        const { g2CompanyCode } = params?.data || {};
        return companies.find(company => company.value === g2CompanyCode)?.name || "";
      },
    },
    {
      field: "mgAorBroker",
      colId: "mgAorBroker",
      headerName: "MGA or Broker",
      width:150,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["MGA", "Broker"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: any) {
        const { mgAorBroker } = params?.data || {};
        return mgAorBroker === "M"
          ? "MGA"
          : mgAorBroker === "B"
          ? "Broker"
          : "";
      },
    },
    {
      field: "branchCode",
      colId: "branchCode",
      headerName: "Branch",
      filter: "agSetColumnFilter",
      width:100,
      filterParams: {
        buttons: ["reset"],
        suppressSelectAll: true,
        defaultToNothingSelected: true,
        filterOptions: ["equals"],
        values: async (params: SetFilterValuesFuncParams) => {
          let values = await getBranchFilters();
          let cashIssueStatusTypeTextList: string[] = values.map(
            (i: branchTypes) => i.branchCode
          );
          params.success(cashIssueStatusTypeTextList);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "active",
      colId: "active",
      headerName: "Active",
      filter: "agSetColumnFilter",
      width:100,
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "",
      filter: false,
      width:50,
      sortable: false,
      onCellClicked: onRowEditSelected,
      cellRenderer: "EditChildRenderer",
    },
  ];

  async function getBranchFilters() {
    return delegatedAxios
    .get("/ClientInfos/GetBranches")
      .then((res: any) => {
        const { data } = res || {};
        return data ? data : [];
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  }

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
  };

  return (
    <GridLoadingSpinner loading={state.gridDataLoading}>
      <Grow in={true} style={{ transitionDelay: "100ms" }}>
        <div
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
          style={{ width: "100%", height: window.innerHeight - 210 }}
        >
          <AgGridReact
            rowHeight={29}
            headerHeight={32}
            components={frameworkComponents}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={state.clientCashDetailGridItems}
            pagination={true}
            paginationPageSize={10}
            animateRows={true}
          ></AgGridReact>
        </div>
      </Grow>
    </GridLoadingSpinner>
  );
}

export default ClientCashDetailsGrid;
