import React from "react";
import { CashTrackerProps } from "../CashTrackerUtils";
import { Typography, Stack } from "@mui/material";

interface Props extends CashTrackerProps {}
function CashTrackerMetaDataBar({ request, dispatch }: Props) {
  if (
    request.cashTrackerScreenSelector === "SUSPENSE" ||
    request.cashTrackerScreenSelector === "CASH_TRACKER_GRID" ||
    request.cashTrackerScreenSelector === "ADD_CASH_TRACKER"
  ) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      display={"flex"}
      justifyContent={"space-between"}
      width={"100%"}
      mb={1}
    >
      <Typography>
        <b>Check Number: </b> {request.cashTrackerMasterFields.checkNumber}
      </Typography>
      <Typography>
        <b>Client Name: </b> {request.cashTrackerMasterFields.client}
      </Typography>
      <Typography>
        {request.cashTrackerDetailTab === "SUSPENSE_DETAIL" ||
        request.cashTrackerScreenSelector === "SUSPENSE_DETAIL" ? (
          <Typography>
            <b>Suspense Status: </b>
            {request.cashTrackerMasterFields?.suspenseStatus}
          </Typography>
        ) : (
          <Typography>
            <b>Cash Tracker Status: </b>{" "}
            {request.cashTrackerMasterFields.cashTrackerStatus}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
}

export default CashTrackerMetaDataBar;
