import { handleBillState, handleGenbillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import { AppDispatch } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { ICellRendererParams } from "ag-grid-community";

export interface BillType {
  billID: string;
  billDisplayID: number;
  assignedToUserID?: any;
  title: string;
  statementStatusTypeID: number;
  billOwner: string;
  clientGroupID?: string;
  clientGroupName?: string;
  clientLocationID?: string;
  clientLocationName?: string;
  statementStatusDate?: any;
  createdDate: string;
  createdBy: string;
  total: number;
}

export const navigateTo = (selectedMenu: string, dispatch: AppDispatch) => {
  dispatch(
    handleGenbillState({
      type: "HANDLE_GENBILL_NAV",
      payload: { selectedMenu: selectedMenu },
    })
  );
}
export const fillBillDetails = (
  params: ICellRendererParams<BillType>,
  dispatch: AppDispatch
) => {
  dispatch(
    handleBillState({
      payload: {
        billID: params?.data?.billID,
        title: params?.data?.title,
        billDisplayID: params?.data?.billDisplayID,
        userAccess: {
          clientGroupID: params?.data?.clientGroupID || "",
          clientLocationID: params?.data?.clientLocationID || "",
        },
      },
    })
  );
};
