import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@genrecp/g2clientportal-common";
import SelectList from "@genrecp/g2clientportal-common/src/components/selectList/SelectList";
import MenuItem from "@mui/material/MenuItem";
import {
  ClientLocationAppType,
  clientLocationArrayType,
  ApptypeType,
} from "../utils";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { SelectChangeEvent } from "@mui/material";
import { ClientLocationGrid } from "./ClientLocationGrid";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { authAxios, commonAxios } from "../../../../../services/axios";
import Typography from "@mui/material/Typography";
import { TextInput } from "@genrecp/g2clientportal-common";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { SimpleBackdrop,showInvalidSnackbar } from "@genrecp/g2clientportal-common";

function ClientLocationApp() {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [inputValue, setInputValue] = React.useState("");
  const [clientLocationArray, setclientLocationArray] = React.useState<
    clientLocationArrayType[]
  >([]);
  const [appTypeArray, setAppTypeArray] = React.useState<ApptypeType[]>([]);

  React.useEffect(() => {
    const getLocation = commonAxios.get("/ClientLocations");

    const getAppTypes = commonAxios.get("/AppTypes");

    setRequest({ ...request, loading: true });

    axios
      .all([getLocation, getAppTypes])
      .then(
        axios.spread((...responses: any) => {
          const Locations = responses[0];
          if (Locations.data) {
            let permittedValues = Locations.data.map((value: any) => ({
              clientLocationID: value.clientLocationID,
              clientLocationUniqueName: value.clientLocationUniqueName,
            }));
            setclientLocationArray(permittedValues);
          }

          const AppTypes = responses[1];

          if (AppTypes.data) {
            setAppTypeArray(AppTypes.data);
          }
          setRequest({ ...request, loading: false });
        })
      )
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });

    return () => {
      setAppTypeArray([]);
      setclientLocationArray([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [request, setRequest] = useState<ClientLocationAppType>({
    clientLocationID: null,
    appTypeID: "",
    active: true,
    refreshToggler: false,
    updatetoggler: false,
    loading: false,
  });
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;
    setRequest({ ...request, [name]: checked });
  };

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;
    setRequest({ ...request, [name]: val });
  };

  const handleUpdate = () => {
    if (!request.clientLocationID || !request.appTypeID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      clientLocationAppTypeID: request.clientLocationAppTypeID,
      appTypeID: request.appTypeID,
      active: request.active,
      clientLocationID: request.clientLocationID?.clientLocationID,
      createdBy: request.createdBy,
      createdDate: request.createdDate,
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    };

    setRequest({ ...request, loading: true });

    authAxios
      .put(
        `/UpdateClientLocationAppTypes/${request.clientLocationAppTypeID}`,
        params
      )
      .then((res: any) => {
        setRequest({
          ...request,
          clientLocationID: null,
          appTypeID: "",
          active: true,
          refreshToggler: !request.refreshToggler,
          updatetoggler: false,
          createdBy: "",
          createdDate: "",
          loading: false,
        });
      })
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleSubmit = () => {
    if (!request.clientLocationID || !request.appTypeID) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      appTypeID: request.appTypeID,
      active: request.active,
      clientLocationID: request.clientLocationID?.clientLocationID,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    };

    setRequest({ ...request, loading: true });

    authAxios
      .post("/ClientLocationAppTypes", params)
      .then((res: any) => {
        setRequest({
          ...request,
          clientLocationID: null,
          appTypeID: "",
          active: true,
          refreshToggler: !request.refreshToggler,
          loading: false,
        });
      })
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: clientLocationArrayType | null
  ) => {
    if (value) {
      setRequest({ ...request, clientLocationID: value });
    } else {
      setRequest({ ...request, clientLocationID: null });
    }
  };
  return (
    <Grid container spacing={1} maxWidth="lg">
      <Grid xs={12} sm={12} md={12} item>
        <Typography variant="displaySmall">Client Location App</Typography>
      </Grid>
      <SimpleBackdrop open={request.loading} />
      <Grid xs={12} sm={12} md={6} item>
        <Autocomplete
          id="asynchronous-demo"
          test-id="autocomplete"
          sx={{ width: "100%" }}
          isOptionEqualToValue={(option, value) =>
            option.clientLocationUniqueName === value.clientLocationUniqueName
          }
          value={request.clientLocationID}
          getOptionLabel={(option: clientLocationArrayType) =>
            option.clientLocationUniqueName
          }
          options={clientLocationArray}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={handleAutoCompleteChange}
          renderInput={(params) => (
            <TextInput
              {...params}
              size="small"
              label="Client Location ID"
              required
            />
          )}
        />
      </Grid>

      <Grid xs={12} sm={12} md={4} item>
        <SelectList
          label="App Type"
          name="appTypeID"
          id="appTypeID"
          test-id="appTypeID"
          required
          value={request.appTypeID}
          onChange={handleDropDownChange}
        >
          {appTypeArray.map((item, idx) => {
            return (
              <MenuItem value={item.appTypeID} key={idx}>
                {item.appTypeText}
              </MenuItem>
            );
          })}
        </SelectList>
      </Grid>


        <Grid
          xs={12}
          sm={12}
          md={10}
          item
          display={"flex"}
          gap={1}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography variant="body1">
            <b>Active</b>
          </Typography>
          <Switch
            {...label}
            name="active"
            id="active"
            test-id="active"
            checked={request.active}
            onChange={handleInputChange}
          />

          <Button
            variant="outlined"
            id="cancel"
            onClick={() =>
              setRequest({
                ...request,
                clientLocationID: null,
                appTypeID: "",
                active: true,
                updatetoggler: false,
                createdBy: "",
                createdDate: "",
              })
            }
          >
            Cancel
          </Button>
          {!request.updatetoggler ? (
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              id="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              id="update"
            >
              Update
            </Button>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={2} item></Grid>

      <Grid xs={12} sm={12} md={10} item>
        {clientLocationArray &&
        clientLocationArray.length > 0 &&
        appTypeArray &&
        appTypeArray.length > 0 ? (
          <ClientLocationGrid
            appTypeArray={appTypeArray}
            clientLocationArray={clientLocationArray}
            request={request}
            setRequest={setRequest}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export default ClientLocationApp;
