import React from "react";
import Grid from "@mui/material/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { handleSuccessResponse, showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { comparatorForDate, formatDateForGrid } from "@genrecp/g2clientportal-common";
import Typography from "@mui/material/Typography";
import { AgGridClasses } from "@genre/g2common-theme";
import { commonAxios } from "../../../services/axios";
import {
  GridContainer,
  LandingHeader,
  ViewSchema,
} from "../../../clientPortalUtils";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

function AllDataSync() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  const manualExecution = () => {
    setIsLoading(true);
    commonAxios
      .get("/DataSync/G2ApplicationAPILossReporting/All")
      .then((res: any) => {
        handleSuccessResponse(
          res,
          enqueueSnackbar,
          `Manual execution action has been performed. Please wait for the logs to appear before triggering another action.`
        );
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "createdDate",
        colId: "createdDate",
        headerName: "Date",
        flex: 1,
        filter:"agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams:{
          filterOptions: ["equals"],
          comparator : (filterLocalDateAtMidnight: Date, cellValue: string) => {
               return comparatorForDate(filterLocalDateAtMidnight,cellValue);
          },
          suppressAndOrCondition: true,
      },
      },
      {
        field: "triggeredBy",
        flex: 1,
        colId: "triggeredBy",
        headerName: "Triggered By",
        filter: "agTextColumnFilter",
        filterParams:{
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        }
      },
      {
        field: "status",
        flex: 1,
        colId: "status",
        headerName: "Status",
        filter: "agSetColumnFilter",
        filterParams:{
          filterOptions: ["equals"],
          values: ["Failed","Completed"],
        }
      },
      {
        field: "endTime",
        flex: 1,
        colId: "endTime",
        headerName: "Duration",
        filter:false,
        valueGetter: function (params: any) {
          let startTime = new Date(params.data.startTime);
          let endTime = new Date(params.data.endTime);
          let difference = endTime.getTime() - startTime.getTime();
          difference = difference / 1000;   
          let hourDifference = Math.floor(difference / 3600);    
          difference -= hourDifference * 3600;    
          let minuteDifference = Math.floor(difference / 60);    difference -= minuteDifference * 60;

          return `${hourDifference === 0 ? "" : hourDifference + " h "}${minuteDifference === 0 ? "" : minuteDifference + " m "} ${Math.round(difference)} s`
        },
      },
      {
        field: "note",
        flex: 4,
        colId: "note",
        headerName: "Note",
        filter: "agTextColumnFilter",
        tooltipField: "note",
        filterParams:{
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        }
      },
    ];
  }

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };

    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;
    return commonAxios.post("/DataSyncLog/GetLogs", obj).then((data: any) => {
      return data.data;
    });
  };

  return (
    <Grid container gap={2}>
      <Grid item md={12} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="displaySmall">
          {" "}
          All Data Synchronization{" "}
        </Typography>
        <LoadingButton
          onClick={() => manualExecution()}
          loading={isLoading}
          variant="contained"
        >
          Manual Execution
        </LoadingButton>
      </Grid>
      <Grid item md={12}>
        <LandingHeader>
          <GridContainer>
            <AggridWrapper
              gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
              getColumnDefs={getColumnDefs}
              getGridRowsData={getGridRowsData}
              dashboardName={""}
              enableUserViews={false}
              enableSystemViews={false}
              landingPage={"ActionLogScreen"}
              views={false}
              gridProps={{
                tooltipMouseTrack: true,
                tooltipShowDelay: 0,
              }}
              currentUser={{
                userName: createdBy && createdBy.id ? createdBy.id : "",
                email: "",
                fullName: "",
              }}
              gridHeight={window.innerHeight - 225}
              hideHeader
              rowHeight={29}
              headerHeight={32}
            ></AggridWrapper>
          </GridContainer>
        </LandingHeader>
      </Grid>
    </Grid>
  );
}

export default AllDataSync;
