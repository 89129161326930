import React from "react";
import { ClientLocationArray } from "./userUtils";

interface Props {
  children: JSX.Element;
  clientLocationArray: ClientLocationArray[];
}
function UsersGridWrapper({
  children,
  clientLocationArray,
}: Props) {

  if (clientLocationArray && clientLocationArray.length > 0) {
    return <div>{children}</div>;
  }

  return null;
}

export default UsersGridWrapper;
