import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import  Button  from "../button";
import {Tooltip} from "@mui/material";

interface Props {
  setShow: (v: boolean) => void;
}

function Toggle({ setShow }: Props) {
  const [value, setValue] = React.useState(0);

  const handleChange = () => {
    setValue(value === 0 ? 1 : 0);
    setShow(value === 0 ? true : false);
  };

  return value === 0 ? (
    <Tooltip title={"Expand Side Menu"} placement="right">
      <Button
        size="small"
        color={"primary"}
        variant="contained"
        sx={{
          background: "#3A5BAA",
          "&:hover": {
            background: "#002B73",
          },
        }}
        onClick={() => handleChange()}
        >
          <KeyboardArrowRightIcon />
      </Button>
    </Tooltip>
  ) : (
    <Tooltip title={"Collapse Side Menu"} placement="right">
      <Button
        size="small"
        color={"primary"}
        variant="contained"
        sx={{
          background: "#3A5BAA",
          "&:hover": {
            background: "#002B73",
          },
        }}
        onClick={() => handleChange()}
        >
          <KeyboardArrowLeftIcon />
      </Button>
    </Tooltip>
  );
}

export default Toggle;
