import React from "react";
import { CashTrackerProps } from "../CashTrackerUtils";
import { Grid, Typography, Stack } from "@mui/material";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";

interface Props extends CashTrackerProps {}

function GridToggler({ request, dispatch }: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  function handleGridChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const cashTrackerScreenSelectorState: any = checked
      ? "SUSPENSE"
      : "CASH_TRACKER_GRID";
    const screenName: any = checked ? "Suspense" : "Cash Tracker";
    dispatch({
      cashTrackerScreenSelector: cashTrackerScreenSelectorState,
      screenName,
    });
  }

  return request.cashTrackerScreenSelector === "SUSPENSE" ||
    request.cashTrackerScreenSelector === "CASH_TRACKER_GRID" ? (
    <Grid
      md={12}
      gap={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      item
    >
      <Stack display={"flex"} direction={"row"} gap={1}>
        <Typography variant="labelMedium">Cash Tracker</Typography>
        <Switch
          {...label}
          size="small"
          value={request.cashTrackerScreenSelector}
          checked={
            request.cashTrackerScreenSelector === "CASH_TRACKER_GRID"
              ? false
              : true
          }
          onChange={handleGridChange}
        />
        <Typography variant="labelMedium">Suspense</Typography>
      </Stack>
    </Grid>
  ) : null;
}

export default GridToggler;
