import { CashTrackerProps } from "../CashTrackerUtils";
import CashTrackerGrid from "../cashTracker/CashTrackerGrid";
import SuspenseGrid from "../suspense/SuspenseGrid";
import CashTracker from "../cashTracker/CashTrackerFormWrapper";
import CashTrackerDetailsWrapper from "../cashTrackerDetails/CashTrackerDetailsWrapper";
import SuspenseDetailWrapper from "../cashTrackerDetails/suspense/SuspenseDetailWrapper";

interface Props extends CashTrackerProps {
}

function InfoSectionSelector({
  request,
  dispatch,
}: Props) {
  switch (request.cashTrackerScreenSelector) {
    case "CASH_TRACKER_GRID":
      return <CashTrackerGrid request={request} dispatch={dispatch} />;
    case "ADD_CASH_TRACKER":
      return (
        <CashTracker
          request={request}
          dispatch={dispatch}
          updateCashTrackerForm={false}
        />
      );
    case "SUSPENSE":
      return <SuspenseGrid request={request} dispatch={dispatch} />;
    case "SUSPENSE_DETAIL":
      return <SuspenseDetailWrapper request={request} dispatch={dispatch}/>
    case "CASH_TRACKER_DETAIL":
      return (
        <CashTrackerDetailsWrapper
          request={request}
          dispatch={dispatch}
        />
      );
    default:
      return null;
  }
}

export default InfoSectionSelector;
