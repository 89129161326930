import React, { useState } from "react";
import { CashTrackerProps, SuspenseNavState } from "../../../CashTrackerUtils";
import {
  MuiStyledTabs as Tabs,
  MuiStyledTab as Tab,
} from "@genre/g2common-theme";
import Grid from "@mui/material/Grid";
import SuspenseLogInfoSectionSelector from "./SuspenseLogInfoSectionSelector";

interface Props extends CashTrackerProps {}

function SuspenseLog({ request, dispatch }: Props) {

  const externalTabs = [
    { value: "COMMENTS", label: "Comments" },
    { value: "RESEARCH_LOG", label: "Research Log" },
    { value: "RESOLUTION_LOG", label: "Resolution Log" },
  ];

  const tabs = () => {
    return externalTabs.map((item, idx) => (
      <Tab value={item.value} label={item.label} key={idx} />
    ));
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: SuspenseNavState
  ) => {
      dispatch({
        ...request,
        suspenseLogScreen : newValue
      });
  };

  return (
    <Grid container mb={2}>
      <Grid
        xs={12}
        sm={12}
        md={12}
        mb={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        item
      >
        <Tabs
          value={request.suspenseLogScreen}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs()}
        </Tabs>
      </Grid>
      <Grid xs={12} sm={12} md={12} item>
        <SuspenseLogInfoSectionSelector
          request={request}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  );
}

export default SuspenseLog;
