import React from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ICellRendererParams } from 'ag-grid-community';

interface DeleteCellRendererParams extends ICellRendererParams {
  top?: string;
}

export default function DeleteChildRenderer({top}:DeleteCellRendererParams) {
  const handleReadByClick = () => {};

  React.useEffect(() => {}, []);
  return (
    <span>
      <IconButton
        name="Remove"
        title="Remove"
        onClick={handleReadByClick}
        style={{ position: "fixed", top: top ? top :"-5px" }}
      >
        <DeleteIcon color="primary" />
      </IconButton>
    </span>
  );
}
