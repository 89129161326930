import React from 'react'
import {MuiStyledTabs as Tabs, MuiStyledTab as Tab} from "@genre/g2common-theme"
import CommonDataSync from './CommonDataSync';
import GenBillDataSync from './GenBillDataSync';
import LossReportingDataSync from './LossReportingDataSync';
import AllDataSync from './AllDataSync';
import Grid from "@mui/material/Grid";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const DataSync = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  return (
    <>
    <Grid container maxWidth="lg" marginTop={"-12px"}>
      <Grid item xs={12} sm={12} md={12} mb={1}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Common" {...a11yProps(1)} />
          <Tab label="Broker Billing" {...a11yProps(2)} />
          <Tab label="Loss Reporting" {...a11yProps(3)} />
        </Tabs>
      </Grid>
    </Grid>
      <TabPanel value={value} index={0}>
        <AllDataSync />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CommonDataSync />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GenBillDataSync />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <LossReportingDataSync />
      </TabPanel> 
    </>
  )
}
