import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { genbillActionTypes } from '../genbill/genbillActionTypes';

interface UserAccessState {
    clientLocationID:string | null;
    clientGroupID:string | null; 
}

interface AccountGroupState {
    accountingGroupID:string;
}

interface BillState {
    billID:string;
    title:string;
    billDisplayID:string;
    accountGroup:AccountGroupState;
    userAccess:UserAccessState;
}

interface BillItemFilters {
    accountingGroupID: string;
    clientBillSubmittedDate: string;
    monthEndDate: string;
}

export interface GenbillState {
    selectedMenu:string;
    redirectPath:string;
    showAddBillsGrid:boolean;
    isClientView:boolean;
    clearSortToggle:boolean;
    showSummaryDrawer:boolean;
    bill:BillState;
    billItemFilters:BillItemFilters
}

const initialState: GenbillState = {
    selectedMenu: "HOME",
    redirectPath:"USERACCESS",
    showAddBillsGrid: false,
    isClientView: false,
    clearSortToggle: false,
    showSummaryDrawer: false,
    bill:{
        billID:"",
        title:"",
        billDisplayID:"",
        accountGroup:{
            accountingGroupID:""
        },
        userAccess:{
            clientGroupID:"",
            clientLocationID:""
        }
    },
    billItemFilters:{
        accountingGroupID:"",
        clientBillSubmittedDate:"",
        monthEndDate:"",
    }
}

export const genbillSlice = createSlice({
    name: 'genbill',
    initialState,
    reducers: {
        handleGenbillState: (state = initialState, action: PayloadAction<any>)=>{
            
            const { type, payload } = action.payload;

            switch (type) {
                case genbillActionTypes.HANDLE_GENBILL_NAV: 
                    return {
                        ...state,
                        selectedMenu: payload.selectedMenu,
                    }
                case genbillActionTypes.SHOW_SUMMARY_DRAWER:
                    return {
                        ...state,
                        showSummaryDrawer:payload.showSummaryDrawer
                    }
                case genbillActionTypes.SET_CLIENT_VIEW:
                    return {
                        ...state,
                        isClientView:payload.isClientView
                    } 
                case genbillActionTypes.CLEAR_SORT_TOGGLE:
                    return {
                        ...state,
                        clearSortToggle:payload.clearSortToggle
                    }                      
                default:
                    break;
            }

        },
        handleBillState: (state = initialState, action: PayloadAction<any>)=>{
            
            const { payload } = action.payload;

            return {
                ...state,
                bill:payload
            }

        },
        handleBillItemsFilters: (state = initialState, action: PayloadAction<any>)=>{
            
            const { payload } = action;

            return {
                ...state,
                billItemFilters:payload
            }

        },
        handleRedirect: (state = initialState, action: PayloadAction<any>)=>{
            
            const { payload } = action;

            return {
                ...state,
                redirectPath:payload
            }

        }
    },
})

// Action creators are generated for each case reducer function
export const { handleGenbillState, handleBillState, handleBillItemsFilters, handleRedirect } = genbillSlice.actions

export default genbillSlice.reducer