import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ConfirmationState {
    prompt: string;
    isOpen: boolean;
    proceed?: any;
    cancel?: any;
}

const initialState: ConfirmationState = {
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null
  }

export const confirmationSlice = createSlice({
    name: 'confirmation',
    initialState,
    reducers: {
        setConfirm: (state = initialState, action: PayloadAction<any>)=>{
            const { payload } = action;
            return {
                ...state,
                prompt: payload.prompt,
                isOpen: payload.isOpen,
                proceed: payload.proceed,
                cancel: payload.cancel,
            }
        },
    },
})

export const { setConfirm } = confirmationSlice.actions

export default confirmationSlice.reducer