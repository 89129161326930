import React from "react";
import { DelegatedProps } from "../utils";
import { IncurredLossesProps } from "../../incurredLosses/utils";
import { TextInput } from "@genrecp/g2clientportal-common";
import { Autocomplete } from "@mui/material";
import SummarySkeletons from "./SummarySkeletons";

interface OthersProps {
  screen?: "INCURRED"
}
function BillPointControl({
  request,
  dispatch,
  screen
}: OthersProps & (DelegatedProps | IncurredLossesProps)) {
  const [riskStateInputValue, setRiskStateInputValue] = React.useState("");

  return (
    <SummarySkeletons condition={request.billPointsLoading}>
      <Autocomplete
        id="billPoints"
        test-id="billPoints"
        multiple
        limitTags={1}
        size="small"
        options={request.billPoints}
        value={request.selectedbillPoints}
        inputValue={riskStateInputValue}
        onInputChange={(event, newInputValue, reason) => {
          setRiskStateInputValue(newInputValue);
        }}
        disabled={screen ? false : !request.firstSubmit}
        isOptionEqualToValue={(option: string, value: string) =>
          option === value
        }
        getOptionLabel={(option: string) => option!}
        onChange={(event, value) => {
          dispatch({
            type: "UPDATE_UNIVERSAL_REQUEST",
            request: {
              ...request,
              selectedbillPoints: value,
            },
          });
        }}
        renderInput={(params) => (
          <TextInput
            {...params}
            label={
              !request.selectedbillPoints.length
                ? "All Bill Points"
                : "Selected Bill Points"
            }
            placeholder=""
          />
        )}
      />
    </SummarySkeletons>
  );
}

export default BillPointControl;
