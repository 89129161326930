import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { departmentListingActionTypes } from '../lossreporting/departmentListing/departmentListingActionTypes';
import { summaryListingActionTypes } from '../lossreporting/summaryListing/summaryListingActionTypes';
import { ModalErrorMessagesType } from '@genrecp/g2clientportal-common';

export interface DepartObject {
    department?: string;
    claimsCount?: number;
    netIncurredITDTotal?: number;
    asOfDate?: string;
    policyId?: string;
    policyDate?: string;
    policyExpirationDate?: string;
    departmentName?: string;
    businessNameAddr?: string;
}

export interface ArgsObject {
    clientLocationId: string;
    policyId:string;
    fromDate: string;
    toDate: string;
    stateCode: string;
    includeLegal:boolean;
}

export interface DepartmentState {
  args:Partial<ArgsObject>;
  departments: DepartObject[];
  error:ModalErrorMessagesType | null;
  isFetching:boolean;
  success:boolean;
  SummaryListingFetching:boolean;
  SummaryListingSuccess:boolean;
  SummaryListingError:ModalErrorMessagesType | null;
  policyIDsForSummaryListing:any;
  policyListingColumnState:any;
  asOfDate: string;
}

const initialState: Partial<DepartmentState> = {
  departments: [],
  error:null,
  isFetching:false,
  success:false,
  args:{},
  SummaryListingFetching:false,
  SummaryListingSuccess:false,
  SummaryListingError:null,
  policyIDsForSummaryListing: [],
  policyListingColumnState:null,
  asOfDate:""  
}

export const lossReportingSlice = createSlice({
  name: 'lossReporting',
  initialState,
  reducers: {
    resetDepartListing:(state = initialState) => {
      return {
        ...state,
        args:{},
        departments:[],
        error:null,
        success:false,
        isFetching:false
      }
    },
    getDepart: (state = initialState, action: PayloadAction<any> ) => {
      const {type,payload} = action.payload;
      
        switch (type) {
          case departmentListingActionTypes.GET_DEPARTMENTLISTING_START:
            return {
              ...state,
              departments:[],
              error:null,
              success:false,
              isFetching:true
            }
            case departmentListingActionTypes.GET_DEPARTMENTLISTING_SUCCESS:
              return {
                ...state,
                departments:payload,
                isFetching:false,
                error:null,
                success:true
              }
              case departmentListingActionTypes.GET_DEPARTMENTLISTING_ERROR:
                return {
                  ...state,
                  isFetching:false,
                  error:payload,
                  success:false,
                  departments:[]
                }
                case departmentListingActionTypes.GET_DEPARTMENTLISTING_ARGS:
                  return {
                    ...state,
                    args:payload
                  }
        
          default:
            break;
        }
    },
    getSummaryListingAction: (state = initialState, action: PayloadAction<any>) => {

      const { type, payload } = action.payload;

      switch (type) {
          case summaryListingActionTypes.GET_SUMMARYLISTING_START:
              return {
                  ...state,
                  SummaryListingFetching: true,
                  SummaryListingError:null
              }
          case summaryListingActionTypes.GET_SUMMARYLISTING_SUCCESS:
              return {
                  ...state,
                  SummaryListingFetching: false,
                  SummaryListingSuccess:true,
                  SummaryListingError:null
              }
          case summaryListingActionTypes.GET_SUMMARYLISTING_ERROR:
              return {
                  ...state,
                  SummaryListingFetching:false,
                  SummaryListingSuccess:false,
                  SummaryListingError:payload
              }

          default:
              break;
      }
  },  
  setPolicyIDsForSummaryListing :  (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        policyIDsForSummaryListing: action.payload.payload
      }
    },
  setPolicyListingColumnState :  (state = initialState, action: PayloadAction<any>) => {
    return {
      ...state,
      policyListingColumnState: action.payload
    }
  },
  setAsOfDateState :  (state = initialState, action: PayloadAction<any>) => {
    return {
      ...state,
      asOfDate: action.payload
    }
  }
  },
})

// Action creators are generated for each case reducer function
export const { getDepart,getSummaryListingAction, setPolicyIDsForSummaryListing,resetDepartListing, setPolicyListingColumnState, setAsOfDateState} = lossReportingSlice.actions

export default lossReportingSlice.reducer