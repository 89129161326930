import React from "react";
import { Divider } from "@mui/material";
import { TextareaAutosize } from '@mui/base';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  CustomDrawer,
  Button,
  useRibbonSnackbar,
  showInvalidSnackbar,
} from "@genrecp/g2clientportal-common";
import { DetailSpan } from "../genbillUtils";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import styled from "styled-components";
import { Spinner } from "@genrecp/g2clientportal-common";
import { currencyFormatter } from "@genrecp/g2clientportal-common";

interface Props {
  node: any;
  text: string;
}

interface CommentsList {
  commentId: string;
  billItemID: string;
  commentText: string;
  billItem?: any;
  createdDate: string;
  createdBy: string;
  modifiedDate?: any;
  modifiedBy?: any;
}

const SpinnerDiv = styled.div`
  backgroundcolor: red;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpan = styled.span<{value:number}>`
  color: ${({ value }) => value < 0 ? "#FF0000" : "#000000"};
`

export default function GeneralCommentRenderer({ node, text }: Props) {
  const [comment, setComment] = React.useState<string>("");
  const [commentsList, setCommentsList] = React.useState<CommentsList[]>([]);
  const [commentsListItem, setCommentsListItem] =
    React.useState<CommentsList | null>(null);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [editForm, setEditForm] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const {netPremiumDueAmount,policyNumber,daysOverdue,insuredName} = node?.data || {};

  const textAreaStyles = React.useMemo(
    () => ({
      //   maxWidth: "665px",
      height: "300px",
      width: "100%",
  // maxHeight: "100px",
      padding: "12px",
      fontSize: "16px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.7",
    }),
    []
  );


  React.useEffect(() => {
    if (node?.data?.hasComment && open) {
        getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getData = () => {
    setLoader(true);
    brokerBillingAxios
      .get(`/Comments/ByBillItems/${node?.data?.billItemID}`)
      .then((res: any) => {
        setLoader(false);
        const data = res.data || {};
        if (data) setCommentsList(data);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }

  const handleSubmit = () => {
    if (!comment) {
      enqueueSnackbar(`Error! comment can't be empty.`, {
        variant: "error",
      });
      return;
    }
    setLoader(true);
    brokerBillingAxios
      .post("/Comments/Create", {
        billItemID: node?.data?.billItemID,
        commentText: comment,
        createdDate: new Date().toISOString(),
        createdBy: createdBy && createdBy.id,
      })
      .then((res: any) => {
        if (res.data) {
          enqueueSnackbar(`Success!`, {
            variant: "success",
            autoHideDuration:3000
          });
          setComment("");
          getData();
        }
      })
      .catch((e: any) => {
        console.log(e);
        setLoader(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleUpdate = () => {
    if (!comment) {
      enqueueSnackbar(`Error! comment can't be empty.`, {
        variant: "error",
      });
      return;
    }
    if (!commentsListItem?.commentId) {
      enqueueSnackbar(`Error! comment ID can't be empty.`, {
        variant: "error",
      });
      return;
    }
    setLoader(true);
    brokerBillingAxios
      .put(`/Comments/Update/${commentsListItem.commentId}`, {
        ...commentsListItem,
        commentText: comment,
        modifiedDate: new Date().toISOString(),
        modifiedBy: createdBy && createdBy.id,
      })
      .then((res: any) => {
        if (res.data) {
          enqueueSnackbar(`Success!`, {
            variant: "success",
            autoHideDuration:3000
          });
          setComment("");
          setEditForm(false);
          getData();
        }
      })
      .catch((e: any) => {
        console.log(e);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleDelete = (v: string) => {
    brokerBillingAxios
      .delete(`/Comments/Delete/${v}`)
      .then((res: any) => {
        if (res.data) {
          enqueueSnackbar(`Success!`, {
            variant: "success",
            autoHideDuration:3000
          });
          getData();
        }
      })
      .catch((e: any) => {
        console.log(e);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleCancel = () => {
    setEditForm(false);
    setComment("");
    setCommentsListItem(null);
  };

  const handleEdit = (item: CommentsList) => {
    setEditForm(true);
    setComment(item.commentText);
    setCommentsListItem(item);
  };

  const purge = () => {
    node.beans.gridApi?.refreshServerSide({ purge: true });
  }

  return (
    <>
      <DetailSpan onClick={() => setOpen(true)}>
        {node?.data?.hasComment ? text : "Add Comment"}
      </DetailSpan>
      <CustomDrawer
        title="General Statement Comments"
        drawerWidth={900}
        setOpen={setOpen}
        open={open}
        purge={purge}
      >
        <Grid container spacing={2} maxWidth="lg">
          <Grid xs={12} sm={12} md={12} item mt={2} mb={4}>
            <Typography variant="h5" component="h1" align="center">
              General Statement Comments
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Insured Name - {insuredName}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              align="right"
            >
              Days Overdue - {daysOverdue}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Policy# - {policyNumber}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              align="right"
            >
              Current Amt Due -{" "}
              {netPremiumDueAmount ? (
                <StyledSpan value={netPremiumDueAmount}>
                  {currencyFormatter(netPremiumDueAmount,true)}
                </StyledSpan>
              ) : null}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} item>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter New Comments"
              style={textAreaStyles}
              value={comment}
              disabled={loader}
              maxLength={500}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setComment(e.target.value)
              }
            />
          </Grid>
          <Divider />
          <Grid xs={12} sm={12} md={6} item>
            {!editForm ? (
              <Button
                variant="contained"
                disabled={loader}
                onClick={() => handleSubmit()}
              >
                Add
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={loader}
                onClick={() => handleUpdate()}
              >
                Update
              </Button>
            )}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            display={"flex"}
            justifyContent={"end"}
          >
            <Button
              disabled={loader}
              variant="contained"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </Grid>
          {/* <Grid
            xs={12}
            sm={12}
            md={4}
            item
            display={"flex"}
            justifyContent={"end"}
          >
            <Button variant="contained">Print</Button>
          </Grid> */}
          <Grid xs={12} sm={12} md={12} item>
            {loader ? (
              <SpinnerDiv>
                <Spinner />
              </SpinnerDiv>
            ) : (
              commentsList.map((item: CommentsList, idx: number) => {
                return (
                  <Card key={idx} sx={{ marginBottom: "10px" }}>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {item.commentText}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button size="small" onClick={() => handleEdit(item)}>
                        Edit
                      </Button>
                      <Button
                        size="small"
                        disabled={loader}
                        onClick={() => handleDelete(item.commentId)}
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                );
              })
            )}
          </Grid>
        </Grid>
      </CustomDrawer>
    </>
  );
}
