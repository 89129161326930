import React from "react";
import { DelegatedIssuesProps } from "../delegatedIssuesAdminUtils";
import { Grid, Grow } from "@mui/material";
import DelegatedIssueTitle from "../delegatedIssuesComponents/DelegatedIssueTitle";
import ClientCashDetailsGrid from "./ClientCashDetailsGrid";
import ClientCashDetailsForm from "./ClientCashDetailsForm";
import {
  ClientCashDetailsItem,
  ClientCashDetailsType,
  grapCodeGenerator,
  handleReset,
} from "./clientCashDetailsUtils";
import { delegatedAxios } from "../../../../../services/axios";
import {
  CustomSnackbar,
  handleErrorResponse,
  handleSuccessResponse,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import axios from "axios";

function ClientCashDetails({ request, dispatch }: DelegatedIssuesProps) {
  const [state, setState] = React.useState<ClientCashDetailsType>({
    active: false,
    updateToggler: false,
    clientCashDetailGridItems: [],
    gridDataLoading: false,
    apiLoading: false,
    clientLocationsLoading: false,
    clientLocations: [],
    selectedClientLocation: "",
    billpointCode: "",
    g2CompanyCode: "GSI",
    mgAorBroker: "M",
    billPoints: [],
    billPointsLoading: false,
    operationContactLoading: false,
    operationContactList: [],
    branches: [],
    branchesLoading: false,
    branchCode: "",
    grapCode: "",
    clientLocationDisplayText:""
  });
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  React.useEffect(() => {
    getDropDownsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   getBillPoints(state, setState, enqueueSnackbar);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.selectedClientLocation, state.clientLocations]);

  function getDropDownsData() {
    setState((prev) => ({
      ...prev,
      operationContactLoading: true,
      gridDataLoading: true,
      branchesLoading: true,
    }));

    const getOperationContacts = delegatedAxios.get(
      "/ClientInfos/GetOperationContacts"
    );

    const getClientInfos = delegatedAxios.get("/ClientInfos");

    const getBranches = delegatedAxios.get("/ClientInfos/GetBranches");

    axios
      .all([
        getOperationContacts,
        getClientInfos,
        getBranches,
      ])
      .then(
        axios.spread((...res: any) => {
          const { data: operationsContact } = res[0] || {};
          const { data: gridData } = res[1] || {};
          const { data: branchData } = res[2] || {};

          setState((prev) => ({
            ...prev,
            operationContactList: operationsContact || [],
            clientCashDetailGridItems: gridData || [],
            branches: branchData || [],
            gridDataLoading: false,
            operationContactLoading: false,
            branchesLoading: false,
          }));
        })
      )
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          operationContactLoading: false,
          gridDataLoading: false,
          branchesLoading: false,
        }));
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function getGridData() {
    setState((prev) => ({
      ...prev,
      gridDataLoading: true,
    }));
    delegatedAxios
      .get("/ClientInfos")
      .then((res) => {
        const { data } = res || {};
        setState((prev) => ({
          ...prev,
          clientCashDetailGridItems: data || [],
          gridDataLoading: false,
        }));
        handleReset(setState);
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          gridDataLoading: false,
        }));
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function selectedClientLocationCheck() {
    if (!state.selectedClientLocation) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Client Location is required!"
      );
      return false;
    }
    return true;
  }

  function billPointCheck() {
    if (!state.billpointCode) {
      showInvalidSnackbar(null, enqueueSnackbar, "Bill Point is required!");
      return false;
    }
    return true;
  }

  function branchCheck() {
    if (!state.branchCode) {
      showInvalidSnackbar(null, enqueueSnackbar, "Branch is required!");
      return false;
    }
    return true;
  }

  function handleUpdate() {
    const clientLocationsCheck = selectedClientLocationCheck();
    if (!clientLocationsCheck) {
      return;
    }
    const bPCheck = billPointCheck();
    if (!bPCheck) {
      return;
    }
    const branchValueCheck = branchCheck();
    if (!branchValueCheck) {
      return;
    }

    const payload: ClientCashDetailsItem = {
      active: state.active,
      billpointCode: state.billpointCode,
      clientLocationID: state.selectedClientLocation,
      g2CompanyCode: state.g2CompanyCode,
      mgAorBroker: state.mgAorBroker,
      ...(state.operationsContact ? {operationsContact: state.operationsContact} : {}),
      branchCode: state.branchCode,
      grapCode: grapCodeGenerator(state, setState),
      createdBy: state.createdBy,
      createdDate: state.createdDate,
      modifiedBy: createdBy!.id,
      modifiedDate: new Date().toISOString(),
      clientInfoID: state.clientInfoID,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .put(`/ClientInfos/Update/${state.clientInfoID}`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
        }
      })
      .catch((e: any) => {
        setState({
          ...state,
          apiLoading: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  function handleSubmit() {
    const check = selectedClientLocationCheck();
    if (!check) {
      return;
    }
    const bPCheck = billPointCheck();
    if (!bPCheck) {
      return;
    }
    const branchValueCheck = branchCheck();
    if (!branchValueCheck) {
      return;
    }

    const payload: ClientCashDetailsItem = {
      active: state.active,
      billpointCode: state.billpointCode,
      clientLocationID: state.selectedClientLocation!,
      g2CompanyCode: state.g2CompanyCode,
      mgAorBroker: state.mgAorBroker,
      ...(state.operationsContact ? {operationsContact: state.operationsContact} : {}),
      branchCode: state.branchCode,
      grapCode: grapCodeGenerator(state, setState),
      createdBy: createdBy!.id,
      createdDate: new Date().toISOString(),
      modifiedBy: null,
      modifiedDate: null,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .post(`/ClientInfos/Create`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
        }
      })
      .catch((e: any) => {
        setState({
          ...state,
          apiLoading: false,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  return (
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={12} md={12} item>
          <DelegatedIssueTitle request={request} />
        </Grid>
        <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />

        <ClientCashDetailsForm
          state={state}
          setState={setState}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
        />

        <Grid xs={12} sm={12} md={12} item>
          <ClientCashDetailsGrid state={state} setState={setState} />
        </Grid>
      </Grid>
    </Grow>
  );
}

export default ClientCashDetails;
