import BillHistory from '../bills/BillHistory';
import AccountGroup from '../userAccess//accountGroup/AccountGroup';
import EmailList from '../userAccess/EmailList';
import SelectionCriteria from '../userAccess/selectionCriteria/SelectionCriteria';
import Home from './Home'
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from 'react-redux';
import UserAccessGrid from '../userAccess/subUserAccess/UserAccessGrid';
import BillDetailSelector from '../bills/BillDetailSelector';
import AccountingGroupGrid from '../userAccess/accountGroup/AccountingGroupGrid';
import NewBillSetup from '../billSetup/newBillSetup/NewBillStepper';

function InfoSectionSelector() {

    const selectedMenu = useSelector((state:RootState) => state.genbill.selectedMenu);

    switch(selectedMenu) {
        case "HOME":
            return <Home />
        case "BILLDETAILS":
            return <BillDetailSelector />
        case "HISTORY":
            return <BillHistory />
        case "USERACCESS":
            return <UserAccessGrid />  
        case "SELECTIONCRITERIA":
            return <SelectionCriteria /> 
        case "NEWBILLSETUP":
            return <NewBillSetup /> 
        case "EMAILLIST":
            return <EmailList />
        case "ACCOUNTGROUP":
            return <AccountGroup />      
        case "ACCOUNTGROUPDETAIL":
            return <AccountingGroupGrid />          
        default:
            return null;
        }
}

export default InfoSectionSelector