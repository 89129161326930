import { Skeleton } from "@mui/material";
import React from "react";

function SummarySkeletons({
  children,
  condition,
  height = "40px"
}: {
  children: JSX.Element;
  condition: boolean;
  height?:string
}) {
  return condition ? (
    <Skeleton variant="rounded" width="100%" height={height}>
    </Skeleton>
  ) : (
    children
  );
}

export default SummarySkeletons;
