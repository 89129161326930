import React from "react";
import { authAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { SelectChangeEvent } from "@mui/material";
import NewBillSetupForm from "./NewBillSetupForm";
import {
  NewBillState,
  ClientLocationArrayType,
  ModalErrorsType,
} from "../../genbillUtils";
import { handleErrorResponse } from "@genrecp/g2clientportal-common";

interface Props extends NewBillState, ModalErrorsType {}
function NewBillSetup({ state, setState, modalErrors, setModalErrors }: Props) {
  const getClientLocations = (v: string) => {
    setState({
      ...state,
      loading: true,
    });
    authAxios
      .post("/GetUserClientLocations", {
        state: v,
      })
      .then((res: any) => {
        if (res.data) {
          let permittedValues = res.data.map((value: any) => ({
            clientLocationID: value.clientLocationID,
            clientLocationUniqueName: value.clientLocationUniqueName,
          }));

          setState({
            ...state,
            clientLocationArray: permittedValues,
            loading: false,
            stateCode: v,
            clientLocation: null,
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
        setState({
          ...state,
          loading: false,
          stateCode: v,
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;

    if (name === "stateCode") {
      val
        ? getClientLocations(val)
        : setState({
            ...state,
            [name]: val,
            clientLocation: null,
          });
      return;
    }

    setState({
      ...state,
      [name]: val,
    });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ClientLocationArrayType | null
  ) => {
    setState({
      ...state,
      clientLocation: value,
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <NewBillSetupForm
      state={state}
      setState={setState}
      handleAutoCompleteChange={handleAutoCompleteChange}
      handleDropDownChange={handleDropDownChange}
      handleTextChange={handleTextChange}
    />
  );
}

export default NewBillSetup;
