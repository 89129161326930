import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { RolesAcessLookUp } from "@genrecp/g2clientportal-client-portal/src/App/Components/admin/adminUtils";
import BillDetailsLink from "./BillDetailsLink";

interface Props {
  children: JSX.Element;
}

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1),
  background: theme.palette.primary.main,
}));

const FlexSpan = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
  gap:2
}));

function HeaderFooter({ children }: Props) {
  const selectedMenu = useSelector(
    (state: RootState) => state.genbill.selectedMenu
  );
  const title = useSelector((state: RootState) => state.genbill.bill.title);
  const clientLocationID = useSelector(
    (state: RootState) => state?.genbill?.bill?.userAccess.clientLocationID
  );
  const clientGroupID = useSelector(
    (state: RootState) => state?.genbill?.bill?.userAccess.clientGroupID
  );
  const billDisplayID = useSelector(
    (state: RootState) => state?.genbill?.bill?.billDisplayID
  );

  const appRolePermissions = useSelector(
    (state: RootState) => state.auth.currentUser?.appRolePermissions
  );
  const permissions = appRolePermissions && JSON.parse(appRolePermissions);

  const brokerBillingExternalViewer = permissions?.find(
    (item: any) => item.roleId === RolesAcessLookUp.BrokerBillingExternalViewer
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: "calc( 100vh - 110px )",
      }}
    >
      {selectedMenu === "HOME" ||
      selectedMenu === "ADD" ||
      selectedMenu === "NEWBILLSETUP" ? null : (
        <Fragment>
          <Header>
            <FlexSpan>
              <BillDetailsLink
                selectedMenu={selectedMenu}
                brokerBillingExternalViewer={brokerBillingExternalViewer}
              />
              <Typography fontWeight={"bold"} color={"white"}>
                Bill ID - {billDisplayID || ""}
              </Typography>
            </FlexSpan>
            <Typography fontWeight={"bold"} color={"white"}>
              Title: {title}
            </Typography>
            {clientGroupID ? (
              <Typography fontWeight={"bold"} color={"white"}>
                Client Group ID - {clientGroupID || ""}
              </Typography>
            ) : clientLocationID ? (
              <Typography fontWeight={"bold"} color={"white"}>
                Client Location ID - {clientLocationID || ""}
              </Typography>
            ) : null}
          </Header>
        </Fragment>
      )}

      {children}

      <Box
        component="footer"
        sx={{
          py: 1,
          mt: "auto",
          backgroundColor: (theme) => theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Box>
          <Typography fontWeight={"bold"} color={"white"} textAlign={"center"}>
            Copyright@1996-2001 General Re Corporation All Rights Reserved -
            General Star Legal and Privacy Statement
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderFooter;
