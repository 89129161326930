import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { ClientLocationDomainType, clientGroupArrayType } from "../utils";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { TextInput } from "@genrecp/g2clientportal-common";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { authAxios, commonAxios } from "../../../../../services/axios";
import Typography from "@mui/material/Typography";
import { ClientLocationDomainGrid } from "./ClientLocationDomainGrid";
import Autocomplete from "@mui/material/Autocomplete";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";

export const ClientLocationDomain = () => {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [inputValue, setInputValue] = React.useState("");
  const [clientGroupArray, setclientGroupArray] = React.useState<
    clientGroupArrayType[]
  >([]);

  React.useEffect(() => {
    setRequest({ ...request, loading: true });

    commonAxios
      .get("/ClientGroups/GetAllClientGroups")
      .then((res: any) => {
        setRequest({ ...request, loading: false });
        if (res.data) {
          let permittedValues = res.data.map((value: any) => ({
            clientGroupID: value.clientGroupID,
            clientLocationUniqueName: value.clientGroupUniqueName,
          }));
          setclientGroupArray(permittedValues);
        }
      })
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });

    return () => {
      setclientGroupArray([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [request, setRequest] = useState<ClientLocationDomainType>({
    clientGroupID: null,
    domain: "",
    active: true,
    refreshToggler: false,
    updatetoggler: false,
    clientLocationDomainID: "",
    createdDate: "",
    createdBy: "",
    loading: false,
  });
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;
    setRequest({ ...request, [name]: checked });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: clientGroupArrayType | null
  ) => {
    if (value) {
      setRequest({ ...request, clientGroupID: value });
    } else {
      setRequest({ ...request, clientGroupID: null });
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const handleUpdate = () => {
    if (!request.clientGroupID || !request.domain) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      domain: request.domain,
      active: request.active,
      clientGroupID: request.clientGroupID?.clientGroupID,
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
      createdDate: request.createdDate,
      createdBy: request.createdBy,
      clientGroupDomainID: request.clientLocationDomainID,
    };

    setRequest({ ...request, loading: true });

    authAxios
      .put(
        `/UpdateClientGroupDomains/${request.clientLocationDomainID}`,
        params
      )
      .then((res: any) => {
        setRequest({
          ...request,
          clientGroupID: null,
          domain: "",
          active: true,
          refreshToggler: !request.refreshToggler,
          clientLocationDomainID: "",
          updatetoggler: false,
          createdBy: "",
          createdDate: "",
          loading: false,
        });
      })
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const handleSubmit = () => {
    if (!request.clientGroupID || !request.domain) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!createdBy) return;

    const params = {
      domain: request.domain,
      active: request.active,
      clientGroupID: request.clientGroupID?.clientGroupID,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    };

    setRequest({ ...request, loading: true });

    authAxios
      .post("/ClientGroupDomains", params)
      .then((res: any) => {
        setRequest({
          ...request,
          clientGroupID: null,
          domain: "",
          active: true,
          refreshToggler: !request.refreshToggler,
          loading: false,
        });
      })
      .catch((e: any) => {
        setRequest({ ...request, loading: false });
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  return (
    <>
      <Grid container spacing={1} maxWidth="lg">
        <Grid xs={12} sm={12} md={12} item>
          <Typography variant="displaySmall">Client Group Domain</Typography>
        </Grid>
        <SimpleBackdrop open={request.loading} />
        <Grid xs={12} sm={6} md={6} item>
          <Autocomplete
            id="asynchronous-demo"
            test-id="autocomplete"
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.clientLocationUniqueName === value.clientLocationUniqueName
            }
            value={request.clientGroupID}
            getOptionLabel={(option: clientGroupArrayType) =>
              option.clientLocationUniqueName
            }
            options={clientGroupArray}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={handleAutoCompleteChange}
            renderInput={(params) => (
              <TextInput
                {...params}
                size="small"
                label="Client Group ID"
                required
              />
            )}
          />
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <TextInput
            inputProps={{ maxLength: 50 }}
            name={"domain"}
            value={request.domain}
            onChange={handleTextChange}
            label="Domain"
            required
            id="domain"
          />
        </Grid>

        <Grid
          gap={1}
          mt={1}
          sx={{ display: "flex", justifyContent: "end", alignItems:"center" }}
          md={10}
          item
        >
          <Typography variant="body1">
            <b>Active</b>
          </Typography>
          <Switch
            {...label}
            name="active"
            test-id="active"
            id="active"
            checked={request.active}
            onChange={handleInputChange}
          />

          <Button
            variant="outlined"
            onClick={() =>
              setRequest({
                ...request,
                clientGroupID: null,
                domain: "",
                active: true,
                createdBy: "",
                createdDate: "",
                updatetoggler: false,
                clientLocationDomainID: "",
              })
            }
            id="cancel"
          >
            Cancel
          </Button>
          {!request.updatetoggler ? (
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              id="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              id="update"
            >
              Update
            </Button>
          )}
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          {clientGroupArray && clientGroupArray.length > 0 ? (
            <ClientLocationDomainGrid
              clientGroupArray={clientGroupArray}
              request={request}
              setRequest={setRequest}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
