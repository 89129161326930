import React from "react";
import {
  BillObj,
  ModalErrorsType,
  NewBillState,
  PermissionLevelObject,
  StyledSpan,
  permissionLevelArray,
} from "../../genbillUtils";
import { Grid } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import {
  CellValueChangedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { Button, handleErrorResponse } from "@genrecp/g2clientportal-common";

interface Props extends NewBillState, ModalErrorsType {
  handleNext: () => void;
}
function NewBillUserAccess({
  state,
  setState,
  modalErrors,
  setModalErrors,
  handleNext,
}: Props) {
  const [rowData, setRowData] = React.useState<any[]>();
  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

  function findName(v: number) {
    const found = permissionLevelArray.find(
      (item: PermissionLevelObject) => item.value === v
    );
    return (
      (
        <StyledSpan>
          {" "}
          <span> {found?.name} </span>{" "}
        </StyledSpan>
      ) || ""
    );
  }

  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: "status",
      colId: "status",
      hide: true,
      // rowGroup: true,
    },
    {
      field: "firstName",
      colId: "firstName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      checkboxSelection: (params: any) => {
        return params.data ? true : false;
      },
      headerCheckboxSelection: true,
    },
    {
      field: "lastName",
      colId: "lastName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "userLoginID",
      colId: "userLoginID",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "emailAddress",
      colId: "emailAddress",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "permissionLevelID",
      colId: "permissionLevelID",
      headerName: "Permissions",
      // enables editing
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["Admin", "Contributor", "Viewer"],
      },
      cellEditorPopup: true,
      valueGetter: (params: any) => {
        if (params.data && params.data.permissionLevelID === 0) {
          return "";
        }
      },
      cellRenderer: (params: any) => {
        return (
          <StyledSpan>
            {!params.data.permissionLevelID
              ? "Select Permissions"
              : findName(params.data.permissionLevelID)}
            <KeyboardArrowDownIcon
              style={{ position: "relative", top: "6px" }}
            />
          </StyledSpan>
        );
      },
      filter: false,
    },
  ]);
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    enableRowGroup: true,
  };
  const onGridReady = (params: any) => {
    params?.api?.sizeColumnsToFit();
    setGridApi(params.api);
    getData();
  };

  React.useEffect(() => {
    return () => {
      gridApi && gridApi.destroy();
      setGridApi(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    let url = "";
    if (state.clientLocation) {
      url = `/Bills/GetUsersBySelectedLocation/${state.clientLocation.clientLocationID}`;
    }
    if (state.groupID) {
      url = `/Bills/GetUsersBySelectedGroup/${state.groupID}`;
    }
    if (!url) return;

    brokerBillingAxios
      .get(url)
      .then((res: any) => {
        setRowData(res.data);
      })
      .catch((e: any) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const onSelectionChanged = React.useCallback(
    (event: SelectionChangedEvent) => {
      let rows: IRowNode<any>[] = event.api.getSelectedNodes();
      setState({
        ...state,
        assignedUserAccessList: rows.map((item: IRowNode) => item.data),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCellValueChanged = React.useCallback(
    (params: CellValueChangedEvent) => {
      let permLevelID: PermissionLevelObject | undefined;
      if (
        params.data.permissionLevelID &&
        typeof params.data.permissionLevelID === "number"
      ) {
        return;
      }

      permLevelID = permissionLevelArray.find(
        (item: PermissionLevelObject) =>
          item.name === params.node.data.permissionLevelID
      );
      params.node.setDataValue("permissionLevelID", permLevelID?.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const assignViewer = () => {
    const newViewerAssignedList = state.assignedUserAccessList.map(
      (item: BillObj) => ({
        ...item,
        permissionLevelID: 3,
      })
    );
    setState({
      ...state,
      assignedUserAccessList: newViewerAssignedList,
    });
    handleNext();
  };

  return (
    <Grid container>
      <Grid
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        style={{ width: "100%", height: window.innerHeight - 300 }}
      >
        <AgGridReact
          rowHeight={29}
          headerHeight={32}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          groupDisplayType={"singleColumn"}
          singleClickEdit={true}
          onCellValueChanged={onCellValueChanged}
          stopEditingWhenCellsLoseFocus={true}
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </Grid>
      {state.assignedUserAccessList.length === 0 ? null : (
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          md={12}
          lg={12}
          mt={1}
        >
          <Button variant="containedTertiary" onClick={assignViewer}>
            Assign Viewer Permission
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default NewBillUserAccess;
