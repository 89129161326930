import React from "react";
import Grid from "@mui/material/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { handleSuccessResponse, showInvalidSnackbar, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { comparatorForDate, formatDateForGrid } from "@genrecp/g2clientportal-common";
import Typography from "@mui/material/Typography";
import { AgGridClasses } from "@genre/g2common-theme";
import { commonAxios } from "../../../services/axios";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { Toggle } from "@genrecp/g2clientportal-common";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import {
  GridContainer,
  LandingHeader,
  ViewSchema,
} from "../../../clientPortalUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

let tableName: string = "ClientLocation";
type SelectedTab = "ClientLocation" | "ClientGroup";

function CommonDataSync() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [selected, setSelected] = React.useState<SelectedTab>("ClientLocation");
  const [showSideMenu, setShow] = React.useState<boolean>(false);
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const gridRef: any = React.useRef(null);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    payload: SelectedTab
  ) => {
    tableName = payload;
    setSelected(payload);
  };

  const manualExecution = () => {
    setIsLoading(true);
    commonAxios
      .get(`/DataSync/G2ApplicationAPIClientLocation/${selected}`)
      .then((res: any) => {
        handleSuccessResponse(
          res,
          enqueueSnackbar,
          `Manual execution action has been performed. Please wait for the logs to appear before triggering another action.`
        );
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "createdDate",
        colId: "createdDate",
        headerName: "Date",
        flex: 1,
        filter:"agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams:{
          filterOptions: ["equals"],
          comparator : (filterLocalDateAtMidnight: Date, cellValue: string) => {
               return comparatorForDate(filterLocalDateAtMidnight,cellValue);
          },
          suppressAndOrCondition: true,
      },
      },
      {
        field: "triggeredBy",
        colId: "triggeredBy",
        headerName: "Triggered By",
        filter: "agTextColumnFilter",
        flex: 1,
        filterParams:{
          suppressAndOrCondition: true,
          filterOptions: ["contains"]
        }
      },
      {
        field: "status",
        colId: "status",
        headerName: "Status",
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams:{
          filterOptions: ["equals"],
          values: ["Failed","Completed"],
        }
      },
      {
        field: "endTime",
        colId: "endTime",
        headerName: "Duration",
        filter:false,
        valueGetter: function (params: any) {
          let startTime = new Date(params.data.startTime);
          let endTime = new Date(params.data.endTime);
          let difference = endTime.getTime() - startTime.getTime();
          difference = difference / 1000;   
          let hourDifference = Math.floor(difference / 3600);    
          difference -= hourDifference * 3600;    
          let minuteDifference = Math.floor(difference / 60);    difference -= minuteDifference * 60;

          return `${hourDifference === 0 ? "" : hourDifference + " h "}${minuteDifference === 0 ? "" : minuteDifference + " m "} ${Math.round(difference)} s`
        },
        flex: 1,
      },
      {
        field: "note",
        colId: "note",
        headerName: "Note",
        filter: "agTextColumnFilter",
        flex: 4,
        tooltipField: "note",
        filterParams:{
          suppressAndOrCondition: true,
          filterOptions: ["contains"]
        }
      },
    ];
  }

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    newObj.qTableName = tableName;

    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;
    return commonAxios.post("/DataSyncLog/GetLogs", obj).then((data: any) => {
      return data.data;
    });
  };

  React.useEffect(() => {
    gridRef && gridRef?.current?.api?.refreshServerSide({ purge: true });
  }, [selected]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Toggle setShow={setShow} />
          <Typography variant="displaySmall">
            {selected === "ClientLocation" ? "Client Location" : "Client Group"}{" "}
            Data Synchronization
          </Typography>
        </Box>
        <LoadingButton
          onClick={() => manualExecution()}
          loading={isLoading}
          variant="contained"
        >
          Manual Execution
        </LoadingButton>
      </Grid>
      {showSideMenu ? (
        <Grid item md={2}>
          <List
          dense
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListItemButton
                sx={{
                  background: "#3A5BAA",
                  "&:hover": {
                    background: "#002B73",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall" color={"#fff"}>
                      Data Synchronization
                    </Typography>
                  }
                />
              </ListItemButton>
            }
          >
            <ListItem disablePadding>
              <ListItemButton
                id="searchbyClient"
                selected={selected === "ClientLocation"}
                onClick={(event) =>
                  handleListItemClick(event, "ClientLocation")
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall">Client Location</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                id="searchbyPolicy"
                selected={selected === "ClientGroup"}
                onClick={(event) => handleListItemClick(event, "ClientGroup")}
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall">Client Group</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </List>
        </Grid>
      ) : null}
      <Grid item md={!showSideMenu ? 12 : 10}>
        <LandingHeader>
          <GridContainer>
            <AggridWrapper
              gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
              getColumnDefs={getColumnDefs}
              getGridRowsData={getGridRowsData}
              dashboardName={""}
              enableUserViews={false}
              enableSystemViews={false}
              landingPage={"ActionLogScreen"}
              views={false}
              gridProps={{
                tooltipMouseTrack: true,
                tooltipShowDelay: 0,
              }}
              currentUser={{
                userName: createdBy && createdBy.id ? createdBy.id : "",
                email: "",
                fullName: "",
              }}
              gridHeight={window.innerHeight - 225}
              ref={gridRef}
              rowHeight={29}
              headerHeight={32}
              hideHeader
            ></AggridWrapper>
          </GridContainer>
        </LandingHeader>
      </Grid>
    </Grid>
  );
}

export default CommonDataSync;
