import { Button, MenuItem, Popover } from "@mui/material";
import React from "react";
import { UserRequestObject, UserDispatch } from "./userUtils";

type LoginIDAppendButtonProps = {
  userRequest: UserRequestObject;
  domain: string;
  updatetoggler: boolean;
  dispatch: UserDispatch
};

export const LoginIDAppendButton = ({
  userRequest,
  domain,
  updatetoggler,
  dispatch
}: LoginIDAppendButtonProps) => {
    
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
  const options = ["@generalstar.com", "@genre.com", "@gumc.com"];
  const internalUsers = !userRequest.isManagedByG2 && !userRequest.external;
  

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function appendDomain(domain:string) {
    dispatch({
      userLoginID: userRequest.userLoginID + domain,
    });
    setAnchorEl(null);
  }

  if (internalUsers) {
    return (
      <>
        <Button aria-describedby={id} variant="containedTertiary" onClick={handleClick} sx={{width:"13rem",marginLeft:2}}>
           Append Domain
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {options.map((item) => (
            <MenuItem onClick={appendDomain.bind(null,item)}>{item}</MenuItem>
          ))}
        </Popover>
      </>
    );
  }

  return domain ? (
      <Button
        disabled={updatetoggler && userRequest.external}
        variant="containedTertiary"
        size="small"
        sx={{width:"13rem",marginLeft:2}}
        onClick={appendDomain.bind(null,"@genstarinsext.com")}
      >
        Append Domain
      </Button>
  ) : null;
};
