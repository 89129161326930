import React from "react";
import { useSelector } from "react-redux";
import { authAxios } from "../../../../../services/axios";
import { RootState } from "../../../../../redux/store";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import {
  Action,
  RequestType,
  appRoleTableLookup,
  appTypeLookup,
  permissionLevelLookup,
  PermissionLevelArray,
} from "../appRolePermissionUtils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {EditChildRenderer, DeleteChildRenderer, showInvalidSnackbar} from '@genrecp/g2clientportal-common';
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common/src/components/snackBar/SnackBarComp";
import { filterColumnsObjCreator } from "@genrecp/g2clientportal-common";
import { RoleType } from '../appRolePermissionUtils';
import {AgGridClasses} from "@genre/g2common-theme"

type Dispatch = React.Dispatch<Action>;

export interface Combine {
  request: RequestType;
  dispatch: Dispatch;
  setRoleArray:(v:RoleType[])=>void;
}

let gridApi: any;
let columnApi: any;

export const AppRolePermissionGrid: React.FC<Combine> = (props) => {

  const { request, dispatch, setRoleArray} = props;
  const token = useSelector((state: RootState) => state?.auth?.token);
  const { enqueueSnackbar } = useRibbonSnackbar();

  const frameworkComponents = {
    DeleteChildRenderer: DeleteChildRenderer,
    EditChildRenderer: EditChildRenderer
  };

  const onRowEditSelected = (params:any) => {
    const {permissionLevelID,roleID,appTypeID,appRolePermissionID,createdDate,createdBy} = params.data;
    request.appTypeArray.forEach((item)=>{
      if(item.appTypeID === appTypeID)
      {
        return setRoleArray(item.roles);
      }
    })
    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, updateRequest: true,permissionLevelID,roleID,appTypeID,appRolePermissionID,createdDate,createdBy  },
    });
    }

  const onDeleteRowSelected = (params:any) => {

    const {appRolePermissionID} = params.data;

    dispatch({
      type: "UPDATE_UNIVERSAL_REQUEST",
      request: { ...request, loading:true },
    });

    authAxios
    .delete(`/AppRolePermission/${appRolePermissionID}`).then((res:any) => {

        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: { ...request, refreshToggler: !request.refreshToggler ,loading:false },
        });
      }
      
    ).catch((e:any)=>{
      
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: { ...request, loading:false },
      });
      showInvalidSnackbar(e,enqueueSnackbar);
    })
  }

  const defaultColDef:ColDef = {
    // cellClass: "cell-wrap-text",
    cellStyle: { "whiteSpace": "normal" },
    sortable: true,
    resizable: true,
    floatingFilter:true,
    cellClass: "cell-wrap-text",
  };

  const colDefs: ColDef[] = [
    {
      field: "appRolePermissionID",
      colId: "appRolePermissionID",
      headerName: "App Role Permission ID",
      filter: "agTextColumnFilter",
      sort:"desc",
      hide:true
    },
    {
      field: "appTypeID",
      colId: "appTypeID",
      headerName: "App Type",
      filter: "agSetColumnFilter",
      flex: .5,
      filterParams: {
        values: request.appTypeArray.map((i:any) => i.appTypeText),
        suppressAndOrCondition: true
    },
      valueGetter: function (params: any) {
        return appTypeLookup(request.appTypeArray, params.data.appTypeID);
      },
    },
    {
      field: "roleID",
      colId: "roleID",
      headerName: "Role",
      filter: "agSetColumnFilter",
      flex: .5,
      filterParams: {
        values: request.roleArray.map((i:any) => i.name),
        suppressAndOrCondition: true
    },
      valueGetter: function (params: any) {
        return appRoleTableLookup(request.roleArray, params.data.roleID);
      },
    },
    {
      field: "permissionLevelID",
      colId: "permissionLevelID",
      headerName: "Permission Level",
      filter: "agSetColumnFilter",
      flex: .5,
      filterParams: {
        values: PermissionLevelArray.map((i:any) => i.name),
        suppressAndOrCondition: true
    },
      valueGetter: function (params: any) {
        return permissionLevelLookup(
          PermissionLevelArray,
          params.data.permissionLevelID
        );
      },
    },
    {
      headerName: '',
      onCellClicked: onRowEditSelected,
      cellRenderer: 'EditChildRenderer',
      flex: .1,
      width: 44,
    },
    {
      headerName: '',
      onCellClicked: onDeleteRowSelected,
      cellRenderer: 'DeleteChildRenderer',
      flex: .1,
      width: 44,
    },
  ];

  
  const ensureNonEmptyString = (str: any) => (typeof str === 'string' && str.trim() !== '');

  function getSortedColumns(columnApi: any) {
      return columnApi?.getColumnState().filter((cs: any) => ensureNonEmptyString(cs.sort));;
  }

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
                let filterModel = gridApi.getFilterModel();
                let filterCount = Object.keys(gridApi.getFilterModel()).length;
                let sortCount = getSortedColumns(columnApi).length
                let searchDataObj:any = {
                    "pageNumber": params.request.endRow / 10,
                    "pageSize": 10,
                };
                if (filterCount > 0) {
                    let gridOpeartionProps = [];
                    // appRolePermissionID
                    if (filterModel.appRolePermissionID !== undefined) {
                       let appRolePermissionIDObj = filterColumnsObjCreator("appRolePermissionID",filterModel.appRolePermissionID.filter,"int");
                       gridOpeartionProps.push(appRolePermissionIDObj);
                    }
                    // appTypeID
                    if (filterModel.appTypeID !== undefined && filterModel.appTypeID.values.length > 0) {

                      let finalappTypeID:string[] = [];
                      filterModel.appTypeID.values.forEach((element:string) => {
                        finalappTypeID.push(appTypeLookup(request.appTypeArray,element));
                      });
                      let appTypeIDObj = filterColumnsObjCreator("appTypeID",finalappTypeID,"int[]");
                      gridOpeartionProps.push(appTypeIDObj);

                    }
                    // roleID
                    if (filterModel.roleID !== undefined && filterModel.roleID.values.length > 0) {

                      let finalroleID:string[] = [];
                      filterModel.roleID.values.forEach((element:string) => {
                        finalroleID.push(appRoleTableLookup(request.roleArray,element));
                      });
                      let roleIDObj = filterColumnsObjCreator("roleID",finalroleID,"int[]");
                      gridOpeartionProps.push(roleIDObj);
                    }                                        
                    // permissionLevelID
                    if (filterModel.permissionLevelID !== undefined && filterModel.permissionLevelID.values.length > 0) {

                      let finalpermissionLevelID:string[] = [];
                      filterModel.permissionLevelID.values.forEach((element:string) => {
                        finalpermissionLevelID.push(permissionLevelLookup(PermissionLevelArray,element));
                      });
                      let permissionLevelIDObj = filterColumnsObjCreator("permissionLevelID",finalpermissionLevelID,"int[]");
                      gridOpeartionProps.push(permissionLevelIDObj);
                    }  


                    if(gridOpeartionProps.length) searchDataObj["filterColumns"] = gridOpeartionProps;
                }
                if (sortCount > 0) {
                  const sortCol = getSortedColumns(columnApi)[0];
                  let colID = sortCol.colId;
                  if(colID === "appTypeID") {
                    colID = "appTypeName";
                  }
                  if(colID === "roleID") {
                    colID = "roleName";
                  }
                  searchDataObj.orderBy = colID + " " + sortCol.sort;
                }  
                
                if (token) {
                  
                  authAxios
                    .post(
                      "/GetAppRolePermissions",
                      {
                        gridOperationsProps: searchDataObj,
                      }
                    )
                    .then((res: any) => {
                      var totalRows = -1;
                      if (res.data !== null && res.data !== undefined) {
                        if (res.data.length < 10) {
                          totalRows = params.request.startRow + res.data.length;
                        }
                        params.successCallback(res.data, totalRows);
                      } else {
                        params.successCallback([], totalRows);
                      }
                    })
                    .catch((e: any) => {
                      console.log(e.message);
                    });
                }
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };

  const refreshGrid = (gridApi:any) => {
    if(gridApi){
        var datasource = ServerSideDatasource();
        gridApi.setServerSideDatasource(datasource);
    }
}

  React.useEffect(() => {
    refreshGrid(gridApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.refreshToggler]);

  React.useEffect(() => {
    return () => {
      gridApi && gridApi.destroy();
      gridApi = null
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
          <div style={{ width: "100%", height: 505 }} className={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
            <AgGridReact
              rowHeight={29}
              headerHeight={32}
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              rowModelType={"serverSide"}
              suppressServerSideInfiniteScroll={false}
              cacheBlockSize={10}
              animateRows={true}
              components={frameworkComponents}
            ></AgGridReact>
          </div>
    </>
  );
};

export default AppRolePermissionGrid;
