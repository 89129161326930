import "./homeGrid.css";
import { BillFilters, DetailSpan, GridContainer, LandingHeader, statusParser } from "../genbillUtils";
import { CustomSnackbar, ModalErrors, SimpleBackdrop, handleErrorResponse, showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import {
  AppDispatch,
  RootState,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  brokerBillingAxios,
  commonAxios,
} from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import React, { useRef } from "react";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { RolesAcessLookUp } from "@genrecp/g2clientportal-client-portal/src/App/Components/admin/adminUtils";
import { ViewSchema } from "../genbillUtils";
import { AgGridClasses } from "@genre/g2common-theme";
import { SetFilterValuesFuncParams } from "ag-grid-community";
import { ICellRendererParams } from 'ag-grid-community';
import { BillType, fillBillDetails, navigateTo } from "./homeUtils";

const SubApp = () => {
  const $dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });

  const currentViewRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const [view, setView] = React.useState<ViewSchema>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );

  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));
  const appRolePermissions = useSelector((state:RootState) => state.auth.currentUser?.appRolePermissions);
  const permissions = appRolePermissions && JSON.parse(appRolePermissions);

  const brokerBillingExternalViewer = permissions?.find(
    (item: any) => item.roleId === RolesAcessLookUp.BrokerBillingExternalViewer
  );

  const statusEnumObj = {
    Disabled: 2,
    Load: 5,
    New: 6,
    Received: 7,
    Review: 8,
    Sent: 9,
  } as const;

  const getGridRowsData = async (
    pagination: Object,
    filterModel: any,
    sortModel: Array<Object>,
    selectedView: any | undefined,
  ) => {
      currentViewRef.current = selectedView;
      if (selectedView?.userGridViewID) {
        sessionStorage.setItem(
          "selectedViewID",
          (selectedView || {}).userGridViewID ?? ""
        );
        sessionStorage.setItem("selectedView", JSON.stringify(selectedView));
      }

      setView(selectedView);
      const newObj: any = {
        ...filterModel,
        ...((selectedView?.screenName === "billsAssigned" ||
          selectedView?.screenName === "billAssignedExternal") && {
          assignedToUserID: createdBy!.id,
        }),
      };

      if (newObj.statementStatusTypeID) {
        const tempStatus = newObj.statementStatusTypeID
          .split(",")
          .map((item: string) => statusParser(item.trim()));
        newObj.statementStatusTypeID = tempStatus.join();
      }

      const obj: any = {
        ...pagination,
        ...newObj,
        orderBy: sortModel
          .map((model: any) => model.colId + " " + model.sort)
          .join(", "),
      };

      if(obj.title) {
        obj.title = obj.title.split(",").map((item:string) => item.trim())
      }

      if (obj.orderBy === "") delete obj.orderBy;
      if (obj?.title === "") delete obj.title;

      return brokerBillingAxios
        .post("/Bills/GetBills", obj)
        .then((res: any) => {
          const {data} = res || {};
          if (data) {
            return data;
          } else {
            return [];
          }
   
        }).catch(e => {
          console.log(e);
          const modalErrorMessages = handleErrorResponse(e);
          setModalErrors({
            ...modalErrors,
            errors: modalErrorMessages,
            ErrorModelOpen: true,
          });
          return [];
        });
  };

  const columnDefs = [
    { field: "billID", colId: "billID", width: 110, hide: true },
    { field: "billDisplayID", colId: "billDisplayID", headerName:"Bill ID", flex: 1,filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
     },
    {
      field: "title",
      filter: "agSetColumnFilter",
      colId: "title",
      flex: 1.9,
      filterParams: {
        buttons:["reset"],
        suppressSelectAll:true,
        defaultToNothingSelected:true,
        filterOptions: ["equals"],
         values: async (params: SetFilterValuesFuncParams) => {
          let values = await getFilers();
          values = values.map((i:BillFilters) => i.title);
          params.success(values);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "statementStatusTypeID",
      colId: "statementStatusTypeID",
      sortable:false,
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        buttons:["reset"],
        suppressSelectAll:true,
        defaultToNothingSelected:true,
        values: Object?.keys(statusEnumObj).map((key: any) => key),
        suppressAndOrCondition: true,
      },
      flex: 1,
      valueGetter: function (params: any) {
        return statusParser(params.data.statementStatusTypeID);
      },
    },
    {
      field: "clientLocationID",
      hide:true,
      colId: "clientLocationID",
      filter: "agTextColumnFilter",
      headerName: "Client Location ID",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 1,
    },
    {
      field: "clientLocationName",
      colId: "clientLocationName",
      filter: "agTextColumnFilter",
      headerName: "Client Name",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 2,
    },
    {
      field: "clientGroupName",
      colId: "clientGroupName",
      filter: "agTextColumnFilter",
      headerName: "Client Group",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 2,
    },
    {
      field: "billOwner",
      colId: "billOwner",
      filter: "agTextColumnFilter",
      headerName: "Bill Owner",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 2,
    },
    {
      field: "clientGroupID",
      hide:true,
      colId: "clientGroupID",
      filter: "agTextColumnFilter",
      headerName: "Client Group ID",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 1,
    },
    {
      field: "billDetails",
      colId: "billDetails",
      headerName: "",
      sortable:false,
      flex: .9,
      filter: false,
      cellRenderer: function (params: ICellRendererParams<BillType>) {
        return (params?.data?.statementStatusTypeID === 2) ? " Bill Details" :(
          <DetailSpan
            onClick={() => {
              fillBillDetails(params,$dispatch);
              navigateTo("BILLDETAILS",$dispatch);
            }}
          >
            Bill Details
          </DetailSpan>
        );
      },
    },
    {
      field: "history",
      colId: "history",
      headerName: "",
      sortable:false,
      filter: false,
      floatingFilter: false,
      flex: .6,
      cellRenderer: function (params: ICellRendererParams<BillType>) {
        return (
          <DetailSpan
            onClick={() => {
              fillBillDetails(params,$dispatch);
              navigateTo("HISTORY",$dispatch);
            }}
          >
            Hist
          </DetailSpan>
        );
      },
    },
    {
      flex: 1,
      filter: false,
      floatingFilter: false,
      field: "editBills",
      colId: "editBills",
      headerName: "",
      sortable:false,
      cellRenderer: (params: ICellRendererParams<BillType>) => {
        return (params?.data?.statementStatusTypeID === 2) ? "Edit Bill Setup" : (
          <DetailSpan
            onClick={() => {
              fillBillDetails(params,$dispatch);
              navigateTo("USERACCESS",$dispatch);
            }}
          >
            Edit Bill Setup
          </DetailSpan>
        );
      },
    },
  ];

  function getColumnDefs(view: ViewSchema | undefined) {
    let filteredColDefs = columnDefs;

    if(brokerBillingExternalViewer){
      filteredColDefs = columnDefs.filter((item:any) => item.field !== "editBills");
    }
    if(isExternalUser) {
      filteredColDefs = columnDefs.filter((item:any) => item.field !== "statementStatusTypeID");
    }
    return filteredColDefs;
  }

  async function getFilers(){
   return brokerBillingAxios
      .get("/Bills/GetBillsForFilter")
      .then((res: any) => {
        const {data} = res || {};
        return data ? data : []
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return []
      });
  }

  const getGridViewsData = async () => {
    setLoading(true);
    return commonAxios
      .get(`/UserGridViews/GetUserGridViewByUserAndAppID/${createdBy!.id}/${3}`)
      .then((res: any) => {
        setLoading(false);
        const billsData = res?.data || {};

        return billsData ? billsData :[];
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  const createGridViewsData: (view: ViewSchema) => Promise<any> = async (
    view: ViewSchema
  ) => {
    const temp: any = { ...view };
    temp.appTypeID = 3;
    return new Promise((resolve, reject) => {
      commonAxios
        .post("/UserGridViews/Create", temp)
        .then((resp) => {
          if (resp.status === 200) {
            sessionStorage.setItem(
              "selectedViewID",
              resp?.data?.userGridViewID ?? ""
            );
            resolve(resp.data);
          } else reject();
        })
        .catch((e) => {
          showInvalidSnackbar(e,enqueueSnackbar);
          reject(e);
        });
    });
  };

  const updateGridViewsData = async (view: ViewSchema) => {
    return commonAxios
      .put(`/UserGridViews/Update/${view.userGridViewID}`, view)
      .then((data: any) => console.log(data))
      .catch(function (e: any) {
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const deleteGridViewsData = async (id: string) => {
    return commonAxios
      .delete(`UserGridViews/Delete/${id}`)
      .then((data: any) => {
        return true;
      })
      .catch(function (e: any) {
        showInvalidSnackbar(e,enqueueSnackbar);
        return false;
      });
  };
  return (
    <LandingHeader>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SimpleBackdrop open={loading} />
      <GridContainer>
        <AggridWrapper
          gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
          getColumnDefs={getColumnDefs}
          getGridRowsData={getGridRowsData}
          dashboardName={view ? view.viewName : ""}
          applyViewId={localStorage.getItem("selectedViewID") ?? undefined}
          enableUserViews={true}
          enableSystemViews={true}
          landingPage={"BillsScreen"}
          views={true}
          ref={gridRef}
          currentUser={{
            userName: createdBy && createdBy.id ? createdBy.id : "",
            email: "",
            fullName: "",
          }}
          showNoneInViews={false}
          gridHeight={isExternalUser ? window.innerHeight - 200 : window.innerHeight - 250}
          createGridViewsData={createGridViewsData}
          updateGridViewsData={updateGridViewsData}
          deleteGridViewsData={deleteGridViewsData}
          getGridViewsData={getGridViewsData}
          rowHeight={29}
          headerHeight={32}
        ></AggridWrapper>
      </GridContainer>
    </LandingHeader>
  );
};

export default SubApp;
