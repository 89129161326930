import { ICellRendererParams } from "ag-grid-community";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

const DetailCellRenderer = ({ data }: ICellRendererParams) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 752,
        height: data?.clientLocationID
        .trim()
        .split(",").length === 1 ? "90px" : "220px",
        margin: "auto",
        padding: "20px",
      }}
    >
      <List dense={true} sx={{ 
        bgcolor: "background.paper",
        overflow: 'auto',
        maxHeight: '182px',
        boxShadow: 3 
         }}>
        {data?.clientLocationID
          .trim()
          .split(",")
          .map((item: string) => {
            return (
              <ListItem>
                <ListItemText primary={item} />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default DetailCellRenderer;
