import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import React from "react";
import { ColDef } from "ag-grid-community";
import {
  comparatorForDate,
  formatDateForGrid,
  GridLoadingSpinner,
} from "@genrecp/g2clientportal-common";
import Grow from "@mui/material/Grow";
import { CashTrackerProps } from "../CashTrackerUtils";

interface Props extends CashTrackerProps {}

function CashLogGrid({ request }: Props) {
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const colDefs: ColDef[] = [
    {
      colId: "createdDate",
      field: "createdDate",
      filter: "agDateColumnFilter",
      headerName: "Created Date",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.createdDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue, true);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "createdByName",
      field: "createdByName",
      tooltipField: "createdByName",
      headerName: "Created By Name",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "actionlog",
      field: "actionlog",
      tooltipField: "actionlog",
      flex: 4,
      headerName: "Action log",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
  ];

  return (
    <GridLoadingSpinner loading={request.logGridDataLoader} style={{alignItems:"flex-start", paddingTop:20}}>
      <Grow in={true} style={{ transitionDelay: "100ms", marginTop: 10 }}>
        <div
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
          style={{ width: "100%", height: window.innerHeight - 510 }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={request.logGridData}
            pagination={true}
            paginationPageSize={5}
            animateRows={true}
            rowHeight={29}
            headerHeight={32}
            tooltipMouseTrack={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </Grow>
    </GridLoadingSpinner>
  );
}

export default CashLogGrid;
