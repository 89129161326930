export const DocArray = [
  "Supplemental Applications",
  "Underwriting Guide",
  // "Oracle and Docucorp Libraries",
  "Applications",
] as const;

export interface DocumentType {
  documentName:Document;
  setDocumentName:(v:Document) => void;
}

export interface DocFolderStructure {
  loading:boolean;
  data:any;
}

export interface Document {
  folder:string;
  subFolder:string;
}