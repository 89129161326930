import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridReadyEvent,
  ICellEditorParams,
  CellEditorSelectorResult,
  GridApi,
  ValueGetterParams,
  SelectionChangedEvent,
  IRowNode,
} from "ag-grid-community";
import { useSelector } from "react-redux";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import {
  CustomSnackbar,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { Button } from "@genrecp/g2clientportal-common";
import Grid from "@mui/material/Grid";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import { AgGridClasses } from "@genre/g2common-theme";
import axios from "axios";
import {
  AcccountingGroupColumn,
  InputProps,
  RowDataType,
  dateParserToString,
  validateRequest,
  validateRequired,
} from "./accountingGroupUtils";

function AccountingGroupGrid() {
  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
  const sqlOperatorData = [
    "=",
    "<>",
    "<",
    ">",
    "in",
    "not In",
    "like",
    "not Like",
  ];
  const compoundOperatorData = ["AND", "OR", "END"];
  const [rowData, setRowData] = React.useState<InputProps[]>([]);
  const [columnData, setColumnData] = React.useState<AcccountingGroupColumn[]>(
    []
  );
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [apiLoading, setApiLoading] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const accountingGroupID = useSelector(
    (state: RootState) => state.genbill.bill.accountGroup.accountingGroupID
  );
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const cellEditorSelector: (
    params: ICellEditorParams<any>
  ) => CellEditorSelectorResult | undefined = (
    params: ICellEditorParams<any>
  ) => {
    if (params.colDef.colId === "openingBrace") {
      return {
        component: "agRichSelectCellEditor",
        params: {
          values: ["(", ""],
        },
        popup: true,
      };
    }

    if (params.colDef.colId === "sqlOperator") {
      return {
        component: "agRichSelectCellEditor",
        params: {
          values: sqlOperatorData,
        },
        popup: true,
      };
    }

    if (params.colDef.colId === "closingBrace") {
      return {
        component: "agRichSelectCellEditor",
        params: {
          values: [")", ""],
        },
        popup: true,
      };
    }

    if (params.colDef.colId === "compoundOperator") {
      return {
        component: "agRichSelectCellEditor",
        params: {
          values: compoundOperatorData,
        },
        popup: true,
      };
    }

    return undefined;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "openingBrace",
      colId: "openingBrace",
      checkboxSelection: true,
      valueGetter: (params: ValueGetterParams<any>) => {
        if (params.data.openingBrace) {
          const openingBrace: string = params.data.openingBrace.trim();
          return openingBrace === "true" || openingBrace === "(" ? "(" : "";
        }
      },
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "compareColumnName",
      colId: "compareColumnName",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: columnData.map((item) => item.name),
      },
      cellEditorPopup: true,
    },
    {
      field: "order",
      colId: "order",
      hide: true,
      sort: "asc",
    },
    {
      field: "sqlOperator",
      colId: "sqlOperator",
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "compareToString",
      headerName: "Compare to value",
      colId: "compareToString",
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "closingBrace",
      colId: "closingBrace",
      valueGetter: (params: ValueGetterParams<any>) => {
        if (params.data.closingBrace) {
          const closingBrace: string = params.data.closingBrace.trim();
          return closingBrace === "true" || closingBrace === ")" ? ")" : "";
        }
      },
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "compoundOperator",
      colId: "compoundOperator",
      cellEditorSelector: cellEditorSelector,
    },
  ]);

  const getRequest = (api: GridApi) => {
    setApiLoading(true);

    const gridDataQuery = brokerBillingAxios.get(
      `/AccountingGroup/GetAccountingGroupRules/${accountingGroupID}`
    );
    const columnNamesQuery = brokerBillingAxios.get(
      `/AccountingGroup/getColumnNames`
    );

    axios
      .all([gridDataQuery, columnNamesQuery])
      .then(
        axios.spread((...responses: any) => {
          const gridRowsData = responses[0].data;
          const columnNames = responses[1].data;

          setApiLoading(false);

          if (columnNames) {
            const columnDef: any = api.getColumnDef("compareColumnName");
            if (columnDef)
              columnDef.cellEditorParams = {
                values: columnNames.map(
                  (item: AcccountingGroupColumn) => item.name
                ),
              };

            setColumnData(columnNames);
          } else {
            setColumnData([]);
          }

          if (gridRowsData) {
            setRowData(gridRowsData);
          } else {
            setRowData([]);
          }
        })
      )
      .catch((e: any) => {
        console.log(e.message);
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);
    getRequest(params.api);
  };

  const defaultColDef = React.useMemo<ColDef>(() => {
    return {
      editable: true,
      cellDataType: false,
    };
  }, []);

  const handleSubmit = () => {
    let allRows: RowDataType[] = [];
    gridApi &&
      gridApi.forEachNode((rowNode: IRowNode<RowDataType>) =>
        allRows.push(rowNode.data!)
      );

    const flag = validateRequest(
      allRows,
      columnData,
      modalErrors,
      setModalErrors
    );
    if (!flag) return;

    setApiLoading(true);
    const finalRows = allRows.map((item: RowDataType, idx: number) => {
      return {
        ...item,
        billID: !item.BillID ? billID : item.BillID,
        modifiedDate: item.createdDate ? new Date().toISOString() : null,
        modifiedBy: item.createdBy ? createdBy?.id : null,
        accountingGroupID: !item.accountingGroupID
          ? accountingGroupID
          : item.accountingGroupID,
        createdDate: item.createdDate
          ? item.createdDate
          : new Date().toISOString(),
        createdBy: item.createdBy ? item.createdBy : createdBy?.id,
        openingBrace:
          item.openingBrace === "(" || item.openingBrace === "true"
            ? "true"
            : "",
        closingBrace:
          item.closingBrace === ")" || item.closingBrace === "true"
            ? "true"
            : "",
        sqlOperator: item.sqlOperator ? item.sqlOperator.trim() : "",
        order: idx + 1,
      };
    });

    const dataTypeParser: RowDataType[] = finalRows.map(
      (item: RowDataType) => {
        const found = columnData.find(
          (column) => column.name === item.compareColumnName
        );
        if (!found) return item;
        return {
          ...item,
          compareToString: found.type === "datetime" ? dateParserToString(item.compareToString) : item.compareToString,
        };
      }
    );

    brokerBillingAxios
      .post("/AccountingGroup/CreateAccountingGroupRule", dataTypeParser)
      .then((res: any) => {
        setApiLoading(false);
        if (res.status === 200) {
          enqueueSnackbar(`Success! Accounting Group Rule created successfully.`, {
            variant: "success",
            autoHideDuration:3000
          });
          gridApi && getRequest(gridApi);
        }
      })
      .catch((e: any) => {
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const onSelectionChanged = React.useCallback(
    (event: SelectionChangedEvent) => {
      let rows = event.api.getSelectedNodes();
      let selectedRowsTemp = rows.map((item: any) => item.data);
      setSelectedRows(selectedRowsTemp);
    },
    []
  );

  const handleDelete = () => {
    gridApi && gridApi.applyTransaction({ remove: selectedRows });
    handleSubmit();
  };

  return (
    <Grid container spacing={2}>
      <SimpleBackdrop open={apiLoading} />
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid md={12} display={"flex"} item gap={2} mt={1}>
        <Button
          variant="contained"
          onClick={() => {
            let allRows: any = [];
            gridApi &&
              gridApi.forEachNode((rowNode: any) => allRows.push(rowNode.data));
            const lastItem = allRows[allRows.length - 1];
            if (allRows.length === 0) {
              gridApi?.applyTransaction({
                add: [{}],
              });
            } else if (validateRequired(lastItem)) {
              gridApi?.applyTransaction({
                add: [{ order: lastItem.order + 1 }],
              });
            } else return;
          }}
        >
          Add
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        {selectedRows.length ? (
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        ) : null}
      </Grid>
      <Grid md={12} item>
        <div
          style={{ height: 440, paddingLeft: 0 }}
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onSelectionChanged={onSelectionChanged}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            rowData={rowData}
            suppressRowClickSelection
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowHeight={29}
            headerHeight={32}
          ></AgGridReact>
        </div>
      </Grid>
    </Grid>
  );
}

export default AccountingGroupGrid;
