import React from "react";
import {
  NewBillState,
  PermissionLevelObject,
  StyledSpan,
  permissionLevelArray,
} from "../../genbillUtils";
import { AgGridReact } from "ag-grid-react";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { SelectionChangedEvent } from "ag-grid-community";
import {Radio, RadioGroup, Typography, FormControl, FormControlLabel,Grid} from "@mui/material";

interface Props extends NewBillState {}
function NewBillEmailAlerts({ state, setState }: Props) {
  function findName(v: number) {
    const found = permissionLevelArray.find(
      (item: PermissionLevelObject) => item.value === v
    );
    return (
      (
        <StyledSpan>
          {" "}
          <span> {found?.name} </span>{" "}
        </StyledSpan>
      ) || ""
    );
  }

  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: "status",
      colId: "status",
      hide: true,
      // rowGroup: true,
    },
    {
      field: "firstName",
      colId: "firstName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        return params.data ? true : false;
      },
    },
    {
      field: "lastName",
      colId: "lastName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "userLoginID",
      colId: "userLoginID",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "emailAddress",
      colId: "emailAddress",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "permissionLevelID",
      colId: "permissionLevelID",
      headerName: "Permissions",
      // enables editing
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["Admin", "Contributor", "Viewer"],
      },
      cellEditorPopup: true,
      valueGetter: (params: any) => {
        if (params.data && params.data.permissionLevelID === 0) {
          return "";
        }
      },
      cellRenderer: (params: any) => {
        return params.data && findName(params.data.permissionLevelID);
      },
      filter: false,
    },
  ]);
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
      let rows = event.api.getSelectedNodes();
      let selectedRows = rows.map((item: any) => item.data);
      setState({
        ...state,
        assignedEmailAlertsList: selectedRows,
      });
    }

  return (
    <Grid container>
      <Grid
        md={12}
        item
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography align="left" fontWeight={"bold"}>
          Auto Send Bills
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={state.autoSendBills}
            onChange={(e) => setState({ ...state, autoSendBills: JSON.parse(e.target.value) })}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <div
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        style={{ width: "100%", height: window.innerHeight - 350 }}
      >
        <AgGridReact
          rowHeight={29}
          headerHeight={32}
          columnDefs={columnDefs}
          rowData={state.assignedUserAccessList}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </div>
    </Grid>
  );
}

export default NewBillEmailAlerts;
