import React, { Fragment } from "react";
import { ActionBar } from "@genrecp/g2clientportal-common";
import GridToggler from "../components/GridToggler";
import { DelegatedIssuesState } from "../utils";
import DelegatedIssuesInfoSectionSelector from "./DelegatedIssuesInfoSectionSelector";
import styled from "styled-components";

const ActionBarWrapper = styled.div`
  align-content: center;
  background-color: #eef0ff;
  border-bottom: solid 1px #000000;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 12px 0 10px;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

function DelegatedPolicyIssues() {
  const [state, setState] = React.useState<DelegatedIssuesState>({
    screenName: "POLICY_ENDORSEMENT",
  });

  return (
    <Fragment>
      <ActionBarWrapper>
        <GridToggler state={state} setState={setState} />
      </ActionBarWrapper>
      <DelegatedIssuesInfoSectionSelector state={state} setState={setState} />
    </Fragment>
  );
}

export default DelegatedPolicyIssues;
