import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Badge,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ConfirmationDialog,
  CustomSnackbar,
  ModalErrors,
  SelectList,
  SimpleBackdrop,
  handleErrorResponse,
  useConfirm,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { DocArray, DocFolderStructure, DocumentType } from "../adminDocUtils";
import { commonAxios, formDataCommonAxios } from "../../../../../services/axios";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface Props extends DocumentType {
  setSelectedMenu: (v: string) => void;
  docFolderStructure: DocFolderStructure;
}

function AddNewDoc({
  documentName,
  setDocumentName,
  setSelectedMenu,
  docFolderStructure,
}: Props) {
  const [files, setFiles] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const {isConfirmed} = useConfirm();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "application/pdf": [] },
      maxFiles:1,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const validateUploadedFile = async (subFolder:any) => {
    setLoader(true);

    try {
      
      const response: any = await commonAxios.get(`/Documentation/ValidateDocument?document=${documentName.folder}${subFolder}/${files[0].name}`);

      setLoader(false);

      const { status, data } = response || {};
      if (status === 401) return false;
      if (status !== 200) {
        enqueueSnackbar(`Error! Invalid Request`, {
          variant: "error",
        });
        return false;
      } 

      return data;

    } catch (e: any) {
      setLoader(false);
      const modalErrorMessages = handleErrorResponse(e);
      setModalErrors({
        ...modalErrors,
        errors: modalErrorMessages,
        ErrorModelOpen: true,
      });
      return false;
    }
  }

  const upload = async () => {
    if (!documentName) {
      enqueueSnackbar(`Error! Select the target folder.`, {
        variant: "error",
      });
      return;
    }
    
    if(docFolderStructure.data.length > 0 && !documentName.subFolder){
      enqueueSnackbar(`Error! Select the target subFolder.`, {
        variant: "error",
      });
      return;
    }

    if (!files.length) {
      enqueueSnackbar(`Error! Upload a file first.`, {
        variant: "error",
      });
      return;
    }

    const subFolder = documentName.subFolder && `/${documentName.subFolder}`;

    const alreadyExists = await validateUploadedFile(subFolder); 

    if(alreadyExists) {
      const flag = await isConfirmed("This file already exists! Click on OK if you want to override the existing file.");
      if(!flag) return;
    }

    setLoader(true);

    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      
      const response: any = await formDataCommonAxios.post(
        `/Documentation/UploadDocument?document=${documentName.folder}${subFolder && subFolder}`,
        formData
      );

      setLoader(false);

      const { status, messages } = response || {};

      if (status === 200) {
        enqueueSnackbar(`Action was successfully performed!`, {
          variant: "success",
          autoHideDuration:3000
        });
        setSelectedMenu("ViEWDOCS");
      } else if (status === 401) return 
      else{
        console.log(messages);
        enqueueSnackbar(`Error! Invalid Request`, {
          variant: "error",
        });
      }
    } catch (e: any) {
      setLoader(false);
      const modalErrorMessages = handleErrorResponse(e);
      setModalErrors({
        ...modalErrors,
        errors: modalErrorMessages,
        ErrorModelOpen: true,
      });
    }
  };

  const removeFile = async (e: any, fileToRemove: any) => {
    const flag = await isConfirmed("Are you sure you want to delete this file?");
    flag && setFiles(files.filter((file: any) => file !== fileToRemove));
  };

  const thumbs = files.map((file: any, idx: any) => (
    <Badge
      badgeContent={
        <IconButton
          sx={{ background: "#000", ":hover": { background: "#000" } }}
          onClick={(e) => removeFile(e, file)}
        >
          <DeleteIcon fontSize="small" sx={{ color: "white" }} />
        </IconButton>
      }
    >
      <Grid
        item
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={1}
        key={idx}
        maxWidth={"100px"}
      >
        <DescriptionIcon sx={{ fontSize: "100px" }} />
        <Typography>{file.name}</Typography>
      </Grid>
    </Badge>
  ));

  return (
    <>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SimpleBackdrop open={loader} />
      <ConfirmationDialog />
      <Grid container gap={1} display={"flex"} alignItems={"center"}>
        <Grid md={4} item>
          {docFolderStructure.loading ? (
            <Skeleton width={"100%"} height={60} variant="text" />
          ) : (
            <SelectList
              label="Documents"
              name="documents"
              id="documents"
              test-id="documents"
              value={documentName.folder}
              allowempty={false}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setDocumentName({
                  ...documentName,
                  folder:event.target.value as string,
                  subFolder:""
                })
              }}
            >
              {DocArray.map((item, idx) => {
                return (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                );
              })}
            </SelectList>
          )}
        </Grid>

        {docFolderStructure.loading ? (
          <Grid md={4} item>
            <Skeleton width={"100%"} height={60} variant="text" />
          </Grid>
        ) : docFolderStructure?.data && docFolderStructure?.data.length > 0 ?  (
          <Grid md={4} item>
             <SelectList
              label="Sub Folder"
              name="subFolder"
              id="subFolder"
              test-id="subFolder"
              allowempty={false}
              value={documentName.subFolder}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setDocumentName({
                  ...documentName,
                  subFolder:event.target.value as string
                })
              }}
            >
              {docFolderStructure.data.map((item: string, idx:number) => {
                return (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                );
              })
            }
            </SelectList>
          </Grid>
        ) : null}

        <Grid md={2} item>
          {docFolderStructure.loading ? (
            <Skeleton width={"30%"} height={60} />
          ) : (
            <Button variant="contained" onClick={() => upload()}>
              Upload
            </Button>
          )}
        </Grid>
        <Grid md={12} item>
          <Container
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <p>Drag and drop a file here or click to select file</p>
          </Container>
        </Grid>
        <Grid container gap={6} pt={2}>
          {thumbs}
        </Grid>
      </Grid>
    </>
  );
}

export default AddNewDoc;
