import React from "react";
import { CashLogProps } from "./cashLogUtils";
import CashLogGrid from "./CashLogGrid";
import AddCashLog from "./AddCashLog";

interface Props extends CashLogProps {}

function InfoSectionSelector({ request, dispatch }: Props) {
  switch (request.gridSelector) {
    case "GRID":
      return <CashLogGrid request={request} dispatch={dispatch} />;
    case "ADD":
    case "DETAIL":
      return <AddCashLog request={request} dispatch={dispatch} />;
    default:
      return null;
  }
}

export default InfoSectionSelector;
