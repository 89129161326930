export type FileType = { name: string; base64: string };
type Links = {
  linkText: string;
  filename: string;
  extraText?:string
};

type DataArrayType = {
  title: string;
  instructions?: boolean;
  links: Links[];
};

export const dataArray: DataArrayType[] = [
  {
    title: "April 2022",
    links: [
      {
        linkText: "Oracle/Docucorp FAP File(s) Download",
        filename: "04.April 2022 FAP Files.zip",
        extraText:"(this zipfile contains the form.dat files)"
      },
      {
        linkText: "Word Version Download",
        filename: "04.April 2022 WORD Files.zip",
      },
      {
        linkText: "Summary of Changes",
        filename: "04.April 2022 Library Update Summary.pdf",
      },
      {
        linkText: "How to Upload Files",
        filename: "zz_How To Upload Forms.docx",
      },
    ],
  },
  {
    title: "October 2021",
    links: [
      {
        linkText: "Oracle/Docucorp FAP File(s) Download",
        filename: "10. October FAP Files.zip",
      },
      {
        linkText: "Word Version Download",
        filename: "10. October WORD Files.zip",
      },
      {
        linkText: "Form.dat",
        filename: "form.dat",
      },
      {
        linkText: "Summary of Changes",
        filename: "10. October Library Update Summary.pdf",
      },
      {
        linkText: "How to Upload Files",
        filename: "zz_How To Upload Forms.docx",
      },
    ],
  },
  {
    title: "April 2021",
    links: [
      {
        linkText: "Oracle/Docucorp FAP File(s) Download",
        filename: "04. April FAP Files.zip",
      },
      {
        linkText: "Word Version Download",
        filename: "04. April WORD Files.zip",
      },
      {
        linkText: "Form.dat",
        filename: "form.dat",
      },
      {
        linkText: "Summary of Changes",
        filename: "04. April Library Update Summary.pdf",
      },
      {
        linkText: "How to Upload Files",
        filename: "zz_How To Upload Forms.docx",
      },
    ],
  },
  {
    title: "March 2021",
    links: [
      {
        linkText: "Word Version Download",
        filename: "03. March WORD Files.zip",
      },
    ],
  },
  {
    title: "February 2021",
    links: [
      {
        linkText: "Oracle/Docucorp FAP File(s) Download",
        filename: "02. February FAP Files.zip",
      },
    ],
  },
  {
    title: "All FAP Files (2019 - 2022)",
    instructions: true,
    links: [
      {
        linkText: "2022 FAP Files",
        filename: "zzz_2022 FAPS.zip",
      },
      {
        linkText: "2021 FAP Files",
        filename: "zzz_2021 FAPS.zip",
      },
      {
        linkText: "2020 FAP Files",
        filename: "zzz_2020 FAPS.zip",
      },
      {
        linkText: "2019 FAP Files",
        filename: "zzz_2019 FAPS.zip",
      },
    ],
  },
];
