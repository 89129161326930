import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { setConfirm } from "@genrecp/g2clientportal-client-portal/src/redux/slices/confirmationSlice";

export const useConfirm = () => {
  const confirm = useSelector((state: RootState) => state.confirmation);
  const $dispatch = useDispatch<AppDispatch>();

  const isConfirmed = (prompt: string) => {
    const promise = new Promise((resolve, reject) => {
      $dispatch(setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      }));
    });

    return promise.then(
      () => {
        $dispatch(setConfirm({ ...confirm, prompt, isOpen: false }));
        return true;
      },
      () => {
        $dispatch(setConfirm({ ...confirm, prompt, isOpen: false }));
        return false;
      }
    );
  };
  return {
    ...confirm,
    isConfirmed,
  };
};
