import React from "react";
import { Spinner } from "@genrecp/g2clientportal-common";
import styled from "styled-components";

interface Props {
  loading: boolean;
  children: JSX.Element;
  style?: any
}

const SpinnerDiv = styled.div`
  width: -webkit-fill-available;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
`;

function GridLoadingSpinner({ loading, children, style={}}: Props) {
  return loading ? (
    <SpinnerDiv style={style}>
      <Spinner />
    </SpinnerDiv>
  ) : (
    children
  );
}

export default GridLoadingSpinner;
