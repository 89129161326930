import React from "react";
import { ActionMenuItemTypeMap, CashTrackerProps } from "../CashTrackerUtils";
import GridToggler from "./GridToggler";
import { ActionBar } from "@genrecp/g2clientportal-common";
import CashTrackerMetaDataBar from "./CashTrackerMetaDataBar";
interface Props extends CashTrackerProps {
  actionMenuItems: ActionMenuItemTypeMap;
}

function CashTrackerActionBar({ request, dispatch, actionMenuItems }: Props) {
  return (
    <>
      <ActionBar
        menuItems={actionMenuItems[request.cashTrackerScreenSelector]}
        dashboardName={request.screenName}
      >
        <GridToggler request={request} dispatch={dispatch} />
      </ActionBar>
      <CashTrackerMetaDataBar request={request} dispatch={dispatch} />
    </>
  );
}

export default CashTrackerActionBar;
