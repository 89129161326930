import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import BillDetails from "./BillDetails";
import ClientViewBillDetails from "./clientView/ClientViewBillDetails";

function BillDetailSelector() {
  const isClientView = useSelector(
    (state: RootState) => state.genbill.isClientView
  );
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );

  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));

  if(!isExternalUser) {
    return isClientView ? <ClientViewBillDetails /> : <BillDetails />;
  }

  if(isExternalUser) {
    return <ClientViewBillDetails />
  }

  return <></>

}

export default BillDetailSelector;
