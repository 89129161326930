import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { TextInput } from "@genrecp/g2clientportal-common";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { QAErrorReasonProps } from "./qaErrorFormUtils";
import Button from "@mui/lab/LoadingButton";

interface Props extends QAErrorReasonProps {
  handleReset: () => void;
  handleSubmit: () => void;
  handleUpdate: () => void;
}
function QAErrorForm({
  state,
  setState,
  handleSubmit,
  handleReset,
  handleUpdate,
}: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setState({
      ...state,
      ...(name === "qaErrorReasonTypeText"
        ? { [name]: value }
        : {
            [name]: checked,
          }),
    });
  };
  return (
    <Fragment>
      <Grid item md={5}>
        <TextInput
          name="qaErrorReasonTypeText"
          value={state.qaErrorReasonTypeText}
          onChange={handleInputChange}
          label="QA Error Reason"
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item md={1.5} display={"flex"} gap={1}>
        <Typography variant="body1">
          <b>Active</b>
        </Typography>
        <Switch
          {...label}
          name="active"
          id="active"
          test-id="active"
          checked={state.active}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item md={3} display={"flex"} gap={1}>
        {state.updateToggler ? (
          <Button
            variant="containedTertiary"
            onClick={handleUpdate}
            loading={state.apiLoading}
            disabled={state.apiLoading}
          >
            UPDATE
          </Button>
        ) : (
          <Button
            variant="containedTertiary"
            onClick={handleSubmit}
            loading={state.apiLoading}
            disabled={state.apiLoading}
          >
            Submit
          </Button>
        )}
        <Button
          disabled={state.apiLoading}
          variant="outlinedTertiary"
          onClick={handleReset}
        >
          Reset
        </Button>
      </Grid>
    </Fragment>
  );
}

export default QAErrorForm;
