import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { Button } from "@genrecp/g2clientportal-common";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { TextInput } from "@genrecp/g2clientportal-common";
import Typography from "@mui/material/Typography";
import SelectList from "@genrecp/g2clientportal-common/src/components/selectList/SelectList";
import { SelectChangeEvent } from "@mui/material";
import { ActionType } from "./actionTypeUtils";
import { ActionTypeReportingGrid } from "./ActionTypeReportingGrid";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";

interface Props {
  request: ActionType;
  setRequest: (v: ActionType) => void;
  handleSubmit: () => void;
  handleUpdate: () => void;
}

const SubActionTypeReporting: React.FC<Props> = (props) => {
  const { request, setRequest, handleSubmit, handleUpdate } = props;

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;
    setRequest({ ...request, [name]: checked });
  };

  const handleDropDownChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    const val = value as string;
    setRequest({ ...request, [name]: val });
  };

  return (
    <>
      <Grid container spacing={1} maxWidth="lg">
        <Grid xs={12} sm={12} md={12} item>
          <Typography variant="displaySmall">Action Type Reporting</Typography>
        </Grid>
        <SimpleBackdrop open={request.loading || request.apiLoading} />

        <Grid xs={12} sm={6} md={6} item>
          <SelectList
            label="App Type"
            name="appTypeID"
            id="appTypeID"
            test-id="appTypeID"
            required
            value={request.appTypeID}
            onChange={handleDropDownChange}
          >
            {request.appTypeArray.map((item, idx) => {
              return (
                <MenuItem value={item.appTypeID} key={idx}>
                  {item.appTypeText}
                </MenuItem>
              );
            })}
          </SelectList>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <TextInput
            inputProps={{ maxLength: 50 }}
            name={"actionTypeText"}
            value={request.actionTypeText}
            onChange={handleTextChange}
            label="Action Type"
            required
            id="actionTypeText"
          />
        </Grid>

        <Grid
          xs={12}
          sm={6}
          md={10}
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={1}
        >
          <Typography variant="body1">
            <b>Active</b>
          </Typography>
          <Switch
            {...label}
            name="active"
            test-id="active"
            id="active"
            checked={request.active}
            onChange={handleInputChange}
          />

          <Button
            variant="outlined"
            onClick={() =>
              setRequest({
                ...request,
                appTypeID: "",
                actionTypeText: "",
                active: true,
                refreshToggler: !request.refreshToggler,
                createdBy: "",
                createdDate: "",
                actionTypeID: "",
                updatetoggler: false,
              })
            }
            id="cancel"
          >
            Cancel
          </Button>
          {!request.updatetoggler ? (
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              id="submit"
              disabled={request.apiLoading}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              id="update"
              disabled={request.apiLoading}
            >
              Update
            </Button>
          )}
        </Grid>

        <Grid xs={12} sm={12} md={10} item>
          {request.appTypeArray && request.appTypeArray.length > 0 ? (
            <ActionTypeReportingGrid
              request={request}
              setRequest={setRequest}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default SubActionTypeReporting;
