import React from "react";
import { CashTrackerProps, SuspenseDetailScreen } from "../../CashTrackerUtils";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

interface Props extends CashTrackerProps {}

function SuspenseSideMenu({ request, dispatch }: Props) {
  const DashboardNameMap: { [key: string]: string } = {
    SUSPENSE_DETAIL: "Suspense Detail",
    SUSPENSE_LOG: "Suspense Log",
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: SuspenseDetailScreen
  ) => {
    dispatch({
      suspenseDetailScreen: value,
      screenName: DashboardNameMap[value] || "",
    });
  };

  type SideNavArrayType = {
    name: string;
    value: SuspenseDetailScreen;
  };
  const sideNavArray: SideNavArrayType[] = [
    { name: "Suspense Detail", value: "SUSPENSE_DETAIL" },
    { name: "Suspense Log", value: "SUSPENSE_LOG" },
  ];

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List
        component="nav"
        subheader={
          <ListItemButton
            sx={{
              background: "#3A5BAA",
              "&:hover": {
                background: "#002B73",
              },
              minHeight: 30,
            }}
          ></ListItemButton>
        }
      >
        {sideNavArray.map((item, idx) => (
          <ListItemButton
            key={idx}
            selected={request.suspenseDetailScreen === item.value}
            onClick={(event) => handleListItemClick(event, item.value)}
          >
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </Box>
  );
}

export default SuspenseSideMenu;
