import { padLeft } from './string-padding';
import { DateLike } from './util.types';

export class DateOnly {

    private month: number = 0;
    private day: number = 1;
    private year: number = 1921;

    constructor(value?: DateLike | DateOnly | null) {
        if (value instanceof DateOnly) {
            this.year = value.year;
            this.month = value.month;
            this.day = value.day;
        }
        else {
            const dt = (value instanceof Date ? value : (typeof value === 'string' || typeof value === 'number' ? new Date(value) : new Date()));
            
            this.year = dt.getUTCFullYear();
            this.month = dt.getUTCMonth();
            this.day = dt.getUTCDate();   
        }
    }

    get value(): Date {
        let d = new Date();
        d.setFullYear(this.year);
        d.setMonth(this.month);
        d.setDate(this.day);
        return d;
    }

    toString(): string {
        return this.value.toDateString();
    }

    toISOString(): string {
        const yyyy = this.value.getFullYear();
        const mm = padLeft(this.value.getMonth() + 1, 2, '0');
        const dd = padLeft(this.value.getDate(), 2, '0');

        return `${yyyy}-${mm}-${dd}T00:00:00.0000Z`;
    }
}