import React from "react";
import { CashTrackerProps } from "../CashTrackerUtils";
import { Grid } from "@mui/material";
import CashTrackerDetailSideMenu from "./cashtrackerDetailComponents/CashTrackerDetailSideMenu";
import CashTrackerDetailsInfoSelector from "./cashtrackerDetailComponents/CashTrackerDetailsInfoSelector";

interface Props extends CashTrackerProps {}

function CashTrackerDetailsWrapper({ request, dispatch }: Props) {
  const restrictForScreens = !(
    request.cashTrackerDetailTab === "ADD_SUSPENSE" ||
    request.cashTrackerDetailTab === "SUSPENSE_DETAIL"
  );
  return (
    <Grid container spacing={2}>
      <Grid item md={restrictForScreens ? 10 : 12}>
        <CashTrackerDetailsInfoSelector request={request} dispatch={dispatch} />
      </Grid>
      {restrictForScreens ? (
        <Grid item md={2} position={"relative"}>
          <CashTrackerDetailSideMenu request={request} dispatch={dispatch} />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default CashTrackerDetailsWrapper;
