import React, { useRef } from "react";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import {
  comparatorForDate,
  CustomSnackbar,
  formatDateForGrid,
  handleErrorResponse,
  ModalErrors,
  showInvalidSnackbar,
  SimpleBackdrop,
  useRibbonSnackbar,
  ViewSchema,
} from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { DelegatedIssuesProps } from "./utils";
import { RootState } from "../../../redux/store";
import { delegatedAxios,commonAxios } from "../../../services/axios";
import { SetFilterValuesFuncParams, ISetFilterParams } from "ag-grid-community";
import CommentRenderer from "./CommentRenderer";

interface Props extends DelegatedIssuesProps {}

interface DelegatedPolicyIssueTypes {
  issueTypeID: number;
  issueTypeIDIdentifierText: string;
}

function PolicyEndorsementGrid({ state, setState }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [delegatedPolicyIssueTypesList, setDelegatedPolicyIssueTypesList] =
    React.useState<DelegatedPolicyIssueTypes[]>([]);

  const delegatedPolicyIssueTypesListRef =
    React.useRef<DelegatedPolicyIssueTypes[]>();

  delegatedPolicyIssueTypesListRef.current = delegatedPolicyIssueTypesList;

  const { enqueueSnackbar } = useRibbonSnackbar();

  const currentViewRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const [view, setView] = React.useState<ViewSchema>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    currentViewRef.current = selectedView;
      if (selectedView?.userGridViewID) {
        sessionStorage.setItem(
          "selectedViewID",
          (selectedView || {}).userGridViewID ?? ""
        );
        sessionStorage.setItem("selectedView", JSON.stringify(selectedView));
      }

      setView(selectedView);
    const newObj: any = { ...filterModel };
    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;

    if (newObj.issueTypeIDIdentifierText) {
      const tempStatus = obj.issueTypeIDIdentifierText
        .split(",")
        .map((item: string) => delegatedPolicyIssueTypesParser(item.trim()))
        .filter((item: number | undefined) => item !== undefined);
      obj.issueTypeIDs = tempStatus;
      delete obj.issueTypeIDIdentifierText;
    }

    return delegatedAxios
      .post(`/Issues/GetDelegatedPolicyIssues`, obj)
      .then((data: any) => {
        return data.data;
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        colId: "policyNumber",
        field: "policyNumber",
        headerName: "Policy Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "endtNumber",
        field: "endtNumber",
        headerName: "Endorsement Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "effectiveDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.effectiveDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        field: "expirationDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.expirationDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "namedInsured",
        field: "namedInsured",
        headerName: "Insured Name",
        filter: "agTextColumnFilter",
        tooltipField: "namedInsured",
        width: 320,
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "issueTypeIDIdentifierText",
        field: "issueTypeIDIdentifierText",
        headerName: "Issue Type",
        filter: "agSetColumnFilter",
        tooltipField: "issueTypeIDIdentifierText",
        width: 320,
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: true,
          defaultToNothingSelected: true,
          filterOptions: ["equals"],
          values: async (params: SetFilterValuesFuncParams) => {
            let values = await getStatusFilters();
            params.success(values);
          },
          showTooltips: true,
          suppressAndOrCondition: true,
        } as ISetFilterParams,
      },

      {
        colId: "issueDescription",
        field: "issueDescription",
        tooltipField: "issueDescription",
        headerName: "Issue Description",
        filter: "agTextColumnFilter",
        width: 420,
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },

      {
        colId: "comments",
        field: "comments",
        tooltipField: "comments",
        width: 320,
        headerName: "Client Comments",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "clientName",
        field: "clientName",
        headerName: "Client Name",
        tooltipField: "clientName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 320,
      },
      {
        colId: "clientCity",
        field: "clientCity",
        headerName: "Client City",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
      {
        colId: "clientState",
        field: "clientState",
        headerName: "Client State",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
      {
        field: "Actions",
        cellRenderer: CommentRenderer,
        sortable:false,
        filter: false,
      }
    ];
  }

  function delegatedPolicyIssueTypesParser(payload: string) {
    if (
      !delegatedPolicyIssueTypesListRef.current ||
      !delegatedPolicyIssueTypesListRef.current.length
    ) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    const finalCashTrackerStatus: number | undefined =
      delegatedPolicyIssueTypesListRef.current.find(
        (item) => item.issueTypeIDIdentifierText.trim() === payload
      )?.issueTypeID || undefined;
    return finalCashTrackerStatus;
  }

  async function getStatusFilters() {
    return delegatedAxios
      .get("/Issues/GetIssueTypesForDelegatedPolicy")
      .then((res: any) => {
        const { data } = res || {};
        setDelegatedPolicyIssueTypesList(data || []);
        return data
          ? data.map(
              (item: DelegatedPolicyIssueTypes) =>
                item.issueTypeIDIdentifierText
            )
          : [];
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  }

  
  const getGridViewsData = async () => {
    setLoading(true);
    return commonAxios
      .get(`/UserGridViews/GetUserGridViewByUserAndAppID/${createdBy!.id}/${23}`)
      .then((res: any) => {
        setLoading(false);
        const data = res?.data || {};

        return data ? data :[];
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  const createGridViewsData: (view: ViewSchema) => Promise<any> = async (
    view: ViewSchema
  ) => {
    const temp: any = { ...view };
    temp.appTypeID = 23;
    return new Promise((resolve, reject) => {
      commonAxios
        .post("/UserGridViews/Create", temp)
        .then((resp) => {
          if (resp.status === 200) {
            sessionStorage.setItem(
              "selectedViewID",
              resp?.data?.userGridViewID ?? ""
            );
            resolve(resp.data);
          } else reject();
        })
        .catch((e) => {
          showInvalidSnackbar(e,enqueueSnackbar);
          reject(e);
        });
    });
  };

  const updateGridViewsData = async (view: ViewSchema) => {
    return commonAxios
      .put(`/UserGridViews/Update/${view.userGridViewID}`, view)
      .then((data: any) => console.log(data))
      .catch(function (e: any) {
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const deleteGridViewsData = async (id: string) => {
    return commonAxios
      .delete(`UserGridViews/Delete/${id}`)
      .then((data: any) => {
        return true;
      })
      .catch(function (e: any) {
        showInvalidSnackbar(e,enqueueSnackbar);
        return false;
      });
  };

  return (
    <Grid md={12} item>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      {/* <SimpleBackdrop open={loading} /> */}
      <AggridWrapper
        gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        getColumnDefs={getColumnDefs}
        getGridRowsData={getGridRowsData}
        dashboardName={view ? view.viewName : ""}
        applyViewId={localStorage.getItem("selectedViewID") ?? undefined}
        enableUserViews={true}
        enableSystemViews={true}
        landingPage={"Suspense"}
        views={true}
        currentUser={{
          userName: createdBy && createdBy.id ? createdBy.id : "",
          email: "",
          fullName: "",
        }}
        gridProps={{
          headerHeight: 40,
          tooltipMouseTrack: true,
          tooltipShowDelay: 0,
        }}
        showNoneInViews={false}
        // hideHeader
        paginationPageSize={50}
        createGridViewsData={createGridViewsData}
        updateGridViewsData={updateGridViewsData}
        deleteGridViewsData={deleteGridViewsData}
        getGridViewsData={getGridViewsData}
        rowHeight={29}
        headerHeight={32}
        gridHeight={window.innerHeight - 190}
        showSidebar
      ></AggridWrapper>
    </Grid>
  );
}

export default PolicyEndorsementGrid;
