import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

type Props = { title: string; mainText: string; delay?: string };
function SummaryCards({ title, mainText, delay }: Props) {
  return (
    <Zoom in={true} style={{ transitionDelay: delay ? delay : "0ms" }}>
      <Card sx={{ cursor: "pointer", ":hover": { boxShadow: 4 },p:1 }} >
          <Typography variant="titleMedium">{title}</Typography>
          <Typography variant="body2">
            {mainText}
          </Typography>
      </Card>
    </Zoom>
  );
}

export default SummaryCards;
