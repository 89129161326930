import { showInvalidSnackbar } from "@genrecp/g2clientportal-common";
import { delegatedAxios } from "../../../services/axios";
import {
  DelegatedDispatch,
  DelegatedRequestType,
} from "./experienceReport/utils";
import {
  IncurredLossesDispatch,
  IncurredLossesRequestType,
} from "./incurredLosses/utils";
import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";

export const getBillPoints = (
  request: DelegatedRequestType | IncurredLossesRequestType,
  dispatch: DelegatedDispatch | IncurredLossesDispatch,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
) => {
  if (
    request.clientLocations.length === 0 &&
    request.selectedClientLocations.length === 0
  )
    return;

  const payload =
    request.selectedClientLocations.length > 0
      ? request.selectedClientLocations
      : request.clientLocations;
  const mappedPayload = payload.map((item) => item.clientLocationID);

  dispatch({
    type: "UPDATE_UNIVERSAL_REQUEST",
    request: {
      billPointsLoading: true,
      selectedbillPoints: [],
    },
  });

  delegatedAxios
    .post(
      `/Delegated/GetBillPoints?billPointType=${request.lineOfBusiness}`,
      mappedPayload
    )
    .then((res: any) => {
      const { data } = res || {};
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          billPointsLoading: false,
          billPoints: data || [],
        },
      });
    })
    .catch((e: any) => {
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          billPointsLoading: false,
        },
      });
      showInvalidSnackbar(e, enqueueSnackbar);
    });
}

export const getRiskStates = (
  request: DelegatedRequestType | IncurredLossesRequestType,
  dispatch: DelegatedDispatch | IncurredLossesDispatch,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
) => {

  if (
    request.clientLocations.length === 0 &&
    request.selectedClientLocations.length === 0
  )
    return;

  const payload =
    request.selectedClientLocations.length > 0
      ? request.selectedClientLocations
      : request.clientLocations;
  const mappedPayload = payload.map((item) => item.clientLocationID);

  dispatch({
    type: "UPDATE_UNIVERSAL_REQUEST",
    request: {
      riskStatesLoading: true,
      selectedRiskStates: [],
    },
  });

  delegatedAxios
    .post(
      `/Delegated/GetStateByClientLocations`,
      mappedPayload
    )
    .then((res: any) => {
      const { data } = res || {};
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          riskStatesLoading: false,
          riskStates: data || [],
        },
      });
    })
    .catch((e: any) => {
      dispatch({
        type: "UPDATE_UNIVERSAL_REQUEST",
        request: {
          riskStatesLoading: false,
        },
      });
      showInvalidSnackbar(e, enqueueSnackbar);
    });
}
