import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  MuiStyledTabs as Tabs,
  MuiStyledTab as Tab,
} from "@genre/g2common-theme";

interface Props {
  selectedMenu: string;
  setSelectedMenu: (v: string) => void;
}

function AdminDocumentTabs({ selectedMenu, setSelectedMenu }: Props) {
  const externalTabs = [
    { value: "ViEWDOCS", label: "View Docs" },
    { value: "NEWDOC", label: "Add New Docs" },
  ];

  const tabs = () => {
    return externalTabs.map((item, idx) => (
      <Tab value={item.value} label={item.label} key={idx} />
    ));
  };

  return (
    <Grid container maxWidth="lg" mb={2}>
      <Grid xs={12} sm={12} md={12} item>
          <Tabs
            value={selectedMenu}
            onChange={(event: React.SyntheticEvent, newValue: string) =>
              setSelectedMenu(newValue)
            }
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            {tabs()}
          </Tabs>
      </Grid>
    </Grid>
  );
}

export default AdminDocumentTabs;
