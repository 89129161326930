import * as React from 'react';
import Button from '@mui/material/Button';
import { useMsal } from "@azure/msal-react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppDispatch,RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useDispatch,useSelector } from "react-redux";
import { fetchTokenError} from "@genrecp/g2clientportal-client-portal/src/redux/auth/authActionCreator";
import Stack from '@mui/material/Stack';
import styled from "styled-components";
import { Spinner } from '@genrecp/g2clientportal-common';
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { getToken } from "@genrecp/g2clientportal-client-portal/src/redux/slices/authSlice";
import { loginRequest } from "../../authConfig";

interface Props {
  // setMultiMsalConfig: (v:Configuration) => void;
}

const LoginDiv = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Login:React.FC<Props> = (props) => {
  // const {setMultiMsalConfig} = props;
  // const loginRedirect = new AuthModule();
  const { enqueueSnackbar } = useRibbonSnackbar();
  const $dispatch = useDispatch<AppDispatch>();
  const { instance } = useMsal();
  const isLoading = useSelector((state:RootState) => state.auth.isFetching);
  const [isSignInButton, setIsSignInButton] =  React.useState<boolean>(true);
  const [isSignInProgress, setIsSignInProgress] =  React.useState<boolean>(false);
  const loginError = useSelector((state:RootState) => state.auth.error);

  const handleLogin = () => {
    setIsSignInButton(false);
    setIsSignInProgress(true);
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }

  React.useEffect(()=>{
    if(loginError)
    {
      enqueueSnackbar(
        loginError?.messages[0] || "",
        {
          variant:"error",
          errorObject:loginError
        }
      );
      $dispatch(getToken(fetchTokenError(null)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginError])

  return (
      <Grid container>
        <LoginDiv>
          <Stack width={265} sx={{position:"relative"}} display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <img
              src={"/GenStar-R.png"}
              alt="general star logo"
              style={{ width: "100%", objectFit:"contain" }}
            />
            <Typography component="h1" variant="h5" align="right" fontStyle={'italic'} sx={{ 
                position: "absolute",
                right: "30px",
                top: "90px",
              }}>
                Client Portal
            </Typography>
            {isSignInButton ?
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb:1, width:"80%" }}
                onClick={() => handleLogin()}
              >
                Sign In 
              </Button>
              : null}
              {(isLoading || isSignInProgress) ? <Spinner sx={{ marginLeft: "6px", marginTop: "6px" }} size={22}/> : null}
          </Stack>
        </LoginDiv>
        </Grid>
  );
}