import React from "react";
import { DelegatedIssuesProps } from "../delegatedIssuesAdminUtils";
import { Typography } from "@mui/material";

function DelegatedIssueTitle({ request }: Pick<DelegatedIssuesProps,"request">) {
  switch (request.screenName) {
    case "CASH_ISSUE_DETAILS":
      return <Typography variant="displaySmall">Cash Issue Details</Typography>;
    case "QA_ERROR_REASON":
      return <Typography variant="displaySmall">QA Error Reasons</Typography>;
    case "CLIENT_CASH_DETAILS":
      return <Typography variant="displaySmall">Client Cash Details</Typography>;
    case "SUSPENSE_REASONS":
      return <Typography variant="displaySmall">Suspense Reasons</Typography>;
    default:
      return null;
  }
}

export default DelegatedIssueTitle;
