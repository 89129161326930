import React from "react";
import { DelegatedIssuesProps } from "../delegatedIssuesAdminUtils";
import { Grid, Grow } from "@mui/material";
import DelegatedIssueTitle from "../delegatedIssuesComponents/DelegatedIssueTitle";
import ClientCashDetailsGrid from "./CashIssueDetailsGrid";
import ClientCashDetailsForm from "./CashIssueDetailsForm";
import { CashIssueDetailsItem, CashIssueDetailsType } from "./cashIssueDetailsUtils";
import { delegatedAxios } from "../../../../../services/axios";
import {
  handleSuccessResponse,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

function CashIssueDetails({ request, dispatch }: DelegatedIssuesProps) {
  const [state, setState] = React.useState<CashIssueDetailsType>({
    cashIssueStatusTypeText: "",
    active: false,
    updateToggler: false,
    cashIssueDetailGridItems: [],
    gridDataLoading: false,
    apiLoading: false,
  });
  const { enqueueSnackbar } = useRibbonSnackbar();
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  React.useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getGridData() {
    setState((prev) => ({
      ...prev,
      gridDataLoading: true,
    }));
    delegatedAxios
      .get("/CashIssuesStatus")
      .then((res) => {
        const { data } = res || {};
        setState((prev) => ({
          ...prev,
          cashIssueDetailGridItems: data || [],
          gridDataLoading: false,
        }));
      })
      .catch((e: any) => {
        showInvalidSnackbar(e, enqueueSnackbar);
        setState((prev) => ({
          ...prev,
          gridDataLoading: false,
        }));
      });
  }

  function cashIssueStatusTextCheck() {
    if (!state.cashIssueStatusTypeText) {
      showInvalidSnackbar(
        null,
        enqueueSnackbar,
        "Cash Issue Status is required!"
      );
      return false;
    }
    return true;
  }

  function cashIssueStatusTypeIDCheck() {
    if (!state.cashIssueStatusTypeID) {
      showInvalidSnackbar(null, enqueueSnackbar, "ID not Found!");
      return false;
    }
    return true;
  }

  function handleUpdate() {
    const textCheck = cashIssueStatusTextCheck();
    if (!textCheck) {
      return;
    }
    const idCheck = cashIssueStatusTypeIDCheck();
    if (!idCheck) {
      return;
    }

    const payload: CashIssueDetailsItem = {
      active: state.active,
      cashIssueStatusTypeText: state.cashIssueStatusTypeText,
      modifiedBy: createdBy!.id,
      modifiedDate: new Date().toISOString(),
      createdBy: state.createdBy,
      createdDate: state.createdDate,
      cashIssueStatusTypeID: state.cashIssueStatusTypeID,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .put(`/CashIssuesStatus/Update/${state.cashIssueStatusTypeID}`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleSubmit() {
    const check = cashIssueStatusTextCheck();
    if (!check) {
      return;
    }

    const payload: CashIssueDetailsItem = {
      active: state.active,
      cashIssueStatusTypeText: state.cashIssueStatusTypeText,
      createdBy: createdBy!.id,
      createdDate: new Date().toISOString(),
      modifiedBy: null,
      modifiedDate: null,
    };

    setState((prev) => ({
      ...prev,
      apiLoading: true,
    }));

    delegatedAxios
      .post(`/CashIssuesStatus/Create`, payload)
      .then((res) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(res, enqueueSnackbar);
          getGridData();
          handleReset();
        }
      })
      .catch((e: any) => {
        setState((prev) => ({
          ...prev,
          apiLoading: false,
        }));
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  }

  function handleReset() {
    setState((prev) => ({
      ...prev,
      active: false,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      cashIssueStatusTypeID: null,
      cashIssueStatusTypeText: "",
      updateToggler: false,
      apiLoading: false,
    }));
  }

  return (
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={12} md={12} item>
          <DelegatedIssueTitle request={request} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <ClientCashDetailsForm
            state={state}
            setState={setState}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <ClientCashDetailsGrid state={state} setState={setState} />
        </Grid>
      </Grid>
    </Grow>
  );
}

export default CashIssueDetails;
