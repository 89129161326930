import AppWithRouter from "./AppWithRouter";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ErrorBoundary } from "react-error-boundary";
import ServerError from "./Components/notFound/ServerError";
import { G2Theme } from "@genre/g2common-theme";
import { ErrorInfo } from "react";
import { commonAxios } from "../services/axios";

type AppProps = {
  pca: IPublicClientApplication;
};

type ErrorPayload = {
  userID: string,
  tag: string,
  message: string
};
const logError = (error: Error, info: ErrorInfo) => {
  const userID = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.currentUser?.id || "";
  const errorPayload:ErrorPayload = {
    userID,
    tag: "UI",
    message: error.message,
  }
  commonAxios.post("/Logging",errorPayload).then(res => console.log(res)).catch(e => console.log(e))
};

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <G2Theme>
        <BrowserRouter>
          <ErrorBoundary fallback={<ServerError />} onError={logError}>
            <AppWithRouter />
          </ErrorBoundary>
        </BrowserRouter>
      </G2Theme>
    </MsalProvider>
  );
}

export default App;
