import React, { useEffect } from "react";
import { CashTrackerProps } from "../../CashTrackerUtils";
import SuspenseForm from "./SuspenseForm";
import { delegatedAxios } from "../../../../../services/axios";
import {
  CustomSnackbar,
  handleErrorResponse,
  ModalErrors,
} from "@genrecp/g2clientportal-common";
import axios from "axios";
import { SuspenseReasonGridItem } from "../../../admin/delegatedIssuesAdmin/suspenseReasons/suspenseReasonUtils";

interface Props extends CashTrackerProps {
}
function SuspenseFormWrapper({ request, dispatch }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  useEffect(() => {
    getSuspenseReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSuspenseReasons() {
    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        suspenseReasonsLoading: true,
      },
    });
    const getSuspenseReasonsQuery = delegatedAxios.get("/SuspenseReason");
    axios
      .all([getSuspenseReasonsQuery])
      .then(
        axios.spread((...res: any) => {
          const { data: suspenseReasons } = res[0] || {};
          const filteredSuspenseReasons = suspenseReasons.filter((item:SuspenseReasonGridItem) => item.active)
          dispatch({
            suspenseForm: {
              ...request.suspenseForm,
              suspenseReasonsLoading: false,
              suspenseReasons: filteredSuspenseReasons || [],
            },
          });
        })
      )
      .catch((e: any) => {
        dispatch({
          suspenseForm: {
            ...request.suspenseForm,
            suspenseReasonsLoading: false,
          },
        });
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  return (
    <React.Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <SuspenseForm request={request} dispatch={dispatch} />
    </React.Fragment>
  );
}

export default SuspenseFormWrapper;
