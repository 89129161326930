import React from "react";
import { Checkbox } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface Props {
  value: boolean;
  isDisabled: boolean;
  onCheckboxChange: (checked: boolean) => void;
}
const CustomCheckboxRenderer: React.FC<Props> = ({
  value,
  onCheckboxChange,
  isDisabled
}) => {
  const [checked, setChecked] = React.useState(value);
  
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked)
    onCheckboxChange(event.target.checked);
  };

  return (
    <Checkbox
      checked={checked}
      disabled={isDisabled}
      {...label}
      onChange={onChange}
      sx={{ top: "-10px"}}
    />
  );
};

export default CustomCheckboxRenderer;
